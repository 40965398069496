<template>
  <div class="w-full flex flex-col px-3">
    <div class="border-b border-slate-200 w-full py-3">
      <h3 class="font-roboto font-bold text-lg">Mot de passe</h3>
    </div>

    <form @submit.prevent="savePassword">
      <div class="mt-5 mb-5">
        <div class="mb-2">
          <div class="w-1/2 mr-1">
            <label
              class="block capitalize tracking-wide text-grey-darker text-xs font-medium mb-1"
              for="grid-phone-1"
              >Actuel mot de passe</label
            >
            <div class="h-10">
              <Field v-slot="{ field }" name="password" v-model="password">
                <input
                  v-bind="field"
                  name="password"
                  v-model="password"
                  class="rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                  type="password"
                  placeholder="Mot de passe"
                />
              </Field>
            </div>
          </div>
        </div>
        <div class="flex mt-3 mb-2">
          <div class="w-1/2 mr-1">
            <label
              class="block capitalize tracking-wide text-grey-darker text-xs font-medium mb-1"
              for="grid-phone-1"
              >Nouveau mot de passe</label
            >
            <div class="h-10">
              <Field
                v-slot="{ field }"
                name="newPassword"
                v-model="newPassword"
              >
                <input
                  v-bind="field"
                  name="newPassword"
                  v-model="newPassword"
                  class="rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                  id="newPassword"
                  type="password"
                  placeholder="Nouveau de passe"
                />
              </Field>
            </div>
          </div>
          <div class="w-1/2 ml-1">
            <label
              class="block capitalize tracking-wide text-grey-darker text-xs font-medium mb-1"
              for="grid-email"
              >Confirmation</label
            >
            <div class="h-10">
              <Field
                v-slot="{ field }"
                name="confirmPassword"
                v-model="confirmPassword"
              >
                <input
                  v-bind="field"
                  autocomplete="off"
                  name="confirmPassword"
                  v-model="confirmPassword"
                  placeholder="Confirmation mot de passe"
                  class="rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                  type="password"
                />
              </Field>
            </div>
          </div>
        </div>

        <div class="mt-5 mb-8">
          <Button
            v-if="!isSubmittingPassword"
            size="sm"
            variant="primary"
            type="submit"
            :disabled="!meta.valid"
            class="border-none rounded bg-primary py-2 px-3 text-white"
          >
            Enrégistrer
          </Button>
          <Button
            v-else
            type="button"
            class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow text-white bg-primary hover:bg-indigo-400 transition ease-in-out duration-150 cursor-not-allowed"
            disabled=""
          >
            <svg
              class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            Sauvegarde en cours...
          </Button>
        </div>

        <div class="flex flex-col">
          <div class="" v-show="passwordError">
            <span class="text-danger text-sm">{{ passwordError }}</span>
          </div>
          <span class="text-danger" v-for="(value, key) in errors" :key="key">{{
            value
          }}</span>
        </div>
      </div>
    </form>
  </div>
  <Toast />
</template>

<script>
  import { computed, defineComponent, ref } from "vue";
  import { Field, useForm } from "vee-validate";
  import * as yup from "yup";
  import Toast from "primevue/toast";
  import Button from "primevue/button";
  import { useToast } from "primevue/usetoast";
  import { useStore } from "vuex";
  import Storage, { storageKeys } from "../../services/Storage";
  export default defineComponent({
    components: { Field, Toast, Button },
    setup() {
      const store = useStore();
      const toast = useToast();
      const isSubmittingPassword = ref(false);
      const passwordError = ref();
      const schema = computed(() =>
        yup.object({
          password: yup
            .string()
            .required("")
            .min(8, "Le mot de passe doit être au moins de 8 caractères"),
          newPassword: yup.string().required(""),
          confirmPassword: yup
            .string()
            .required("")
            .oneOf([yup.ref("newPassword")], "Your passwords do not match."),
        })
      );

      const { meta, errors } = useForm({ validationSchema: schema.value });
      const password = ref("***********");
      const newPassword = ref("");
      const confirmPassword = ref("");

      const savePassword = () => {
        passwordError.value = "";
        if (
          newPassword.value == password.value ||
          newPassword.value.includes(password.value)
        ) {
          passwordError.value =
            "L'ancien mot de passe et le nouveau doivent être différents";
          return;
        }
        isSubmittingPassword.value = true;
        store.dispatch("auth/updatePassword", {
          data: {
            password_confirmation: confirmPassword.value,
            password: newPassword.value,
            old_password: password.value,
          },
          onSuccess: () => {
            isSubmittingPassword.value = false;

            toast.add({
              severity: "success",
              summary: "Profile - Mot de passe",
              detail: "Votre mot de passe a bien été mis à jour",
              life: 3000,
            });

            Storage.remove(storageKeys.authToken);
            window.location.replace("/login");
          },
          onError: () => {
            isSubmittingPassword.value = false;

            toast.add({
              severity: "error",
              summary: "Profile - Mot de passe",
              detail: "Ooops, une erreur est survenue lors de mis à jour",
              life: 5000,
            });
          },
        });
      };
      return {
        confirmPassword,
        newPassword,
        isSubmittingPassword,
        password,
        meta,
        passwordError,
        errors,
        savePassword,
      };
    },
  });
</script>
