<template>
    <component :is="icon" :size="size" />
</template>

<script>
import * as icons from "lucide-vue-next";
import { computed } from "vue";
export default {
    name: "Icon",
    props: {
        name: {
            type: String,
            required: true,
        },
        size: {
            type: Number,
            required: false,
            default: 24,
        },
    },
    setup(props) {
        const icon = computed(
            () =>
                icons[
                    `${props.name.charAt(0)?.toUpperCase()}${props.name.slice(
                        1
                    )}`
                ]
        );
        return { icon };
    },
};
</script>
