<template>
  <div v-if="lastPage > 1">
    <el-pagination
      v-model:current-page="currentPage"
      :page-size="size"
      :small="false"
      :disabled="false"
      :background="true"
      layout="total, prev, pager, next"
      :total="total"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
  import { ref, watch, computed, onMounted } from "vue";
  import { ElPagination } from "element-plus";
  export default {
    components: { ElPagination },
    name: "Pagination",
    props: {
      pagination: {
        type: Object,
        default: {
          current_page: 1,
          last_page: 1,
          from: 0,
          to: 0,
          total: 0,
        },
      },
      page: { type: Number, default: 1 },
    },
    emits: ["changed"],
    setup(props, { emit }) {
      const currentPage = ref(props.pagination.page || 1);
      const lastPage = computed(() => props.pagination.lastPage || 1);
      const size = computed(() => props.pagination.perPage);
      const total = computed(() => props.pagination.total || 9);
      const handleChange = (page) => {
        emit("changed", page);
      };

      const handleCurrentChange = (value, data) => {
        emit("changed", value);
      };
      watch(
        () => props.pagination.page,
        (newPage) => {
          currentPage.value = newPage;
        }
      );

      return {
        handleChange,

        currentPage,
        total,
        size,
        lastPage,
        handleCurrentChange,
      };
    },
  };
</script>
