<template>
  <div class="w-full flex justify-center">
    <div class="w-9/12 mt-10">
      <div class="flex justify-center text-slate-200" v-if="isLoading">
        <Loader></Loader>
      </div>

      <div v-else-if="currentAppointment?.id">
        <div class="my-5 sticky top-0">
          <div
            class="w-10 h-10 cursor-pointer flex justify-center items-center bg-white rounded-full sticky top-0 shadow-md"
            @click.prevent="$router.push({ name: 'patient.appointments' })"
          >
            <i class="la la-arrow-left text-xl text-black"></i>
          </div>
        </div>
        <appointment
          :appointment="currentAppointment"
          @updateAppointment="updateAppointment"
        ></appointment>
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent, onMounted, ref } from "vue";
  import { useRoute } from "vue-router";
  import { useStore } from "vuex";
  import Appointment from "./appointment.vue";
  import Loader from "@/components/Loader/index.vue";

  export default defineComponent({
    components: { Appointment, Loader },
    setup(props) {
      const store = useStore();
      const route = useRoute();
      const isLoading = ref(false);
      const currentAppointment = ref(null);
      const getAppointment = () => {
        isLoading.value = true;
        store.dispatch("medical/getAppointment", {
          appointmentId: route.params.appointmentId,
          onSuccess: (response) => {
            currentAppointment.value = { ...response };
            isLoading.value = false;
          },
          onError: (error) => {
            isLoading.value = false;
          },
        });
      };

      const updateAppointment = (id) => {
        getAppointment(id);
      };

      onMounted(() => {
        getAppointment();
      });

      return { currentAppointment, isLoading, updateAppointment };
    },
  });
</script>