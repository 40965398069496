<template>
    <div class="w-full">
        <b-row>
            <b-col md="12">
                <div class="rounded-sm bg-white flex p-3 mb-5">
                    <div class="d-flex flex-row justify-content-between mb-3">
                        <div>
                            <h4 class="card-title">
                                Les prescriptions medicales
                            </h4>
                        </div>
                    </div>
                    <div>
                        <b-row>
                            <b-col md="12" class="table-responsive">
                                <b-table
                                    striped
                                    thead-class="text-md font-normal"
                                    class="text-sm"
                                    hover
                                    :items="formattedPrescriptions"
                                    :fields="columns"
                                >
                                    <template v-slot:cell(identifier)="data">
                                        <div
                                            class="d-flex justify-content-start align-items-center"
                                        >
                                            <span>{{ data.item.code }}</span>
                                        </div>
                                    </template>
                                    <template v-slot:cell(name)="data">
                                        <span v-if="!data.item.editable">{{
                                            data.item.name
                                        }}</span>
                                    </template>
                                    <template v-slot:cell(type)="data">
                                        <span v-if="!data.item.editable">{{
                                            $t(
                                                `prescription.${data.item.type}.label`
                                            )
                                        }}</span>
                                    </template>
                                    <template v-slot:cell(patient)="data">
                                        <span v-if="!data.item.editable">{{
                                            data.item.patient.full_name
                                        }}</span>
                                    </template>
                                    <template v-slot:cell(doctor)="data">
                                        <span v-if="!data.item.editable">{{
                                            data.item.doctor.full_name
                                        }}</span>
                                    </template>
                                    <template v-slot:cell(center)="data">
                                        <span v-if="!data.item.editable">{{
                                            data.item.medical_center.name
                                        }}</span>
                                    </template>
                                    <template v-slot:cell(createdAt)="data">
                                        <span v-if="!data.item.editable">{{
                                            data.item.created_at
                                        }}</span>
                                    </template>
                                    <template v-slot:cell(action)="data">
                                        <b-button
                                            @click="showModal(data.item)"
                                            variant=" iq-bg-primary mr-1 mb-1"
                                            size="sm"
                                            ><i
                                                class="las la-arrow-right m-0"
                                            ></i
                                        ></b-button>
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-modal
            ref="new-details"
            id="new-details"
            size="xl"
            centered
            :title="`Detail de la prescription > ${
                currentPrescription ? currentPrescription.code : ''
            }`"
        >
            <div class="w-full">
                <div class="flex">
                    <div class="w-2/5" v-if="currentPrescription">
                        <div class="py-3">
                            <h3 class="text-sm font-medium text-primary-700">
                                {{ currentPrescription.medical_center.name }} /
                                {{
                                    currentPrescription.medical_center
                                        .identifier
                                }}
                            </h3>
                            <address
                                class="mb-0"
                                v-if="currentPrescription.medical_center"
                            >
                                {{ currentPrescription.medical_center.address }}
                            </address>
                            <span v-if="currentPrescription.medical_center"
                                >+{{
                                    currentPrescription.medical_center.phone_1
                                }}
                                / +{{
                                    currentPrescription.medical_center.phone_2
                                }}</span
                            >
                        </div>
                        <div class="w-11/12 border bg-gray-200 flex p-2">
                            <div
                                class="border-2 border-primary-600 h-20 w-20 rounded-full overflow-hidden"
                            >
                                <img src="/images/08.jpg" alt="" srcset="" />
                            </div>
                            <div class="ml-2">
                                <h3
                                    class="text-primary-600 text-sm font-bold mt-1"
                                >
                                    {{ currentPrescription.patient.full_name }}
                                </h3>
                                <h5 class="text-black mt-1 text-sm">
                                    <strong>Patient ID:</strong>
                                    {{ currentPrescription.patient.username }}
                                </h5>
                                <h4 class="text-gray-700 text-xs">
                                    Age:
                                    {{
                                        currentPrescription?.patient?.profile
                                            ?.birth_date
                                    }}
                                    ans
                                </h4>
                            </div>
                        </div>

                        <div>
                            <div
                                class="w-11/12 border bg-gray-200 flex p-2 mt-3"
                            >
                                <div
                                    class="border-2 border-primary-600 h-20 w-20 rounded-full overflow-hidden"
                                >
                                    <img
                                        src="/images/05.jpg"
                                        alt=""
                                        srcset=""
                                    />
                                </div>
                                <div class="ml-2">
                                    <h3
                                        class="text-primary-600 text-sm font-bold mt-1"
                                    >
                                        {{
                                            currentPrescription.doctor.full_name
                                        }}
                                    </h3>
                                    <h5 class="text-black mt-1 text-sm">
                                        <strong>Patient ID:</strong>
                                        {{
                                            currentPrescription.doctor.username
                                        }}
                                    </h5>
                                </div>
                            </div>

                            <h5 class="text-sm py-3">
                                Date d'emission :
                                {{ currentPrescription?.created_at }}
                            </h5>
                        </div>
                    </div>
                    <div class="w-3/5" v-if="currentPrescription">
                        <div
                            v-for="item in currentPrescription.items"
                            :key="item.id"
                            class="border-gray-100 border p-2"
                        >
                            <h5 class="text-sm py-2 uppercase font-medium">
                                {{ item.name }}
                            </h5>
                            <div class="flex">
                                <span>{{ item.dosage }}</span
                                ><span class="px-1">|</span
                                ><span>{{ item.duration }}</span>
                            </div>
                            <h6 class="text-sm">{{ item.notes }}</h6>
                        </div>
                        <p class="py-3">{{ currentPrescription.notes }}</p>
                    </div>
                </div>
            </div>
            <template #modal-footer="{ cancel }">
                <b-button
                    size="sm"
                    variant="outline-danger"
                    @click="
                        currentPrescription = null;
                        cancel();
                    "
                >
                    Fermer
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import HereMap from "@/components/maps/map";
import { mapGetters } from "vuex";
import moment from "moment";
export default {
    async beforeRouteEnter(to, from, next) {
        next();
    },
    components: {
        HereMap,
    },
    watch: {},
    data() {
        return {
            map: null,

            center: {
                lat: 40.73061,
                lng: -73.935242,
            },
            currentPrescription: null,
            columns: [
                {
                    label: "ID",
                    key: "identifier",
                    class: "text-sm-left font-weight-normal",
                    thClass: "font-normal text-primary-800",
                },
                {
                    label: "Type",
                    key: "type",
                    class: "text-sm-left font-weight-normal",
                    thClass: "font-normal text-primary-800",
                },
                {
                    label: "Patient",
                    key: "patient",
                    class: "text-sm-left font-weight-normal",
                    thClass: "font-normal text-primary-800",
                },
                {
                    label: "Medecin",
                    key: "doctor",
                    class: "text-sm-left font-weight-normal",
                    thClass: "font-normal text-primary-800",
                },
                {
                    label: "Hopital",
                    key: "center",
                    class: "text-sm-left font-weight-normal",
                    thClass: "font-normal text-primary-800",
                },
                {
                    label: "Date de creation",
                    key: "createdAt",
                    class: "text-sm-left font-weight-normal",
                },
                {
                    label: "",
                    key: "action",
                    class: "text-sm-left font-weight-normal",
                },
            ],
        };
    },
    methods: {
        showModal(data) {
            this.currentPrescription = { ...data };
            this.$refs["new-details"].show();
        },
        updateMap(map) {
            this.map = map;
        },
    },
    computed: {
        ...mapGetters({
            resourceTypes: "config/resourceTypes",
            resourcesList: "config/resources",
            prescriptions: "medical/prescriptions",
            patient: "medical/patient",
        }),
        formattedPrescriptions() {
            const values = this.prescriptions.sort(
                (a, b) => moment.unix(b.created_at) - moment.unix(a.created_at)
            );
            return values;
        },
    },
    mounted() {
        this.$store.dispatch("medical/getPrescriptions");
    },
};
</script>
