<template>
    <div class="relative">
        <div class="loader"></div>
    </div>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({});
</script>

<style lang="scss" scoped>
.loader {
    width: 60px;
    height: 60px;
}

.loader::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border-top: 2px solid currentColor;
    border-right: 2px solid transparent;
    animation: spinner8217 0.8s linear infinite;
}

@keyframes spinner8217 {
    to {
        transform: rotate(360deg);
    }
}
</style>
