<template>
    <Head>
        <meta name="robots" content="index,follow" />
        <title>Allô Doto - Notre raison d'être</title>
        <meta
            head-key="description"
            name="description"
            :content="`La technologie au service de la santé des Africains.Gagnez en temps et en efficacité avec nous à vos côtés pour une meilleure santé`"
        />
    </Head>
    <app-bar></app-bar>

    <div
        class="w-full flex flex-col justify-center items-center mt-10"
        id="notice"
    >
        <div
            class="flex lg:flex-row md:flex-row xl:flex-row sm:flex-col xs:flex-col justify-between items-center w-10/12 py-20"
        >
            <div class="w-1/2 md:w-1/2 xs:w-full sm:w-full">
                <h1
                    class="text-5xl text-white font-bold font-qs leading-tight bg-primary px-3"
                >
                    La technologie au service de la santé des Africains
                </h1>
                <p class="py-5 text-slate-600 text-md font-medium">
                    Faciliter l'accès au soins pour chacun quelque soit sa
                    position géographique sur le continent et à des soins de
                    qualité tout en améliorant le service en cabinet en
                    réduisant le temps d'attente, facilitant le rapport avec la
                    patientèle
                </p>
            </div>
            <div class="w-1/4 md:w-2/5 xs:w-full sm:w-full">
                <img src="/images/teams.svg" alt="" />
            </div>
        </div>

        <div class="w-10/12 flex justify-start items-center py-20">
            <h1 class="text-4xl font-bold text-left text-slate-500">
                Nos souhaits :
            </h1>
        </div>

        <div
            class="w-3/4 md:w-3/4 flex md:flex-row sm:flex-col xs:flex-col justify-between items-center py-20"
        >
            <div class="w-2/4 md:w-2/5 xs:w-full sm:w-full">
                <img src="/images/doctors_1.svg" alt="" />
            </div>

            <div
                class="w-1/2 md:w-1/2 xs:w-full sm:w-full flex flex-col justify-start"
            >
                <h1
                    class="text-2xl font-bold w-1/2 text-primary2 font-montserrat flex"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="50"
                        height="50"
                        fill="currentColor"
                        class="bi bi-1-circle-fill mr-3"
                        viewBox="0 0 16 16"
                    >
                        <path
                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M9.283 4.002H7.971L6.072 5.385v1.271l1.834-1.318h.065V12h1.312z"
                        /></svg
                    ><span>Améliorer le service en cabinet</span>
                </h1>

                <p class="py-5 text-slate-700">
                    En mettant à votre disposition de nouvelles technologies,
                    nous souhaitons vous aider à :
                </p>

                <ul class="list-disc px-5 ml-5" style="list-style-type: disc">
                    <li class="text-slate-700 py-1">
                        Gagner du temps médical et profiter d’une nouvelle
                        expérience de travail
                    </li>

                    <li class="text-slate-700 py-1">Augmenter vos revenus</li>
                    <li class="text-slate-700 py-1">
                        Offrir de nouveaux services à vos patients
                    </li>
                    <li class="text-slate-700 py-1">
                        Mettre en place de nouveaux moyens de prise en charge de
                        vos patients (à distance, de manière préventive, en
                        coopération avec des confrères ou en continu)
                    </li>
                    <li class="text-slate-700 py-1">
                        Prendre de meilleures décisions cliniques
                    </li>

                    <li class="text-slate-700 py-1">
                        Dévélopper votre patientèle
                    </li>
                </ul>
            </div>
        </div>

        <div
            class="w-3/4 md:w-3/4 md:flex-row flex sm:flex-col xs:flex-col justify-between items-center py-20"
        >
            <div
                class="w-1/2 md:w-1/2 xs:w-full sm:w-full flex flex-col justify-end"
            >
                <h1
                    class="text-2xl font-bold w-1/2 xs:w-full sm:w-full text-primary font-montserrat flex"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="50"
                        height="50"
                        fill="currentColor"
                        class="bi bi-2-circle-fill mr-3"
                        viewBox="0 0 16 16"
                    >
                        <path
                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M6.646 6.24c0-.691.493-1.306 1.336-1.306.756 0 1.313.492 1.313 1.236 0 .697-.469 1.23-.902 1.705l-2.971 3.293V12h5.344v-1.107H7.268v-.077l1.974-2.22.096-.107c.688-.763 1.287-1.428 1.287-2.43 0-1.266-1.031-2.215-2.613-2.215-1.758 0-2.637 1.19-2.637 2.402v.065h1.271v-.07Z"
                        /></svg
                    ><span>Faciliter l'accès aux soins pour tous</span>
                </h1>

                <p class="py-5 text-slate-700">
                    Nos solutions permettent à vos patients de :
                </p>

                <ul class="list-disc px-5 ml-5" style="list-style-type: disc">
                    <li class="text-slate-700 py-1">
                        Accéder facilement aux meilleurs spécialistes
                    </li>

                    <li class="text-slate-700 py-1">
                        Gérer leur santé plus facilement
                    </li>
                    <li class="text-slate-700 py-1">
                        Recevoir des soins adaptés
                    </li>
                    <li class="text-slate-700 py-1">
                        Recevoir des soins continus
                    </li>
                </ul>
            </div>

            <div class="w-2/4 md:w-2/5 xs:w-full sm:w-full">
                <img src="/images/patients.svg" alt="" />
            </div>
        </div>

        <div class="w-10/12 flex justify-start items-center py-20">
            <h1 class="text-4xl font-bold text-left text-slate-500">
                Nos engagements :
            </h1>
        </div>

        <div
            class="flex sm:flex-col md:flex-row xs:flex-col w-10/12 justify-between mb-20 mx-auto"
        >
            <div class="w-1/3 xs:w-full sm:w-full">
                <div class="w-11/12 p-3">
                    <h1 class="py-3 font-bold text-xl font-montserrat">
                        Co-construction
                    </h1>
                    <p class="text- font-qs text-slate-600">
                        À l'écoute de vos problèmes et vos besoins, nous
                        tacheroons d'oeuvrer afin d'améliorer la plateforme en
                        mettant toujours à votre disposition plus de
                        fonctionnalités pour simplifier votre quotidien
                    </p>
                </div>
            </div>

            <div class="w-1/3 xs:w-full sm:w-full">
                <div class="w-11/12 p-3">
                    <h1 class="py-3 font-bold text-xl font-montserrat">
                        Accessibilité
                    </h1>
                    <p class="text- font-qs text-slate-600">
                        Nous vous garantissons un accès à nos différents
                        services via nos différents canaux afin de remplir nos
                        missions dans les meilleures conditions.Les praticiens
                        et leurs équipes doivent pouvoir gérer leur patientèle
                        en toute autonomie.
                    </p>
                </div>
            </div>
            <div class="w-1/3 xs:w-full sm:w-full">
                <div class="w-11/12 p-3">
                    <h1 class="py-3 font-bold text-xl font-montserrat">
                        Confidentialité
                    </h1>
                    <p class="text- font-qs text-slate-600">
                        Nous appliquons un large éventail de mesures de
                        protection, nos données sont stockées et traitées dans
                        le respect des réglementations nationales des pays
                        africains dans lesquels nous oeuvrons.
                    </p>
                </div>
            </div>
        </div>
    </div>

    <Footer></Footer>
</template>

<script>
import { Head } from "@inertiajs/vue3";
import Home from "@/components/home/index.vue";
import Footer from "@/components/footer/index.vue";
export default {
    components: { Home, Footer, Head },
    setup() {},
};
</script>
