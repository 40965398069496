<template>
  <div>
    <div class="bg-white rounded p-2 mt-4">
      <h4 class="font-bold">Prescriptions</h4>

      <div class="flex justify-start my-5">
        <div
          v-for="p in lastPrescriptions"
          :key="p.id"
          class="w-1/ flex flex-col bg-primary-100 rounded border border-slate-400 p-3 mr-2"
        >
          <h5 class="underline text-left">{{ p.name }}</h5>
          <span class="text-sm text-left">{{ p.code }}</span>
          <div class="flex items-center text-gray-400 text-xs my-2">
            <p class="text-gray-400 text-left">
              <span v-if="p.items[0].from">{{
                moment(p.items[0].from).format("l")
              }}</span>
              <span v-else>-</span>
            </p>
            <i class="fa fa-arrow-right text-gray-400 mx-2"></i>
            <p class="text-gray-400">
              <span v-if="p.items[0].to">{{
                moment(p.items[0].to).format("l")
              }}</span>
              <span v-else>-</span>
            </p>
          </div>

          <div class="flex justify-start items-center w-full">
            <div
              class="w-6 h-6 flex justify-center items-center bg-slate-400 rounded-sm"
            >
              <i
                :class="`las la-${
                  p.type === 'examen' ? 'microscope' : 'capsules'
                } text-lg text-white`"
              ></i>
            </div>
            <div class="flex flex-col ml-2">
              <h5 class="text-sm" v-for="item in p.items" :key="item.id">
                {{ item.name }}
              </h5>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full">
        <button
          @click="showModal"
          class="border-slate-400 px-3 border py-2 rounded font-semibold text-slate-400"
        >
          <i class="la la-plus"></i> Nouvelle prescription
        </button>
      </div>
    </div>
    <modal
      id="show-modal"
      title="Nouvelle prescription"
      size="4xl"
      :isVisible="displayModal"
      @close="showModal"
      @submit="registerPrescription"
      @cancel="showModal"
    >
      <template #content>
        <div class="flex p-2">
          <div class="w-2/5">
            <div class="w-11/12 border bg-gray-200 flex p-2">
              <div
                class="border-2 border-primary-600 h-20 w-20 rounded-full overflow-hidden"
              >
                <img :src="patient.avatar" alt="" srcset="" />
              </div>
              <div class="ml-2">
                <h3 class="text-primary-600 text-md font-bold mt-1">
                  {{ patient.full_name }}
                </h3>
                <h5 class="text-black mt-1 text-sm">
                  <strong>Patient ID:</strong>
                  {{ patient.username }}
                </h5>
                <h4 v-if="age" class="text-gray-700 text-xs">
                  Âge:
                  {{ age }}
                  ans
                </h4>
                <span v-else>-</span>
              </div>
            </div>
          </div>
          <div class="w-3/5">
            <form action="">
              <div class="">
                <div class="flex flex-col w-1/2 mb-2">
                  <label
                    class="block capitalize tracking-wide mb-1 text-xs text-left"
                    for="grid-profession"
                  >
                    Centre médical
                  </label>
                  <Field
                    v-slot="{ field }"
                    name="hospital"
                    v-model="prescription.hospital"
                  >
                    <Select
                      v-bind="field"
                      name="hospital"
                      v-model="prescription.hospital"
                      placeholder="Centre médical"
                      :options="
                        hospitals.map((x) => ({
                          ...x,
                          name: x.name,
                        }))
                      "
                      @changed="([type]) => (prescription.hospital = type)"
                    ></Select
                  ></Field>
                </div>
              </div>
              <div class="flex w-full">
                <div class="w-full mb-3 md:mb-0">
                  <label
                    class="block capitalize tracking-wide text-xs text-left mb-1"
                    for="grid-profession"
                  >
                    Nom ordonnance
                  </label>
                  <Field
                    v-slot="{ field }"
                    name="name"
                    v-model="prescription.name"
                  >
                    <input
                      v-bind="field"
                      name="name"
                      v-model="prescription.name"
                      class="w-full bg-slate-100 h-10 rounded border border-slate-300 h-10 w-full px-2 focus:border-slate-400 transition transition-all"
                      id="name"
                      type="text"
                      placeholder="Ex:Ordonnance d'imagerie"
                    />
                  </Field>
                </div>
              </div>
              <div class="flex my-3">
                <div class="flex flex-col w-1/2">
                  <label
                    class="block capitalize tracking-wide mb-1 text-xs text-left"
                    for="grid-profession"
                  >
                    Type d'ordonnance
                  </label>
                  <div class="h-10">
                    <Field
                      v-slot="{ field }"
                      name="type"
                      v-model="prescription.type"
                    >
                      <Select
                        v-bind="field"
                        name="type"
                        v-model="prescription.type"
                        placeholder="Type"
                        :options="types"
                        @changed="([type]) => (prescription.type = type)"
                      ></Select
                    ></Field>
                  </div>
                </div>
                <div class="flex flex-col w-1/2 ml-2">
                  <label
                    class="block capitalize tracking-wide mb-1 text-xs text-left"
                    for="grid-profession"
                  >
                    Médécin prescripteur
                  </label>
                  <Field
                    v-slot="{ field }"
                    name="doctor"
                    v-model="prescription.doctor"
                  >
                    <Select
                      v-bind="field"
                      name="doctor"
                      v-model="prescription.doctor"
                      placeholder="Médecin prescripteur"
                      :options="
                        doctors.map((x) => ({
                          ...x,
                          name: x.full_name,
                        }))
                      "
                      @changed="([type]) => (prescription.doctor = type)"
                    ></Select
                  ></Field>
                </div>
              </div>

              <FieldArray
                name="items"
                key-path="id"
                v-slot="{ fields, push, remove }"
              >
                <div
                  class="w-full flex mt-2"
                  :key="item.id"
                  v-for="(item, index) in fields"
                >
                  <div class="w-1/12">
                    <h6
                      class="mt-3 w-8 h-8 rounded-full bg-gray-400 flex justify-center items-center text-white font-bold"
                    >
                      {{ index + 1 }}
                    </h6>
                  </div>
                  <div class="w-10/12">
                    <div class="w-full px-3 mb-1 md:mb-0">
                      <label
                        class="block capitalize text-xs mb-1 text-left"
                        for="grid-profession"
                      >
                        {{
                          $t(
                            `prescription.${
                              prescription.type.id || "pharmaceuticals"
                            }.name`
                          )
                        }}
                      </label>
                      <Field v-slot="{ field }" :name="`items[${index}].name`"
                        ><input
                          v-bind="field"
                          :name="`items[${index}].name`"
                          class="bg-slate-100 h-10 rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                          type="text"
                          :placeholder="
                            $t(
                              `prescription.${
                                prescription.type.id || 'pharmaceuticals'
                              }.name`
                            )
                          "
                      /></Field>
                    </div>
                    <div class="w-full px-3 mb-1 md:mb-0 mt-2">
                      <label
                        class="block capitalize text-xs mb-1 text-left"
                        for="grid-profession"
                      >
                        {{
                          $t(
                            `prescription.${
                              prescription.type.id || "pharmaceuticals"
                            }.dosage`
                          )
                        }}
                      </label>
                      <Field v-slot="{ field }" :name="`items[${index}].dosage`"
                        ><input
                          v-bind="field"
                          :name="`items[${index}].dosage`"
                          class="bg-slate-100 h-10 rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                          id="insurance_provider"
                          type="text"
                          :placeholder="
                            $t(
                              `prescription.${
                                prescription.type?.id || 'pharmaceuticals'
                              }.dosage`
                            )
                          "
                      /></Field>
                    </div>
                    <div
                      class="flex flex-col px-3 mt-2"
                      v-if="prescription.type?.id === 'pharmaceuticals'"
                    >
                      <label
                        class="block capitalize text-xs text-left"
                        for="grid-profession"
                      >
                        Durée de prise
                      </label>
                      <div class="flex">
                        <Field
                          v-slot="{ field }"
                          :name="`items[${index}].duration.value`"
                          ><input
                            v-bind="field"
                            :name="`items[${index}].duration.value`"
                            class="w-1/3 rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all mr-2"
                            id="insurance_provider"
                            type="number"
                            placeholder="Nombre de..."
                        /></Field>
                        <Field
                          v-slot="{ field }"
                          :name="`items[${index}].duration.type`"
                          ><Select
                            :name="`items[${index}].duration.type`"
                            v-bind="field"
                            placeholder="Durée de prise"
                            :options="durations"
                            @changed="
                              ([type]) =>
                                (prescription.items[index].duration.type = type)
                            "
                          >
                          </Select
                        ></Field>
                      </div>
                    </div>
                    <div class="w-full px-3 mt-2">
                      <label
                        class="block capitalize text-xs text-left mb-1"
                        for="grid-profession"
                      >
                        Notes
                      </label>
                      <Field v-slot="{ field }" :name="`items[${index}].notes`">
                        <textarea
                          v-bind="field"
                          :name="`items[${index}].notes`"
                          :id="`notes-${index}`"
                          placeholder="Notes..."
                          class="w-full px-2 resize-none h-10 rounded border border-slate-300 h-20 py-2 w-full px-2 focus:border-slate-400 transition transition-all"
                        ></textarea>
                      </Field>
                    </div>
                  </div>
                  <div class="w-1/12 flex flex-col justify-end items-end">
                    <button
                      v-if="fields.length != 1"
                      class="flex justify-center items-center bg-danger text-danger bg-opacity-25 hover:bg-opacity-50 rounded-full h-11 w-11 font-bold mb-2"
                      @click.prevent="remove(index)"
                    >
                      <i class="la la-minus text-lg"></i>
                    </button>
                    <button
                      v-if="index === fields.length - 1"
                      class="flex justify-center items-center bg-success bg-opacity-25 text-success rounded-full h-11 w-11 font-bold"
                      @click.prevent="
                        push({
                          ...itemDefault,
                          id: Date.now(),
                        })
                      "
                    >
                      <i class="la la-plus text-lg"></i>
                    </button>
                  </div>
                </div>
              </FieldArray>
              <div class="w-full mt-2">
                <label
                  class="block capitalize text-xs text-left"
                  for="grid-profession"
                >
                  Notes
                </label>
                <textarea
                  id="textarea"
                  v-model="prescription.notes"
                  placeholder="Note globale..."
                  class="w-full h-20 resize-none rounded border border-slate-300 w-full py-2 px-2 focus:border-slate-400 transition transition-all"
                ></textarea>
              </div>
            </form>
          </div>
        </div>
      </template>
    </modal>
  </div>
</template>
<script>
  import { mapGetters, useStore } from "vuex";
  import { ref, computed, onBeforeMount } from "vue";
  import moment from "moment";
  import Select from "../../components/Select/index.vue";
  import * as yup from "yup";

  import { Field, FieldArray, useForm } from "vee-validate";
  export default {
    components: { Field, Select, FieldArray },
    data() {
      return {};
    },
    setup() {
      const prescription = ref({
        name: "",
        type: {
          id: "pharmaceuticals",
          name: "Médicaments pharmaceutiques",
        },
        notes: "",
        doctor_id: "",
        hospital: null,
        items: [
          {
            name: "",
            notes: "",
            dosage: "",
            duration: {
              value: 5,
              type: "days",
            },
          },
        ],
      });
      const types = ref([
        { id: "examen", name: "Examen(s) bilogique(s)" },
        { id: "pharmaceuticals", name: "Médicaments pharmaceutiques" },
      ]);
      const schema = yup.object({
        name: yup.string().required("Entrer le nom de l'ordonance"),
        type: yup.object({ id: yup.string() }).required("Choisissez le type"),
        doctor: yup
          .object({ id: yup.string() })
          .required("Choisissez le médécin traitant"),
        items: yup
          .array()
          .of(
            yup.object({
              name: yup.string().required("Entrer le nom du produit"),
              dosage: yup.string().optional(),
            })
          )
          .min(1),
      });
      const itemDefault = ref({
        id: 1,
        name: "",
        duration: { type: { id: "days", name: "Jour(s)" }, value: 5 },
        dosage: "",
      });
      const initialValues = ref({
        type: {
          id: "pharmaceuticals",
          name: "Médicaments pharmaceutiques",
        },
        items: [itemDefault.value],
      });
      const { values, meta, handleReset } = useForm({
        validationSchema: schema,
        initialValues: initialValues.value,
      });
      const durations = ref([
        { id: "days", name: "Jour(s)" },
        { id: "weeks", name: "Semaine(s)" },
        { id: "months", name: "Mois" },
        { id: "years", name: "Annees" },
      ]);
      const store = useStore();
      const portfolio = computed(() => store.getters["auth/portfolio"]);
      const hospitals = computed(() =>
        store.getters["medical/hospitals"].map((x) => ({
          id: x.id,
          name: x.name,
        }))
      );
      const patient = computed(() => store.getters["medical/patient"]);
      const displayModal = ref(false);
      const showModal = () => {
        displayModal.value = !displayModal.value;
      };
      const age = computed(() =>
        Math.round(moment().diff(patient.value.birth_date, "years", true))
      );

      const registerPrescription = () => {
        const formData = {
          ...values,
          type: prescription.value.type.id,
          doctor_id: prescription.value.doctor.id,
          patient_id: patient.value.id,
          medical_center_id: prescription.value.hospital.id,
          items: values.items.map((x) => ({
            ...x,
            duration: `${x?.duration?.value} ${x?.duration?.type?.id}`,
          })),
        };

        store.dispatch("medical/savePrescription", {
          patientId: patient.value.id,
          data: formData,
          onSuccess: () => {
            handleReset();
            prescription.value = initialValues.value;
          },
          onError: () => {},
        });
      };

      const getHospitals = () => {
        store.dispatch("medical/getHospitals", {
          filter: { portfolioId: portfolio.value.id },
        });
      };

      onBeforeMount(() => {
        store.dispatch("medical/getDoctors", {
          filters: { portfolioId: portfolio.value?.id },
        });

        getHospitals();
      });

      return {
        showModal,
        registerPrescription,
        moment,
        displayModal,
        age,
        types,
        durations,
        values,
        prescription,
        hospitals,
        meta,
        itemDefault,
      };
    },
    methods: {
      removePrescriptionItem(index) {
        if (index === 0) return;
        this.prescription.items = (this.prescription.items || []).filter(
          (x, i) => i !== index
        );
      },
      addNewPrescriptionItem() {
        this.prescription.items.push({
          name: "",
          notes: "",
          dosage: "",
          duration: {
            value: 5,
            type: "days",
          },
        });
      },
    },
    computed: {
      ...mapGetters({
        patient: "medical/patient",
        doctors: "medical/doctors",
        prescriptions: "medical/prescriptions",
      }),

      lastPrescriptions() {
        return (
          (this.prescriptions || [])
            //   .filter((x) => x.type === "pharmaceuticals")
            .sort((a, b) =>
              moment.utc(b.created_at).diff(moment.utc(a.created_at))
            )
            .slice(0, 3)
        );
      },
    },
  };
</script>
