<template>
    <div class="w-full">
        <div class="my-5 flex">
            <div class="w-1/4 h-10 mr-2">
                <Calendar
                    selectionMode="range"
                    v-model="date"
                    dateFormat="dd/mm/yy"
                    showButtonBar
                    inputClass="rounded border border-slate-300 w-full h-full w-full px-2 shadow-none focus:border-slate-400 transition transition-all"
                    placeholder="Date de facturation"
                    class="w-full h-full"
                    @clear-click="date = []"
                />
            </div>
            <div class="w-1/4 h-10">
                <InputText
                    v-model="search"
                    type="text"
                    name="search"
                    id="search"
                    placeholder="Rechercher..."
                    class="rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                />
            </div>
        </div>

        <div class="w-full bg-white rounded p-4 my-4">
            <div><h3 class="text-lg font-semibold">Les factures</h3></div>
            <div v-if="isLoading" class="flex justify-center text-primary2">
                <Loader class="w-20" />
            </div>
            <div v-else class="mt-5">
                <DataTable
                    :value="products"
                    stripedRows
                    tableStyle="text-xs text-slate-500"
                    class="text-sm text-slate-500"
                    ><template #empty>Aucune facture</template>
                    <Column
                        header="Identifiant"
                        style="font-size: 14px; font-weight: 500"
                    >
                        <template #body="slotProps">
                            {{ slotProps.data.identifier }}
                        </template>
                    </Column>
                    <Column
                        field="patient"
                        header="Patient"
                        style="font-size: 14px; font-weight: 500"
                    >
                        <template #body="slotProps">
                            <div class="flex items-center">
                                <img
                                    :src="`${slotProps.data.invoiceable.patient.avatar}`"
                                    :alt="slotProps.data.invoiceable.image"
                                    class="w-10 h-10 rounded-full overflow-hidden shadow-2 border-round"
                                />
                                <div class="ml-2">
                                    <h3>
                                        {{
                                            slotProps.data?.invoiceable.patient
                                                ?.full_name
                                        }}
                                    </h3>
                                    <span class="text-slate-500 text-xs">{{
                                        slotProps.data?.invoiceable.patient
                                            ?.username
                                    }}</span>
                                </div>
                            </div></template
                        ></Column
                    >

                    <Column
                        field="date"
                        header="Date de paiement"
                        style="font-size: 14px; font-weight: 500"
                    >
                        <template #body="slotProps">
                            <div
                                class="flex flex-col"
                                v-if="slotProps.data?.transaction"
                            >
                                <span class="text-sm capitalize">{{
                                    moment(
                                        slotProps.data?.transaction?.created_at
                                    ).format("dddd DD MMMM")
                                }}</span>
                                <span class="text-semibold text-sm">{{
                                    moment(
                                        slotProps.data?.transaction?.created_at
                                    ).format("HH:mm")
                                }}</span>
                            </div>
                            <p v-else class="text-sm text-slate-500">-</p>
                        </template>
                    </Column>

                    <Column
                        field="center"
                        header="Montant"
                        style="font-size: 14px; font-weight: 500"
                    >
                        <template #body="slotProps">
                            <div
                                class="ml-2"
                                v-if="
                                    slotProps.data?.transaction?.paid_amount >=
                                    0
                                "
                            >
                                <h3
                                    class="text-slate-500 text-xs font-semibold"
                                >
                                    {{
                                        slotProps.data?.transaction?.paid_amount
                                    }}
                                    {{
                                        slotProps.data?.transaction
                                            ?.paid_amount >= 0
                                            ? "f CFA"
                                            : ""
                                    }}
                                </h3>
                            </div>
                            <p v-else class="text-sm text-slate-500">-</p>
                        </template>
                    </Column>
                    <Column
                        field="service"
                        header="Transaction"
                        style="font-size: 14px; font-weight: 500"
                    >
                        <template #body="slotProps">
                            <div
                                class="flex items-center"
                                v-if="slotProps.data?.transaction"
                            >
                                <div class="ml-2">
                                    <h3>
                                        {{
                                            slotProps.data?.transaction
                                                ?.identifier
                                        }}
                                    </h3>
                                    <span class="text-slate-500 text-xs"
                                        >{{
                                            slotProps.data?.transaction
                                                ?.partner_transaction_id
                                        }}
                                    </span>
                                    <span v-if="slotProps.data?.transaction"
                                        >({{
                                            slotProps.data?.transaction
                                                ?.partner_name
                                        }})</span
                                    >
                                </div>
                            </div>
                            <p v-else class="text-sm text-slate-500">-</p>
                        </template>
                    </Column>
                    <Column
                        header="Status"
                        style="font-size: 14px; font-weight: 500"
                    >
                        <template #body="slotProps">
                            <Tag
                                class="bg-opacity-25 text-sm"
                                v-if="slotProps.data.transaction"
                                :value="
                                    statuses[slotProps.data.transaction.status]
                                "
                                :severity="
                                    getSeverity(
                                        slotProps.data.transaction?.status
                                    )
                                "
                            />
                            <p v-else class="text-sm text-slate-500">-</p>
                        </template>
                    </Column>
                    <Column header="" field="actions">
                        <template #body="slotProps">
                            <button
                                @click="
                                    $router.push({
                                        name: 'app.appointment',
                                        params: {
                                            appointmentId: slotProps.data.id,
                                        },
                                    })
                                "
                                class="px-3 py-1 bg-slate-300 border-none rounded"
                            >
                                <i
                                    class="la la-file-pdf text-lg text-slate-600 font-bold"
                                ></i>
                            </button>
                        </template>
                    </Column>
                </DataTable>

                <div class="mt-3" v-if="pagination">
                    <Paginator
                        :rows="pagination.per_page"
                        :totalRecords="pagination.total"
                        @page="updatePage"
                    ></Paginator>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Datepicker from "@/components/Datepicker/index.vue";
import Calendar from "primevue/calendar";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup"; // optional
import Row from "primevue/row";
import { defineComponent, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import moment from "moment";
import InputText from "primevue/inputtext";
import Tag from "primevue/tag";
import Loader from "@/components/Loader/index.vue";
import Paginator from "primevue/paginator";
export default defineComponent({
    components: {
        Calendar,
        DataTable,
        Datepicker,
        Column,
        Paginator,
        ColumnGroup,
        Row,
        Tag,
        InputText,
        Loader,
    },

    setup() {
        const search = ref("");
        const date = ref([]);
        const isLoading = ref(true);
        const products = ref([]);
        const store = useStore();
        const pagination = ref();
        const statuses = ref({
            SUCCESS: "Succès",
            FAILED: "Échec",
            REVERTED: "Remboursé",
            patient_absent: "Patient absent",
            pratician_absent: "Pratician absent",
        });

        const getInvoices = (page = 1) => {
            let filter = {
                page,
                search: search.value,
            };

            if (date.value.length == 2) {
                //  const start_date = moment.utc(date.value[0]).startOf("day").toDate();
                //   const end_date = moment.utc(date.value[1]).endOf("day").toDate();

                const start_date = date.value[0];
                const end_date = date.value[1];
                filter = {
                    ...filter,
                    end_date,
                    start_date,
                };
            }
            isLoading.value = true;
            store.dispatch("medical/getInvoices", {
                filter: {
                    ...filter,
                },
                onError: () => {
                    isLoading.value = false;
                },
                onSuccess: (resp) => {
                    isLoading.value = false;
                    console.log(resp);
                    pagination.value = { ...resp };
                    products.value = [...resp.data];
                    delete pagination.value.data;
                },
            });
        };

        const updatePage = (data) => {
            getInvoices(data?.page + 1);
        };

        const getSeverity = (status) => {
            switch (status) {
                case "cancelled":
                    return "danger";

                case "pratician_absent":
                    return "warning";

                case "REVERTED":
                    return "info";

                case "patient_absent":
                    return "warning";

                case "FAILED":
                    return "danger";
                case "SUCCESS":
                    return "success";

                case "renewal":
                    return null;
            }
        };

        watch(
            () => search.value,
            (val) => {
                getInvoices();
            }
        );

        watch(
            () => date.value,
            (val) => {
                console.log("Logg", val);
                getInvoices();
            }
        );

        onMounted(() => {
            getInvoices();
        });

        return {
            products,
            moment,
            isLoading,
            statuses,
            pagination,
            search,
            date,
            updatePage,
            getSeverity,
        };
    },
});
</script>
