<template>
    <div id="map">
        <!-- <div id="mapContainer" style="height:100%;width:100%" ref="hereMap"></div> -->

        <GmapMap
            ref="map"
            :center="center"
            :zoom="10"
            :options="options"
            map-type-id="terrain"
            style="width: 100%; height: 100%"
        >
            <!-- <GmapMarker
        :position="center"
        :clickable="true"
        :draggable="true"
        @click="center = m.position"
        @dragend="onDrag"
      /> -->
        </GmapMap>
    </div>
</template>

<script>
import { hereMapApiKey, hereMapId } from "@/services/config";
import { gmapStyle } from "@/Utils/map.js";
import { ref } from "vue";

export default {
    name: "HereMap",
    props: {
        center: Object,
        onMapUpdate: Function,
    },
    setup(props, { emit }) {
        const options = ref({ styles: gmapStyle, disableDefaultUI: true });
        const map = ref(null);
        const onDrag = (evt) => {
            emit("onMapUpdate", map.value, {
                lat: evt.latLng.lat(),
                lng: evt.latLng.lng(),
            });
        };
        return { options, map, onDrag };
    },
    data() {
        return {
            platform: null,
            appId: hereMapId,
            apikey: hereMapApiKey,
        };
    },
    async mounted() {
        // Initialize the platform object:
        // const platform = new H.service.Platform({
        //   apikey: this.apikey,
        //   app_id: this.appId,
        //   app_code: this.apikey,
        // });
        // this.platform = platform;
        this.initializeHereMap();
    },
    methods: {
        initializeHereMap() {
            // rendering map
            const mapContainer = this.$refs.hereMap;
            // Obtain the default map types from the platform object
            var maptypes = this.platform.createDefaultLayers();
            // Instantiate (and display) a map object:
            var map = new H.Map(mapContainer, maptypes.vector.normal.map, {
                zoom: 13,
                center: this.center,
            });

            console.log(mapContainer, maptypes.vector.normal.map);

            addEventListener("resize", () => map.getViewPort().resize());

            // add behavior control
            new H.mapevents.Behavior(new H.mapevents.MapEvents(map));

            // add UI
            H.ui.UI.createDefault(map, maptypes);

            this.$emit("onMapUpdate", map);
            // End rendering the initial map
        },
    },
};
</script>
<!--
<style scoped>
#map {
    background-color: #ccc;
}
</style> -->
