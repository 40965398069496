<template></template>

<script>
import { onMounted } from "vue";
import Echo from "laravel-echo";
import Pusher from "pusher-js";
export default {
    setup(props) {
        const initEcho = () => {
            console.log(
                "window.location.hostname",
                window.location.hostname,
                window.location.host
            );
            window.Pusher = Pusher;
            window.Echo = new Echo({
                broadcaster: "pusher",
                key: "6976bcfeeaba692f8cb7",
                cluster: "us3",
                encrypted: true,
                wsHost: "sosdoctor.test",
                wssHost: "sosdoctor.test",
                wsPort: window.location.host.includes(".com") ? 6002 : 6001,
                wssPort: window.location.host.includes(".com") ? 6002 : 6001,
                forceTLS: false,
                disableStats: true,
                enabledTransports: ["ws", "wss"],
            });

            window.Echo.connector.pusher.connection.bind(
                "connected",
                (event) => {
                    console.log("Status: connected", event);
                    //this.isConnected = true;
                }
            );

            window.Echo.connector.pusher.connection.bind("disconnected", () =>
                console.log("Status: disconnected")
            );

            window.Echo.connector.pusher.connection.bind("error", () =>
                console.log("Status: Error")
            );
        };

        onMounted(() => {
            if (typeof window != "undefined" && !window.Echo) initEcho();
        });
    },
};
</script>
