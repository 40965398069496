<template>
    <div class="w-full rounded-lg overflow-hidden">
        <div :class="`bg-slate-800`">
            <div
                :class="`h-10 flex bg-${
                    colors[appointment.status]
                } items-center p-3 text-sm text-white`"
            >
                <span class="capitalize">
                    <i class="la la-calendar mr-1"></i
                    >{{
                        moment(appointment?.start_date).format("dddd LL")
                    }}</span
                >
                <span>
                    <i class="la la-clock ml-2 mr-1"></i
                    >{{ moment(appointment?.start_date).format("HH:mm") }}</span
                >
            </div>
        </div>
        <div>
            <div class="bg-white p-3">
                <div class="flex items-center">
                    <div class="flex flex-col px-2">
                        <h3 class="font-bold font-qs text-xl text-slate-600">
                            Dr. {{ appointment?.pratician?.full_name }}
                        </h3>
                        <p v-if="specialties" class="text-slate-400 text-xs">
                            {{ specialties }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="py-5 bg-white border-t-2 border-[#F8F9FC] px-12 text-sm font-semibold"
        >
            <div
                class="flex justify-between"
                v-if="appointment?.status == 'pending'"
            >
                <div class="">
                    <p
                        class="text-primary cursor-pointer"
                        @click.prevent="requestUpdate"
                    >
                        <i class="la la-history mr-1"></i> Déplacer le RDV
                    </p>
                </div>
                <div>
                    <p
                        class="text-danger cursor-pointer"
                        @click.prevent="requestCancel"
                    >
                        <i class="la la-calendar-times mr-1"></i> Annuler le RDV
                    </p>
                </div>
            </div>
            <div class="" v-else>
                <p :class="`text-${colors[appointment.status]}`">
                    {{ statuses[appointment.status] }}
                </p>
            </div>
        </div>

        <div class="my-4 rounded-lg bg-white overflow-hidden cursor-pointer">
            <div class="flex justify-between p-4">
                <div>
                    <h3 class="font-semibold text-md">
                        Préparez la consultation
                    </h3>
                    <p class="font-normal text-sm py-2">
                        Pour gagner du temps et améliorer votre prise en charge.
                    </p>
                </div>
                <div>
                    <img
                        src="/images/upload_documents.png"
                        alt=""
                        class="w-12"
                    />
                </div>
            </div>
            <div
                v-if="!isOld"
                class="p-4 flex justify-between items-center hover:bg-slate-100"
                @click="addDocuments"
            >
                <h3 class="text-primary text-sm cursor-pointer font-semibold">
                    <i class="la la-paperclip"></i> Charger les documents
                </h3>
                <i class="la la-angle-right text-primary"></i>
            </div>
        </div>

        <div class="my-4 rounded-lg bg-white overflow-hidden cursor-pointer">
            <div class="flex justify-between p-4">
                <div>
                    <h3 class="font-semibold text-md">Patient</h3>
                    <div class="flex">
                        <i class="la la-user-circle text-xl mr-1"></i>
                        <div>
                            <h4
                                class="font-normal flex items-center text-sm py-1 text-slate-600"
                            >
                                {{ appointment?.patient?.full_name }}
                            </h4>
                            <p class="text-slate-400 text-xs">
                                {{ appointment?.patient?.username }}
                            </p>
                        </div>
                    </div>
                </div>
                <div></div>
            </div>
            <div
                v-if="!isOld"
                class="p-4 flex justify-between items-center hover:bg-slate-100 border-t-2 border-[#F8F9FC]"
                @click="shareModalVisible = true"
            >
                <h3 class="text-primary text-sm font-semibold cursor-pointer">
                    Partager avec quelqu'un
                </h3>
                <i class="la la-angle-right text-primary"></i>
            </div>
        </div>

        <div
            class="my-4 p-4 rounded-lg bg-white overflow-hidden cursor-pointer"
        >
            <div class="flex border-b-2 border-[#F8F9FC] py-4">
                <div class="w-7">
                    <i class="la la-headset text-md text-slate-600"></i>
                </div>
                <div>
                    <h3 class="text-md text-slate-600 font-semibold">
                        Téléphone du lieu de consultation
                    </h3>
                    <p
                        class="text-primary"
                        v-if="appointment?.medical_center?.profile?.phone_1"
                    >
                        {{
                            formatPhone(
                                appointment?.medical_center?.profile?.phone_1
                            )
                        }}
                    </p>
                    <p class="text-primary" v-else>-</p>
                    <p
                        class="text-primary"
                        v-if="appointment?.medical_center?.profile?.phone_2"
                    >
                        {{
                            formatPhone(
                                appointment?.medical_center?.profile?.phone_2
                            )
                        }}
                    </p>
                </div>
            </div>
            <div class="flex border-b-2 border-[#F8F9FC] py-4">
                <div class="w-7">
                    <i class="la la-map-marker text-md text-slate-600"></i>
                </div>
                <div>
                    <h3 class="text-md text-slate-600 font-semibold">
                        Lieu de la consultation
                    </h3>
                    <p class="py-2 text-primary">
                        {{ appointment?.medical_center?.name }}
                    </p>
                    <p class="text-primary">{{ appointment?.address }}</p>
                </div>
            </div>
            <div class="flex border-b-2 border-[#F8F9FC] py-4">
                <div class="w-7">
                    <i class="la la-credit-card text-md text-slate-600"></i>
                </div>
                <div>
                    <h3 class="text-md text-slate-600 font-semibold">
                        Moyen de paiement
                    </h3>
                    <p class="py-2 text-primary">
                        {{ payment }}
                    </p>
                </div>
            </div>
            <div class="flex py-4">
                <div class="w-7">
                    <i class="la la-money-bill text-md text-slate-600"></i>
                </div>
                <div>
                    <h3 class="text-md text-slate-600 font-semibold">Tarif</h3>
                    <p
                        class="py-2 text-blackp text-sm"
                        v-if="appointment?.planning?.price"
                    >
                        {{ price.format(appointment?.planning?.price?.min) }}
                        <span class="px-2">-</span>
                        {{ price.format(appointment?.planning?.price?.max) }}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <Toast />
    <ConfirmDialog
        :pt="{
            headerTitle: { class: 'text-danger' },
            footer: {
                class: [
                    'shrink-0 ',
                    'border-t-0 bg-white text-gray-700 px-6 pb-6 text-right rounded-b-lg',
                    'dark:bg-gray-900  dark:text-white/80',
                ],
            },
        }"
    ></ConfirmDialog>
    <Dialog
        v-model:visible="displayTimes"
        modal
        :header="`Modification du rendez-vous - ${moment(selectedTime).format(
            'LLL'
        )}`"
        :style="{ width: isSmall || isMedium ? '85vw' : '40vw' }"
    >
        <div v-if="loadingSlots" class="flex justify-center text-primary2">
            <Loader class="w-20" />
        </div>
        <div v-else>
            <Accordion
                :activeIndex="0"
                :pt="{
                    headerTitle: 'font-semibold text-sm',
                }"
            >
                <AccordionTab
                    :header="moment(dateData.date).format('LL')"
                    v-for="(dateData, index) in slotsData"
                    :key="index"
                >
                    <div class="grid grid-cols-6 gap-4 w-full">
                        <button
                            v-for="idx in dateData.slots.length"
                            :key="idx"
                            :class="`rounded   px-2 py-2 opacity-75 slot ${
                                moment(selectedTime).unix() ==
                                moment(dateData.slots[idx - 1]?.start).unix()
                                    ? 'bg-slate-700 text-white'
                                    : 'bg-[#B5B9C9] text-[#2b4660]'
                            }`"
                            @click.prevent="selectSlot(dateData.slots[idx - 1])"
                        >
                            {{
                                moment(dateData.slots[idx - 1]?.start).format(
                                    "HH:mm"
                                )
                            }}
                        </button>
                    </div>
                </AccordionTab>
            </Accordion>
        </div>
        <template #footer>
            <Button
                label="Fermer"
                icon="la la-times"
                class="px-3 py-2 text-sm mr-2"
                @click="displayTimes = false"
                text
            />
            <Button
                label="Valider"
                class="px-3 py-2 text-sm bg-success text-white font-mediun"
                icon="la la-check"
                @click="validate"
                autofocus
            />
        </template>
    </Dialog>
    <Dialog
        v-model:visible="shareModalVisible"
        modal
        header="Partager avec quelqu'un"
        :style="{ width: isSmall ? '90vw' : isMedium ? '80vw' : '50vh' }"
    >
        <div
            class="flex border rounded-lg border-slate-300 p-3 cursor-pointer"
            @click.prevent="buildShare"
        >
            <div>
                <img src="/images/calendar.png" alt="" />
            </div>
            <div class="ml-3 flex flex-col justify-between">
                <h3 class="py-2 text-sm">
                    Partager les détails de ce rendez-vous grace à ce lien
                </h3>

                <h4 class="text-md text-primary font-semibold">
                    Partager ce lien
                </h4>
            </div>
        </div>

        <div class="mt-4">
            <Message severity="warn" sticky :closable="false">
                <strong>Attention!!!</strong>
                <p class="text-sm">
                    Ce lien doit se partager uniquement avec celui pour qui vous
                    avez pris le rendez-vous.Il pourra modifier l'horaire ou
                    l'annuler si besoin (en respectant nos
                    <a href="/terms">conditions d'utilisation</a>).
                </p>
            </Message>
        </div>
    </Dialog>
    <Dialog
        v-model:visible="showDocumentLoad"
        header="Envoyer les documents"
        :style="{ width: isSmall ? '90vw' : isMedium ? '80vw' : '70vh' }"
        position="bottom"
        :modal="true"
        :draggable="false"
    >
        <div>
            <h3 class="font-ibm">
                Envoyez des documents à
                <strong>Dr {{ appointment?.pratician?.full_name }}</strong> pour
                gagner du temps en consultation.
            </h3>

            <div class="mt-4">
                <Message
                    :closable="false"
                    icon="la la-exclamation-circle text-slate-800"
                    class="bg-[#6fddde] text-slate-800 font-medium"
                >
                    <p class="text-sm font-ibm">
                        Exemple de documents : Lettre d’adressage, compte rendu,
                        résultats d’analyse, ordonnance...
                    </p>
                </Message>
            </div>

            <div class="card flex justify-content-center font-ibm">
                <SplitButton
                    label="Ajouter un document"
                    :model="types"
                    icon="la bi-plus"
                    @click="save"
                    class="bg-slate-300 py-2 px-1"
                ></SplitButton>
            </div>
            <input
                ref="file"
                type="file"
                name="ambulance-image"
                id="ambulance-image"
                class="invisible"
                :multiple="true"
                accept="image/*, .pdf"
                @change="fileLoaded"
            />
            <div class="my-2">
                <span class="text-sm text-warning"
                    >Taille 50MO max ; formats : jpeg, jpg, png, pdf</span
                >
            </div>
            <div class="my-1" v-if="hasError && selectedFiles.length > 0">
                <span class="text-sm text-danger font-ibm"
                    >Vous devez sélectionner le type de document que vous
                    envoyer au praticien</span
                >
            </div>
            <div class="my-1" v-else-if="hasError && !selectedFiles.length">
                <span class="text-sm text-danger font-ibm"
                    >Choisissez les documents à partager avec ce praticien</span
                >
            </div>
        </div>
        <div
            v-if="isSubmittingFiles"
            class="flex justify-center items-center text-primary2"
        >
            <Loader class="w-20" />
        </div>
        <div class="py-4" v-else>
            <p
                v-if="selectedFiles.length"
                class="text-sm font-ibm font-semibold"
            >
                Les documents chargés
            </p>

            <div
                class="flex py-2 justify-between"
                v-for="(f, index) in selectedFiles"
                :key="index"
            >
                <div class="w-2/3 bg-slate-100 px-2 py-2 flex items-center">
                    <h3 class="text-sm">{{ f?.file?.name }}</h3>
                </div>
                <div class="">
                    <Dropdown
                        v-model="selectedFiles[index].type"
                        :options="fileTypes"
                        optionLabel="name"
                        placeholder="Type de document"
                        class="w-full bg-slate-100 border border-slate-300"
                    />
                </div>
                <div class="w-14 flex justify-center items-center">
                    <span
                        class="la la-times text-xl text-danger cursor-pointer"
                        @click="deleteFile(index)"
                    ></span>
                </div>
            </div>

            <div>
                <div
                    v-for="(doc, index) in attachedFiles"
                    :key="index"
                    class="bg-slate-100 rounded py-1 px-2 flex justify-between items-center mb-2"
                >
                    <div class="flex justify-start items-center">
                        <div
                            :class="`bg-${
                                typesColors[doc.type]
                            } bg-opacity-25 w-10 h-10 rounded-md flex justify-center items-center`"
                        >
                            <i
                                :class="`las la-${icons[doc.type]} text-${
                                    typesColors[doc.type]
                                } text-lg`"
                            ></i>
                        </div>
                        <div class="p-2">
                            <h3 class="text-sm font-ibm font-semibold">
                                {{ typesTexts[doc?.type] }}
                            </h3>
                            <p class="text-sm font-medium font-qs">
                                {{
                                    doc.created_by == appointment.patient.id
                                        ? "Créé par vous"
                                        : doc?.creator?.full_name
                                }}
                                ·
                                <strong>{{
                                    moment(doc.created_at).format("LL")
                                }}</strong>
                            </p>
                        </div>
                    </div>
                    <div>
                        <div class="w-14 flex justify-center items-center">
                            <span
                                class="la la-times text-xl text-danger cursor-pointer"
                                @click="deleteDocument(doc.id)"
                            ></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <Button
                label="Non"
                icon="pi pi-times"
                class="px-3 py-1 mr-3"
                @click="showDocumentLoad = false"
                text
            />
            <Button
                label="Envoyer"
                class="px-3 py-1 bg-primary text-white"
                icon="la la-check"
                @click="handleFiles"
            />
        </template>
    </Dialog>
    <Dialog
        v-model:visible="displayExistingDocumentModal"
        header="Les documents de votre espace"
        :style="{ width: isSmall ? '90vw' : isMedium ? '80vw' : '50vh' }"
        position="top"
        :modal="true"
        :draggable="false"
    >
        <div class="">
            <div
                v-for="(doc, index) in documents"
                :key="index"
                class="bg-slate-100 rounded py-1 px-2 flex justify-between items-center mb-2"
            >
                <div class="flex justify-start items-center">
                    <div
                        :class="`bg-${
                            typesColors[doc.type]
                        } bg-opacity-25 w-10 h-10 rounded-md flex justify-center items-center`"
                    >
                        <i
                            :class="`las la-${icons[doc.type]} text-${
                                typesColors[doc.type]
                            } text-lg`"
                        ></i>
                    </div>
                    <div class="p-2">
                        <h3 class="text-sm font-ibm font-semibold">
                            {{ typesTexts[doc?.type] }}
                        </h3>
                        <p class="text-sm font-medium font-qs">
                            {{
                                doc.created_by == appointment.patient.id
                                    ? "Créé par vous"
                                    : doc?.creator?.full_name
                            }}
                            ·
                            <strong>{{
                                moment(doc.created_at).format("LL")
                            }}</strong>
                        </p>
                    </div>
                </div>
                <div>
                    <Checkbox
                        v-model="choosenFiles"
                        :inputId="`${doc.id}`"
                        name="choosenFiles"
                        :value="doc.id"
                        class=""
                    />
                </div>
            </div>
        </div>
        <template #footer>
            <Button
                label="Fermer"
                icon="pi pi-times"
                class="px-2 py-2 text-medium"
                :disabled="isSubmittingFiles"
                @click="displayExistingDocumentModal = false"
                text
            />
            <Button
                label="Valider la sélection"
                class="bg-slate-200 px-2 py-2 rounded-lg ml-2 text-medium"
                :disabled="isSubmittingFiles"
                @click="submitFiles"
            />
        </template>
    </Dialog>
</template>

<script>
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import moment from "moment";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import ConfirmDialog from "primevue/confirmdialog";
import Toast from "primevue/toast";
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import Loader from "@/components/Loader/index.vue";
import { AsYouType } from "libphonenumber-js";
import Message from "primevue/message";
import store from "../../store";
import { useClipboard, useWindowSize } from "@vueuse/core";
import Dropdown from "primevue/dropdown";
import SplitButton from "primevue/splitbutton";
import Checkbox from "primevue/checkbox";
export default defineComponent({
    components: {
        Toast,
        Checkbox,
        SplitButton,
        Dropdown,
        ConfirmDialog,
        Dialog,
        Button,
        Loader,
        Message,
        AccordionTab,
        Accordion,
    },
    props: {
        appointment: { type: Object },
    },
    emits: ["updateAppointment"],
    setup(props, { emit }) {
        const isOld = computed(
            () =>
                ["cancelled", "patient_absent", "pratician_absent"].includes(
                    props.appointment?.status
                ) ||
                moment().isSameOrAfter(moment(props.appointment?.start_date))
        );
        const showDocumentLoad = ref(false);
        const sharedLink = ref("");
        const documents = ref([]);
        const { copy } = useClipboard({
            source: sharedLink,
        });
        const { width, height } = useWindowSize();

        const isSmall = computed(() => width.value <= 540);
        const isMedium = computed(
            () => width.value <= 1000 && width.value > 540
        );
        const isLarge = computed(() => width.value > 1000);
        const choosenFiles = ref(
            (props.appointment?.documents ?? []).map((x) => x.id)
        );
        const attachedFiles = ref([]);
        const shareModalVisible = ref(false);
        const displayTimes = ref(false);
        const selectedTime = ref(props.appointment.start_date);
        const slotsData = ref([]);
        const allSlots = ref([]);
        const loadingSlots = ref(false);
        const slots = ref([]);
        const confirm = useConfirm();
        const toast = useToast();
        const selectedSlot = ref(null);
        const file = ref();
        const hasError = ref(false);
        const displayExistingDocumentModal = ref(false);
        const selectedFiles = ref([]);
        const isSubmittingFiles = ref(false);
        const icons = computed(() => ({
            MEDICAL_TEST: "file-medical-alt",
            LABORATORY_RESULT: "prescription",
            RADIOLOGY_REPORT: "biohazard",
        }));
        const typesColors = computed(() => ({
            MEDICAL_TEST: "primary2",
            LABORATORY_RESULT: "primary3",
            RADIOLOGY_REPORT: "warning",
        }));
        const typesTexts = computed(() => ({
            MEDICAL_TEST: "Test Médical",
            LABORATORY_RESULT: "Résultats - Laboratoire",
            RADIOLOGY_REPORT: "Rapport de radiologie",
        }));
        const statuses = computed(() => ({
            cancelled: "Rendez-vous annulé",
            confirmed: "Confirmé",
            done: "Présent",
            patient_absent: "Patient non présent au rendez-vous",
            pratician_absent: "Pratician non présent au rendez-vous",
        }));
        const selectedMode = ref();
        const fileTypes = ref([
            { name: "Résultats - Laboratoire", code: "LABORATORY_RESULT" },
            { name: "Test Médical", code: "MEDICAL_TEST" },
            { name: "Rapport de radiologie", code: "RADIOLOGY_REPORT" },
        ]);
        const types = ref([
            {
                label: "Depuis mon ordinateur",
                code: "NEW_DOCS",
                command: () => {
                    file.value?.click();
                },
            },
            {
                label: "Depuis mes documents Allô Doto",
                code: "EXISTING_DOCS",
                command: () => {
                    displayExistingDocumentModal.value = true;
                },
            },
        ]);
        const colors = computed(() => ({
            cancelled: "danger",
            confirmed: "success",
            done: "success",
            pending: "slate-500",
            patient_absent: "warning",
            pratician_absent: "warning",
        }));
        const ways = computed(() => ({
            CARD: "Cartes bancaires",
            CASH: "Èspèces",
            CHECK: "Chèques de banque",
        }));
        const specialties = computed(() =>
            (props.appointment?.planning?.specialties ?? [])
                .filter((s, i) => i < 3)
                .reduce(
                    (acc, x, i) => (acc += `${i > 0 ? "," : ""} ${x.name}`),
                    ""
                )
                .toString()
        );
        const formatPhone = (phone) => {
            return new AsYouType().input(`+${phone}`);
        };
        const payment = computed(() => {
            let value = "-";
            const len = (props.appointment?.payments ?? []).length;
            if (len)
                value = (props.appointment?.payments ?? [])
                    .slice(0, len - 1)
                    .reduce(
                        (acc, x, i) =>
                            (acc +=
                                i > 0
                                    ? `, ${ways.value[x]}`
                                    : `${ways.value[x]}`),
                        ""
                    );
            if (len >= 2 && (props.appointment.payments ?? [])[len - 1])
                value += ` et ${
                    ways.value[(props.appointment.payments ?? [])[len - 1]]
                }`;

            return value;
        });
        const initials = computed(() =>
            props.appointment?.pratician?.full_name
                .match(/(^\S\S?|\s\S)?/g)
                .map((v) => v.trim())
                .join("")
                .match(/(^\S|\S$)?/g)
                .join("")
                .toLocaleUpperCase()
        );

        const price = ref(
            new Intl.NumberFormat("fr-FR", {
                style: "currency",
                currency: "XOF",
            })
        );

        const deleteDocument = (id) => {
            choosenFiles.value = choosenFiles.value.filter((x) => x != id);
            attachedFiles.value = attachedFiles.value.filter(
                (x) => x.id !== id
            );
        };

        const addDocuments = () => {
            showDocumentLoad.value = true;
            getAllDocuments();
        };

        const submitFiles = () => {
            displayExistingDocumentModal.value = false;
            attachedFiles.value = [...documents.value].filter((x) =>
                choosenFiles.value.includes(x.id)
            );
        };
        const handleFiles = () => {
            hasError.value =
                (!choosenFiles.value.length && !selectedFiles.value.length) ||
                selectedFiles.value.some((x) => !x.type);

            if (hasError.value || isSubmittingFiles.value) return;
            isSubmittingFiles.value = true;
            let formData = new FormData();
            formData.append("documents", choosenFiles.value);
            selectedFiles.value.forEach((element) => {
                formData.append("files", element.file); // check this out for more info: https://developer.mozilla.org/en-US/docs/Web/API/FormData/append#example
                formData.append("files[][type]", element.type?.code); // check this out for more info: https://developer.mozilla.org/en-US/docs/Web/API/FormData/append#example
            });
            store.dispatch("medical/attachFiles", {
                appointmentId: props.appointment?.id,
                data: formData,
                onSuccess: () => {
                    isSubmittingFiles.value = false;
                    choosenFiles.value = [];
                    selectedFiles.value = [];
                    showDocumentLoad.value = false;
                },
                onError: () => {
                    isSubmittingFiles.value = false;
                },
            });
        };

        const selectSlot = (data) => {
            selectedSlot.value = { ...data };
            selectedTime.value = data.start;
        };

        const openFiler = () => {
            file.value.click();
        };

        const buildShare = () => {
            store.dispatch("medical/buildShare", {
                id: props.appointment.id,
                onSuccess: (hash) => {
                    console.log(hash, "hash");
                    sharedLink.value = `${window.location.host}/anonimous-sessions/${hash}`;
                    copy(sharedLink.value);

                    toast.add({
                        severity: "info",
                        summary: "Partage de rendez-vous",
                        detail: "Lien du rendez-vous copié. Collez-le pour partager.",
                        life: 3000,
                    });
                },
                onError: () => {
                    // loadingSlots.value = false;
                },
            });
        };
        const getSlots = () => {
            loadingSlots.value = true;
            store.dispatch("medical/getSlots", {
                planningId: props.appointment.planning_id,
                onSuccess: (data) => {
                    slots.value = [...data];
                    buildSlots(data);
                    loadingSlots.value = false;
                },
                onError: () => {
                    loadingSlots.value = false;
                },
            });
        };

        const deleteFile = (index) => {
            selectedFiles.value.splice(index, 1);
        };

        const fileLoaded = (data) => {
            hasError.value = false;
            const { files } = data.target;
            for (let index = 0; index < files.length; index++) {
                selectedFiles.value.push({ file: files[index], type: "" });
            }
        };

        const buildSlots = (slots) => {
            const groups = slots.reduce((groups, game) => {
                const date = game.start.split("T")[0];
                if (!groups[date]) {
                    groups[date] = [];
                }
                groups[date].push(game);
                return groups;
            }, {});

            const groupArrays = Object.keys(groups).map((date) => {
                return {
                    date,
                    slots: groups[date],
                };
            });
            slotsData.value = groupArrays;
            allSlots.value = [...groupArrays]
                .sort((a, b) => moment(a.start).unix() - moment(b.start).unix())
                .map((s) => ({
                    date: s.date,
                    slots: s.slots.sort(
                        (a, b) =>
                            moment(a.start).unix() - moment(b.start).unix()
                    ),
                }));
        };

        const getAllDocuments = () => {
            store.dispatch("medical/getAllDocuments", {
                onSuccess: (resp) => {
                    documents.value = resp?.data ?? [];
                },
                onError: () => {},
            });
        };

        const validate = () => {
            store.dispatch("medical/updateAppointmentTime", {
                slot: selectedSlot.value,
                appointmentId: props.appointment.id,
                onSuccess: () => {
                    emit("updateAppointment", props.appointment.id);
                    displayTimes.value = false;
                    toast.add({
                        severity: "success",
                        summary: "Votre rendez-vous a été mis à jour",
                        detail: "Modification horaire",
                        life: 3000,
                    });
                },
                onError: () => {},
            });
        };

        const cancel = () => {
            store.dispatch("medical/cancelAppointmentTime", {
                appointmentId: props.appointment.id,
                onSuccess: () => {
                    emit("updateAppointment", props.appointment.id);
                    displayTimes.value = false;
                    toast.add({
                        severity: "success",
                        summary: "Votre rendez-vous a été bien annulé",
                        detail: "Annulation",
                        life: 3000,
                    });
                },
                onError: () => {},
            });
        };

        const requestUpdate = () => {
            displayTimes.value = true;
            getSlots();
        };

        const requestCancel = () => {
            confirm.require({
                message: "Êtes-vous sûre de vouloir annuler ce rendez-vous ?",
                icon: "la la-exclamation-triangle",
                header: "Annulation",
                acceptLabel: "Oui",
                rejectLabel: "Non",
                draggable: false,
                acceptClass: "",
                rejectClass: "",
                accept: () => {
                    cancel();
                },
                reject: () => {},
            });
        };

        watch(
            () => selectedMode.value,
            (val) => {
                if (val?.id == "NEW_DOCS") openFiler();
                else if (val.id == "EXISTING_DOCS") {
                }
                console.log(val);
            }
        );

        watch(
            () => width.value,
            (val) => {
                console.log(val, "width");
            }
        );

        return {
            documents,
            initials,
            payment,
            price,
            handleFiles,
            colors,
            icons,
            typesTexts,
            typesColors,
            statuses,
            selectSlot,
            selectedTime,
            displayTimes,
            specialties,
            ways,
            isSmall,
            isLarge,
            isMedium,
            file,
            choosenFiles,
            selectedMode,
            types,
            showDocumentLoad,
            shareModalVisible,
            slotsData,
            loadingSlots,
            moment,
            isSubmittingFiles,
            hasError,
            fileTypes,
            isOld,
            selectedFiles,
            fileLoaded,
            buildShare,
            validate,
            displayExistingDocumentModal,
            formatPhone,
            deleteFile,
            attachedFiles,
            addDocuments,
            requestUpdate,
            submitFiles,
            deleteDocument,
            requestCancel,
        };
    },
});
</script>

<style lang="scss">
.grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
}
.grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
}
.p-checkbox-box {
    @apply border border-slate-300;
}
</style>
