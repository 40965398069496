<template>
    <Head>
        <meta name="robots" content="index,follow" />
        <title>
            Allô Doto - Inscrivez votre {{ text }} et profiter d'une solution
            high tech pour gérer facilement.
        </title>
        <meta
            head-key="description"
            name="description"
            content="Rejoignez la famille Allô Doto pour offrir de meilleurs soins à votre patientèle au Bénin et Côte d'Ivoire"
        />
    </Head>
    <div>
        <doctor-form :all-cities="cities"></doctor-form>
    </div>
</template>
<script setup>
import { computed, onMounted } from "vue";
import { Head } from "@inertiajs/vue3";
const props = defineProps({ cities: Array });

const text = computed(
    () =>
        "Hôpital (Centre de soin) privé, Centre de santé, Clinique (Centre médical privé), pharmacie ou Laboratoire d'analyses médicales"
);
onMounted(() => {});
</script>
