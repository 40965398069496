export default {
    cities: state => state.cities,
    countries: state => state.countries,
    roles: state => state.roles,
    users: state => state.users,
    cars: state => state.cars,
    allCities: state => state.allCities,
    resourceTypes: state => state.resourceTypes,
    recordTypes: state => state.recordTypes,
    recordTypesPagination: state => state.recordTypesPagination,
    usersPagination: state => state.usersPagination,
    portfolios: state => state.portfolios,
    portfolio: state => state.portfolio,
    recordSections: state => state.recordSections,
    resourcePagination: state => state.resourcePagination,
    resources: state => state.resources,
    allResources: state => state.allResources,
    allRecordSections: state => state.allRecordSections,
    allRecordTypes: state => state.allRecordTypes,
    specialties: state => state.specialties,
    resourceTypesPagination: state => state.resourceTypesPagination
}
