<template>
    <div class="container">
        <h1
            v-if="context"
            class="text-md font-semibold uppercase text-slate-500 mt-2"
        >
            {{ context }}
        </h1>
        <span :class="`text-slate-400`">{{ message }}</span>
    </div>
</template>

<script>
import { defineComponent, computed } from "vue";
export default defineComponent({
    name: "MedicalToast",
    props: {
        type: {
            type: String,
            required: true,
        },
        message: {
            type: String,
            required: true,
        },
        context: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const color = computed(() =>
            props.type === "error"
                ? "danger"
                : props.type === "default"
                ? "primary"
                : props.type.toString()
        );
        return { color };
    },
});
</script>
<style lang="scss">
.Vue-Toastification__toast--success {
    background: white !important;
    max-width: 40% !important;
    @apply text-success;

    .Vue-Toastification__icon {
        @apply text-success;
    }
}
.Vue-Toastification__toast--error {
    background: white !important;
    max-width: 40% !important;
    //   @apply text-error;

    .Vue-Toastification__icon {
        @apply text-danger;
    }
}

.Vue-Toastification__toast--warning {
    background: white !important;
    max-width: 40% !important;
    //   @apply text-error;

    .Vue-Toastification__icon {
        @apply text-warning;
    }
}
.Vue-Toastification__toast {
    @apply py-1 bg-white;
}
.Vue-Toastification__container.top-right {
    @apply py-1;
}
</style>
