import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
    user: null,
    doctors: [],
    patients: [],
    alerts: [],
    hospitals: [],
    medicalCenter: null,
    patient: null,
    interventions: [],
    prescriptions: [],
    alert: {},
    plannings: [],
    planningPagination: {
        page: 1,
        total: 0,
    },
    doctorPagination: {
        page: 1,
        total: 0,
    },
    usersPagination: {
        page: 1,
        total: 0,
    },
    patientsPagination: {
        page: 1,
        total: 0,
    },
    alertPagination: {
        page: 1,
        total: 0,
    },
    hospitalsPagination: {
        last_page: 1,
        current_page: 1,
        total: 0,
    },
    interventionPagination: {
        page: 1,
        total: 0,
    }
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
