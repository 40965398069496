import { createAcl, defineAclRules } from 'vue-simple-acl';
import AuthService from '@/services/user';

import router from "../router";



const user3 = () => {

    // if (['/login', '/i-am-practitioner', '/reset', '/', '/search-praticians'].includes(window.location.pathname)) {
    //     return {
    //         id: 0,
    //         name: 'Allô Doto',
    //         is_editor: true,
    //         is_admin: false, roles: [{ slug: '' }]
    //     }
    // }
    if (window.location.pathname.startsWith('/app') || window.location.pathname.startsWith('/account'))
        return AuthService.getProfile().then((response) => {
            return response.user
        }).catch((er) => {
            console.log(er);
        });

    else {

        return {
            id: 0,
            name: 'Allô Doto',
            is_editor: true,
            is_admin: false, roles: [{ slug: '' }]
        }
    }
}

const rules = () => defineAclRules((setRule) => {

    setRule('is-user', (user) => true)

    setRule('is-admin', (user) => [...user?.roles].map(x => x.slug).includes('administrator') || [...user?.roles].map(x => x.slug).includes('super-user'));
    setRule('manager', (user) => [...user?.roles].map(x => x.slug).includes('manager'));
    setRule('doctor', (user) => [...user?.roles].map(x => x.slug).includes('doctor'));
    setRule('medical', (user) => [...user?.roles].map(x => x.slug).includes('medical-administration'));

});


const simpleAcl = createAcl({
    user: user3,
    rules,
    router,
    onDeniedRoute: { name: 'auth.unauthorizedPage', replace: true }
});

export default simpleAcl;