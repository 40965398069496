<template>
    <div class="w-full h-full" id="allo-calendar">
        <div class="w-full flex flex-col mt-5 pb-10">
            <div>
                <div class="w-full px-2">
                    <div
                        class="w-full flex justify-between mb-4 flex items-end"
                    >
                        <div class="w-1/3 flex flex-col">
                            <label for=""
                                >Recherche
                                <el-tooltip
                                    content="<span><strong>Recherche</strong> <br></span> <p>Vous pouvez rechercher <br> du simple text (spécialité, nom, hôpital, ...), <br>une date (31/07/2023) <br>ou une plafe de date  (31/07/2023 <strong>-></strong> 31/01/2024) </p>"
                                    raw-content
                                >
                                    <i class="la la-info-circle"></i>
                                </el-tooltip>
                            </label>

                            <input
                                type="text"
                                class="rounded w-full border border-slate-300 xl:w-3/4"
                                placeholder="Entrer votre recherche"
                                v-model="search"
                            />
                        </div>
                        <div>
                            <button
                                class="flex justify-center items-center border-none font-semibold rounded-sm bg-opacity-25 hover:bg-opacity-50 bg-doto-100 text-doto-100 px-3 py-2"
                                @click="
                                    $router.push({
                                        name: 'app.newPlanning',
                                    })
                                "
                            >
                                Créer
                            </button>
                        </div>
                    </div>
                    <div
                        v-if="isLoadingPlanning"
                        class="flex justify-center items-center w-full mt-10"
                    >
                        <loader class="text-slate-500" />
                    </div>
                    <div
                        v-else-if="plannings.length == 0"
                        class="flex justify-center items-center w-full mt-44"
                    >
                        <img
                            src="/images/no-schedule.svg"
                            class="w-56"
                            alt=""
                            srcset=""
                        />
                    </div>
                    <div class="grid grid-rows-4 grid-flow-col gap-4">
                        <div
                            v-for="plan in plannings"
                            :key="plan._id"
                            :class="`rounded p-3 h-fit bg-white grow-0 relative planning-card ${
                                plan._id == currentPlanning?.id
                                    ? 'border border-secondary'
                                    : ''
                            }`"
                            style="
                                box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px
                                    0px;
                            "
                        >
                            <div
                                class="absolute top-0 right-0 mt-2 mr-2 flex planning-actions"
                            >
                                <button
                                    class="bg-secondary text-secondary bg-opacity-25 hover:bg-opacity-50 rounded p-1"
                                    @click="startEdition(plan)"
                                >
                                    <i class="la la-pen"></i>
                                </button>
                                <button
                                    @click="openDialog(plan)"
                                    class="bg-danger text-danger bg-opacity-25 hover:bg-opacity-50 rounded p-1 ml-2"
                                >
                                    <i class="la la-trash"></i>
                                </button>
                            </div>
                            <div class="flex">
                                <div
                                    :style="`background: ${plan?.color_code}`"
                                    :class="`w-4 h-4 rounded-full  bg-[${plan?.color_code}]`"
                                ></div>
                                <span
                                    :style="`color: ${plan?.color_code}`"
                                    :class="`pl-2 text-[${plan?.color_code}]`"
                                    >{{ plan.medical_center?.name }}</span
                                >
                            </div>
                            <div class="flex items-center my-2">
                                <div
                                    :class="`w-10 h-10 flex justify-center items-center rounded-full bg-white overflow-hidden`"
                                >
                                    <img
                                        v-if="plan.pratician"
                                        :src="plan.pratician?.avatar"
                                        class="object-fill w-8 h-8"
                                        alt=""
                                    />
                                    <p v-else>-</p>
                                </div>
                                <h3
                                    v-if="plan.pratician"
                                    class="px-2 font-semibold text-sm font-medium"
                                >
                                    {{ plan.pratician?.full_name }}
                                </h3>
                            </div>

                            <div>
                                <p class="text-slate-600">
                                    {{
                                        plan.specialties
                                            .map((x) => x.name)
                                            .join(", ")
                                    }}
                                </p>
                            </div>

                            <div class="my-2">
                                <h2
                                    class="text-qs font-semibold text-slate-600 text-sm"
                                >
                                    Période du :
                                    <span class="">{{
                                        moment(plan.planning.fromDate).format(
                                            "DD/MM/yyyy HH:mm"
                                        )
                                    }}</span>
                                    au
                                    <span>{{
                                        moment(plan.planning.toDate).format(
                                            "DD/MM/yyyy HH:mm"
                                        )
                                    }}</span>
                                </h2>
                            </div>

                            <div>
                                <p
                                    :class="`font-semibold text-sm  ${
                                        plan.count_remaining_slots <= 50
                                            ? 'text-danger'
                                            : plan.count_remaining_slots >=
                                                  100 &&
                                              plan.count_remaining_slots <= 300
                                            ? 'text-warning'
                                            : 'text-primary'
                                    }`"
                                >
                                    {{ plan.count_remaining_slots }} créneaux
                                    réservable{{
                                        plan.count_remaining_slots >= 2
                                            ? "s"
                                            : ""
                                    }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal
            id="map-modal"
            width="w-4/5"
            :title="`Adresse du praticien`"
            :isVisible="showPositionModal"
            @close="closeModal"
            @submit="validateModal"
            @cancel="closeModal"
        >
            <template #content="{}">
                <div class="h-64">
                    <div class="h-5/6" style="height: 90%">
                        <gmap
                            class="h-full"
                            :center="mapCenter"
                            :locate="mapCenter.lat == null"
                            @onMapUpdate="updateMap"
                            :delay="1000"
                        />
                    </div>

                    <div class="my-3">
                        <h3 class="text-sm text-primary">
                            {{ selectedAddress?.address ?? "-" }}
                        </h3>
                    </div>
                </div>
            </template>
        </modal>
        <ConfirmDialog></ConfirmDialog>
    </div>
</template>
<script>
import FullCalendar from "@fullcalendar/vue3";
import Select from "@/components/Select/index.vue";
import Datepicker from "@/components/Datepicker/index.vue";
import { Field } from "vee-validate";
import { computed, onMounted, ref, watch } from "vue";
import moment from "moment";
import pkg from "lodash";
import { useStore } from "vuex";
import ConfirmDialog from "primevue/confirmdialog";

import Loader from "@/components/Loader/index.vue";
import { ElTooltip } from "element-plus";
import gmap from "@/components/maps/gmap.vue";
import { reverseGeocode } from "@/services/map";
import { mapCenter } from "@/services/config";
import Textarea from "primevue/textarea";
import Dropdown from "primevue/dropdown";
import Checkbox from "primevue/checkbox";
import Chip from "primevue/chip";
import { useRouter } from "vue-router";
import { useConfirm } from "primevue/useconfirm";

const { range } = pkg;
const center = mapCenter;
export default {
    components: {
        Dropdown,
        ConfirmDialog,
        Textarea,
        FullCalendar,
        Select,
        Field,
        gmap,
        Chip,
        Checkbox,
        Datepicker,
        Loader,
        ElTooltip,
    },
    setup() {
        const confirm = useConfirm();
        const store = useStore();
        const router = useRouter();
        const portfolio = computed(() => store.getters["auth/portfolio"]);
        //   const plannings = computed(() => store.getters["medical/plannings"]);
        const plannings = ref([]);
        const hospitals = computed(() =>
            store.getters["medical/hospitals"].map((x) => ({
                id: x.id,
                name: x.name,
            }))
        );

        const selectedPlanning = ref();

        const allDoctors = computed(() => store.getters["medical/doctors"]);
        const doctors = ref([...allDoctors.value]);
        const allSpecialties = computed(() =>
            store.getters["config/specialties"].map((x) => ({
                id: x.id,
                name: x.name,
            }))
        );

        const search = ref("");
        const mapCenter = ref(null);
        const hiddenIndications = ref(false);
        const showPositionModal = ref(false);
        const accessibleEntrance = ref(true);
        const selectedHospital = ref(null);
        const addresses = computed(() =>
            selectedHospital.value
                ? (
                      hospitals.value.find(
                          (x) => x.id == selectedHospital.value?.id
                      )?.addresses ?? []
                  ).map((x) => ({ id: x._id, ...x }))
                : [{ id: 0, name: "Nouvelle adresse" }]
        );
        const selectFloor = ref(null);
        const selectedSpecialties = ref([]);
        const excludes = ref([]);
        const countSlots = ref(0);
        const isLoadingPlanning = ref(true);
        const canDelete = ref(false);
        const planning = ref({
            price: { min: "8000", max: "12000" },
            colorCode: "#54a4de",
            doctorId: null,
            breakDuration: 0,
            fromDate: new Date().toISOString(),
            toDate: new Date().toISOString(),
            startHour: "",
            endHour: "",
            duration: 30,
            excludes: {
                everyDay: [{}],
                "11-12-34": [
                    {
                        from: 12,
                        to: 14,
                    },
                ],
            },
        });

        const durations = computed(() => [
            { id: 15, name: "15 Min" },
            { id: 20, name: "20 Min" },
            { id: 25, name: "25 Min" },
            { id: 30, name: "30 Min" },
            { id: 45, name: "45 Min" },
            { id: 60, name: "1h" },
        ]);

        const days = computed(() => [
            { id: 0, name: "Tous les jours" },
            { id: 1, name: "Lundi" },
            { id: 2, name: "Mardi" },
            { id: 3, name: "Mercredi" },
            { id: 4, name: "Jeudi" },
            { id: 5, name: "Vendredi" },
            { id: 6, name: "Samedi" },
            { id: 7, name: "Dimanche" },
        ]);
        const floors = computed(() => [
            { id: 0, name: "Rez de chaussez" },
            { id: 1, name: "1er Étage" },
            { id: 2, name: "2ème Étage" },
            ...Array.from(Array(45).keys())
                .filter((x) => x > 2)
                .map((x) => ({ id: x, name: `${x}ème Étage` })),
        ]);
        const addressIndications = ref("");
        const specialties = ref([]);
        const hours = computed(() =>
            Array.from(Array(24).keys()).map((x) => ({ id: x, name: `${x} h` }))
        );
        const dayExludedHours = computed(() =>
            range(
                planning.value.startHour.id,
                planning.value.endHour.id,
                1
            ).map((x) => ({ id: x, name: `${x} h` }))
        );
        const selectedAddress = ref(null);
        const currentPlanning = ref(null);
        const viewMode = ref("dayGridYear");
        const events = computed(() => [
            {
                title: "Advanced algebra",
                with: "Chandler Bing",
                time: { start: "2022-05-16 12:05", end: "2022-05-16 13:35" },
                color: "yellow",
                isEditable: true,
                id: "753944708f0f",
                description:
                    "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores assumenda corporis doloremque et expedita molestias necessitatibus quam quas temporibus veritatis. Deserunt excepturi illum nobis perferendis praesentium repudiandae saepe sapiente voluptatem!",
            },
            {
                title: "Ralph on holiday",
                with: "Rachel Greene",
                time: { start: "2023-05-10 12:05", end: "2023-12-22 12:35" },
                color: "green",
                isEditable: true,
                isCustom: true,
                id: "5602b6f589fc",
                colorScheme: "meetings",
            },
            {
                time: {
                    start: "2023-07-16 12:45",
                    end: "2023-07-16 13:00",
                    isCustom: true,
                },
                duration: 60,
                title: "meeting",
                color: "green",
                isEditable: true,
                isCustom: true,
                id: "5602b6f589fc",
            },
            {
                title: "Ralph on holiday",
                with: "-",
                time: {
                    start: "2023-07-16 12:45",
                    end: "2023-07-16 13:00",
                    isCustom: true,
                },
                color: "green",
                isEditable: true,
                isCustom: true,
                id: "5602b6f589fc",
                colorScheme: "sports",
            },
        ]);

        const saveDisabled = computed(
            () =>
                selectedSpecialties.value.length == 0 ||
                !planning.value.doctorId?.id ||
                !planning.value.duration.id
        );

        const config = computed(() => ({
            locale: "fr-FR",
            defaultMode: "month",
            week: {
                startsOn: "monday",
                nDays: 7,
                scrollToHour: 5,
            },
            dayIntervals: {
                length: 15, // Length in minutes of each interval. Accepts values 15, 30 and 60 (the latter is the default)
                height: 50, // The height of each interval
                displayClickableInterval: true, // Needs to be set explicitly to true, if you want to display clickable intervals
                intervalStyles: { backgroundColor: "red", color: "white" },
            },
            dayBoundaries: {
                start: 8,
                end: 18,
            },
            isEditable: true,
            showCurrentTime: true,
            style: {
                colorSchemes: {
                    meetings: {
                        color: "#fff",
                        backgroundColor: "#131313",
                    },
                    sports: {
                        color: "#fff",
                        backgroundColor: "#ff4081",
                    },
                },
            },
        }));

        const openDialog = (planning) => {
            console.log(planning);
            currentPlanning.value = planning;

            confirm.require({
                message: "Êtes-vous sûr de vouloir supprimer ce planning ?",
                header: "Suppression",
                icon: "la la-exclamation-circle",
                rejectLabel: "Non",
                acceptLabel: "Oui",
                rejectClass:
                    "bg-danger text-danger py-1 text-sm text-medium px-2 mr-2 bg-opacity-25",
                acceptClass:
                    "bg-primary text-primary py-1 text-sm text-medium px-2  bg-opacity-25",
                accept: () => {
                    removePlanning();
                },
                reject: () => {},
            });
        };

        const handleMode = (data) => {};

        const insertNew = (index, position) => {
            excludes.value[index].times.push({ endHour: "", startHour: "" });
        };
        const insertNewAll = () => {
            planning.value.excludes.everyDay.push({
                endHour: "",
                startHour: "",
            });
        };
        const removeElm = (index, position) => {
            excludes.value[index].times.splice(position, 1);
        };
        const removeEveryElm = (index) => {
            planning.value.excludes.everyDay.splice(index, 1);
        };
        const addNew = () => {
            const nowString = new Date().toISOString();

            excludes.value.push({
                date: nowString,
                times: [{ endHour: "", startHour: "" }],
            });
        };
        const getDoctors = () => {
            store.dispatch("medical/getDoctors", {
                filters: { portfolioId: portfolio.value?.id },
            });
        };
        const remove = (index) => {
            excludes.value.splice(index, 1);
        };

        const calculateSlots = () => {
            if (
                !planning.value.duration.id ||
                !planning.value.fromDate ||
                !planning.value.toDate ||
                !planning.value.endHour.id ||
                !planning.value.startHour.id
            )
                return;
            const slotDuration = parseInt(planning.value.duration.id);

            const slots = [];
            let startDate = moment(planning.value.fromDate)
                .set("hour", planning.value.startHour.id)
                .set("minute", 0);
            const endDate = moment(planning.value.toDate)
                .set("hour", planning.value.endHour.id)
                .set("minute", 0);
            let excluded = false;
            let isLastExcluded = false;

            let lastDate = startDate.clone();
            while (startDate < endDate) {
                let s = startDate.clone();

                startDate = startDate.add("minute", slotDuration);
                let h = `${s.format("HH:mm")} - ${startDate.format("HH:mm")}`;

                if (planning.value.excludes.everyDay.length > 0) {
                    planning.value.excludes.everyDay.forEach((elm) => {
                        if (
                            elm.day &&
                            elm.from &&
                            [startDate.isoWeekday(), 0].includes(elm?.day.id) &&
                            s >=
                                s
                                    .clone()
                                    .set("hour", elm.from?.id)
                                    .set("minute", 0) &&
                            startDate <=
                                startDate
                                    .clone()
                                    .set(
                                        "hour",
                                        elm.to?.id ?? planning.value.endHour.id
                                    )
                                    .set("minute", 0)
                        )
                            excluded = true;
                    });
                }
                if (excludes.value.length > 0) {
                    excludes.value.forEach((elm) => {
                        (elm?.times ?? []).forEach((time) => {
                            if (
                                elm.date &&
                                time.startHour &&
                                moment(elm.date).isSame(s, "day") &&
                                s <=
                                    s
                                        .clone()
                                        .set(
                                            "hour",
                                            time?.endHour?.id ??
                                                planning.value.endHour.id
                                        )
                                        .set("minute", 0) &&
                                s >=
                                    s
                                        .clone()
                                        .set("hour", time.startHour?.id)
                                        .set("minute", 0)
                            )
                                excluded = true;
                        });
                    });
                }
                if (
                    !excluded &&
                    s >=
                        s
                            .clone()
                            .set("hour", planning.value.startHour.id)
                            .set("minute", 0) &&
                    s <=
                        s
                            .clone()
                            .set("hour", planning.value.endHour.id)
                            .set("minute", 0) &&
                    startDate <=
                        startDate
                            .clone()
                            .set("hour", planning.value.endHour.id)
                            .set("minute", 0)
                ) {
                    if (isLastExcluded) {
                        s = s.set("minute", 0);
                        startDate = s.clone().add("minute", slotDuration);
                        h = `${s.format("HH:mm")} - ${startDate.format(
                            "HH:mm"
                        )}`;
                    }

                    slots.push({
                        start: s,
                        end: startDate,
                        human: h,
                    });
                }
                isLastExcluded = excluded;

                excluded = false;
                lastDate = startDate.clone();
                startDate.add("minute", parseInt(planning.value.breakDuration));
            }

            countSlots.value = slots.length;
        };

        const save = () => {
            const excludedDays = {};

            excludes.value.forEach((el) => {
                excludedDays[moment(el.date).format("DD/MM/yyyy")] = [
                    ...el.times.map((x) => ({
                        from: x.startHour?.id,
                        to: x.endHour?.id,
                    })),
                ];
            });

            const data = {
                id: planning.value?._id,
                color_code: planning.value.colorCode,
                portfolio_id: portfolio.value.id,
                doctor_id: planning.value.doctorId?.id,
                specialties: selectedSpecialties.value,
                medical_center_id: selectedHospital.value?.id,
                address: selectedAddress.value,
                price: planning.value.price,
                planning: {
                    fromDate: moment(planning.value.fromDate)
                        .set("hour", planning.value.startHour?.id)
                        .set("minute", 0),
                    toDate: moment(planning.value.toDate)
                        .set("hour", planning.value.endHour?.id)
                        .set("minute", 0),
                    duration: parseInt(planning.value.duration?.id),
                    startHour: planning.value.startHour?.id,
                    endHour: planning.value.endHour?.id,
                    breakDuration: planning.value.breakDuration ?? 0,
                    excludes: {
                        everyDay: planning.value.excludes.everyDay.map((x) => ({
                            day: x.day?.id ?? 0,
                            from: x.from?.id,
                            to: x.to?.id,
                        })),
                        ...excludedDays,
                    },
                },
            };
            let action = "savePlanning";
            if (currentPlanning.value?.id) action = "editPlanning";

            store.dispatch(`medical/${action}`, {
                planningId: currentPlanning.value?.id,
                data,
                onSuccess: () => {
                    store.dispatch("medical/displayToast", {
                        message: "Planning sauvegardé avec succès",
                        context: "Planning rendez-vous",
                        type: "TYPE.SUCCESS",
                    });
                    planning.value = {
                        colorCode: "#54a4de",
                        doctorId: null,
                        breakDuration: 0,
                        fromDate: new Date().toISOString(),
                        toDate: new Date().toISOString(),
                        startHour: "",
                        endHour: "",
                        duration: 30,
                        excludes: {
                            everyDay: [{}],
                            "11-12-34": [
                                {
                                    from: 12,
                                    to: 14,
                                },
                            ],
                        },
                    };
                    selectedHospital.value = null;
                    selectedSpecialties.value = [];
                    currentPlanning.value = null;
                    getPlannings(1);
                },
                onError: () => {
                    store.dispatch("medical/displayToast", {
                        message:
                            "Oooops, une erreur est survenue lors de la sauvegarde",
                        context: "Planning rendez-vous",
                        type: "TYPE.ERROR",
                    });
                },
            });
        };

        const closeModal = () => {
            showPositionModal.value = false;
        };

        const validateModal = () => {
            showPositionModal.value = false;
        };

        const getAddress = (lat, lng) => {
            if (!lat || !lng) return;
            reverseGeocode(lat, lng).then((result) => {
                const { position, address } = result.items[0];

                selectedAddress.value = {
                    latitude: lat,
                    longitude: lng,
                    address: address.label,
                };
            });
        };

        const updateMap = (map, position) => {
            mapCenter.value = { ...position };
            console.log(mapCenter.value);
            getAddress(position.lat, position.lng);
        };

        const getSpecialties = (page = 1) => {
            store.dispatch("config/getSpecialties", {});
        };

        const getHospitals = (page = 1) => {
            store.dispatch("medical/getHospitals", {
                filter: { portfolioId: portfolio.value?.id, page },
            });
        };

        const getPlannings = (page = 1, search = "") => {
            isLoadingPlanning.value = true;
            store.dispatch("medical/getPlannings", {
                filter: { portfolioId: portfolio.value?.id, page, search },
                onSuccess: (res) => {
                    plannings.value = [...res.data];
                    isLoadingPlanning.value = false;
                },
                onError: () => {
                    isLoadingPlanning.value = false;
                },
            });
        };

        watch(
            () => selectedHospital.value,
            (val) => {
                selectedSpecialties.value = [];
                if (!val.slug) return;
                store.dispatch("medical/getHospital", {
                    slug: val?.slug,
                    onError: () => {},
                    onSuccess: (data) => {
                        doctors.value = [
                            ...data.doctors,
                            ...allDoctors.value,
                        ].map((x) => ({
                            id: x.id,
                            name: x.full_name,
                        }));
                        specialties.value = [...data.specialties];
                    },
                });
            }
        );

        const startEdition = (data) => {
            router.push({
                name: "app.editPlanning",
                params: { planningId: data._id },
            });
        };

        const removePlanning = () => {
            store.dispatch("medical/removePlanning", {
                id: currentPlanning.value._id,
                onSuccess: () => {
                    canDelete.value = false;
                    currentPlanning.value = null;
                    getPlannings();
                },
                onError: () => {},
            });
        };

        const deletingPlanning = (data) => {
            selectPlanning(data);
            canDelete.value = true;
        };

        const selectPlanning = (data) => {
            selectedHospital.value = data.medical_center;
            setTimeout(() => {
                selectedSpecialties.value = [...data.specialties];
            }, 2000);
            selectedAddress.value = data?.address;
            currentPlanning.value = {
                id: data._id,
                price: data.price ?? { min: 8000, max: 12000 },
                colorCode: data.color_code,
                doctorId: {
                    // ...data.pratician,
                    id: data.pratician?.id,
                    name: data.pratician?.full_name,
                },
                breakDuration: data.planning.breakDuration,
                address: data.address,
                fromDate: data.planning.fromDate,
                toDate: data.planning.toDate,
                startHour: hours.value.find(
                    (x) => x.id == data.planning?.startHour
                ),
                endHour: {
                    id: data.planning?.endHour ?? 18,
                },
                duration: durations.value.find(
                    (x) => x.id == data.planning.duration
                ),
                excludes: {
                    everyDay: [...data.planning?.excludes.everyDay].map(
                        (x) => ({
                            day: { id: x?.day },
                            from: { id: x?.from ?? data.planning.startHour },
                            to: { id: x?.to ?? data.planning?.endHour },
                        })
                    ),
                    "11-12-34": [
                        {
                            from: 12,
                            to: 14,
                        },
                    ],
                },
            };

            console.log(currentPlanning.value);
        };

        const showAddress = () => {
            mapCenter.value = selectedAddress.value
                ? {
                      lng: selectedAddress.value.longitude,
                      lat: selectedAddress.value.latitude,
                  }
                : center;
            showPositionModal.value = true;
        };

        watch(
            () => excludes.value,
            (val) => {
                calculateSlots();
            },
            { deep: true }
        );

        watch(
            () => planning.value,
            (val) => {
                calculateSlots();
            },
            { deep: true }
        );

        watch(
            () => allDoctors.value,
            (val) => {
                doctors.value = [...doctors.value, ...val].map((x) => ({
                    id: x.id,
                    name: x.full_name,
                }));
            }
        );

        watch(
            () => selectedAddress.value,
            (val) => {
                selectFloor.value = floors.value.find((x) => x.id == val.floor);
                accessibleEntrance.value = val?.accessibleEntrance ?? true;
                addressIndications.value = val.indications;
                selectedAddress.value.address_indications = val.indications;
                selectedAddress.value.address_floor = val.floor ?? 0;
                selectedAddress.value.address_accessibility =
                    accessibleEntrance.value;
            }
        );

        watch(
            () => search.value,
            (val) => {
                getPlannings(1, val);
            }
        );

        watch(
            () => addressIndications.value,
            (val) => {
                if (selectedAddress.value)
                    selectedAddress.value.address_indications = val;
            }
        );

        onMounted(() => {
            getPlannings();

            getHospitals();
            getDoctors();
            getSpecialties();
        });

        return {
            config,
            hours,
            dayExludedHours,
            events,
            showPositionModal,
            addNew,
            days,
            hiddenIndications,
            selectedAddress,
            currentPlanning,
            search,
            save,
            mapCenter,
            saveDisabled,
            selectedHospital,
            hospitals,
            doctors,
            allSpecialties,
            durations,
            excludes,
            viewMode,
            selectFloor,
            planning,
            moment,
            openDialog,
            canDelete,
            accessibleEntrance,
            isLoadingPlanning,
            countSlots,
            selectedSpecialties,
            specialties,
            plannings,
            addresses,
            floors,
            addressIndications,
            deletingPlanning,
            currentPlanning,
            remove,
            validateModal,
            closeModal,
            updateMap,
            showAddress,
            removePlanning,
            startEdition,
            removeEveryElm,
            removeElm,
            insertNew,
            insertNewAll,
            handleMode,
        };
    },
};
</script>
<style lang="scss" scoped>
#allo-calendar {
    .calendar-root {
        background: white;
    }

    .p-inputtext,
    .p-dropdown {
        border: 1px solid #e1e1e1 !important;

        &:focus {
            outline-color: none !important;
        }
        &:focus-within {
            outline-color: none !important;
        }
    }
    .break-duration,
    .price {
        color: rgb(107 114 128) !important;
        @apply rounded-sm border border-[#ddd];
        font-size: 0.9rem !important;
        &::placeholder {
            color: rgb(148 163 184) !important;
            font-weight: 400 !important;
        }
        &:focus {
            // border-color: #80bdff !important;
        }
    }

    .planning-card .planning-actions {
        transition: all 0.3s;
        visibility: hidden;
    }

    .planning-card:hover .planning-actions {
        visibility: visible;
    }
}
</style>
