<template>
    <Head>
        <meta name="robots" content="index,follow" />
        <title>Allô Doto - Conditions d'utilisation</title>
        <meta
            head-key="description"
            name="description"
            content="Allô Doto - Conditions d'utilisation"
        />
    </Head>
    <app-bar></app-bar>

    <div class="w-full flex justify-center mt-10">
        <div class="w-3/5 xs:w-11/12 px-5 text-justify" id="app-terms">
            <div class="text-left my-10">
                <h3 class="text-4xl text-primary font-semibold text-left">
                    Conditions d'utilisation
                </h3>
                <span class="text-sm italic"
                    >Dernière mise à jour le
                    <strong>15 juillet 2023</strong></span
                >
            </div>

            <div class="my-5">
                <h3 class="text-lg text-primary font-semibold text-left">
                    Dispositions Générales
                </h3>
                <h4 class="text-sm text-primary font-semibold text-left my-2">
                    Définitions
                </h4>
                <div>
                    <p>
                        Pour les besoins des présentes Conditions Générales
                        d’Utilisation, les termes commençant par une majuscule
                        sont définis comme suit :
                    </p>

                    <ul>
                        <li>
                            Le terme « <strong>Service</strong> » désigne ici
                            l’ensemble des services proposés par la plateforme
                            Allô Doto.
                        </li>
                        <li>
                            Le terme « <strong>Site</strong> » et «
                            <strong>Site Internet</strong> » désignent le site
                            www.allodoto.com permettant d’accéder aux Services.
                        </li>
                        <li>
                            Le terme « <strong>Solution Allô Doto</strong> » ou
                            « <strong>Solution</strong> » désigne l’application
                            mobile ainsi que l’interface web accessible à
                            l’adresse www.allodoto.com permettant aux
                            Utilisateurs d'accéder aux différents Services
                        </li>
                        <li>
                            Les termes «
                            <strong>Professionnel de Santé</strong> » ou «
                            <strong>Praticien</strong> » désignent ici les
                            professionnels de santé s’étant inscrits sur le Site
                            permettant la mise à jour de leurs disponibilités,
                            la prise de rendez-vous à distance et prêt á gerer
                            les urgences des patients.
                        </li>
                        <li>
                            Le terme « <strong>Prestation</strong> » désigne ici
                            toute consultation, acte médical, prise en charge ou
                            soin proposé et réalisé par un Professionnel de
                            Santé dans le cadre d’un rendez-vous.
                        </li>
                        <li>
                            Les termes « <strong>Vous</strong> », «
                            <strong>Utilisateur</strong> » ou «
                            <strong>Patient</strong> » désignent ici toute
                            personne physique qui accède au Site Internet et
                            bénéficie du Service dans le cadre d’un usage
                            strictement privé.
                        </li>
                        <li>
                            Le terme « <strong>Société</strong> » désigne la
                            société
                            <a target="_blank" href="https://habilis.bj"
                                >Habilis</a
                            >
                            BENIN qui édite et exploite la Solution Allô Doto et
                            fournit le Service aux Utilisateurs et aux
                            Professionnels de Santé.
                        </li>

                        <li>
                            Le terme « <strong>Demande d'intervention</strong> »
                            désigne un service de demande de prise en charge en
                            cas de besoin via l'application mobile. Ce service
                            (seulement disponible via l'application mobile)
                            nécessite des configurations en amont sur la
                            plateforme et une adhésion de votre centre de soin à
                            ce service.
                        </li>
                    </ul>
                </div>

                <h3 class="text-sm text-primary font-semibold text-left my-2">
                    Acceptation des Conditions d’Utilisation du Service
                </h3>
                <div>
                    <p class="pt-4">
                        Les présentes Conditions d’Utilisation du Service ont
                        pour objet de définir les conditions dans lesquels
                        l’Utilisateur peut bénéficier du Service fourni par la
                        Société.
                    </p>

                    <p class="pt-4">
                        L’utilisation de ce Service est soumise à l’acceptation
                        inconditionnelle par l’Utilisateur des Conditions
                        Générales d’Utilisation du Service.
                    </p>
                    <p class="py-4">
                        En accédant au Site Internet et en bénéficiant du
                        Service, l’Utilisateur reconnaît donc avoir lu et
                        compris l’intégralité des présentes Conditions Générales
                        d’Utilisation, et les accepter sans restrictions ni
                        réserves.
                    </p>

                    <p>
                        Si l’Utilisateur n’accepte pas ces conditions, il doit
                        cesser d’utiliser le Site Internet (et l'application
                        mobile) et renoncer à bénéficier du Service proposé par
                        la Société.
                    </p>
                    <p></p>
                </div>

                <h3 class="text-sm text-primary font-semibold text-left my-2">
                    Champs d’application
                </h3>
                <div>
                    <p class="py-4">
                        Les présentes Conditions Générales d’Utilisation sont en
                        vigueur à compter du 30 juillet 2013. Elles sont
                        applicables à toute utilisation du Site par
                        l’Utilisateur. Les présentes conditions sont soumises au
                        droit béninois : tout Utilisateur étranger accepte
                        expressément l’application de la loi béninoise en
                        utilisant le Service.
                    </p>
                    <p class="py-4">
                        Dans l’hypothèse où une clause contractuelle
                        particulière serait nulle, illégale, ou inapplicable, la
                        validité des autres dispositions des Conditions
                        Générales n’en serait aucunement affectée.
                    </p>
                </div>
                <h3 class="text-sm text-primary font-semibold text-left my-2">
                    Modification des Conditions d’Utilisation du Service
                </h3>
                <div>
                    <p class="py-4">
                        La Société se réserve la faculté de modifier les
                        présentes Conditions Générales d’Utilisation du Service
                        à tout moment et sans préavis ni information, et sans
                        préjudice. Les Conditions d’Utilisation du Service ainsi
                        modifiées deviennent, dès leurs modifications,
                        effectives et applicables à tous les Utilisateurs et à
                        tous les Professionnels de santé quelque soit la date de
                        leur inscription sur le Site Internet.
                    </p>
                </div>

                <h3 class="text-lg text-primary font-semibold text-left">
                    Description du Service
                </h3>
                <h3 class="text-sm text-primary font-semibold text-left my-2">
                    Objectif du Service
                </h3>
                <div>
                    <p>
                        Le Site internet www.allodoto.com permet de rechercher
                        un Professionnel de Santé, de visualiser les créneaux
                        disponibles chez les Professionnels de Santé autour
                        d’une adresse, et de prendre rendez-vous en ligne avec
                        l’un d’entre eux.Via son application mobile, il est
                        possible de faire une Demande d'intervention auprès du
                        service médical.
                    </p>
                    <p>
                        Le Service vise à faciliter la mise en relation entre le
                        Patient et son Praticien et en aucun cas à la remplacer.
                        Par ailleurs, le Service ne constitue pas un service de
                        recommandation d’un Professionnel de Santé, et ne vise
                        pas à se substituer au libre choix de son Praticien,
                        conformément à la législation en vigueur.
                    </p>
                </div>
                <h3 class="text-sm text-primary font-semibold text-left my-2">
                    Fonctionnement du Service
                </h3>
                <div>
                    <p>
                        L’Utilisateur accède au Service via le Site
                        www.allodoto.com ou l'application mobile (iOS ou
                        android).
                    </p>
                </div>
                <h3 class="text-sm text-primary font-semibold text-left my-2">
                    Fonctionnement du Service
                </h3>
                <div>
                    <p>
                        L’Utilisateur accède au Service via le Site
                        www.allodoto.com ou l'application mobile (iOS ou
                        android).
                    </p>
                    <p>Sur le site, l’Utilisateur peut :</p>
                    <ul>
                        <li>
                            Demande une intervention urgence (Service d'urgence)
                            en cas de malaise (Service soumis à conditions - Non
                            public)
                        </li>
                        <li>
                            Trouver un Professionnel de Santé à partir de
                            paramètres géographiques, de spécialité exercée ou
                            nominatifs
                        </li>
                        <li>
                            Consulter les informations sur les Professionnels de
                            Santé correspondant à leur recherche que ces
                            derniers ont choisi de proposer sur leur fiche
                            Praticien
                        </li>
                        <li>
                            De prendre rendez-vous avec un Professionnel de
                            Santé de leur choix selon les disponibilités de ces
                            derniers
                        </li>
                        <li>
                            De gérer ses rendez-vous (annulation ou confirmation
                            des rendez-vous) et d’en suivre l’historique sur un
                            compte personnel
                        </li>
                    </ul>
                    <p>
                        Chaque demande de rendez-vous est transmise en temps
                        réel au Professionnel de Santé qui doit l’accepter pour
                        que le rendez-vous soit confirmé et que la mise en
                        relation entre l’Utilisateur et le Professionnel de
                        Santé soit réputée avérée.
                    </p>
                </div>
                <h3 class="text-sm text-primary font-semibold text-left my-2">
                    Gratuité du Service pour l’Utilisateur
                </h3>
                <div>
                    <p>
                        Le Service de recherche d’un Professionnel de Santé et
                        de prise de rendez-vous en ligne est proposé
                        gratuitement à l’Utilisateur.
                    </p>
                </div>
                <h3 class="text-sm text-primary font-semibold text-left my-2">
                    Limitations du Service
                </h3>
                <div>
                    <p>
                        L'inscription sur le Site est ouverte à l’ensemble des
                        Professionnels de Santé qui en font la demande moyennant
                        un abonnement. Ainsi, seuls les Professionnels de Santé
                        qui se seront inscrits et auront choisi un plan
                        tarifaire sur le Site pourront bénéficier de toutes les
                        fonctionnalités qu’offre DabaDoc et être identifiés au
                        cours d’une recherche. Le Service de recherche d’un
                        Professionnel de Santé et de prise de rendez-vous en
                        ligne est proposé gratuitement à l’Utilisateur. Il est
                        en revanche entendu que les actes ou soins médicaux
                        pratiqués par le Professionnel de Santé dans le cadre
                        d’un rendez-vous pris via le Site feront l’objet d’une
                        facturation d’honoraires, conformément aux conventions
                        de soins en vigueur chez ce Professionnel de Santé.
                    </p>
                    <p>
                        La mise en œuvre de la Prestation par le Professionnel
                        de Santé est sous l’entière responsabilité du
                        Professionnel de Santé. La Société ne pourra être tenu
                        responsable de la non-exécution ou de la mauvaise
                        exécution de la Prestation proposée par le Professionnel
                        de Santé et ne pourra être redevable des frais éventuels
                        engagés par l’Utilisateur dans ce cadre. L’utilisation
                        du Service par le Professionnel de Santé n’exempt ni
                        n’atténue en aucune façon celui-ci de ses obligations
                        professionnelles et de sa responsabilité vis-à-vis de
                        l’Utilisateur.
                    </p>
                    <p>
                        Le Professionnel de santé renonce à tous recours à
                        l’encontre de la Société, en ce compris de toute action
                        en responsabilité contractuelle ou délictuelle, au titre
                        de la mise en relation avec les patients et plus
                        généralement au titre de tout acte de télémédecine
                        réalisé avec des patients par l’intermédiaire de la
                        plateforme opérée par la Société.
                    </p>
                </div>
                <br />
                <h3 class="text-sm text-primary font-semibold text-left my-2">
                    Situations d’urgence
                </h3>
                <div>
                    <p>
                        Le Service proposé par la Société n’est pas un service
                        d’urgence. Chaque demande de rendez-vous formulée par
                        l’Utilisateur est soumise à l’acceptation du Praticien.
                        La Société ne pourra pas être tenue pour responsable de
                        l’indisponibilité d’un Professionnel de Santé, du délai
                        d’acceptation du rendez-vous, ou de sa modification ou
                        son annulation éventuelle. En conséquence, en situation
                        d’urgence, l’Utilisateur ne doit utiliser le Service que
                        s'il dispose d'un contrat de Demande d'intervention via
                        notre plateforme; dans le cas échéant il doit s’adresser
                        directement aux services d’urgences situés à proximité
                        de lui.
                    </p>
                </div>
                <br />
                <h3 class="text-lg text-primary font-semibold text-left">
                    Informations fournies par les Professionnels de Santé
                </h3>
                <div>
                    <p>
                        Le Service est proposé par la Société à la demande du
                        Professionnel de Santé. Dans le cadre de son adhésion au
                        Service, la Société met à disposition du Professionnel
                        de Santé une page sur laquelle ce dernier peut
                        renseigner des informations le concernant ou concernant
                        son exercice médical (diplômes, formation etc…). Ces
                        informations sont fournies par les Professionnels de
                        Santé : elles ne sont pas vérifiées ni validées par la
                        Société et sont sous la seule responsabilité du
                        Professionnel de Santé qui certifie sur l’honneur que
                        toutes les informations personnelles sont vraies.
                    </p>
                </div>

                <h3 class="text-lg text-primary font-semibold text-left mt-3">
                    Règles d’utilisation du service
                </h3>
                <br />
                <h3 class="text-sm text-primary font-semibold text-left my-2">
                    Conditions d’accès au service
                </h3>
                <div>
                    <p>
                        Le Service est ouvert à tous mais son utilisation est
                        subordonnée aux conditions suivantes :
                    </p>
                    <ul>
                        <li>Être majeur</li>
                        <li>
                            Reconnaitre avoir lu et compris l’intégralité des
                            présentes Conditions Générales d’Utilisation, et les
                            accepter sans restrictions ni réserves
                        </li>
                        <li>
                            Consentir à la collecte et au traitement de données
                            personnelles afin d’assurer le bon fonctionnement du
                            Service, et ce dans le strict respect de notre
                            Politique de Confidentialité et des règles de
                            protection des données personnelles en vigueur
                        </li>
                        <li>
                            Demander la création de votre compte chez votre
                            Médécin ou créer un compte personnel et fournir les
                            informations nécessaires au bon fonctionnement du
                            Service de prise de rendez-vous
                        </li>
                        <li>
                            Accéder au Service en utilisant votre véritable
                            identité et s’engager à renseigner des informations
                            authentiques lors de la création du compte
                            Utilisateur
                        </li>
                        <li>
                            En aucun cas la Société ne peut être tenue pour
                            responsable de l’utilisation frauduleuse du compte
                            d’un Utilisateur si ce dernier a informé directement
                            ou indirectement, volontairement ou involontairement
                            un tiers de son mot de passe.
                        </li>
                    </ul>
                </div>
                <br />
                <h3 class="text-sm text-primary font-semibold text-left my-2">
                    Règles de bonne conduite sur le site
                </h3>
                <div>
                    <p>
                        L’accès au Service proposé par la Société implique que
                        l’Utilisateur s’engage au respect d’une charte de bonne
                        conduite. L’utilisation du Service est soumise à
                        l’acceptation et au strict respect des règles ci-dessous
                        :
                    </p>
                    <ul>
                        <li>
                            Il est rappelé à l’Utilisateur que l’utilisation du
                            Service pour prendre rendez-vous constitue un
                            engagement ferme de sa part envers le Professionnel
                            de Santé. L’Utilisateur s’engage à assumer toutes
                            les conséquences d’une non-présentation à un ou
                            plusieurs rendez-vous,
                        </li>
                        <li>
                            L’Utilisateur s’engage à prévenir la Société ou le
                            Professionnel de Santé en cas d’impossibilité à se
                            rendre à un rendez-vous pris via le Site. Il devra
                            utiliser pour cela la fonctionnalité « Annuler un
                            rendez-vous »,
                        </li>
                        <li>
                            L’Utilisateur s’engage à veiller à conserver son nom
                            d’utilisateur et son mot de passe confidentiel afin
                            de protéger les données personnelles qu’il
                            renseigne,
                        </li>
                        <li>
                            L’Utilisateur s’engage à n’utiliser le Service qu’à
                            des fins personnelles : le Service ne peut en aucune
                            façon être utilisé à des fins commerciales ou
                            lucratives, et en particulier ne peut en aucun cas
                            être utilisé pour recueillir des informations
                            commerciales,
                        </li>
                        <li>
                            L’Utilisateur s’engage à ne pas accéder et utiliser
                            le Site ou le Service fourni par la Société à des
                            fins illicites ou dans le but de causer un préjudice
                            à la réputation et l'image de la Société ou plus
                            généralement à porter atteinte aux droits, notamment
                            de propriété intellectuelle, de la Société et/ou des
                            Professionnels de Santé,
                        </li>
                        <li>
                            L’Utilisateur s’engage à ne pas utiliser des
                            dispositifs ou des logiciels autres que ceux fournis
                            par la Société dans le but d’affecter ou de tenter
                            d’affecter le bon fonctionnement du Site ou du
                            Service ou encore d’extraire ou de modifier tout ou
                            partie du Site,
                        </li>
                        <li>
                            L’Utilisateur s’engage à ne pas copier tout ou
                            partie du contenu présent sur le Site sur quelque
                            support que ce soit sans autorisation écrite de la
                            part de la Société,
                        </li>
                        <li>
                            De façon générale, l’Utilisateur s’engage à accéder
                            et à utiliser le Site et les Services en toute bonne
                            foi, de manière raisonnable, non contraire aux
                            termes de la présente Charte et pour une utilisation
                            strictement personnelle et à des fins non
                            lucratives.
                        </li>
                    </ul>

                    <p>
                        En cas de manquement à l’un ou l’autre de ces
                        engagements, et sans que cette liste ne soit limitative,
                        l'Utilisateur reconnaît et accepte que la Société aura
                        la faculté de lui refuser, unilatéralement et sans
                        notification préalable, l'accès à tout ou partie du Site
                        Internet.
                    </p>
                </div>
                <br />
                <h3 class="text-lg text-primary font-semibold text-left">
                    Protection des données à caractère personnel
                </h3>
                <div>
                    <p>
                        Vous vous engagez à fournir des informations exactes
                        quant à vos coordonnées personnelles ainsi que toute
                        autre information nécessaire à l'accès aux Services du
                        Site et à mettre à jour régulièrement ces informations
                        si nécessaire.
                    </p>
                    <p>
                        Au Bénin, conformément à l’article 378 de la Loi
                        N°2017-20 du 20 Avril 2018 portant code du numérique en
                        République du Bénin, relative à la protection des
                        personnes physiques à l'égard du traitement des données
                        à caractère personnel, vous bénéficiez d'un droit
                        d'accès et de rectification aux informations qui vous
                        concernent, que vous pouvez exercer en vous adressant à
                        hello@habilis.bj. Vous pouvez également, pour des motifs
                        légitimes, vous opposer à ce que les données qui vous
                        concernent fassent l'objet d'un traitement. Ce
                        traitement a été notifié et autorisé par la APDP au
                        titre du récépissé / autorisation N° A-W-353/2017.
                    </p>
                </div>
                <br />
                <h3 class="text-lg text-primary font-semibold text-left">
                    Convention de preuve – Signature électronique
                </h3>
                <div>
                    <p>
                        Les registres informatisés conservés dans les systèmes
                        informatiques de la Société seront considérés comme les
                        preuves des communications et des différentes
                        transmissions d’écrits et de documents électroniques
                        entre les Utilisateurs et la Société.
                    </p>
                    <p>
                        L’Utilisateur reconnaît et accepte, qu’après son
                        identification, toute manifestation de volonté par le
                        biais de l’utilisation des fonctionnalités proposées au
                        sein de la Solution, et en particulier l’acceptation des
                        CGU, des CGV et la sélection des Services, constitue une
                        signature électronique au sens des dispositions des
                        articles 1366 et suivants du Code civil, et manifeste
                        son consentement en caractérisant sa preuve.
                    </p>
                    <p>
                        Conformément aux dispositions des articles 1366 et
                        suivants du Code civil, la mise en œuvre d’une signature
                        électronique, sur la base d’un procédé fiable
                        d'identification garantissant son lien avec l'acte
                        auquel elle s'attache, est considérée comme une
                        signature valable et comme une preuve au sens des
                        dispositions précitées.
                    </p>
                    <p>
                        L’Utilisateur ne pourra pas contester la recevabilité,
                        la validité ou la force probante des éléments sous
                        format ou support électronique précités, sur le
                        fondement de quelque disposition légale que ce soit et
                        qui spécifierait que certains documents doivent être
                        écrits ou signés pour constituer une preuve. Ainsi, les
                        éléments considérés constituent des preuves et, s’ils
                        sont produits comme moyens de preuve par la Société dans
                        toute procédure contentieuse ou autre, seront
                        recevables, valables et opposables de la même manière,
                        dans les mêmes conditions et avec la même force probante
                        que tout document qui serait établi, reçu ou conservé
                        par écrit.
                    </p>
                </div>
                <br />
                <h3 class="text-lg text-primary font-semibold text-left">
                    Limitation de responsabilité
                </h3>
                <h3 class="text-sm text-primary font-semibold text-left my-2">
                    Disponibilité du Service
                </h3>
                <div>
                    <p>
                        La Société fait ses meilleurs efforts afin de rendre son
                        Service disponibles 24 heures sur 24 et 7 jours sur 7.
                        Cependant, compte tenu des spécificités du réseau
                        Internet, la Société n'offre aucune garantie de
                        continuité du service, n'étant tenue à cet égard que
                        d'une obligation de moyens.
                    </p>
                    <p>
                        La responsabilité de la Société ne peut pas être engagée
                        en cas de dommages liés à l'impossibilité temporaire
                        d'accéder à l'un des services proposés par le Site.
                    </p>
                </div>
                <br />
                <h3 class="text-sm text-primary font-semibold text-left my-2">
                    Modification du site
                </h3>
                <div>
                    <p>
                        La Société se réserve la possibilité de modifier,
                        interrompre, à tout moment, temporairement ou de manière
                        permanente tout ou partie du Service sans information
                        préalable des Utilisateurs et sans droit à indemnités.
                    </p>
                </div>
                <br />
                <h3 class="text-sm text-primary font-semibold text-left my-2">
                    Responsabilité de l’Utilisateur
                </h3>
                <div>
                    <p>
                        L'Utilisateur est seul responsable de l'utilisation
                        qu'il fait du Site Internet et du Service auquel il
                        accède depuis le Site Internet
                    </p>
                    <p>
                        La Société ne pourra en aucun cas être tenue responsable
                        dans le cadre d'une procédure introduite à l'encontre de
                        l'Utilisateur qui se serait rendu coupable d'une
                        utilisation non-conforme du Site Internet et/ou du
                        Service qu'il procure.
                    </p>
                    <p>
                        L'Utilisateur reconnaît et accepte à cet égard qu'il
                        fera son affaire personnelle de toute réclamation ou
                        procédure formée contre la Société, du fait de
                        l'utilisation non-conforme par lui du Service et/ou du
                        Site Internet.
                    </p>
                </div>
                <br />
                <h3 class="text-sm text-primary font-semibold text-left my-2">
                    Force majeure
                </h3>
                <div>
                    <p>
                        La responsabilité de la Société ne pourra pas être
                        recherchée si l'exécution de l'une de ses obligations
                        est empêchée ou retardée en raison d'un cas de force
                        majeure tel que définie par la jurisprudence des
                        Tribunaux béninois, et notamment les catastrophes
                        naturelles, incendies, dysfonctionnement ou interruption
                        du réseau de télécommunications ou du réseau électrique.
                    </p>
                </div>
                <br />
                <h3 class="text-lg text-primary font-semibold text-left">
                    Réclamation et résiliation
                </h3>
                <div>
                    <p>
                        Pour toute information relative au fonctionnement du
                        Service accessible via le Site, l'Utilisateur est invité
                        à se reporter à la rubrique Contacts accessible sur le
                        Site Internet.
                    </p>
                </div>
                <br />
                <h3 class="text-lg text-primary font-semibold text-left">
                    Protection des signes distinctifs
                </h3>
                <div>
                    <p>
                        Les marques, logos, dénominations sociales, sigles, noms
                        commerciaux, enseignes et nom de domaine de la Société
                        permettant l'accès au Service constituent des signes
                        distinctifs insusceptibles d'utilisation sans
                        l'autorisation expresse et préalable de leur titulaire.
                    </p>
                    <p>
                        Toute représentation, reproduction ou exploitation
                        partielle ou totale de ces signes distinctifs est donc
                        prohibée et constitutif de contrefaçon de marque, en
                        application des dispositions du Code de la propriété
                        intellectuelle de l’OMPIC, d'usurpation de dénomination
                        sociale, nom commercial et de nom de domaine engageant
                        la responsabilité civile délictuelle de son auteur.
                    </p>
                </div>
                <br />
                <h3
                    class="hidden text-lg text-primary font-semibold text-left"
                    id="teleconsultation"
                >
                    Service de Téléconsultation
                </h3>
                <div class="hidden">
                    <p>
                        Les Services proposés permettent une mise en relation
                        avec des médecins inscrits au Conseil de l’Ordre
                        National des Médecins via le site www.dabadoc.com et qui
                        reconnaissent être titulaire d’une autorisation délivrée
                        par le ministre de la santé, conformément aux
                        dispositions du décret n°2-18-378 du 25 juillet 2018
                        relatif à la télémédecine.
                    </p>
                    <p>
                        Les Services permettent ainsi aux utilisateurs d’accéder
                        à un service de téléconsultation délivré par des
                        médecins.
                    </p>
                    <br />
                    <p>
                        Le Service de téléconsultation ne correspond pas à un
                        service d’urgence. Les médecins répondent, dans le
                        domaine de leurs compétences, de leurs qualifications
                        ordinales, aux Utilisateurs en se basant, d’une part,
                        sur les informations fournies par les Utilisateurs,
                        d’autre part, sur les données acquises de la science et
                        les bonnes pratiques.
                    </p>
                    <p>
                        L’Utilisateur est informé et accepte que le Service de
                        téléconsultation ne comprend pas les actes suivants :
                    </p>
                    <ul>
                        <li>Les prescriptions d’arrêts de travail</li>
                        <li>Les prescriptions de certificats médicaux</li>
                        <li>
                            Les prescriptions soumises à des règles
                            particulières
                        </li>
                    </ul>
                    <p>
                        L'Utilisateur s'engage à délivrer, sous sa
                        responsabilité, son contrôle et sa direction, des
                        informations réelles et exactes et à ne pas usurper
                        l’identité d’un tiers, ni à masquer ou modifier des
                        informations le concernant.
                    </p>
                    <p>
                        Le Service de téléconsultation est délivré par les
                        médecins avec le consentement libre et éclairé de
                        l’Utilisateur après information :
                    </p>
                    <ul>
                        <li>de la collecte et du traitement de ses données</li>
                        <li>
                            des modalités de réalisation de l’acte de
                            téléconsultation
                        </li>
                        <li>
                            du cadre légal et réglementaire applicable aux actes
                            de télémédecine, notamment les dispositions du
                            décret n°2-18-378 du 25 juillet 2018, ainsi qu’une
                            renonciation à toute réclamation/action en
                            responsabilité à l’égard de la Société à ce titre.
                        </li>
                    </ul>
                    <p>
                        Les informations personnelles concernant les
                        Utilisateurs sont confidentielles. Les questions posées
                        par les Utilisateurs, ainsi que les réponses faites par
                        les médecins dans le cadre des Services sont également
                        absolument confidentielles, et protégées par le secret
                        médical.
                    </p>
                </div>
                <br />

                <h3
                    class="text-lg text-primary font-semibold text-left"
                    id="emergency"
                >
                    Service de prises en charge ambulatoires
                </h3>
                <div>
                    <p>
                        Ce service n'est pas public et n'est donc accessible
                        qu'avec une configuration précise.
                    </p>
                    <p>
                        Le Service de de Demande d'intervention permet à un
                        utilisateur suite à son inscription en tant que patient
                        dans le registre d'un praticien disposant d'un service
                        des urgences au sein de son établissement de formuler
                        une demande d'intervention (en cas d'urgence) par
                        l'intermédiaire de la plateforme Allô Doto spécialement
                        ici notre application mobile.
                    </p>

                    <p>
                        Via l'application, l'utilisateur est informé à chaque
                        étape de sa prise en charge
                    </p>
                </div>
            </div>
        </div>
    </div>
    <Footer></Footer>
</template>
<script>
import { Head } from "@inertiajs/vue3";
import Footer from "@/components/footer/index.vue";
export default {
    components: { Footer, Head },
    setup() {},
};
</script>
