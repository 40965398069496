<template>
    <div class="flex">
        <div class="flex flex-col left-box overflow-visible">
            <div class="mb-10 mt-10">
                <div class="flex items-end" style="white-space: nowrap">
                    <h1 class="text-5xl font-medium text-primary leading-snug">
                        Besoin d'un
                    </h1>

                    <div class="border-primary1-800 px-1 rounded mx-2">
                        <h1
                            class="inline-block font-black list-enter-active text-5xl px-1 font-ibm text-slate-400 leading-snug transition transition-all"
                        >
                            <VueTyper :text="list" />
                        </h1>
                        <div class="hidden">
                            <h3
                                v-for="(item, i) in list"
                                :key="i"
                                :class="`${
                                    i == index ? 'block' : 'hidden'
                                } inline-block font-black list-enter-active italic text-5xl px-1 font-ibm text-slate-400 leading-snug transition transition-all`"
                            >
                                {{ item }}
                            </h3>
                        </div>
                    </div>

                    <h3 class="text-5xl font-medium text-primary leading-snug">
                        ?
                    </h3>
                </div>
                <strong
                    class="text-6xl font-black font-medium text-primary py-0 leading-snug italic"
                    >c'est ici,</strong
                >
                <h2 class="text-6xl font-black text-primary font-medium italic">
                    et <a href="/create-my-account" class="">maintenant</a>.
                </h2>
            </div>

            <form id="welcome-form">
                <div class="mt-20">
                    <div
                        class="flex search-box bg-slate-100 justify-between rounded-lg"
                    >
                        <div
                            class="name-box w-1/2 flex ml-2 justify-start items-center border-r border-[#e3e6e7]"
                        >
                            <div
                                class="text-xs mx-1 text-[#a3abb0] border-right"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="w-5 h-5"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                                    />
                                </svg>
                            </div>

                            <input
                                class="w-full"
                                type="text"
                                v-model="text"
                                placeholder="Nom, établissement, spécialité"
                            />
                        </div>
                        <div class="place-box flex items-center">
                            <div class="ml-3 mr-1 text-xs text-[#a3abb0]">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="w-5 h-5"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                                    />
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                                    />
                                </svg>
                            </div>
                            <input
                                class="w-full"
                                type="text"
                                placeholder="Où ?"
                                v-model="place"
                            />
                        </div>
                        <div class="py-1 mr-1">
                            <button
                                type="submit"
                                style="border-radius: 0 8px 8px 0"
                                class="flex py-4 px-2 text-slate-800 bg-opacity-25 hover:bg-opacity-50 justify-center items-center h-full bg-slate-800"
                                @click.prevent="search"
                            >
                                <span class="mx-1">Rechercher</span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="w-6 h-6"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M8.25 4.5l7.5 7.5-7.5 7.5"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div
            class="bg-right right-box top-0 bg-no-repeat h-full bg-contain absolute right-0"
            :style="`background-image: url(${images[index]})`"
        ></div>
    </div>
</template>
<script>
import {
    defineComponent,
    computed,
    ref,
    onMounted,
    onBeforeUnmount,
} from "vue";
import VueTyper from "vue3-typer";
import "vue3-typer/dist/vue-typer.css";
export default defineComponent({
    components: { VueTyper },
    setup() {
        const interval = ref(null);
        const index = ref(0);
        const text = ref("");
        const place = ref("");
        const list = computed(() => [
            "médécin généraliste",
            "pédiatre",
            "psychiatre",
            "dermatologue",
            "gynécologue",
        ]);

        const images = computed(() => [
            "/images/bg-general-4.svg",
            "/images/bg-5.svg",
            "/images/bg-6.svg",
            "/images/bg-7.svg",
            "/images/bg-8.svg",
        ]);

        const currentElm = computed(() => list.value[index.value]);

        const search = () => {
            let query = "";
            if (text.value) query += `?search=${text.value}`;
            if (place.value) query += `?where=${place.value}`;

            if (typeof window != "undefined")
                window.location.replace(`/search-praticians${query}`);
        };

        onMounted(() => {
            interval.value = setInterval(() => {
                if (index.value === list.value.length - 1) index.value = 0;
                else index.value += 1;
            }, 5000);
        });

        onBeforeUnmount(() => {
            clearInterval(interval.value);
        });
        return { place, text, list, currentElm, images, index, search };
    },
});
</script>
<style lang="scss">
.search-box {
    width: 100%;
    input[type="text"],
    textarea {
        @apply bg-slate-100 #{!important};
    }
}

.list-enter-active {
    animation: fade-in 0.5s ease-in-out;
}

.list-leave-active {
    animation: fade-in 0.5s ease-in-out reverse;
}

@keyframes fade-in {
    0% {
        opacity: 0;
        transform: translateX(30px);
    }
    100% {
        transform: translateX(0px);
    }
}

@keyframes fade-out {
    0% {
        transform: translateX(0px);
    }
    100% {
        opacity: 0;
        transform: translateX(30px);
    }
}
.left-box {
    width: 55%;
}
.right-box {
    width: 45%;
    &::before {
        content: ""; /* empty but necessary */
        position: absolute;
    }
}

@media (max-width: 500px) {
    .left-box {
        width: 100% !important;
        .text-5xl {
            font-size: 20px !important;
        }
        .text-6xl {
            font-size: 40px !important;
        }
    }
    .right-box {
        width: 0 !important;
    }
    .search-box {
        flex-direction: column;

        .name-box,
        .place-box {
            width: 90% !important;
            height: 50px;
            border: none;
            @apply py-2;
        }
        button {
            width: 99%;
            border-radius: 8px !important;
            @apply ml-1;
        }
    }
}
</style>
