import { helpers } from 'gmap-vue'
const { MapElementFactory } = helpers;



export default MapElementFactory({
    name: "DirectionsRenderer",

    ctr() {
        return google.maps.DirectionsRenderer;
    },

    events: ['directions_changed'],

    mappedProps: {},

    props: {
        origin: { type: [Object, Array] },
        destination: { type: [Object, Array] },
        waypoints: { type: [Object, Array], default: () => ([]) },
        travelMode: { type: String },
        remove: { type: Boolean, default: false },
        handleDirection: { type: Function }
    },

    afterCreate(directionsRenderer) {
        let directionsService = new google.maps.DirectionsService({ color: '#58b368', suppressMarkers: true, suppressPolylines: true, });

        directionsRenderer.addListener("directions_changed", () => {
            const directions = directionsRenderer.getDirections();
            console.log('waypoints', this.waypoints);
            if (directions && this.handleDirection && !this.remove) {
                const { routes: [route] } = directions
                this.handleDirection(route)
            }
        });
        this.$watch(
            () => this.remove,
            (val) => {
                if (val) {
                    //directionsRenderer = new google.maps.DirectionsRenderer()
                    // directionsRenderer.setDirections({ routes: [] });

                    directionsRenderer.set('directions', null)
                }
            })

        this.$watch(
            () => [this.origin, this.destination, this.travelMode],
            () => {

                let { origin, destination, travelMode, waypoints, handleDirection } = this;
                if (!origin || !destination || !travelMode) {

                    return;
                }
                directionsService.route(
                    {
                        origin,
                        destination,
                        travelMode,
                        waypoints
                    },
                    (response, status) => {
                        if (status !== "OK") return;

                        directionsRenderer.setOptions({
                            draggable: true,
                            suppressMarkers: true,
                            polylineOptions: { strokeColor: '#58b368' }
                        })
                        directionsRenderer.setDirections(response);
                        if (handleDirection) {
                            const { routes: [route] } = response

                            handleDirection(route)
                        }
                    }
                );
            }
        );
    },
});
