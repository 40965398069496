<template>
    <div class="w-full flex my-6">
        <div class="w-2/3">
            <div class="rounded-sm bg-white flex flex-col p-3 mb-5">
                <div class="flex justify-between items-center mb-3">
                    <div>
                        <h4 class="card-title font-semibold text-slate-800">
                            Les Pays
                        </h4>
                    </div>
                    <button
                        class="flex justify-center items-center border-none text-sm font-semibold rounded-sm bg-opacity-25 hover:bg-opacity-50 bg-doto-100 text-doto-100 px-2 py-2"
                        @click.prevent="newCountry()"
                    >
                        <i class="la la-plus" />Ajouter un pays
                    </button>
                </div>
                <div>
                    <div class="table-responsive">
                        <div class="overflow-x-auto">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th
                                            class="whitespace-nowrap font-medium text-sm"
                                        >
                                            Nom
                                        </th>
                                        <th
                                            class="whitespace-nowrap font-medium text-sm"
                                        >
                                            Date de creation
                                        </th>
                                        <th
                                            class="whitespace-nowrap font-medium text-sm"
                                        ></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="city in countries"
                                        :key="city.id"
                                    >
                                        <td class="text-sm text-slate-700">
                                            {{ city.name }}
                                        </td>
                                        <td class="text-sm text-slate-700">
                                            {{
                                                moment(city.created_at).format(
                                                    "L"
                                                )
                                            }}
                                        </td>
                                        <td>
                                            <div class="flex justify-end">
                                                <button
                                                    class="py-1 px-2 bg-slate-500 text-sm bg-opacity-25 hover:bg-opacity-50 text-slate-500 rounded mr-1"
                                                    size="sm"
                                                    @click="selectCountry(city)"
                                                >
                                                    <i
                                                        class="las la-eye text-lg"
                                                    ></i>
                                                </button>
                                                <button
                                                    class="bg-warning mr-1 px-2 text-sm py-1 bg-opacity-25 hover:bg-opacity-50 rounded text-warning"
                                                    size="sm"
                                                    @click="
                                                        openNewCityModal(city)
                                                    "
                                                >
                                                    <i
                                                        class="la la-plus text-lg"
                                                    ></i>
                                                    ville
                                                </button>
                                                <button
                                                    class="bg-success mr-1 text-sm px-2 py-1 bg-opacity-25 hover:bg-opacity-50 rounded text-success"
                                                    size="sm"
                                                    @click="
                                                        openEditCountryModal(
                                                            city
                                                        )
                                                    "
                                                >
                                                    <i
                                                        class="la la-pen text-lg"
                                                    ></i>
                                                    Modifier
                                                </button>
                                                <button
                                                    class="px-2 py-1 bg-danger text-sm rounded bg-opacity-25 hover:bg-opacity-50 text-danger"
                                                    size="sm"
                                                    @click="
                                                        openCountryRemoveModal(
                                                            city
                                                        )
                                                    "
                                                >
                                                    <i
                                                        class="la la-trash text-lg"
                                                    ></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="cities.length > 0" class="w-1/3 flex justify-end">
            <div class="w-11/12 flex justify-end">
                <div class="rounded-sm bg-white flex flex-col p-3 mb-5">
                    <div class="flex justify-between mb-3">
                        <div>
                            <p class="card-title">
                                Les Villes -
                                <strong>{{ selectedCountry.name }}</strong>
                            </p>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div class="table-responsive">
                                <table striped class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th
                                                class="whitespace-nowrap font-medium text-sm"
                                            >
                                                Name
                                            </th>
                                            <th class="whitespace-nowrap"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="city in cities"
                                            :key="city.id"
                                        >
                                            <td class="text-sm text-slate-700">
                                                {{ city.name }}
                                            </td>
                                            <td>
                                                <div class="flex justify-end">
                                                    <button
                                                        class="py-1 px-2 bg-slate-500 text-sm text-slate-500 rounded mr-1 bg-opacity-25 hover:bg-opacity-50"
                                                        size="sm"
                                                        @click="edit(city)"
                                                    >
                                                        <i
                                                            class="ri-ball-pen-fill m-0"
                                                        ></i>
                                                        Modifier
                                                    </button>
                                                    <button
                                                        class="py-1 px-2 text-sm bg-danger text-danger rounded ml-1 bg-opacity-25 hover:bg-opacity-50"
                                                        size="sm"
                                                        @click="
                                                            openCityRemoveDialog(
                                                                city
                                                            )
                                                        "
                                                    >
                                                        <i
                                                            class="ri-delete-bin-line m-0"
                                                        ></i>
                                                        Supprimer
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SampleModal
            :is-visible="showNewCityModal"
            width="w-2/5"
            id="new-city"
            centered
            :title="
                city.id
                    ? `Modifier la ville - ${selectedCountry.name}`
                    : `Nouvelle ville`
            "
            @close="cancelCityCreate()"
            @cancel="cancelCityCreate()"
            @submit="city.id ? editCity() : addNewCity()"
        >
            <div class="mb-2">
                <div class="my-2 w-100">
                    <form>
                        <div>
                            <label
                                class="block capitalize tracking-wide text-slate-500 text-xs font-semibold text-left mb-1"
                                for="grid-state"
                            >
                                Nom
                            </label>
                            <div class="h-10">
                                <Field
                                    name="name"
                                    v-slot="{ field }"
                                    v-model="city.name"
                                >
                                    <input
                                        placeholder="Le nom de la ville"
                                        name="name"
                                        focus
                                        v-bind="field"
                                        class="bg-white h-10 rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                    />
                                </Field>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </SampleModal>

        <SampleModal
            :is-visible="showNewCountryModal"
            :title="
                !country.id ? 'Ajouter un nouveau pays' : 'Modifier un pays'
            "
            id="new-country"
            width="w-2/5"
            @close="cancelCountryCreate()"
            @cancel="cancelCountryCreate()"
            @submit="country.id ? editCountry() : addNewCountry()"
        >
            <div class="mb-2">
                <div class="my-2 w-100">
                    <form>
                        <div class="mb-2">
                            <label
                                class="block capitalize tracking-wide text-slate-500 text-xs font-semibold text-left mb-1"
                                for="grid-state"
                            >
                                Nom
                            </label>
                            <div class="h-10">
                                <Field
                                    name="name"
                                    v-slot="{ field }"
                                    v-model="country.name"
                                >
                                    <input
                                        placeholder="Entrer le nom du pays"
                                        name="name"
                                        focus
                                        v-bind="field"
                                        class="bg-white h-10 rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                    />
                                </Field>
                            </div>
                        </div>
                        <div class="mb-2">
                            <label
                                class="block capitalize tracking-wide text-slate-500 text-xs font-semibold text-left mb-1"
                                for="grid-state"
                            >
                                Code
                            </label>
                            <div class="h-10">
                                <Field
                                    name="code"
                                    v-slot="{ field }"
                                    v-model="country.code"
                                >
                                    <input
                                        placeholder="Entrer le code"
                                        name="code"
                                        focus
                                        v-bind="field"
                                        class="bg-white h-10 rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                    />
                                </Field>
                            </div>
                        </div>
                        <div class="mb-2">
                            <label
                                class="block capitalize tracking-wide text-slate-500 text-xs font-semibold text-left mb-1"
                                for="grid-state"
                            >
                                Indicatif
                            </label>
                            <div class="h-10">
                                <Field
                                    name="indicative"
                                    v-slot="{ field }"
                                    v-model="country.indicative"
                                >
                                    <input
                                        placeholder="Entrer l'indicative du pays (Ex: 225)"
                                        focus
                                        v-bind="field"
                                        class="bg-white h-10 rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                    />
                                </Field>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </SampleModal>

        <ConfirmDialog></ConfirmDialog>
    </div>
</template>

<script>
import store from "@/store";
import { mapGetters } from "vuex";
import moment from "moment";
import { Form, Field } from "vee-validate";
import { ref } from "vue";
import SampleModal from "@/components/Modal/SampleModal.vue";
import { useConfirm } from "primevue/useconfirm";
import ConfirmDialog from "primevue/confirmdialog";
import { TYPE } from "vue-toastification";
export default {
    components: { Field, SampleModal, ConfirmDialog },
    beforeRouteEnter(to, from, next) {
        store.dispatch("config/getCountries").then((res) => next());
        // store.dispatch('config/getCities').then(res => next() )
    },
    setup(props) {
        const confirm = useConfirm();
        const country = ref({});
        const city = ref({});
        const showNewCountryModal = ref(false);
        const showNewCityModal = ref(false);
        const canDeleteCity = ref(false);
        const openEditCountryModal = (data) => {
            console.log(data);
            country.value = { ...data };

            showNewCountryModal.value = true;
        };
        const addNewCountry = () => {
            store.dispatch("config/createCountry", {
                data: country.value,
                onSuccess: () => {
                    showNewCountryModal.value = false;
                    country.value = {};
                    store.dispatch("medical/displayToast", {
                        message: "Ville ajoutée avec succès.",
                        context: country.value?.name,
                        type: TYPE.SUCCESS,
                    });
                },
                onError: () => {
                    store.dispatch("medical/displayToast", {
                        message: "Oooops, une erreur est survenue.",
                        context: country.value?.name,
                        type: TYPE.ERROR,
                    });
                },
            });
        };

        const editCountry = () => {
            console.log("country.value", country.value);
            store.dispatch("config/editCountry", {
                data: country.value,
                id: country.value?.id,
                onSuccess: () => {
                    showNewCountryModal.value = false;
                    country.value = {};
                    store.dispatch("medical/displayToast", {
                        message: "Ville modifiée avec succès.",
                        context: country.value?.name,
                        type: TYPE.SUCCESS,
                    });
                },
                onError: () => {
                    store.dispatch("medical/displayToast", {
                        message: "Oooops, une erreur est survenue.",
                        context: country.value?.name,
                        type: TYPE.ERROR,
                    });
                },
            });
        };

        const newCountry = () => {
            country.value = {};
            showNewCountryModal.value = true;
        };

        const cancelCityCreate = () => {
            showNewCityModal.value = false;
        };

        const openNewCityModal = (country) => {
            city.value = { country_id: country.id, name: "" };
            showNewCityModal.value = true;
        };
        const addNewCity = () => {
            store.dispatch("config/createCity", {
                data: {
                    ...city.value,
                },
                onSuccess: () => {
                    showNewCityModal.value = false;
                    store.dispatch("medical/displayToast", {
                        message: "Ville créée avec succès.",
                        context: city.value?.name,
                        type: TYPE.SUCCESS,
                    });
                    city.value = {};
                },
                onError: () => {
                    store.dispatch("medical/displayToast", {
                        message: "Oooops, une erreur est survenue.",
                        context: city.value?.name,
                        type: TYPE.ERROR,
                    });
                },
            });
        };

        const edit = (data) => {
            console.log(data);
            city.value = { ...data };
            showNewCityModal.value = true;
        };
        const editCity = () => {
            store.dispatch("config/editCity", {
                id: city.value?.id,
                data: { ...city.value },
                onSuccess: () => {
                    showNewCityModal.value = false;

                    store.dispatch("medical/displayToast", {
                        message: "Ville modifiée avec succès.",
                        context: `${country.value?.name}`,
                        type: "TYPE.SUCCESS",
                    });
                    country.value = {};
                    city.value = {};
                },
                onError: () => {
                    store.dispatch("medical/displayToast", {
                        message: "Oooops, une erreur est survenue.",
                        context: city.value?.name,
                        type: TYPE.ERROR,
                    });
                },
            });
        };

        // const removeCity = () => {
        //     store.dispatch("config/removeCity", {
        //         id: city.value?.id,
        //         onSuccess: () => {
        //             canDeleteCity.value = false;

        //             store.dispatch("medical/displayToast", {
        //                 message: "Ville modifiée avec succès.",
        //                 context: `${country.value?.name}`,
        //                 type: TYPE.SUCCESS,
        //             });
        //             city.value = {};
        //         },
        //         onError: () => {
        //             store.dispatch("medical/displayToast", {
        //                 message: "Oooops, une erreur est survenue.",
        //                 context: country.value?.name,
        //                 type: TYPE.ERROR,
        //             });
        //         },
        //     });
        // };

        const openCityRemoveModal = (data) => {
            console.log(data);
            city.value = { ...data };
            canDeleteCity.value = true;
        };

        const cancelCountryCreate = () => {
            showNewCountryModal.value = false;
        };

        const removeCountry = () => {
            store.dispatch("config/removeCountry", {
                id: country.value.id,
                onSuccess: () => {
                    store.dispatch("medical/displayToast", {
                        message: "Ville supprimée avec succès.",
                        context: `${country.value?.name}`,
                        type: TYPE.SUCCESS,
                    });
                },
                onError: () => {
                    store.dispatch("medical/displayToast", {
                        message: "Oooops, une erreur est survenue.",
                        context: country.value?.name,
                        type: TYPE.ERROR,
                    });
                },
            });
        };

        const removeCity = () => {
            store.dispatch("config/removeCity", {
                id: city.value.id,
                onSuccess: () => {
                    get;
                    store.dispatch("medical/displayToast", {
                        message: "Ville supprimée avec succès.",
                        context: `${country.value?.name}`,
                        type: TYPE.SUCCESS,
                    });
                },
                onError: () => {
                    store.dispatch("medical/displayToast", {
                        message: "Oooops, une erreur est survenue.",
                        context: country.value?.name,
                        type: TYPE.ERROR,
                    });
                },
            });
        };

        const openCityRemoveDialog = (city) => {
            city.value = city;

            confirm.require({
                message:
                    "Êtes-vous sûr de vouloir supprimer cette ville de l'application ?",
                header: "Suppression",
                icon: "la la-exclamation-circle",
                rejectLabel: "Non",
                acceptLabel: "Oui",
                rejectClass:
                    "bg-danger text-danger py-1 text-sm text-medium px-2 mr-2 bg-opacity-25",
                acceptClass:
                    "bg-primary text-primary py-1 text-sm text-medium px-2  bg-opacity-25",
                accept: () => {
                    removeCity();
                },
                reject: () => {},
            });
        };

        const openCountryRemoveModal = (value) => {
            country.value = value;

            confirm.require({
                message:
                    "Êtes-vous sûr de vouloir supprimer ce pays de l'application ?",
                header: "Suppression",
                icon: "la la-exclamation-circle",
                rejectLabel: "Non",
                acceptLabel: "Oui",
                rejectClass:
                    "bg-danger text-danger py-1 text-sm text-medium px-2 mr-2 bg-opacity-25",
                acceptClass:
                    "bg-primary text-primary py-1 text-sm text-medium px-2  bg-opacity-25",
                accept: () => {
                    removeCountry();
                },
                reject: () => {},
            });
        };

        return {
            moment,
            showNewCountryModal,
            country,
            city,
            canDeleteCity,
            showNewCityModal,
            addNewCountry,
            removeCity,
            openNewCityModal,
            editCountry,
            addNewCity,
            newCountry,
            openCityRemoveModal,
            editCity,
            edit,
            openCountryRemoveModal,
            openCityRemoveDialog,
            openEditCountryModal,
            cancelCountryCreate,
            cancelCityCreate,
        };
    },
    watch: {
        countries: function (val) {
            if (this.selectedCountry) {
                const country = this.countries.find(
                    (x) => x.id === this.selectedCountry.id
                );
                if (country) {
                    this.cities = country.cities;
                }
            }
        },
    },
    data() {
        return {
            // showNewCityModal: false,
            // canDeleteCity: false,
            canDeleteCountry: false,
            // showNewCountryModal: false,
            cities: [],
            selectedCountry: null,
            selectedCity: null,
            countryName: "",
            cityName: "",
            countryIndicative: "",
            countryCode: "",
        };
    },

    methods: {
        //   openEditCountryModal(city) {
        //     this.cityName = city.name;
        //     this.showNewCityModal = true;
        //   },

        selectCountry(country) {
            this.selectedCountry = country;
            this.cities = [...country.cities];
        },
        selectCity(city) {
            this.selectedCity = city;
        },

        addNewCountry3() {
            const data = {
                name: this.countryName,
                code: this.countryCode,
                indicative: this.countryIndicative,
            };
            console.log(data);
        },
    },

    computed: {
        ...mapGetters({
            countries: "config/countries",
        }),
    },
    mounted() {},
};
</script>
