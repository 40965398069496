<template>
    <nav
        class="bg-primary w-full border-gray-200 dark:bg-gray-900 sticky top-0 h-20 z-50 flex justify-center"
    >
        <div
            class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto px-12 w-full"
        >
            <a href="/" class="flex items-center">
                <img
                    alt="Allô Doto Image"
                    class="logo__image w-28"
                    src="/images/logo_white.svg"
                />
            </a>

            <div
                class="items-center justify-between xs:w-full sm:w-full md:flex md:w-auto md:order-0"
                id="navbar-user"
            >
                <ul
                    class="flex sm:flex-col xs:flex-colfont-medium md:p-0 bg-primary rounded-lg md:flex-row md:space-x-8 md:mt-0 md:border-0 dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700"
                >
                    <li class="py-1">
                        <a
                            href="#"
                            class="block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-blue-500"
                            aria-current="page"
                            ><i class="la la-question-circle mr-1"></i>Aide
                        </a>
                    </li>
                    <li
                        :class="`py-1 px-2 hover:bg-opacity-25 cursor-pointer ${
                            [
                                'patient.appointments',
                                'patient.appointment',
                            ].includes($route.name)
                                ? 'bg-slate-800 bg-opacity-25'
                                : ''
                        }`"
                    >
                        <router-link
                            to="/account/appointments"
                            class="block py-2 pl-3 pr-4 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                            >Mes rendez-vous</router-link
                        >
                    </li>
                    <li
                        :class="`py-1 px-2 hover:bg-opacity-25 cursor-pointer ${
                            pathName == '/account/documents'
                                ? 'bg-slate-800 bg-opacity-25'
                                : ''
                        }`"
                    >
                        <router-link
                            to="/account/documents"
                            class="block py-2 text-white rounded pl-2 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                            >Mes documents
                        </router-link>
                    </li>
                </ul>
            </div>
            <div
                class="z-50 hidden my-4 text-base n list-none bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600"
                id="user-dropdown"
            >
                <!-- <div class="flex items-center md:order-2"> -->
                <Menu ref="menu" id="overlay_menu" :model="items" :popup="true">
                    <template #start>
                        <button
                            class="w-full p-link flex items-center p-2 pl-3 text-color hover:surface-200 border-noround border-b border-slate-300 rounded-none"
                        >
                            <div class="flex flex-col justify-center">
                                <span class="font-medium font-ibm">{{
                                    user?.full_name
                                }}</span>
                                <span class="text-xs font-qs">{{
                                    user?.username
                                }}</span>
                            </div>
                        </button>
                    </template>
                    <template #item="{ item, label, props }">
                        <a class="flex" v-bind="props.action" :href="item.url">
                            <span v-bind="props.icon" />
                            <span v-bind="props.label">{{ label }}</span>
                            <Badge
                                v-if="item.badge"
                                class="ml-auto"
                                :value="item.badge"
                            />
                        </a>
                    </template>
                    <template #end>
                        <button
                            class="w-full text-danger p-link flex items-center rounded-none p-2 pl-4 text-color hover:surface-200 border-t border-slate-300"
                            @click="logout"
                        >
                            <i class="la la-sign-out" />
                            <span class="ml-2">Déconnexion</span>
                        </button>
                    </template>
                </Menu>
            </div>
            <div class="flex">
                <div class="flex items-center mx-2">
                    <button
                        type="button"
                        class="flex mr-3 text-sm bg-white rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                        id="user-menu-button"
                        aria-expanded="false"
                        data-dropdown-toggle="user-dropdown"
                        data-dropdown-placement="bottom"
                        @click="toggle"
                    >
                        <span class="sr-only">Open user menu</span>

                        <Avatar
                            :image="user?.avatar"
                            class="bg-white border-2 border-white shadow-none"
                            shape="circle"
                        />
                    </button>
                    <!-- Dropdown menu -->
                </div>
                <button
                    data-collapse-toggle="navbar-user"
                    type="button"
                    class="inline-flex md:hidden lg:hidden xl:hidden items-center text-white p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                    aria-controls="navbar-user"
                    aria-expanded="false"
                >
                    <span class="sr-only">Open main menu</span>
                    <svg
                        class="w-5 h-5"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 17 14"
                    >
                        <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M1 1h15M1 7h15M1 13h15"
                        />
                    </svg>
                </button>
            </div>
        </div>
    </nav>
</template>

<script>
import { computed, ref, defineComponent, onBeforeMount, onMounted } from "vue";
import Badge from "primevue/badge";
import Menubar from "primevue/menubar";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import Menu from "primevue/menu";
import Button from "primevue/button";
import Avatar from "primevue/avatar";
import Storage, { storageKeys } from "../../services/Storage";
export default defineComponent({
    components: { Menubar, Menu, Avatar, Button, Badge },
    setup() {
        const route = useRoute();
        const store = useStore();
        const user = computed(() => store.getters["auth/user"]);
        const pathName = computed(() => route.path);
        const menu = ref();
        const items = computed(() => [
            {
                label: "Mon espace",
                icon: "la la-tachometer-alt",
                url: "/account",
            },
            {
                label: "Profile",
                icon: "la la-user ",
                url: "/account/profile",
            },
        ]);
        const toggle = (event) => {
            menu.value.toggle(event);
        };

        const logout = () => {
            Storage.remove(storageKeys.authToken);
            window.location.replace("/");
        };
        onBeforeMount(() => {
            // isLoading.value = true;
            store.dispatch("auth/initApp", {
                onSuccess: () => {
                    store.dispatch("auth/getUser", {
                        onSuccess: (user) => {
                            // isLoading.value = false;
                        },
                    });
                    // next();
                },
                onError: () => {
                    // isLoading.value = false;
                },
            });
        });
        onMounted(() => {});
        return { user, toggle, logout, menu, items, pathName };
    },
});
</script>
