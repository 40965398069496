<template>
    <div class="w-full flex justify-between mt-4">
        <div class="w-[25%] bg-white p-3 h-full">
            <h3 class="text-lg text-slate-700 font-roboto">Settings</h3>

            <div class="mt-3">
                <router-link
                    :to="{ name: 'account.profile' }"
                    :class="`flex ${
                        ['account.profile'].includes(
                            $route?.name?.toString() || ''
                        )
                            ? 'bg-secondary text-white'
                            : ''
                    } mb-2 cursor-pointer transition transition-all justify-between text-slate-500 items-center py-2 px-1 hover:px-2 rounded-sm hover:bg-secondary hover:text-white`"
                >
                    <h3 class="font-qs">Informations</h3>
                    <i class="la la-angle-right"></i>
                </router-link>

                <router-link
                    :to="{ name: 'account.password' }"
                    :class="`${
                        ['account.password'].includes(
                            $route?.name?.toString() || ''
                        )
                            ? 'bg-secondary text-white'
                            : ''
                    } mb-2 transition transition-all cursor-pointer flex justify-between text-slate-500 items-center py-2 px-1 hover:px-2  rounded-sm hover:bg-secondary hover:text-white`"
                >
                    <h3 class="font-qs">Mot de passe</h3>
                    <i class="la la-angle-right"></i>
                </router-link>

                <router-link
                    :to="{ name: 'account.notification' }"
                    :class="`${
                        ['account.notification'].includes(
                            $route?.name?.toString() || ''
                        )
                            ? 'bg-secondary text-white'
                            : ''
                    } cursor-pointer transition transition-all flex justify-between text-slate-500 items-center py-2 px-1 hover:px-2 rounded-sm hover:bg-secondary hover:text-white`"
                >
                    <h3 class="font-qs">Notification</h3>
                    <i class="la la-angle-right"></i>
                </router-link>
            </div>
        </div>

        <div class="flex justify-between w-[73%]">
            <div class="bg-white w-full">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>
