<template>
    <div class="w-full flex flex-col px-3">
        <div class="border-b border-slate-200 w-full py-3">
            <h3 class="font-roboto font-bold text-lg">Notification</h3>
        </div>

        <div class="my-5">
            <div class="">
                <div class="flex pretty p-default p-thick p-pulse">
                    <Field
                        v-slot="{ field }"
                        name="sms"
                        type="checkbox"
                        :unchecked-value="false"
                        :value="true"
                        v-model="form.sms"
                    >
                        <input
                            v-bind="field"
                            name="sms"
                            type="checkbox"
                            :value="true"
                        />
                    </Field>
                    <div class="state p-warning-o">
                        <label class="ml-2">Sms</label>
                    </div>
                </div>
            </div>
            <div class="mt-3">
                <div class="flex pretty p-default p-thick p-pulse">
                    <Field
                        v-slot="{ field }"
                        name="email"
                        type="checkbox"
                        :unchecked-value="false"
                        :value="true"
                        v-model="form.email"
                    >
                        <input
                            v-bind="field"
                            name="email"
                            type="checkbox"
                            :value="true"
                        />
                    </Field>
                    <div class="state p-warning-o">
                        <label class="ml-2">Email</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { Field, useForm } from "vee-validate";

export default defineComponent({
    components: { Field },
    setup() {
        const form = ref({ sms: false, email: false });
        return {
            form,
        };
    },
});
</script>
