<template>
    <div class="w-full">
        <div
            class="w-full flex justify-center items-center h-screen bg-secondary bg-opacity-25 from-primary from-10% via-sky-500 via-30% to-primary1-300 to-90%"
        >
            <div class="flex w-full h-screen overflow-hidden">
                <div
                    class="relative sm:hidden xs:hidden md:w-3/5 xl:w-3/5 lg:w-3/5 bg-bottom bg-contain"
                >
                    <div class="mt-10 flex justify-center items-center top">
                        <a href="/" class="text-5xl font-bold text-white">
                            <img
                                src="/images/allo_logo.svg"
                                class="w-32"
                                alt="AlloDoto Logo"
                            />
                        </a>
                    </div>
                    <div
                        class="w-full h-2/3 bg-no-repeat bg-center absolute bottom-0 right-0 left-0 overflow-hidden"
                    >
                        <img src="/images/medical_2.svg" alt="" />
                    </div>
                </div>
                <div
                    class="md:w-2/5 lg:w-2/5 xl:w-2/5 sm:w-full xs:w-full bg-white h-screen px-5 py-6 overflow-scroll"
                >
                    <div
                        class="mt-3 mb-5 flex justify-start items-center md:hidden lg:hidden xl:hidden"
                    >
                        <a href="/" class="text-5xl font-bold text-white">
                            <img
                                src="/images/allo_logo.svg"
                                class="w-32"
                                alt="AlloDoto Logo"
                            />
                        </a>
                    </div>
                    <div>
                        <h3
                            class="text-xl font-ibm text-slate-500 font-semibold"
                        >
                            Inscription
                        </h3>
                    </div>

                    <div class="w-full flex flex-col justify-start items-start">
                        <div class=""></div>
                    </div>

                    <div>
                        <form
                            @submit.prevent="handleForm"
                            autocomplete="off"
                            class="flex flex-col mb-0 mt-10"
                        >
                            <input
                                autocomplete="false"
                                name="hidden"
                                type="text"
                                style="display: none"
                            />
                            <div class="flex flex-col">
                                <div class="flex flex-wrap gap-3">
                                    <div class="flex align-items-center">
                                        <RadioButton
                                            v-model="formData.gender"
                                            inputId="m"
                                            name="gender"
                                            value="m"
                                            inputClass="border border-slate-300 bg-primary"
                                            @update:modelValue="
                                                (data) => handleGender(data)
                                            "
                                        />
                                        <label
                                            for="m"
                                            class="ml-2 text-slate-500"
                                            >Homme</label
                                        >
                                    </div>
                                    <div class="flex align-items-center">
                                        <RadioButton
                                            v-model="formData.gender"
                                            inputId="f"
                                            name="gender"
                                            value="f"
                                            inputClass="border border-slate-300 bg-primary"
                                            @update:modelValue="
                                                (data) => handleGender(data)
                                            "
                                        />
                                        <label
                                            for="f"
                                            class="ml-2 text-slate-500"
                                            >Femme</label
                                        >
                                    </div>
                                </div>

                                <div class="flex mt-4 xs:flex-col">
                                    <div
                                        class="flex flex-col md:mr-1 w-1/2 xs:w-full"
                                    >
                                        <label
                                            class="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-1"
                                            for="grid-last-name"
                                            >Nom</label
                                        >
                                        <div class="h-10">
                                            <Field
                                                v-slot="{ field }"
                                                v-model="formData.last_name"
                                                name="last_name"
                                            >
                                                <InputText
                                                    v-bind="field"
                                                    type="text"
                                                    autocomplete="off"
                                                    placeholder="Nom de famille *"
                                                    class="rounded border bg-white border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                                />
                                            </Field>
                                        </div>
                                    </div>

                                    <div
                                        class="flex flex-col md:ml-1 lg:ml-1 xl:ml-1 xs:mt-4 w-1/2 xs:w-full"
                                    >
                                        <label
                                            class="block tracking-wide text-grey-darker text-xs font-bold mb-1"
                                            for="grid-last-name"
                                            >Prénom (s)</label
                                        >
                                        <div class="h-10">
                                            <Field
                                                v-slot="{ field }"
                                                v-model="formData.first_name"
                                                name="first_name"
                                            >
                                                <InputText
                                                    v-bind="field"
                                                    type="text"
                                                    placeholder="Prénom(s) *"
                                                    class="rounded border bg-white border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                                />
                                            </Field>
                                        </div>
                                    </div>
                                </div>

                                <div class="flex mt-4 xs:flex-col">
                                    <div
                                        class="flex flex-col md:mr-1 w-1/2 xs:w-full"
                                    >
                                        <label
                                            class="block tracking-wide text-grey-darker text-xs font-bold mb-1"
                                            for="grid-last-name"
                                            >Email</label
                                        >
                                        <div class="h-10">
                                            <Field
                                                v-slot="{ field }"
                                                v-model="formData.email"
                                                name="email"
                                            >
                                                <InputText
                                                    v-bind="field"
                                                    type="text"
                                                    autocomplete="off"
                                                    placeholder="Email *"
                                                    class="rounded border bg-white border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                                />
                                            </Field>
                                        </div>
                                    </div>

                                    <div
                                        class="flex flex-col md:ml-1 lg:ml-1 xl:ml-1 w-1/2 xs:w-full xs:mt-4"
                                    >
                                        <label
                                            class="block tracking-wide text-grey-darker text-xs font-bold mb-1"
                                            for="grid-last-name"
                                            >Téléphone</label
                                        >
                                        <div class="h-10">
                                            <Field
                                                v-slot="{ field }"
                                                v-model="formData.phoneNumber"
                                                name="phoneNumber"
                                            >
                                                <phone-number-input
                                                    type="text"
                                                    v-bind="field"
                                                    :defaultCountry="
                                                        countryCode
                                                    "
                                                    placeholder="Numero de télephone *"
                                                    class="doctor-info-phone w-full rounded-sm border border-slate-300 bg-white px-2 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:border focus:border-blue-200"
                                                />
                                            </Field>
                                        </div>
                                    </div>
                                </div>

                                <div class="flex mt-4 xs:flex-col">
                                    <div
                                        class="flex flex-col md:mr-1 w-1/2 xs:w-full"
                                    >
                                        <label
                                            class="block tracking-wide text-grey-darker text-xs font-bold mb-1"
                                            for="grid-last-name"
                                            >Ville</label
                                        >
                                        <div class="h-10">
                                            <Field
                                                v-slot="{}"
                                                name="city"
                                                v-model="formData.city"
                                            >
                                                <Dropdown
                                                    v-model="formData.city"
                                                    :options="cities"
                                                    optionLabel="name"
                                                    placeholder="Ville"
                                                    :inputStyle="{
                                                        paddingTop: '8px',
                                                    }"
                                                    class="w-full focus:shadow-none shadow-none bg-white rounded border border-slate-300 h-full w-full focus:border-slate-400 transition transition-all"
                                                    @update:modelValue="
                                                        handleCity
                                                    "
                                                />
                                            </Field>
                                        </div>
                                    </div>

                                    <div
                                        class="flex flex-col md:ml-1 lg:ml-1 xl:ml-1 w-1/2 xs:w-full xs:mt-4"
                                    >
                                        <label
                                            class="block tracking-wide text-grey-darker text-xs font-bold mb-1"
                                            for="grid-last-name"
                                            >Date de naissance</label
                                        >
                                        <div class="h-10">
                                            <Field
                                                v-slot="{ field }"
                                                v-model="formData.birth_date"
                                                name="birth_date"
                                            >
                                                <Calendar
                                                    v-bind="field"
                                                    name="birth_date"
                                                    id="birth_date"
                                                    v-model="
                                                        formData.birth_date
                                                    "
                                                    :maxDate="
                                                        moment()
                                                            .subtract(
                                                                18,
                                                                'years'
                                                            )
                                                            .toDate()
                                                    "
                                                    dateFormat="dd/mm/yy"
                                                    class="w-full h-full rounded-sm border border-slate-300 bg-white px-2 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:border focus:border-blue-200"
                                                    placeholder="Date de naissance *"
                                                />
                                            </Field>
                                        </div>
                                    </div>
                                </div>

                                <div class="flex mt-4 xs:flex-col">
                                    <div
                                        class="flex flex-col md:mr-1 w-1/2 xs:w-full"
                                    >
                                        <label
                                            class="block tracking-wide text-grey-darker text-xs font-bold mb-1"
                                            for="grid-last-name"
                                            >Mot de passe</label
                                        >
                                        <div class="h-10">
                                            <Field
                                                v-slot="{ field }"
                                                v-model="formData.password"
                                                name="password"
                                            >
                                                <InputText
                                                    autocomplete="new-password"
                                                    v-bind="field"
                                                    type="password"
                                                    placeholder="Mot de passe *"
                                                    class="rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                                />
                                            </Field>
                                        </div>
                                    </div>

                                    <div
                                        class="flex flex-col md:ml-1 lg:ml-1 xl:ml-1 w-1/2 xs:w-full xs:mt-4"
                                    >
                                        <label
                                            class="block tracking-wide text-grey-darker text-xs font-bold mb-1"
                                            for="grid-last-name"
                                            >Confirmation
                                        </label>
                                        <div class="h-10">
                                            <Field
                                                v-slot="{ field }"
                                                v-model="
                                                    formData.password_confirmation
                                                "
                                                name="password_confirmation"
                                            >
                                                <InputText
                                                    v-bind="field"
                                                    autocomplete="new-password"
                                                    type="password"
                                                    placeholder="Confirmation du mot de passe*"
                                                    class="rounded bg-white border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                                />
                                            </Field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-8 flex flex-col">
                                <div
                                    class="flex align-items-center items-center"
                                >
                                    <Field
                                        v-slot="{ field }"
                                        name="acceptTerms"
                                        type="checkbox"
                                    >
                                        <Checkbox
                                            v-bind="field"
                                            v-model="formData.acceptTerms"
                                            inputId="acceptTerms"
                                            name="acceptTerms"
                                            inputClass="bg-primary"
                                            :binary="true"
                                        />
                                    </Field>
                                    <label
                                        for="acceptTerms"
                                        class="ml-2 text-sm text-slate-500"
                                    >
                                        J'accepte les
                                        <a href="/terms" target="_blank"
                                            >conditions d'utilisation</a
                                        >
                                    </label>
                                </div>

                                <div class="mt-3 h-10">
                                    <h3
                                        v-if="errors?.acceptTerms"
                                        class="text-xs text-danger"
                                    >
                                        {{ errors?.acceptTerms }}
                                    </h3>
                                </div>
                            </div>
                            <div class="my-3 flex justify-end items-center">
                                <Button
                                    v-if="!isSubmitting"
                                    type="submit"
                                    label="Enregistrer"
                                    :disabled="!meta.valid"
                                    class="w-full bg-primary py-2 px-5 rounded text-primary bg-opacity-25 hover:bg-opacity-50 font-semibold leading-6 text-sm shadow"
                                />

                                <Button
                                    v-else
                                    type="button"
                                    class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow text-white bg-primary hover:bg-indigo-400 transition ease-in-out duration-150 cursor-not-allowed"
                                    disabled=""
                                >
                                    <svg
                                        class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            class="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            stroke-width="4"
                                        ></circle>
                                        <path
                                            class="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                        ></path>
                                    </svg>
                                    Création de votre compte en cours...
                                </Button>
                            </div>
                            <div class="flex justify-center mt-3">
                                <div class="flex items-center text-sm">
                                    <p class="">Vous avez déjà un compte ?</p>
                                    <a
                                        href="/login"
                                        label="Se connecter"
                                        class="bg-white px-2 text-primary rounded"
                                        >Se connecter
                                        <i class="la la-sign-in mx-1"></i
                                    ></a>
                                </div>
                            </div>
                            <div class="mt-5 h-10">
                                <div
                                    v-if="isSuccess"
                                    class="flex justify-start items-center"
                                >
                                    <i
                                        class="la la-check text-success text-2xl mr-2"
                                    ></i>
                                    <span class="text-success text-sm">
                                        Votre compte a été créé. Veuillez
                                        checker votre boite email pour le
                                        valider
                                    </span>
                                </div>
                                <span
                                    class="text-danger text-sm"
                                    v-if="cities.length == 0 && countryCode"
                                    >Il semble que nous ne sommes pas encore
                                    displonible dans votre pays:
                                    {{ emoji }}</span
                                >
                                <span
                                    class="text-danger text-sm"
                                    v-else-if="errorMessage"
                                    >{{ errorMessage }}</span
                                >
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, onMounted, defineComponent, ref, watch } from "vue";
import InputText from "primevue/inputtext";
import { Field, useForm } from "vee-validate";
import Dropdown from "primevue/dropdown";
import Calendar from "primevue/calendar";
import moment from "moment";
import Button from "primevue/button";
import RadioButton from "primevue/radiobutton";
import Checkbox from "primevue/checkbox";
import * as yup from "yup";
import Storage, { storageKeys } from "../../services/Storage";
import { useStore } from "vuex";
export default defineComponent({
    components: {
        InputText,
        Field,
        Dropdown,
        Calendar,
        RadioButton,
        Button,
        Checkbox,
    },
    props: { allCities: { type: Array, required: true } },
    setup(props) {
        const store = useStore();
        const isSuccess = ref(false);
        const isSubmitting = ref(false);
        const countryCode = ref(Storage.get(storageKeys.country_code));
        const errorMessage = ref();
        const emoji = ref();
        const country = ref();

        const schema = computed(() =>
            yup.object({
                any: yup.string().optional(),
                acceptTerms: yup
                    .boolean()
                    .oneOf(
                        [true],
                        "Vous devez accepter les conditions d'utilisation pour continuer."
                    ),
                first_name: yup.string().required("Prénom(s) obligatoire"),
                last_name: yup.string().required("Nom obligatoire"),
                email: yup.string().email().required("Email invalide"),
                city: yup
                    .object({
                        id: yup.number().required("Sélectionnez votre ville"),
                    })
                    .required("Sélectionnez votre ville"),
                gender: yup.string().required(),
                phoneNumber: yup.string().required(),
                password: yup.string().required(),
                password_confirmation: yup.string().required(),
                birth_date: yup.date().required(),
            })
        );

        const cities = computed(() =>
            props.allCities.filter(
                (x) => x?.country?.code === countryCode.value?.toLowerCase()
            )
        );

        const formData = ref({
            password: "",
            acceptTerms: false,
            password_confirmation: "",
            last_name: "",
            first_name: "",
            birth_date: "",
            email: "",
            phoneNumber: "",
            city: {},
            gender: "m",
        });

        const {
            errors,
            values,
            meta,
            setFieldValue,
            handleReset,
            handleSubmit,
        } = useForm({
            initialValues: formData.value,
            validationSchema: schema.value,
        });

        const handleTerms = (data) => {
            // setFieldValue("acceptTerms", data);
            // validateField("acceptTerms");

            console.log(data, values.acceptTerms);
        };

        const handleForm = handleSubmit((values) => {
            errorMessage.value = null;
            isSubmitting.value = true;
            const data = {
                last_name: values.last_name,
                first_name: values.first_name,
                email: values.email,
                city_id: values.city.id,
                password: values.password,
                gender: values.gender,
                birth_date: values.birth_date,
                phone: values.phoneNumber.replaceAll(" ", "").replace("+", ""),
                password_confirmation: values.password_confirmation,
            };

            store.dispatch("auth/register", {
                data,
                onSuccess: () => {
                    isSubmitting.value = false;
                    isSuccess.value = true;
                    handleReset();
                },
                onError: (error) => {
                    isSuccess.value = false;
                    console.log("yyde", error);
                    isSubmitting.value = false;
                    errorMessage.value =
                        "Une erreur est survenue lors de la creation du compte";
                    if (error?.errors?.password) {
                        errorMessage.value =
                            "Le mot de passe et la confirmation doivent être conformes";
                        return;
                    }
                    if (
                        error?.errors?.email &&
                        error.errors.email.includes(
                            "The email has already been taken."
                        )
                    ) {
                        errorMessage.value =
                            "Cette adresse e-mail est déjà associé un compte";
                        return;
                    }
                },
            });
        });

        const handleCity = (data) => {
            setFieldValue("city", data.value);
        };

        const handleGender = (value) => {
            setFieldValue("gender", value);
        };

        const intPosition = () => {
            const unix_time = Storage.get(storageKeys.position_time);
            const now_unix = moment().unix();
            if (unix_time && now_unix - unix_time < 3600 * 10) {
                return;
            }
            fetch("https://api.ipregistry.co/?key=c33s648p0gjrije7")
                .catch(function (err) {
                    console.log("Error", err);
                })
                .then(function (response) {
                    return response.json();
                })
                .then(function (payload) {
                    console.log("loccc", payload);
                    if (payload?.location) {
                        countryCode.value =
                            payload.location?.country?.code.toLowerCase() ?? "";

                        Storage.set(
                            storageKeys.country_code,
                            countryCode.value
                        );
                        Storage.set(
                            storageKeys.country_emoji,
                            payload.location?.country?.flag.emoji
                        );
                        Storage.set(
                            storageKeys.country_name,
                            payload.location?.country?.name
                        );
                        emoji.value = payload.location?.country?.flag.emoji;
                        country.value = {
                            name: payload.location?.country?.name,
                            capital: payload.location?.country.capital,
                        };
                    }

                    if (payload.currency) {
                        Storage.set(
                            storageKeys.currency_code,
                            payload.currency?.code?.toLowerCase() ?? ""
                        );
                        Storage.set(
                            storageKeys.currency_symbol,
                            payload.currency?.symbol?.toLowerCase() ?? ""
                        );
                    }

                    Storage.set(storageKeys.position_time, moment().unix());
                });
        };

        onMounted(() => {
            intPosition();
        });

        return {
            formData,
            countryCode,
            meta,
            errorMessage,
            errors,
            isSuccess,
            cities,
            moment,
            emoji,
            isSubmitting,
            handleGender,
            handleTerms,
            handleForm,
            handleCity,
        };
    },
});
</script>
<style lang="scss">
.p-calendar {
    .p-inputtext {
        background: white !important;
    }
}
</style>
