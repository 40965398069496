
import { createLogger, createStore } from 'vuex'
import Setting from './Setting/index'
import auth from './Auth/index'
import config from './Config/index'
import medical from './Medical/index'
import echo from './Echo/index';
import centers from './MedicalCenter/index'
import blog from './Blog/index'

export const strict = false

const debug = process.env.NODE_ENV !== 'production'
const plugins = debug ? [createLogger({})] : []

// plugins.push(createPersistedState({ storage: window.sessionStorage }))

const store = createStore({
    modules: { Setting, auth, config, medical, echo, centers, blog },
    plugins: process.env.NODE_ENV === 'development' ? [createLogger()] : []
})

export default store
