<template>
    <div id="map">
        <div
            id="mapContainer"
            style="height: 100%; width: 100%"
            ref="hereMap"
        ></div>
    </div>
</template>

<script>
import { hereMapApiKey, hereMapId } from "@/services/config";
export default {
    name: "HereMap",
    props: {
        center: Object,
        onMapUpdate: Function,
        delay: {
            type: Number,
            default: 0,
        },
    },
    watch: {
        center: function (newval) {
            this.map.removeObjects(this.map.getObjects());
            this.addMarker(newval);
            this.map.setCenter(newval);
            this.map.setZoom(12);
        },
    },
    data() {
        return {
            map: null,
            platform: null,
            appId: hereMapId,
            apikey: hereMapApiKey,
            initialized: false,
        };
    },
    async mounted() {
        // Initialize the platform object:
        const platform = new H.service.Platform({
            apikey: this.apikey,
            app_id: this.appId,
            app_code: this.apikey,
        });
        this.platform = platform;

        setTimeout(() => {
            this.buildMap();
        }, this.delay);
        // this.$nextTick(function () {
        //   this.buildMap();
        // });
    },
    created() {},
    methods: {
        buildMap() {
            this.initializeHereMap();
            console.log("center", this.center);
        },
        addMarker(position) {
            if (!this.map) return;
            var svgMarkup =
                '<svg width="24" height="24" ' +
                'xmlns="http://www.w3.org/2000/svg">' +
                '<rect stroke="white" fill="#1b468d" x="1" y="1" width="22" ' +
                'height="22" /><text x="12" y="18" font-size="12pt" ' +
                'font-family="Arial" font-weight="bold" text-anchor="middle" ' +
                'fill="white">H</text></svg>';
            var iconMarkup = `<svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" aria-labelledby="title"
      aria-describedby="desc" role="img" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>Location Pin</title>
        <desc>A line styled icon from Orion Icon Library.</desc>
        <path stroke-width="4"
        stroke-miterlimit="10" stroke="#174750" fill="none" d="M32 62c0-17.1 16.3-25.2 17.8-39.7A18 18 0 1 0 14 20a18.1 18.1 0 0 0 .2 2.2C15.7 36.8 32 44.9 32 62z"
        data-name="layer2" stroke-linejoin="round" stroke-linecap="round"></path>
        <circle stroke-width="4" stroke-miterlimit="10" stroke="#174750"
        fill="none" r="6" cy="20" cx="32" data-name="layer1" stroke-linejoin="round"
        stroke-linecap="round"></circle>
      </svg>`;
            var icon = new H.map.Icon(iconMarkup, {
                size: {
                    w: 20,
                    h: 34,
                },
            });
            console.log("position", position);
            this.marker = new H.map.Marker(position, {
                volatility: false,
                // icon: icon,
            });
            this.marker.draggable = true;
            this.map.addObject(this.marker);
        },
        initializeHereMap() {
            // rendering map
            const mapContainer = this.$refs.hereMap;
            // Obtain the default map types from the platform object
            let maptypes = this.platform.createDefaultLayers();
            // Instantiate (and display) a map object:
            this.map = new H.Map(mapContainer, maptypes.vector.normal.map, {
                zoom: 13,
                center: this.center,
                pixelRatio: window.devicePixelRatio || 1,
            });

            addEventListener("resize", (evt) => {
                // console.log(this.map);
                this.map.getViewPort().resize();

                if (evt.target instanceof H.map.Marker) {
                    evt.target.setZIndex(6);
                }
            });

            let behavior = new H.mapevents.Behavior(
                new H.mapevents.MapEvents(this.map)
            );

            // add UI
            let ui = H.ui.UI.createDefault(this.map, maptypes, "fr-FR");

            this.$emit("onMapUpdate", this.map, this.center);

            // End rendering the initial map

            this.addDraggableMarker(this.map, behavior);

            if (this.center) this.addMarker(this.center);
        },
        addDraggableMarker(map, behavior) {
            this.marker = new H.map.Marker(this.center, {
                volatility: true,
            });
            // Ensure that the marker can receive drag events
            this.marker.draggable = true;
            this.map.addObject(this.marker);

            // disable the default draggability of the underlying map
            // and calculate the offset between mouse and target's position
            // when starting to drag a marker object:
            this.map.addEventListener(
                "dragstart",
                function (ev) {
                    var target = ev.target,
                        pointer = ev.currentPointer;
                    if (target instanceof H.map.Marker) {
                        var targetPosition = map.geoToScreen(
                            target.getGeometry()
                        );
                        target["offset"] = new H.math.Point(
                            pointer.viewportX - targetPosition.x,
                            pointer.viewportY - targetPosition.y
                        );
                        behavior.disable();
                    }
                },
                false
            );

            // re-enable the default draggability of the underlying map
            // when dragging has completed
            this.map.addEventListener(
                "dragend",
                (ev) => {
                    var target = ev.target;
                    if (target instanceof H.map.Marker) {
                        behavior.enable();
                        this.map.setCenter(target.getGeometry());
                        this.$emit(
                            "onMapUpdate",
                            this.map,
                            target.getGeometry()
                        );
                    }
                },
                false
            );

            // Listen to the drag event and move the position of the marker
            // as necessary
            this.map.addEventListener(
                "drag",
                function (ev) {
                    var target = ev.target,
                        pointer = ev.currentPointer;
                    if (target instanceof H.map.Marker) {
                        target.setGeometry(
                            map.screenToGeo(
                                pointer.viewportX - target["offset"].x,
                                pointer.viewportY - target["offset"].y
                            )
                        );
                    }
                },
                false
            );
        },
    },
};
</script>
<style scoped>
#map {
    background-color: #ccc;
}
</style>
