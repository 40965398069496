<template>
    <Head>
        <meta name="robots" content="index,follow" />

        <title>
            Allô Doto - Votre rendez-vous médical et soin ambulatoire au Bénin
            et en Côte d'Ivoire
        </title>
        <meta name="author" content="Allô Doto" />
        <meta
            name="keywords"
            content="médécin,praticien, professionel de santé,docteur,consulttation,medical,e-medecine,e-santé,benin,côte d'Ivoire"
        />
        <meta
            head-key="description"
            name="description"
            content="Plateforme de prise de rendez-vous médical et de prise en charge
            ambulatoire au Bénin et en Côte d'Ivoire"
        />

        <meta
            property="og:title"
            content="Allô Doto - Votre rendez-vous médical et soin ambulatoire au Bénin
            et en Côte d'Ivoire"
        />
        <meta
            property="og:description"
            content="Plateforme de prise de rendez-vous médical et de prise en charge
            ambulatoire au Bénin et en Côte d'Ivoire"
        />
        <meta
            name="twitter:title"
            content="Allô Doto - Votre rendez-vous médical et soin ambulatoire au Bénin
            et en Côte d'Ivoire"
        />
        <meta
            name="twitter:description"
            content="Plateforme de prise de rendez-vous médical et de prise en charge
            ambulatoire au Bénin et en Côte d'Ivoire"
        />
    </Head>
    <div class="w-full">
        <home></home>
        <Footer></Footer>
    </div>
</template>

<script>
import Home from "@/components/home/index";
import Footer from "@/components/footer/index";
import { Head } from "@inertiajs/vue3";
export default {
    components: { Home, Footer, Head },
    setup() {},
};
</script>
