<template>
    <div class="mt-5 grid grid-cols-3 gap-4">
        <div
            v-for="agent in agents"
            :key="agent.id"
            href="#"
            class="flex bg-white rounded shadow-md p-3 cursor-pointer w-full block max-w-sm border border-slate-100 rounded-lg"
        >
            <Avatar
                size="xlarge"
                :image="agent.avatar"
                class="mr-2.5 border-2 border-slate-400"
                shape="circle"
            />
            <div>
                <p class="text-gray-700 dark:text-gray-400 pb-2 font-bold">
                    {{ agent.full_name }}
                </p>
                <span
                    class="text-indigo-800 text-xs font-semibold mr-2 py-0.5 rounded dark:text-indigo-900"
                    >{{ agent.username }}</span
                >
                <div class="mt-2">
                    <div class="" v-for="acc in agent.accounts" :key="acc.id">
                        <span
                            class="bg-blue-100 text-blue-800 border-none inline-block text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800"
                            >{{ acc.role.name }}
                        </span>
                        <span v-if="acc.from || acc.to" class="text-xs"
                            >({{
                                acc.from
                                    ? moment(acc.from).format("DD/MM/yyyy")
                                    : ""
                            }}
                            {{
                                acc.to
                                    ? ` - ${moment(acc.to).format(
                                          "DD/MM/yyyy"
                                      )}`
                                    : ""
                            }})</span
                        >
                    </div>
                </div>
            </div>
        </div>
        <div
            class="w-full flex bg-white rounded p-3 cursor-pointer w-1/3 block max-w-sm border border-slate-100 rounded-lg shadow-md"
            @click="
                $router.push({
                    name: 'medical.staffCreate',
                    params: { slug: $route.params.slug },
                })
            "
        >
            <div class="flex justify-center items-center">
                <icon name="plus" size="50"></icon>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { TheCard } from "flowbite-vue";
import moment from "moment";
import Avatar from "primevue/avatar";
export default defineComponent({
    components: { TheCard, Avatar },
    setup(props) {
        const route = useRoute();
        const store = useStore();
        const agents = ref([]);
        const pagination = ref({
            total: 0,
            lastPage: 1,
            currentPage: 1,
            perpage: 20,
        });
        const getMedicalCenterAgents = () => {
            store.dispatch("medical/getMedicalCenterAgents", {
                medicalCenterId: route.params.slug,
                onSuccess: (response) => {
                    agents.value = response?.data || [];
                    pagination.value = {
                        total: response.total,
                        currentPage: response.current_page,
                        lastPage: response.last_page,
                    };
                    console.log("data", response);
                },
                onError: (error) => {
                    console.log("error");
                },
            });
        };

        onMounted(() => {
            getMedicalCenterAgents();
        });
        return { agents, moment };
    },
});
</script>
