<template>
    <div>
        <div class="rounded-sm bg-white flex flex-col p-5 mb-5 h-full">
            <div class="flex justify-between items-center mb-3">
                <div>
                    <h4 class="card-title font-semibold">Portfolios</h4>
                </div>
            </div>
            <div v-if="portfolios.length > 0" class="w-full">
                <table striped outlined class="table table-striped" hover>
                    <thead>
                        <tr class="">
                            <th class="whitespace-nowrap font-semibold font-qs">
                                Nom
                            </th>
                            <th class="whitespace-nowrap font-semibold font-qs">
                                Détenteur
                            </th>
                            <th class="whitespace-nowrap font-semibold font-qs">
                                Patients
                            </th>
                            <th class="whitespace-nowrap font-semibold font-qs">
                                Resources
                            </th>
                            <th class="whitespace-nowrap font-semibold font-qs">
                                Hôpitaux
                            </th>
                            <th class="whitespace-nowrap font-semibold font-qs">
                                Ambulances
                            </th>
                            <th class="whitespace-nowrap font-semibold font-qs">
                                Alertes
                            </th>
                            <th class="whitespace-nowrap font-semibold font-qs">
                                Interventions
                            </th>
                            <th class="whitespace-nowrap font-semibold font-qs">
                                Équipe
                            </th>
                            <th class="whitespace-nowrap font-semibold font-qs">
                                Création
                            </th>
                            <th
                                class="whitespace-nowrap font-semibold font-qs"
                            ></th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="user in portfolios" :key="user.id">
                            <td>
                                <span class="text-xs rounded">{{
                                    user.name
                                }}</span>
                            </td>

                            <td>
                                {{ user.creator?.full_name }}
                            </td>
                            <td>{{ user.patients }}</td>
                            <td>{{ user.resources }}</td>
                            <td>
                                {{ user.medicalCenters }}
                            </td>
                            <td>
                                {{ user.ambulances }}
                            </td>
                            <td>
                                {{ user.alerts }}
                            </td>
                            <td>
                                {{ user.interventions }}
                            </td>
                            <td>
                                {{ user.staff }}
                            </td>
                            <td>
                                {{ moment(user.created_at).format("LLL") }}
                            </td>
                            <td>
                                <div class="flex justify-end">
                                    <button
                                        class="bg-warning mr-1 px-2 py-1 rounded text-white"
                                        size="sm"
                                        @click="navigate(user.id)"
                                    >
                                        <i class="las la-cog text-white"></i>
                                    </button>
                                    <!-- <button
                                        class="px-2 py-1 bg-danger rounded text-white"
                                        size="sm"
                                        @click="handlePopup(user)"
                                    >
                                        <i class="la la-trash m-0"></i>
                                    </button> -->
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div v-else class="p-3 h-full">
                <div class="flex flex-col justify-center items-center h-full">
                    <img class="w-1/4" src="/images/no-result.svg" />
                    <h4 class="text-primary font-medium my-3 text-xl">
                        Aucun portfolio créé pour le moment.
                    </h4>
                </div>
            </div>
        </div>
        <pagination :pagination="pagination" @changed="getData"></pagination>
    </div>
    <SampleModal
        :is-visible="openEditionModal"
        @close="closeEditionModal"
        @cancel="closeEditionModal"
        @submit="handleEdit()"
        centered
        id="new-car"
        :title="'Editer un portfolio'"
    >
        <div>
            <div>
                <label for="name">Nom</label>
                <Field
                    v-slot="{ field }"
                    name="name"
                    v-model="selectedPortfolio.name"
                >
                    <input
                        type="text"
                        id="name"
                        v-bind="field"
                        placeholder="Nom "
                        class="w-full border rounded-sm focus:outline-none py-2 px-3 text-grey-darker"
                /></Field>
            </div>
        </div>
    </SampleModal>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import moment from "moment";
import SampleModal from "@/components/Modal/SampleModal.vue";
import { Field } from "vee-validate";
import { useRouter } from "vue-router";
export default {
    components: { SampleModal, Field },
    setup() {
        const store = useStore();
        const router = useRouter();
        const portfolio = computed(() => store.getters["auth/portfolio"]);
        const portfolios = computed(() => store.getters["config/portfolios"]);
        const selectedPortfolio = ref(null);
        const isEditing = ref(false);
        const openEditionModal = ref(false);
        const pagination = computed(
            () => store.getters["config/portfoliosPagination"]
        );
        const opennModal = (data) => {
            selectedPortfolio.value = { ...data };
            isEditing.value = true;
            openEditionModal.value = true;
        };
        const closeEditionModal = () => {
            openEditionModal.value = false;
            isEditing.value = false;

            selectedPortfolio.value = null;
        };
        const handleEdit = () => {};
        const getPortfolios = () => {
            store.dispatch("config/getPortfolios", {});
        };
        const navigate = (id) => {
            router.push({
                name: "account.portfolioDetail",
                params: { portfolioId: id },
            });
        };
        onMounted(() => {
            getPortfolios();
        });
        return {
            portfolios,
            openEditionModal,
            isEditing,
            selectedPortfolio,
            portfolio,
            pagination,
            moment,
            navigate,
            closeEditionModal,
            opennModal,
            handleEdit,
        };
    },
};
</script>
