<template>
    <div class="w-full my-6">
        <div>
            <div>
                <div class="rounded-sm bg-white flex flex-col p-5 mb-5">
                    <div class="flex justify-between items-center mb-3">
                        <div>
                            <h4 class="card-title font-semibold text-slate-800">
                                Les types de ressources
                            </h4>
                        </div>
                        <button
                            class="flex justify-center items-center text-sm border-none font-semibold rounded-sm bg-opacity-25 hover:bg-opacity-50 bg-doto-100 text-doto-100 px-2 py-2"
                            @click.prevent="openModal"
                        >
                            <icon name="plus"></icon>
                            Ajouter un nouveau
                        </button>
                    </div>
                    <div>
                        <div class="table-responsive">
                            <div class="overflow-x-auto">
                                <table
                                    striped
                                    outlined
                                    class="table table-striped"
                                    hover
                                    :items="resourceTypes"
                                >
                                    <thead>
                                        <tr>
                                            <th
                                                class="whitespace-nowrap font-medium text-sm"
                                            >
                                                Name
                                            </th>
                                            <th
                                                class="whitespace-nowrap font-medium text-sm"
                                            >
                                                Nb.
                                            </th>
                                            <th
                                                class="whitespace-nowrap font-medium text-sm"
                                            >
                                                Type
                                            </th>
                                            <th
                                                class="whitespace-nowrap font-medium text-sm"
                                            >
                                                Description
                                            </th>
                                            <th
                                                class="whitespace-nowrap font-medium text-sm"
                                            ></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr
                                            v-for="rType in resourceTypes"
                                            :key="rType.id"
                                        >
                                            <td class="text-sm text-slate-700">
                                                {{ rType.name }}
                                            </td>
                                            <td class="text-sm text-slate-700">
                                                {{ rType.resources_count }}
                                            </td>
                                            <td class="text-sm text-slate-700">
                                                {{ rType.type }}
                                            </td>
                                            <td class="text-sm text-slate-700">
                                                {{ rType.description }}
                                            </td>
                                            <td>
                                                <div class="flex justify-end">
                                                    <button
                                                        class="bg-warning text-warning rounded bg-opacity-25 text-sm hover:bg-opacity-50 px-3 py-2 mr-1"
                                                        @click="
                                                            handleResTypeEdit(
                                                                rType
                                                            )
                                                        "
                                                    >
                                                        <i
                                                            class="las la-pen"
                                                        ></i>
                                                    </button>
                                                    <button
                                                        class="bg-danger text-danger rounded bg-opacity-25 text-sm hover:bg-opacity-50 px-3 py-2"
                                                        @click="
                                                            handleRemoveResType(
                                                                rType.id
                                                            )
                                                        "
                                                    >
                                                        <i
                                                            class="la la-trash m-0"
                                                        ></i>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex justify-end my-5">
                    <pagination
                        v-model="pagination.current_page"
                        :pagination="pagination"
                        @changed="getData"
                    ></pagination>
                </div>
            </div>
        </div>
        <popup
            message="Êtes vous sûre de vouloir supprimer ?"
            :isVisible="canDelete"
            @submit="removeResType"
            @cancel="canDelete = false"
            @close="canDelete = false"
        ></popup>

        <SampleModal
            id="new-type"
            :is-visible="showCreateModal"
            title="Nouveau type de ressource"
            @cancel="cancel()"
            @close="cancel()"
            @submit="addNewType()"
        >
            <div
                class="w-full flex border border-slate-300 rounded mb-3 p-3"
                v-for="(item, index) in types"
                :key="index"
            >
                <div class="flex flex-col w-1/2">
                    <div class="w-full mb-2 pr-2">
                        <label
                            class="block capitalize tracking-wide text-slate-500 text-xs font-semibold text-left mb-1"
                            for="grid-state"
                        >
                            Nom de la ressource
                        </label>
                        <div class="h-10">
                            <input
                                type="text"
                                id="model"
                                v-model="item.name"
                                :state="item.name.length >= 10"
                                :name="`types[${index}][name]`"
                                placeholder="Entre le nom de la ressource"
                                class="bg-white rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                            />
                        </div>
                    </div>
                    <div class="w-full pr-2">
                        <label
                            class="block capitalize tracking-wide text-slate-500 text-xs font-semibold text-left mb-1"
                            for="grid-state"
                        >
                            Icône
                        </label>
                        <div class="h-10">
                            <input
                                placeholder="Icon"
                                v-model="item.icon_class"
                                :name="`types[${index}][icon_class]`"
                                :state="item.icon_class.length >= 3"
                                focus
                                class="bg-white rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                            />
                        </div>
                    </div>
                </div>
                <div class="w-1/2 flex flex-col ml-3">
                    <label
                        class="block capitalize tracking-wide text-slate-500 text-xs font-semibold text-left mb-1"
                        for="grid-state"
                    >
                        Description
                    </label>
                    <textarea
                        v-model="item.description"
                        :name="`types[${index}][description]`"
                        id="exampleFormControlTextarea1"
                        class="resize-none outline-none focus:outline-none bg-white rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                        rows="1"
                    ></textarea>
                </div>
                <div class="flex items-center pl-2">
                    <button
                        size="sm"
                        class="bg-danger text-white rounded px-2 py-1 mr-2"
                        @click="removeElm(index)"
                    >
                        <i class="la la-trash"></i>
                    </button>
                </div>
            </div>
            <div class="flex justify-end">
                <button
                    class="bg-slate-600 text-white border-none text-sm rounded hover:bg-slate-500 rounded text-white px-3 py-2"
                    @click="add"
                >
                    Ajouter un nouveau
                </button>
            </div>
        </SampleModal>

        <SampleModal
            id="new-type"
            width="w-3/5"
            :is-visible="editResType"
            centered
            title="Modification type de ressource"
            @cancel="cancelEdit()"
            @close="cancelEdit()"
            @submit="editType()"
        >
            <div class="w-full flex mb-3 py-3">
                <div class="flex flex-col w-full">
                    <div class="w-full">
                        <label
                            class="block capitalize tracking-wide text-xs font-semibold text-left text-slate-600 mb-1"
                            for="grid-state"
                        >
                            Nom de la ressource
                        </label>
                        <div class="h-10">
                            <input
                                type="text"
                                id="model"
                                v-model="currentResType.name"
                                name="name"
                                placeholder="Entre le nom de la ressource"
                                class="bg-white rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                            />
                        </div>
                    </div>
                    <div class="w-full mt-3">
                        <label
                            class="block capitalize tracking-wide text-xs font-semibold text-left text-slate-600 mb-1"
                            for="grid-state"
                        >
                            Icône
                        </label>
                        <div class="h-10">
                            <input
                                placeholder="Icon"
                                v-model="currentResType.icon_class"
                                name="icon_class"
                                focus
                                class="bg-white rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                            />
                        </div>
                    </div>
                    <div class="w-full flex flex-col mt-3">
                        <label
                            class="block capitalize tracking-wide text-slate-600 text-xs font-semibold text-left outline-none mb-1"
                            for="grid-state"
                        >
                            Description
                        </label>
                        <textarea
                            v-model="currentResType.description"
                            name="description"
                            id="exampleFormControlTextarea1"
                            class="resize-none bg-white rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                            rows="4"
                            colspan="5"
                        ></textarea>
                    </div>
                </div>
            </div>
        </SampleModal>
    </div>
    <ConfirmDialog></ConfirmDialog>
</template>

<script>
import { useStore } from "vuex";
import SampleModal from "@/components/Modal/SampleModal.vue";
import { useConfirm } from "primevue/useconfirm";
import ConfirmDialog from "primevue/confirmdialog";
import { TYPE } from "vue-toastification";
import { onMounted } from "vue";
import { ref } from "vue";
import { computed } from "vue";
export default {
    components: { ConfirmDialog, SampleModal },

    setup() {
        const store = useStore();
        const confirm = useConfirm();
        const pagination = computed(
            () => store.getters["config/resourceTypesPagination"]
        );
        const resourceTypes = computed(
            () => store.getters["config/resourceTypes"]
        );
        const currentResType = ref({});
        const editResType = ref(false);
        const currentResTypeId = ref(null);
        const showCreateModal = ref(false);
        const types = ref([
            {
                name: "",
                icon_class: "",
                description: "",
            },
        ]);
        const getTypes = (page = 1) => {
            store.dispatch("config/getResourceTypes", { page });
        };

        const openModal = () => {
            showCreateModal.value = true;
        };

        const removeResType = () => {
            store.dispatch("config/removeResourceType", {
                id: currentResTypeId.value,
                onSuccess: () => {
                    currentResTypeId.value = null;
                },
                onError: () => {},
            });
        };

        const submitResTypeEdition = () => {
            store.dispatch("config/editResourceTypes", {
                typeId: currentResType.value.id,
                typeData: currentResType.value,
                onSuccess: () => {
                    cancelEdit();
                },
                onError: () => {},
            });
        };
        const handleResTypeEdit = (data) => {
            currentResType.value = { ...data };
            editResType.value = true;
        };

        const cancel = () => {
            showCreateModal.value = false;
        };

        const cancelEdit = () => {
            editResType.value = false;
        };

        const removeElm = (index) => {
            if (types.value.length === 1) return;
            types.value = types.value.filter((x, i) => i !== index);
        };

        const init = () => {
            types.value = [
                {
                    name: "",
                    icon_class: "",
                    description: "",
                },
            ];
        };

        const add = () => {
            types.value.push({
                name: "",
                icon_class: "",
                description: "",
            });
        };
        const addNewType = () => {
            store.dispatch("config/addResourceTypes", {
                types: types.value,
                onSuccess: () => {
                    types.value = [
                        {
                            name: "",
                            icon_class: "",
                            description: "",
                        },
                    ];
                },
                onError: () => {},
            });
        };

        const editType = () => {
            store.dispatch("config/editResourceTypes", {
                typeId: currentResType.value?.id,
                typeData: currentResType.value,
                onSuccess: () => {
                    currentResType.value = null;
                },
                onError: () => {},
            });
        };

        const handleRemoveResType = (id) => {
            currentResTypeId.value = id;

            confirm.require({
                message:
                    "Êtes-vous sûr de vouloir supprimer ce type de ressource ?",
                header: "Suppression",
                icon: "la la-exclamation-circle",
                rejectLabel: "Non",
                acceptLabel: "Oui",
                rejectClass:
                    "bg-danger text-danger py-1 text-sm text-medium px-2 mr-2 bg-opacity-25",
                acceptClass:
                    "bg-primary text-primary py-1 text-sm text-medium px-2  bg-opacity-25",
                accept: () => {
                    removeResType();
                },
                reject: () => {},
            });
        };

        onMounted(() => {
            getTypes();
        });

        return {
            currentResTypeId,
            currentResType,
            pagination,
            types,
            editResType,
            showCreateModal,
            resourceTypes,
            openModal,
            cancelEdit,
            cancel,
            handleResTypeEdit,
            submitResTypeEdition,
            removeResType,
            handleRemoveResType,
            editType,
            addNewType,
            removeElm,
            add,
            init,
        };
    },
};
</script>
