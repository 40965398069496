<template>
    <div class="bg-white">
        <div class="w-full" v-if="!isLoading">
            <div class="flex">
                <div class="w-2/5">
                    <div class="p-3">
                        <file-input :url="image" @fileChanged="fileChanged" />
                    </div>
                    <form class="py-3">
                        <div class="">
                            <h3 class="text text-black text-sm p-3">
                                Renseignez les informations du centre de santé
                            </h3>
                        </div>
                        <div class="w-full px-3 mb-2 flex flex-col">
                            <label
                                class="block capitalize tracking-wide text-grey-darker text-xs font-bold"
                                for="grid-state"
                            >
                                Type <span class="text-danger px-1">*</span>
                            </label>
                            <div class="">
                                <Field
                                    v-slot="{ field }"
                                    name="type"
                                    v-model="hospital.type"
                                >
                                    <Select
                                        v-bind="field"
                                        name="type"
                                        v-model="hospital.type"
                                        :options="types"
                                        @changed="
                                            (city) => (hospital.city_id = city)
                                        "
                                    >
                                    </Select>
                                </Field>
                            </div>
                        </div>
                        <div class="w-full px-3 mb-2 flex flex-col">
                            <label
                                class="block capitalize tracking-wide text-grey-darker text-xs font-bold"
                                for="grid-state"
                            >
                                Ville <span class="text-danger">*</span>
                            </label>
                            <div class="">
                                <Field
                                    v-slot="{ field }"
                                    name="city"
                                    v-model="hospital.city"
                                >
                                    <Select
                                        name="city"
                                        v-bind="field"
                                        v-model="hospital.city"
                                        :options="cities"
                                        @changed="
                                            (city) => (hospital.city = city)
                                        "
                                    >
                                    </Select
                                ></Field>
                            </div>
                        </div>
                        <div class="w-full px-3 mb-2 flex flex-col">
                            <label
                                class="block capitalize tracking-wide text-grey-darker text-xs font-bold"
                                for="grid-first-name-1"
                                >Nom <span class="text-danger">*</span></label
                            >
                            <div class="h-10">
                                <Field
                                    v-slot="{ field }"
                                    name="name"
                                    v-model="hospital.name"
                                >
                                    <input
                                        v-model="hospital.name"
                                        name="name"
                                        v-bind="field"
                                        class="rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                        type="text"
                                        placeholder="Le nom "
                                /></Field>
                            </div>
                        </div>
                        <div class="w-full px-3 mb-2 flex flex-col">
                            <label
                                class="block capitalize tracking-wide text-grey-darker text-xs font-bold"
                                for="grid-first-name-1"
                                >Spécialités
                                <span class="text-danger">*</span></label
                            >
                            <div class="h-10">
                                <Field
                                    v-slot="{ field }"
                                    name="specialties"
                                    v-model="hospital.specialties"
                                >
                                    <v-select
                                        v-bind="field"
                                        placeholder="Sélectionnez les Spécialités"
                                        v-model="hospital.specialties"
                                        :value="hospital.specialties"
                                        :options="specialties"
                                        :multiple="true"
                                        :taggable="true"
                                        @selected="resourceSelected"
                                    ></v-select>
                                </Field>
                            </div>
                        </div>

                        <div class="w-full px-3 mb-2 flex flex-col">
                            <label
                                class="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-1"
                                for="grid-first-name-1"
                                >Numero Autorisation
                                <span class="text-danger">*</span></label
                            >
                            <div class="h-10">
                                <Field
                                    v-slot="{ field }"
                                    name="authorization_number"
                                    v-model="hospital.authorization_number"
                                >
                                    <input
                                        v-bind="field"
                                        v-model="hospital.authorization_number"
                                        name="authorization_number"
                                        class="rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                        type="text"
                                        placeholder="Ex: BJ-004/89994/CTN/094884 "
                                    />
                                </Field>
                            </div>
                        </div>

                        <div class="w-full px-3 mb-2 flex flex-col">
                            <label
                                class="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-1"
                                for="grid-first-name-1"
                                >E-mail
                            </label>
                            <div class="h-10">
                                <Field
                                    v-slot="{ field }"
                                    name="email"
                                    v-model="hospital.email"
                                >
                                    <input
                                        v-bind="field"
                                        v-model="hospital.email"
                                        name="email"
                                        class="rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                        type="text"
                                        placeholder="hop_sourulere@gmail.bj"
                                    />
                                </Field>
                            </div>
                        </div>

                        <div class="w-full px-3 mb-2 flex flex-col">
                            <label
                                class="block capitalize tracking-wide text-grey-darker text-xs font-bold"
                                for="grid-first-name-1"
                                >Adresse
                                <span class="text-danger">*</span></label
                            >
                            <div class="h-10">
                                <Field
                                    v-slot="{ field }"
                                    name="address"
                                    v-model="hospital.address"
                                >
                                    <input
                                        v-bind="field"
                                        v-model="hospital.address"
                                        name="address"
                                        disabled
                                        class="rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                        type="text"
                                        placeholder="L'adresse"
                                /></Field>
                            </div>
                        </div>

                        <div class="px-3 w-full mt-2">
                            <label
                                class="block capitalize tracking-wide text-grey-darker text-xs font-bold"
                                for="grid-first-name"
                            >
                                Téléphone (Ligne 1)
                                <span class="text-danger">*</span>
                            </label>
                            <div class="h-10">
                                <Field
                                    v-slot="{ field }"
                                    name="phone_1"
                                    v-model="hospital.phone_1"
                                >
                                    <PhoneInput
                                        v-bind="field"
                                        name="phone_1"
                                        v-model="hospital.phone_1"
                                        @phoneChanged="
                                            (phone1Updated) =>
                                                (hospital.phone_1 =
                                                    phone1Updated)
                                        "
                                /></Field>
                            </div>
                        </div>
                        <div class="px-3 mt-2 w-full">
                            <label
                                class="block capitalize tracking-wide text-grey-darker text-xs font-bold"
                                for="grid-last-name"
                            >
                                Téléphone (Ligne 2)
                            </label>
                            <div class="h-10">
                                <Field
                                    v-slot="{ field }"
                                    name="phone_2"
                                    v-model="hospital.phone_2"
                                >
                                    <PhoneInput
                                        v-bind="field"
                                        v-model="hospital.phone_2"
                                        name="phone_2"
                                        @phoneChanged="
                                            (number) =>
                                                (hospital.phone_2 = number)
                                        "
                                    />
                                </Field>
                            </div>
                        </div>

                        <div class="w-full px-3 mb-2 flex flex-col mt-2">
                            <label
                                class="block capitalize tracking-wide text-grey-darker text-xs font-bold"
                                for="grid-state"
                            >
                                Référant <span class="text-danger">*</span>
                            </label>
                            <div class="h-10">
                                <Field
                                    v-slot="{ field }"
                                    name="doctor"
                                    v-model="hospital.doctor"
                                >
                                    <Select
                                        v-bind="field"
                                        name="doctor"
                                        v-model="hospital.doctor"
                                        :options="doctors"
                                        @changed="
                                            (city) => (hospital.doctor = city)
                                        "
                                    >
                                    </Select>
                                </Field>
                            </div>
                        </div>

                        <div class="flex justify-end mt-2 mr-4 mb-1 pt-2 pb-1">
                            <button
                                @click.prevent="
                                    $router.push({ name: 'config.hospitals' })
                                "
                                class="border-0 rounded-sm border-danger-200 mr-2 p-2 text-danger"
                            >
                                Quitter
                            </button>
                            <button
                                :disabled="!meta.valid"
                                @click.prevent="createHospital()"
                                class="border-none rounded bg-success text-white px-3 py-2"
                            >
                                Enrégistrer
                            </button>
                        </div>
                    </form>
                </div>
                <div class="w-3/5">
                    <gmap
                        :center="center"
                        :zoom="15"
                        @onMapUpdate="updateMap"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import gmap from "@/components/maps/gmap";
import store from "@/store";
import { mapCenter, hereMapApiKey } from "@/services/config";
import Select from "../../components/Select/index.vue";
import PhoneInput from "@/components/PhoneInput";
import { useForm, Field } from "vee-validate";
import * as yup from "yup";
import { computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import moment from "moment";
import Storage, { storageKeys } from "../../services/Storage";
export default {
    beforeRouteEnter(to, from, next) {
        store.dispatch("config/getSpecialties", {});
        next();
    },
    components: {
        Select,
        Field,
        gmap,
        PhoneInput,
        Select,
    },

    setup(props) {
        const route = useRoute();
        const isLoading = ref(false);
        const doctors = ref([]);
        const initalValues = ref(null);
        const platform = ref(null);
        const cities = computed(() => store.getters["config/allCities"]);
        const specialties = computed(() => store.getters["config/specialties"]);
        const portfolio = computed(() => store.getters["auth/portfolio"]);
        const image = ref(null);
        const loadedImage = ref(null);

        const types = ref([
            { id: "public_hospital", name: "Hôpital Public" },
            { id: "health_center", name: "Centre de santé" },
            { id: "clinical", name: "Clinique (Centre de santé privé)" },
            { id: "pharmacy", name: "Pharmacie" },
            { id: "laboratory", name: "Laboratoire d'analyses médicales" },
        ]);
        const transaltions = ref({
            countrySelectorLabel: "Code pays",
            countrySelectorError: "Choisir un pays",
            phoneNumberLabel: "Numéro de téléphone",
            example: "Exemple :",
        });
        const hospital = ref({
            specialties: [],
            doctor: null,
            type: null,
            address: "",
            name: "",
            city: null,
            latitude: null,
            longitude: null,
            phone_2: "",
            phone_1: "",
        });
        const center = ref(mapCenter);
        const router = useRouter();
        const schema = computed(() =>
            yup.object({
                name: yup.string().required("Entrer le nom"),
                authorization_number: yup
                    .string()
                    .required("Numero autorisation des autorités sanitaires"),
                city: yup
                    .object({ id: yup.string() })
                    .required("Sélectionnez une ville"),
                doctor: yup
                    .object({ id: yup.string() })
                    .required("Sélectionnez un référant"),
                type: yup
                    .object({ id: yup.string() })
                    .required("Sélectionnez un type de centre"),
                phone_1: yup.string().required("Telephone obligatoire"),
            })
        );

        const getHospital = () => {
            isLoading.value = true;
            store.dispatch("medical/getHospital", {
                slug: route.params.slug,
                onSuccess: (data) => {
                    const point = (data.addresses || [])
                        .sort((a, b) =>
                            moment(a.created_at).isBefore(moment(b.created_at))
                        )
                        ?.find((x) => x.isMain == true);
                    initalValues.value = { name: data.name };
                    hospital.value = {
                        type: types.value.find((t) => t.id == data?.type),
                        id: data.id,
                        city: data?.city,
                        name: data.name,
                        email: data.email,
                        latitude: point?.latitude,
                        longitude: point?.longitude,
                        authorization_number: data.authorization_number,
                        specialties: data.specialties,
                        doctor: {
                            id: data?.creator.id,
                            name: data?.creator.full_name,
                        },
                    };
                    image.value = data.profile?.img;
                    hospital.value.email = data.profile?.email;
                    hospital.value.phone_1 = data.profile?.phone_1
                        ? `+${data.profile?.phone_1}`
                        : "";
                    hospital.value.phone_2 = data.profile?.phone_2
                        ? `+${data.profile?.phone_2}`
                        : ""
                        ? `+${data.profile?.phone_2}`
                        : "";
                    if (point?.latitude && point?.longitude)
                        center.value = {
                            lat: point.latitude,
                            lng: point.longitude,
                        };
                    hospital.value.address = point?.address;
                    isLoading.value = false;
                },
                onError: () => {
                    isLoading.value = false;
                },
            });
        };

        const { errors, values, meta } = useForm({
            validationSchema: schema.value,
            validateOnMount: true,
            initialValues: initalValues.value,
        });

        const updateMap = (map, position) => {
            hospital.value.latitude = position.lat;
            hospital.value.longitude = position.lng;
            if (position) {
                reverseGeocodePosition(position.lat, position.lng);
            }
        };
        const reverseGeocodePosition = (lat, lng) => {
            var service = platform.value?.getSearchService();
            service.reverseGeocode(
                {
                    at: `${lat},${lng},150`,
                },
                (result) => {
                    const { position, address } = result.items[0];
                    hospital.value.address = address.label;
                },
                (err) => {
                    console.error("Error: [reverseGeocodePosition] => ", err);
                }
            );
        };

        const geocodePosition = (address) => {
            var service = platform.value.getSearchService();
            service.geocode(
                {
                    q: `${address},150`,
                },
                (result) => {
                    const point = result.items[0];
                    if (point?.position) {
                        center.value = point.position;
                    }
                },
                (err) => {
                    console.log("Error: [geocodePosition] => ", err);
                }
            );
        };

        const getAllDoctors = () => {
            store.dispatch("medical/getHospitalReferrers", {
                onSuccess: (ds) => {
                    doctors.value = [...ds].map((x) => ({
                        id: x.id,
                        name: x.full_name,
                    }));
                },
                onError: () => {},
            });
        };

        const createHospital = () => {
            const formData = { ...hospital.value };
            formData.specialties = hospital.value.specialties.map((x) => x.id);
            formData.doctor_id = hospital.value.doctor?.id;
            formData.city_id = hospital.value.city?.id;
            formData.type = hospital.value.type?.id;
            formData.phone_1 = hospital.value.phone_1
                ?.replaceAll(" ", "")
                ?.replace("+", "");
            formData.phone_2 = hospital.value.phone_2
                ?.replaceAll(" ", "")
                ?.replace("+", "");
            formData.portfolio_id = portfolio.value.id;

            store.dispatch("medical/editHospital", {
                id: hospital.value.id,
                data: formData,
                onSuccess: () => {
                    if (loadedImage.value) {
                        saveImage(hospital.value.id);
                    }
                    router.push({
                        name: "config.hospitalDetails",
                        params: { slug: route.params.slug },
                    });
                },
                onError: () => {},
            });
        };
        const saveImage = (id) => {
            const form = new FormData();
            form.append("image", loadedImage.value);
            store.dispatch("medical/attachMedicalCenterImage", {
                id: id,
                data: form,
                onSuccess: () => {
                    loadedImage.value = null;
                },
            });
        };
        const fileChanged = (file) => {
            loadedImage.value = file;
        };
        watch(
            () => portfolio.value,
            (val) => {
                router.push({ name: "config.hospitals" });
            }
        );
        onMounted(() => {
            const code = Storage.get(storageKeys.country_code);
            store.dispatch("config/getAllCities", {});
            getHospital();
            getAllDoctors();
            platform.value = new H.service.Platform({
                apikey: hereMapApiKey,
                useHTTPS: false,
            });
        });

        return {
            types,
            transaltions,
            types,
            errors,
            values,
            meta,
            center,
            hospital,
            initalValues,
            isLoading,
            doctors,
            specialties,
            image,
            cities,
            createHospital,
            updateMap,
            fileChanged,
        };
    },
};
</script>

<style lang="scss" scoped>
#box {
    margin: 20px 20px;
}
</style>
