<template>
    <div class="bg-[#F8F9FC] h-full overflow-hidden">
        <!-- <div v-if="isLoading"></div> -->
        <div class="content-data h-screen overflow-scroll">
            <router-view v-slot="{ Component }">
                <transition name="nested">
                    <component :is="Component" />
                </transition>
            </router-view>
        </div>
    </div>
    <!-- <div v-else>
          <Error401 />
      </div> -->
</template>

<script>
import { onBeforeMount, ref, onMounted, computed } from "vue";
import Echo from "laravel-echo";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Pusher from "pusher-js";

export default {
    components: {},
    setup(props) {
        const route = useRoute();
        const store = useStore();
        const router = useRouter();
        const isLoading = ref(true);
        const isAdmin = ref(false);
        const display401 = computed(() => !route.meta?.anyCan);
        const initEcho = () => {
            window.Pusher = Pusher;
            window.Echo = new Echo({
                broadcaster: "pusher",
                key: "6976bcfeeaba692f8cb7",
                // cluster: process.env.MIX_PUSHER_APP_CLUSTER,
                cluster: "us3",
                encrypted: true,
                wsHost: window.location.host,
                wssHost: window.location.host,
                wsPort: window.location.host.includes(".com") ? 6002 : 6001,
                wssPort: window.location.host.includes(".com") ? 6002 : 6001,
                forceTLS: true,
                disableStats: true,
                enabledTransports: ["ws", "wss"],
            });

            window.Echo.connector.pusher.connection.bind(
                "connected",
                (event) => {
                    console.log("Status: connected", event);
                    //this.isConnected = true;
                }
            );
            window.Echo.connector.pusher.connection.bind("disconnected", () =>
                console.log("Status: disconnected")
            );

            window.Echo.connector.pusher.connection.bind("error", () =>
                console.log("Status: Error ==> ")
            );
        };

        onBeforeMount(() => {
            isLoading.value = true;
            store.dispatch("auth/initApp", {
                onSuccess: () => {
                    store.dispatch("auth/getUser", {
                        onSuccess: (user) => {
                            isAdmin.value =
                                Math.max(
                                    ...(user.roles ?? []).map((x) => x.level)
                                ) < 3;

                            isLoading.value = false;
                        },
                    });
                    // next();
                },
                onError: () => {
                    isLoading.value = false;
                },
            });
        });

        onMounted(() => {
            if (!window.Echo) initEcho();
        });
        return { display401, isLoading, isAdmin };
    },
};
</script>
