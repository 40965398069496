<template>
    <nav class="side-nav">
        <ul>
            <li>
                <router-link
                    v-if="$can.any(['doctor', 'manager', 'is-admin'])"
                    :class="`side-menu ${
                        $route.name === 'app.dashboard'
                            ? 'side-menu--active'
                            : ''
                    }`"
                    :to="{ name: 'app.dashboard' }"
                >
                    <div class="side-menu__icon">
                        <i class="las la-tachometer-alt text-xl"></i>
                    </div>
                    <div class="side-menu__title">Dashboard</div>
                </router-link>

                <ul
                    v-if="$can.any(['is-admin'])"
                    class="side-menu__sub-open bg-tertiary1"
                >
                    <li>
                        <router-link
                            :class="`side-menu ${
                                $route.name === 'config.cities'
                                    ? 'side-menu--active'
                                    : ''
                            }`"
                            :to="{ name: 'config.cities' }"
                        >
                            <div class="side-menu__icon">
                                <i class="las la-city text-xl"></i>
                            </div>
                            <div class="side-menu__title">Villes</div>
                        </router-link>
                    </li>
                    <li>
                        <router-link
                            :class="`side-menu ${
                                $route.name === 'config.resource-types'
                                    ? 'side-menu--active'
                                    : ''
                            }`"
                            :to="{ name: 'config.resource-types' }"
                        >
                            <div class="side-menu__icon">
                                <i class="las la-biohazard text-xl"></i>
                            </div>
                            <div class="side-menu__title">
                                Types de ressources
                            </div>
                        </router-link>
                    </li>

                    <li>
                        <router-link
                            :class="`side-menu ${
                                $route.name === 'config.record-types'
                                    ? 'side-menu--active'
                                    : ''
                            }`"
                            :to="{ name: 'config.record-types' }"
                            class="side-menu"
                        >
                            <div class="side-menu__icon">
                                <i class="las la-briefcase-medical text-xl"></i>
                            </div>
                            <div class="side-menu__title">Type d'enrég.</div>
                        </router-link>
                    </li>
                </ul>
            </li>
            <li v-if="$can.any(['is-admin', 'manager'])">
                <router-link
                    :class="`side-menu ${
                        $route.name === 'config.resources'
                            ? 'side-menu--active'
                            : ''
                    }`"
                    :to="{ name: 'config.resources' }"
                >
                    <div class="side-menu__icon">
                        <i class="las la-compress text-xl"></i>
                    </div>
                    <div class="side-menu__title">Ressources</div>
                </router-link>
            </li>
            <li v-if="$can.any(['is-admin', 'manager'])">
                <router-link
                    :class="`side-menu ${
                        $route.name === 'config.cars' ? 'side-menu--active' : ''
                    }`"
                    :to="{ name: 'config.cars' }"
                >
                    <div class="side-menu__icon">
                        <i class="las la-ambulance text-xl"></i>
                    </div>
                    <div class="side-menu__title">Ambulances</div>
                </router-link>
            </li>
            <li v-if="$can.any(['is-admin', 'manager', 'doctor'])">
                <router-link
                    :class="`side-menu ${
                        [
                            'app.alerts',
                            'app.alertCreate',
                            'app.alerts_get',
                        ].includes($route.name?.toString() || '')
                            ? 'side-menu--active'
                            : ''
                    }`"
                    :to="{ name: 'app.alerts' }"
                >
                    <div class="side-menu__icon">
                        <i class="las la-exclamation-triangle text-xl"></i>
                    </div>
                    <div class="side-menu__title">Alertes</div>
                </router-link>
            </li>

            <li>
                <router-link
                    v-if="$can.any(['is-admin', 'manager', 'doctor'])"
                    :class="`side-menu ${
                        [
                            'config.staff',
                            'config.staffCreate',
                            'config.staffEdit',
                        ].includes(($route.name || '')?.toString())
                            ? 'side-menu--active'
                            : ''
                    }`"
                    :to="{ name: 'config.staff' }"
                    class="side-menu"
                >
                    <div class="side-menu__icon">
                        <i class="las la-user-md text-xl"></i>
                    </div>
                    <div class="side-menu__title">
                        Staffs
                        <div class="side-menu__sub-icon"></div>
                    </div>
                </router-link>
                <router-link
                    v-if="$can.any(['is-admin', 'manager', 'doctor'])"
                    :class="`side-menu ${
                        [
                            'config.hospitals',
                            'config.hospitalDetails',
                            'config.hospitalsCreate',
                            'config.editMedicalCenter',
                        ].includes($route.name?.toString() || '')
                            ? 'side-menu--active'
                            : ''
                    }`"
                    :to="{ name: 'config.hospitals' }"
                    class="side-menu"
                >
                    <div class="side-menu__icon">
                        <i class="las la-hospital-symbol text-xl"></i>
                    </div>
                    <div class="side-menu__title">
                        Hôpitaux
                        <div class="side-menu__sub-icon"></div>
                    </div>
                </router-link>

                <router-link
                    :class="`side-menu ${
                        [
                            'app.patients',
                            'app.patientCreate',
                            'patient.details',
                            'patient.edit',
                        ].includes($route?.name?.toString() || '')
                            ? 'side-menu--active'
                            : ''
                    }`"
                    :to="{ name: 'app.patients' }"
                    class="side-menu"
                >
                    <div class="side-menu__icon">
                        <i class="las la-wheelchair text-xl"></i>
                    </div>
                    <div class="side-menu__title">
                        Patients
                        <div class="side-menu__sub-icon"></div>
                    </div>
                </router-link>
            </li>
            <li class="side-nav__devider my-"></li>
            <ul
                v-if="$can.any(['is-admin', 'manager', 'doctor'])"
                class="side-menu__sub-open bg-tertiary1 side-menu--active"
            >
                <li>
                    <router-link
                        :class="`side-menu ${
                            ['app.plannings', 'app.newPlannings'].includes(
                                $route.name
                            )
                                ? 'side-menu--active'
                                : ''
                        }`"
                        :to="{ name: 'app.plannings' }"
                    >
                        <div class="side-menu__icon">
                            <i class="las la-calendar-week text-xl"></i>
                        </div>
                        <div class="side-menu__title">Config Rendez-vous</div>
                    </router-link>
                </li>
                <li>
                    <router-link
                        :class="`side-menu ${
                            ['app.appointments', 'app.appointment'].includes(
                                $route.name
                            )
                                ? 'side-menu--active'
                                : ''
                        }`"
                        :to="{ name: 'app.appointments' }"
                    >
                        <div class="side-menu__icon">
                            <i class="las la-calendar-check text-xl"></i>
                        </div>
                        <div class="side-menu__title">Rendez-vous</div>
                    </router-link>
                </li>
            </ul>
            <li class="side-nav__devider"></li>

            <li v-if="$can.any(['is-admin', 'manager'])">
                <router-link
                    :class="`side-menu ${
                        ['app.invoices', 'app.invoice'].includes(
                            $route?.name?.toString() || ''
                        )
                            ? 'side-menu--active'
                            : ''
                    }`"
                    :to="{ name: 'app.invoices' }"
                    class="side-menu"
                >
                    <div class="side-menu__icon">
                        <i class="las la-wallet text-xl"></i>
                    </div>
                    <div class="side-menu__title">
                        Factures
                        <div class="side-menu__sub-icon"></div>
                    </div>
                </router-link>
            </li>

            <ul
                v-if="$can.any(['is-admin', 'editor'])"
                class="side-menu__sub-open bg-tertiary1 side-menu--active"
            >
                <li>
                    <router-link
                        :class="`side-menu ${
                            ['blog.configs'].includes($route.name)
                                ? 'side-menu--active'
                                : ''
                        }`"
                        :to="{ name: 'blog.config' }"
                    >
                        <div class="side-menu__icon">
                            <i class="las la-paste text-xl"></i>
                        </div>
                        <div class="side-menu__title">Catégories / Tags</div>
                    </router-link>
                </li>
                <li>
                    <router-link
                        :class="`side-menu ${
                            ['blog.writers', 'blog.newWriter'].includes(
                                $route.name
                            )
                                ? 'side-menu--active'
                                : ''
                        }`"
                        :to="{ name: 'blog.writers' }"
                    >
                        <div class="side-menu__icon">
                            <i class="las la-snowflake text-xl"></i>
                        </div>
                        <div class="side-menu__title">Éditeurs</div>
                    </router-link>
                </li>
                <li>
                    <router-link
                        :class="`side-menu ${
                            ['blog.posts', 'blog.post'].includes($route.name)
                                ? 'side-menu--active'
                                : ''
                        }`"
                        :to="{ name: 'blog.posts' }"
                    >
                        <div class="side-menu__icon">
                            <i class="las la-pen-nib text-xl"></i>
                        </div>
                        <div class="side-menu__title">Posts</div>
                    </router-link>
                </li>
            </ul>
            <li class="side-nav__devider"></li>
        </ul>
    </nav>
</template>
