import service from '@/services/editor';


export default {
    async createCategory({ commit }, { data, onSuccess, onError }) {
        try {
            await service.createCategory(data);

            onSuccess()
        } catch (error) {
            console.log(error)

            onError()
        }
    },

    async editCategory({ commit }, { data, categoryId, onSuccess, onError }) {
        try {
            await service.editCategory(categoryId, data);

            onSuccess()
        } catch (error) {
            console.log(error)

            onError()
        }
    },

    async getCategories({ commit }, { onSuccess, onError }) {
        try {
            const { categories } = await service.getCategories();
            onSuccess(categories)

        } catch (error) {
            console.log(error)

            onError()
        }
    },

    async deleteCategory({ commit }, { categoryId, onSuccess, onError }) {
        try {
            await service.deleteCategory(categoryId);
            onSuccess()

        } catch (error) {
            console.log(error)

            onError()
        }
    },

    async getTags({ commit }, { onSuccess, onError }) {
        try {
            const { tags } = await service.getTags();
            onSuccess(tags)

        } catch (error) {
            console.log(error)

            onError()
        }
    },

    async editTag({ commit }, { data, tagId, onSuccess, onError }) {
        try {
            await service.editTag(tagId, data);
            onSuccess()

        } catch (error) {
            console.log(error)

            onError()
        }
    },

    async createTag({ commit }, { data, onSuccess, onError }) {
        try {
            await service.createTag(data);
            onSuccess()

        } catch (error) {
            console.log(error)

            onError()
        }
    },

    async deleteTag({ commit }, { tagId, onSuccess, onError }) {
        try {
            await service.deleteTag(tagId);
            onSuccess()

        } catch (error) {
            console.log(error)

            onError()
        }
    },


    async postWriter({ commit }, { data, onSuccess, onError }) {
        try {
            await service.postWriter(data);
            onSuccess()
        } catch (error) {
            console.log(error);
            onError(error)
        }
    },

    async savePost({ commit }, { data, onSuccess, onError }) {
        try {
            await service.savePost(data);
            onSuccess()
        } catch (error) {
            console.log(error);
            onError(error)
        }
    },

    async deletePost({ commit }, { postId, onSuccess, onError }) {
        try {
            await service.removePost(postId);
            onSuccess()
        } catch (error) {
            console.log(error);
            onError(error)
        }
    },

    async getPosts({ commit }, { filter, onSuccess, onError }) {
        try {
            const { posts } = await service.getPosts(filter);
            onSuccess(posts)
        } catch (error) {
            console.log(error);
            onError(error)
        }
    },

    async postsSearch({ commit }, { filter, onSuccess, onError }) {
        try {
            const { posts } = await service.postsSearch(filter);
            onSuccess(posts)
        } catch (error) {
            console.log(error);
            onError(error)
        }
    },

    async storeMedia({ commit }, { data, onSuccess, onError }) {
        try {
            const response = await service.storeMedia(data);
            onSuccess(response)
        } catch (error) {
            console.log(error);
            onError(error)
        }
    },

    async savePostComment({ commit }, { postId, data, onSuccess, onError }) {
        try {
            const response = await service.savePostComment(postId, data);
            onSuccess()
        } catch (error) {
            console.log(error);
            onError(error)
        }
    },
}
