
export const SET_CITIES = 'SET_CITIES';
export const SET_COUNTRIES = 'SET_COUNTRIES';
export const SET_ROLES = 'SET_ROLES';
export const SET_ALL_CITIES = "SET_ALL_CITIES";
export const SET_STAFF = "SET_STAFF";
export const SET_CARS = "SET_CARS";
export const SET_RESOURCE_TYPES = "SET_RESOURCE_TYPES";
export const SET_RESOURCES = "SET_RESOURCES";
export const SET_RESOURCE_PAGINATION = "SET_RESOURCE_PAGINATION";
export const SET_RECORD_TYPES = "SET_RECORD_TYPES";
export const SET_RECORD_TYPES_PAGINATION = "SET_RECORD_TYPES_PAGINATION";
export const SET_RECORD_SECTIONS = "SET_RECORD_SECTIONS";
export const SET_ALL_RECORDS = "SET_ALL_RECORDS"
export const SET_SPECIALTIES = 'SET_SPECIALTIES'
export const SET_ALL_RESOURCES = 'SET_ALL_RESOURCES'
export const SET_ALL_PORTFOLIOS = 'SET_ALL_PORTFOLIOS'
export const SET_PORTFOLIO = 'SET_PORTFOLIO'

export default {
    [SET_CITIES](state, { cities }) {
        state.cities = cities;
    },
    [SET_ALL_CITIES](state, { cities }) {
        state.allCities = cities;
    },
    [SET_COUNTRIES](state, { countries }) {
        state.countries = countries;
    },
    [SET_ROLES](state, { roles }) {
        state.roles = roles;
    },
    [SET_STAFF](state, { users }) {
        const { data, current_page, last_page, per_page, from, to, total } = users;
        state.users = data;
        state.usersPagination = { current_page, last_page, per_page, from, to, total }
    },
    [SET_CARS](state, { cars }) {
        state.cars = cars;
    },
    [SET_ALL_PORTFOLIOS](state, { portfolios }) {
        state.portfolios = [...portfolios.data];
        state.portfoliosPagination = {
            page: portfolios.current_page,
            total: portfolios.total,
            lastPage: portfolios.last_page,
            perPage: portfolios.per_page
        }
    },
    [SET_SPECIALTIES](state, { specialties }) {
        state.specialties = specialties;
    },
    [SET_RESOURCE_TYPES](state, { types, total, last_page, per_page, current_page }) {
        state.resourceTypes = types;
        state.resourceTypesPagination.total = total;
        state.resourceTypesPagination.last_page = last_page;
        state.resourceTypesPagination.per_page = per_page;
        state.resourceTypesPagination.current_page = current_page;
    },
    [SET_RECORD_TYPES](state, { types, pagination }) {
        state.recordTypes = types;
        state.recordTypesPagination = { ...pagination }
    },
    [SET_RECORD_TYPES_PAGINATION](state, { types, pagination }) {
        state.recordTypes = types;

    },
    [SET_ALL_RECORDS](state, { types, sections }) {
        state.allRecordTypes = types;
        state.allRecordSections = sections
        // state.recordTypesPagination = {
        //     last_page: types.last_page,
        //     current_page: types.current_page,
        //     total: types.total,
        // }
    },
    [SET_RECORD_SECTIONS](state, { sections }) {
        state.recordSections = sections;
    },
    [SET_RESOURCE_PAGINATION](state, { page }) {
        state.resourcePagination.page = page;
    },
    [SET_ALL_RESOURCES](state, { resources }) {
        state.allResources = [...resources];
    },
    [SET_PORTFOLIO](state, { portfolio }) {
        state.portfolio = { ...portfolio };
    },
    [SET_RESOURCES](state, { resources }) {
        const { data, last_page, per_page, total, current_page } = resources;
        state.resources = data;
        state.resourcePagination.total = total;
        state.resourcePagination.lastPage = last_page;
        state.resourcePagination.perPage = per_page;
        state.resourcePagination.page = current_page;
    }
}
