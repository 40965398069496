<template>
    <!-- component -->
    <div class="bg-white dark:bg-gray-900">
        <div class="flex justify-center h-screen">
            <div
                class="hidden bg-cover bg-no-repeat bg-primary lg:block lg:w-2/3"
                style="
                    'background-image':url(https://minzel.io/svg/logout_background_blue_spot_1.svg) ;
                "
            >
                <div
                    class="flex items-center h-full px-20 bg-gray-900 bg-opacity-40"
                >
                    <div>
                        <h2 class="text-4xl font-bold text-blackp">
                            <img
                                src="/images/logo_white.svg"
                                class="mr-3 w-32"
                                alt="Allô Doto Logo"
                            />
                        </h2>

                        <!-- <p class="max-w-xl mt-3 text-white text-xl">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. In autem
              ipsa, nulla laboriosam dolores, repellendus perferendis libero
              suscipit nam temporibus molestiae.
            </p> -->
                    </div>
                </div>
            </div>

            <div
                class="flex items-center w-full max-w-md px-6 mx-auto lg:w-2/6"
            >
                <div class="flex-1">
                    <div class="flex flex-col justify-center">
                        <p
                            class="mt-3 text-2xl text-secondary dark:text-primary"
                        >
                            Entrer votre nouveau mot de passe
                        </p>
                    </div>

                    <div class="mt-8">
                        <form @submit.prevent="save">
                            <div class="mt-3">
                                <div class="flex justify-between mb-1">
                                    <label
                                        for="password"
                                        class="text-sm text-gray-600 dark:text-gray-200"
                                        >Password</label
                                    >
                                </div>

                                <div class="h-10">
                                    <input
                                        v-model="form.password"
                                        type="password"
                                        name="password"
                                        id="password"
                                        placeholder="Votre nouveau mot de passe"
                                        class="rounded border border-slate-300 bg-white h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                    />
                                </div>
                            </div>
                            <div class="mt-3">
                                <div class="flex justify-between mb-1">
                                    <label
                                        for="password"
                                        class="text-sm text-gray-600 dark:text-gray-200"
                                        >Confirmation</label
                                    >
                                </div>

                                <div class="h-10">
                                    <input
                                        v-model="form.confirmation"
                                        type="password"
                                        name="confirmation"
                                        id="confirmation"
                                        placeholder="Confirmer votre nouveau mot de passe"
                                        class="rounded border border-slate-300 bg-white h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                    />
                                </div>
                            </div>
                            <div class="mt-6">
                                <button
                                    type="submit"
                                    :class="`
                          w-full
                          px-4
                          py-2
                          tracking-wide
                          text-white pointer-cursor
                          transition-colors
                          duration-200
                          transform rounded-md ${
                              disabled
                                  ? 'bg-slate-300 text-slate-500'
                                  : 'bg-primary hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50'
                          } `"
                                    :disabled="disabled"
                                >
                                    Valider
                                    <span>
                                        <i
                                            class="la la-arrow-right text-lg text-white"
                                        ></i>
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { computed, getCurrentInstance, onMounted, ref } from "vue";

export default {
    components: {},
    props: [],
    setup() {
        const form = ref({ password: "", confirmation: "" });
        const isSuccess = ref(true);
        const phone = ref("");
        const email = ref("");
        const instance = getCurrentInstance();
        const store = instance.proxy.$store;

        const isSubmitting = ref(false);
        const disabled = computed(
            () =>
                !form.value?.password ||
                !form.value?.confirmation ||
                isSubmitting.value
        );

        const save = () => {
            let uri = window.location.search.substring(1);
            let params = new URLSearchParams(uri);
            const token = params.get("rToken");
            isSubmitting.value = true;
            store.dispatch("auth/changePassword", {
                data: { ...form.value, token },
                onSuccess: () => {
                    isSubmitting.value = false;
                    isSuccess.value = true;
                    store.dispatch("medical/displayToast", {
                        message: "Mot de passe bien enrégistré.",
                        context: "Mot de passe ",
                        type: "TYPE.SUCCESS",
                        icon: "la-user",
                    });
                    window.location.replace("/login");
                },
                onError: () => {
                    isSuccess.value = false;
                    isSubmitting.value = false;

                    store.dispatch("medical/displayToast", {
                        message:
                            "Vérifier que la confirmation et le mot de passe sont les mêmes.",
                        context: "Mot de passe ",
                        type: "TYPE.ERROR",
                        icon: "la-user",
                    });
                },
            });
        };

        const getResettingUser = () => {
            let uri = window.location.search.substring(1);
            let params = new URLSearchParams(uri);
            const token = params.get("rToken");
            store.dispatch("auth/getUserByRequestToken", {
                token,
                onSuccess: (resp) => {
                    phone.value = resp?.phone;
                    email.value = resp?.email;
                },
                onError: () => {
                    isSuccess.value = false;
                    isSubmitting.value = false;
                },
            });
        };
        onMounted(() => {
            getResettingUser();
        });

        return { isSuccess, disabled, form, isSubmitting, save };
    },
    methods: {},
};
</script>
