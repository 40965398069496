<template>
    <div class="become-doctor h-screen">
        <div
            v-if="isSuccess"
            class="flex flex-col justify-center items-center h-full"
        >
            <div
                class="w-20 h-20 rounded-full bg-success flex items-center justify-center text-white"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-10 h-10"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4.5 12.75l6 6 9-13.5"
                    />
                </svg>
            </div>

            <h3 class="pt-4 pb-2 text-lg text-center">
                Votre compte a bien été créé. Un mail vous a été envoyé.
            </h3>

            <span>Vous pourrez configurer votre compte une fois connecté.</span>

            <a
                href="/login"
                class="bg-primary2 mt-10 font-ibm text-white px-5 py-3 font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                >Se connecter <i class="la la-sign-in ml-2"></i
            ></a>
        </div>
        <div v-else class="flex large-header h-full" id="large-header">
            <div class="area lg:w-1/2 md:w-1/3 xs:hidden">
                <ul class="circles xs:hidden">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>

                <div class="context lg:w-1/2 w-1/3 xs:hidden">
                    <div class="">
                        <a href="/"
                            ><img
                                src="/images/logo_white.svg"
                                class="w-30 ml-5"
                                style="max-width: 35%"
                                alt="AllôDoto Logo"
                        /></a>
                    </div>

                    <div class="flex flex-col ml-5 mt-10 relative xs:hidden">
                        <div
                            class="lg:-right-[80px] top-[8px] absolute border-t border-white w-[60vw] md:-right-[20px]"
                        ></div>
                        <div
                            class="flex items-center my-2 bg-white px-2 w-auto py-1 w-fit"
                        >
                            <i class="la la-check mr-2 text-primary"></i>
                            <div
                                class="text-md font-ibm text-slate-500 font-semibold"
                            >
                                Inscription de votre établissement
                            </div>
                        </div>

                        <div class="flex items-center my-2">
                            <i class="la la-times mr-2 text-white"></i>
                            <div
                                class="text-white text-md font-ibm whitespace-pre-wrap md:w-2/3"
                            >
                                Transmettez les justificatifs pour permettant
                                d'exercer en toute conformité
                            </div>
                        </div>
                        <div class="flex items-center my-2">
                            <i class="la la-times mr-2 text-white"></i>
                            <div
                                class="text-white text-md font-ibm whitespace-pre-wrap md:w-2/3"
                            >
                                Configurer les divers élements de fonctionnement
                                de votre établissement
                            </div>
                        </div>
                        <div class="flex items-center my-2">
                            <i class="la la-times mr-2 text-white"></i>
                            <div
                                class="text-white text-md font-ibm whitespace-pre-wrap md:w-2/3"
                            >
                                Mise en ligne de votre établissement médical
                                avec des créneaux réservables
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="flex flex-col bg-doct lg:w-1/2 md:w-2/3 sm:w-full xs:w-full"
            >
                <h3
                    class="text-slate-500 text-2xl px-20 xs:px-4 sm:px-20 pt-5 font-ibm font-bold"
                >
                    Inscription du praticien
                </h3>
                <div class="w-full">
                    <form
                        @submit.prevent="onSubmit"
                        class="mt-2 px-20 xs:px-4 font-ibm"
                    >
                        <div class="w-full">
                            <h3
                                class="pt-2 mb-2 text-slate-600 text-md font-ibm italic"
                            >
                                Informations personnelles
                            </h3>
                            <div class="flex justify-between">
                                <div class="mb-2 mr-1 w-1/2">
                                    <label
                                        for="last_name"
                                        class="block text-base font-tight text-gray-400"
                                    >
                                        Nom
                                    </label>
                                    <div class="h-10">
                                        <Field
                                            v-slot="{ field }"
                                            name="last_name"
                                            v-model="form.last_name"
                                        >
                                            <InputText
                                                type="text"
                                                v-bind="field"
                                                id="last_name"
                                                name="last_name"
                                                placeholder="Nom *"
                                                class="rounded border bg-white border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                            />
                                        </Field>
                                    </div>
                                </div>

                                <div class="mb-2 ml-1 w-1/2">
                                    <label
                                        for="first_name"
                                        class="block text-base font-tight text-gray-400"
                                    >
                                        Prénom(s)
                                    </label>
                                    <div class="h-10">
                                        <Field
                                            v-slot="{ field }"
                                            name="first_name"
                                            v-model="form.first_name"
                                        >
                                            <InputText
                                                v-bind="field"
                                                type="text"
                                                name="first_name"
                                                id="first_name"
                                                v-model="form.first_name"
                                                placeholder="Prenom(s) *"
                                                class="rounded border bg-white border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                        /></Field>
                                    </div>
                                </div>
                            </div>

                            <div class="flex justify-between">
                                <div class="mb-2 mr-1 w-1/2">
                                    <label
                                        for="birth_date"
                                        class="block text-base font-tight text-gray-400"
                                    >
                                        Date de naissance
                                    </label>
                                    <div class="h-10">
                                        <Field
                                            v-slot="{ field }"
                                            name="birth_date"
                                            v-model="form.birth_date"
                                        >
                                            <Calendar
                                                v-bind="field"
                                                id="birth_date"
                                                name="birth_date"
                                                v-model="form.birth_date"
                                                :maxDate="
                                                    moment()
                                                        .subtract(18, 'years')
                                                        .toDate()
                                                "
                                                dateFormat="dd/mm/yy"
                                                class="w-full h-full rounded-sm border border-slate-300 bg-white px-2 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:border focus:border-blue-200"
                                                placeholder="Date de naissance *"
                                        /></Field>
                                    </div>
                                </div>

                                <div class="mb-2 ml-1 w-1/2">
                                    <label
                                        for="birth_place"
                                        class="block text-base font-tight text-gray-400"
                                    >
                                        Lieu de naissance
                                    </label>
                                    <div class="h-10">
                                        <Field
                                            v-slot="{ field }"
                                            name="birth_place"
                                            v-model="form.birth_place"
                                            ><InputText
                                                v-bind="field"
                                                type="text"
                                                name="birth_place"
                                                id="birth_place"
                                                placeholder="Ville de naissance *"
                                                class="rounded border border-slate-300 bg-white h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                        /></Field>
                                    </div>
                                </div>
                            </div>

                            <div class="flex justify-between">
                                <div class="mb-2 mr-1 w-1/2">
                                    <label
                                        for="email"
                                        class="block text-base font-tight text-gray-400"
                                    >
                                        Email
                                    </label>
                                    <div class="h-10">
                                        <Field
                                            v-slot="{ field }"
                                            name="email"
                                            v-model="form.email"
                                        >
                                            <InputText
                                                v-bind="field"
                                                type="text"
                                                name="email"
                                                id="email"
                                                placeholder="Votre adresse email *"
                                                v-model="form.email"
                                                class="rounded border bg-white border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                        /></Field>
                                    </div>
                                </div>

                                <div class="mb-2 ml-1 w-1/2">
                                    <label
                                        for="phone_1"
                                        class="block text-base font-tight text-gray-400"
                                    >
                                        Télephone
                                    </label>
                                    <div class="doctor-info-phone h-10">
                                        <Field
                                            v-slot="{ field }"
                                            name="phone_1"
                                            v-model="form.phone_1"
                                        >
                                            <phone-number-input
                                                v-bind="field"
                                                name="phone_1"
                                                id="phone_1"
                                                :default-country="countryCode"
                                                v-model="form.phone_1"
                                                placeholder="Numero personel *"
                                                class="rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                        /></Field>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <h3
                                class="py-4 text-slate-600 text-md font-ibm italic"
                            >
                                Établissement de santé / pharmaceutique
                            </h3>

                            <div class="flex justify-between">
                                <div class="mb-2 w-1/2 mr-1">
                                    <label
                                        class="block text-base font-tight text-gray-400"
                                    >
                                        Type d'établissement
                                    </label>
                                    <div class="h-10">
                                        <Field
                                            v-slot="{}"
                                            name="type"
                                            v-model="form.type"
                                        >
                                            <Dropdown
                                                :options="types"
                                                v-model="form.type"
                                                optionLabel="name"
                                                placeholder="Type *"
                                                :inputStyle="{
                                                    paddingTop: '8px',
                                                }"
                                                class="w-full focus:shadow-none shadow-none bg-white rounded border border-slate-300 h-full w-full focus:border-slate-400 transition transition-all"
                                                @update:modelValue="handleType"
                                            />
                                        </Field>
                                    </div>
                                </div>
                                <div class="mb-2 w-1/2 ml-1">
                                    <label
                                        for="center_name"
                                        class="block text-base font-tight text-gray-400"
                                    >
                                        Nom
                                    </label>
                                    <div class="h-10">
                                        <Field
                                            v-slot="{ field }"
                                            name="center_name"
                                            v-model="form.center_name"
                                        >
                                            <InputText
                                                v-bind="field"
                                                type="text"
                                                name="center_name"
                                                id="center_name"
                                                placeholder="Nom de l'établissement"
                                                class="rounded border bg-white border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                        /></Field>
                                    </div>
                                </div>
                            </div>

                            <div class="flex justify-between">
                                <div class="mb-2 w-1/2 mr-1">
                                    <label
                                        for="authorization_number"
                                        class="block text-base font-tight text-gray-400"
                                    >
                                        Numero Autorisation
                                    </label>
                                    <div class="h-10">
                                        <Field
                                            v-slot="{ field }"
                                            name="authorization_number"
                                            v-model="form.authorization_number"
                                        >
                                            <InputText
                                                type="text"
                                                v-bind="field"
                                                name="authorization_number"
                                                id="authorization_number"
                                                v-model="
                                                    form.authorization_number
                                                "
                                                placeholder="ex: BJ/HPS-764CTN/MSP"
                                                class="rounded border border-slate-300 bg-white h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                        /></Field>
                                    </div>
                                </div>
                                <div class="mb-2 w-1/2 ml-1">
                                    <label
                                        for="center_email"
                                        class="block text-base font-tight text-gray-400"
                                    >
                                        Email
                                    </label>
                                    <div class="h-10">
                                        <Field
                                            v-slot="{ field }"
                                            name="center_email"
                                            v-model="form.center_email"
                                        >
                                            <InputText
                                                v-bind="field"
                                                type="e-mail"
                                                id="center_email"
                                                name="center_email"
                                                placeholder="Email de l'établissement *"
                                                class="rounded border border-slate-300 bg-white h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                        /></Field>
                                    </div>
                                </div>
                            </div>

                            <div class="flex justify-between">
                                <div class="mb-2 w-1/2 mr-1">
                                    <label
                                        class="block text-base font-tight text-gray-400"
                                    >
                                        Ville d'exercice
                                    </label>
                                    <div class="h-10">
                                        <Field
                                            v-slot="{}"
                                            name="city"
                                            v-model="form.city"
                                        >
                                            <Dropdown
                                                :options="cities"
                                                v-model="form.city"
                                                optionLabel="name"
                                                placeholder="Ville *"
                                                :inputStyle="{
                                                    paddingTop: '8px',
                                                }"
                                                class="w-full focus:shadow-none shadow-none bg-white rounded border border-slate-300 h-full w-full focus:border-slate-400 transition transition-all"
                                                @update:modelValue="handleCity"
                                            />
                                        </Field>
                                    </div>
                                </div>
                                <div class="mb-2 ml-1 w-1/2">
                                    <label
                                        class="block text-base font-tight text-gray-400"
                                    >
                                        Ligne principale
                                    </label>
                                    <div class="doctor-info-phone h-10">
                                        <Field
                                            v-slot="{ field }"
                                            name="phone_2"
                                            v-model="form.phone_2"
                                        >
                                            <phone-number-input
                                                type="text"
                                                v-bind="field"
                                                name="phone_2"
                                                id="phone_2"
                                                :default-country="countryCode"
                                                v-model="form.phone_2"
                                                placeholder="Téléphone 1 *"
                                                class="rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                        /></Field>
                                    </div>
                                </div>
                            </div>

                            <div class="text-danger h-10 my-5">
                                <span v-if="currentError"
                                    >* {{ currentError }}
                                </span>
                            </div>

                            <div class="my-5 flex justify-end">
                                <button
                                    v-if="!isSubmitting"
                                    type="submit"
                                    :disabled="!meta.valid"
                                    class="relative w-full flex justify-center items-center py-3 px-4 text-sm font-medium rounded-md text-primary bg-opacity-25 hover:bg-opacity-50 bg-primary focus:outline-none focus:border-blue-700 focus:shadow-outline-indigo active:bg-blue-700 transition duration-150 ease-in-out"
                                >
                                    Enrégistrer
                                    <i
                                        class="la la-arrow-right ml-4 self-end"
                                    ></i>
                                </button>
                                <button
                                    v-else
                                    type="button"
                                    class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow text-white bg-primary hover:bg-indigo-400 transition ease-in-out duration-150 cursor-not-allowed"
                                    disabled=""
                                >
                                    <svg
                                        class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            class="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            stroke-width="4"
                                        ></circle>
                                        <path
                                            class="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                        ></path>
                                    </svg>
                                    Inscription de votre établissement en
                                    cours...
                                </button>
                            </div>
                            <div class="flex items-center justify-end">
                                <div
                                    class="flex items-center justify-center mr-2"
                                >
                                    <label
                                        class="ml-2 block text-sm leading-5 text-gray-900 mb-0"
                                    >
                                        Vous avez déjà un compte?
                                    </label>
                                </div>

                                <div class="text-sm leading-5">
                                    <a
                                        href="/login"
                                        class="font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                                    >
                                        Connectez-vous
                                    </a>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <Dialog
        v-model:visible="displayTerms"
        :draggable="false"
        modal
        :header="`Avant d'enregistrer ${
            parts1[form.type?.id]
        } ${selectedTypeName}`"
        :style="{ width: isSmall || isMedium ? '80vw' : '50vw' }"
    >
        <h3 class="py-3 font-semibold">Vous attestez:</h3>
        <p>
            <strong>1 -</strong> Être le propriétaire légal de
            {{ parts1[form.type?.id] }} {{ selectedTypeName }} et/ou vous avez
            l'autorisation (droit) nécéssaire pour gérer
            {{ parts1[form.type?.id] }} {{ selectedTypeName }} sur la
            plateforme.
        </p>
        <Divider />
        <p>
            <strong>2 -</strong>
            Vous exposer à des poursuites judiciaires si les informations
            fournies s'avéraient fausses
        </p>
        <Divider />
        <p>
            <strong>3 -</strong>
            Accepter nos
            <a href="/terms" target="_blank">conditions d'utilisation.</a>
        </p>

        <p class="py-4 text-warning">
            En continuant, vous accepter toutes ces règles
        </p>
        <template #footer>
            <Button
                label="Non, annuler"
                icon="pi pi-times"
                class="px-2 py-2 text-medium"
                @click="displayTerms = false"
                text
            />
            <Button
                label="Oui, je continue"
                class="bg-primary3 shadow-primary3 py-2 px-3 text-white font-normal"
                @click="acceptTerms"
                autofocus
            />
        </template>
    </Dialog>
</template>

<script>
import { useForm, Field } from "vee-validate";
import { defineComponent, computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import Storage, { storageKeys } from "../services/Storage";
import Dialog from "primevue/dialog";
import Divider from "primevue/divider";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Calendar from "primevue/calendar";
import Dropdown from "primevue/dropdown";
import * as yup from "yup";
import moment from "moment";

export default defineComponent({
    components: {
        Field,
        InputText,
        Calendar,
        Button,
        Divider,
        Dialog,
        Dropdown,
    },
    props: { allCities: { type: Array, required: true } },
    setup(props) {
        const country = ref(Storage.get(storageKeys.country_name));
        const displayTerms = ref(false);
        const emoji = ref(Storage.get(storageKeys.country_emoji));
        const countryCode = ref(Storage.get(storageKeys.country_code));
        const schema = computed(() =>
            yup.object({
                center_name: yup
                    .string()
                    .required("Le nom du centre est obligatoire"),
                last_name: yup.string().required("Votre nom est obligatoire"),
                first_name: yup.string().required("Prénom(s) obligatoire(s)"),
                city: yup
                    .object({
                        id: yup.number().required("Sélectionnez votre ville"),
                    })
                    .required("Sélectionnez votre ville"),
                authorization_number: yup
                    .string()
                    .required("Le numero d'autorisation est obligatoire"),
                birth_date: yup
                    .string()
                    .required("La date de naissance est obligatoire"),
                birth_place: yup.string().optional(),
                phone_1: yup
                    .string()
                    .required("Le numéro du déclarant est obligatoire"),
                phone_2: yup
                    .string()
                    .required(
                        "Veuillez fournir au moins un numero de contact de l'établissement"
                    ),
                phone_3: yup.string().optional(),
                email: yup
                    .string()
                    .required("Veuillez entrer votre adresse mail"),
                center_email: yup
                    .string()
                    .required(
                        "Il nous faut une adresse mail de l'établissement"
                    ),
            })
        );
        const cities = computed(() =>
            props.allCities.filter(
                (x) => x?.country?.code === countryCode.value?.toLowerCase()
            )
        );
        const form = ref({ phone_1: "", phone_2: "", city: {} });
        const isSuccess = ref(false);
        const store = useStore();
        const isSubmitting = ref(false);
        const { errors, values, handleSubmit, meta, setFieldValue } = useForm({
            initialValues: form.value,
            validationSchema: schema.value,
            validateOnMount: false,
        });
        const handleCity = (data) => {
            setFieldValue("city", data);
        };

        const handleType = (data) => {
            setFieldValue("type", data);
        };

        const parts1 = ref({
            public_hospital: "cet",
            health_center: "ce",
            clinical: "cette",
            pharmacy: "cette",
            laboratory: "ce",
        });
        const types = ref([
            { id: "public_hospital", name: "Hôpital Public" },
            { id: "health_center", name: "Centre de santé" },
            { id: "clinical", name: "Clinique (Centre de santé privé)" },
            { id: "pharmacy", name: "Pharmacie" },
            { id: "laboratory", name: "Laboratoire d'analyses médicales" },
        ]);
        const selectedTypeName = computed(
            () =>
                types.value.find((x) => x.id == form.value.type?.id)?.name ?? ""
        );
        const currentError = computed(() => {
            const [firstKey] = Object.keys(errors.value);
            console.log(errors.value[firstKey]);
            return errors.value[firstKey] ?? "";
        });

        const onSubmit = handleSubmit((values) => {
            displayTerms.value = true;
        });

        const acceptTerms = () => {
            displayTerms.value = false;
            save(values);
        };
        const save = (formData) => {
            const data = { ...formData };
            data.phone_1 = formData?.phone_1
                ?.replace("+", "")
                ?.replace(" ", "");
            data.phone_2 = formData?.phone_2
                ?.replace("+", "")
                ?.replace(" ", "");
            data.phone_3 = formData?.phone_3
                ?.replace("+", "")
                ?.replace(" ", "");
            data.city_id = formData?.city.id;
            data.type = formData?.type.id;

            delete data.city;

            isSubmitting.value = true;
            console.log("Hello", data);
            store.dispatch("centers/create", {
                data,
                onSuccess: () => {
                    isSuccess.value = true;
                    isSubmitting.value = false;
                },
                onError: (error) => {
                    console.log(error);
                    isSuccess.value = false;
                    isSubmitting.value = false;
                },
            });
        };

        const initPosition = () => {
            const unix_time = Storage.get(storageKeys.position_time);
            const now_unix = moment().unix();
            if (unix_time && now_unix - unix_time < 3600 * 10) {
                return;
            }
            fetch("https://api.ipregistry.co/?key=c33s648p0gjrije7")
                .catch(function (err) {
                    console.log("Error", err);
                })
                .then(function (response) {
                    return response.json();
                })
                .then(function (payload) {
                    console.log("loccc", payload);
                    if (payload?.location) {
                        countryCode.value =
                            payload.location?.country?.code.toLowerCase() ?? "";

                        Storage.set(
                            storageKeys.country_code,
                            countryCode.value
                        );
                        Storage.set(
                            storageKeys.country_emoji,
                            payload.location?.country?.flag.emoji
                        );
                        Storage.set(
                            storageKeys.country_name,
                            payload.location?.country?.name
                        );
                        emoji.value = payload.location?.country?.flag.emoji;
                        country.value = {
                            name: payload.location?.country?.name,
                            capital: payload.location?.country.capital,
                        };
                    }

                    if (payload.currency) {
                        Storage.set(
                            storageKeys.currency_code,
                            payload.currency?.code?.toLowerCase() ?? ""
                        );
                        Storage.set(
                            storageKeys.currency_symbol,
                            payload.currency?.symbol?.toLowerCase() ?? ""
                        );
                    }

                    Storage.set(storageKeys.position_time, moment().unix());
                });
        };
        onMounted(() => {
            initPosition();
        });
        return {
            countryCode,
            isSuccess,
            form,
            cities,
            meta,
            acceptTerms,
            country,
            types,
            selectedTypeName,
            errors,
            currentError,
            moment,
            handleType,
            displayTerms,
            parts1,
            isSubmitting,
            save,
            handleCity,
            onSubmit,
        };
    },
    methods: {},
});
</script>
<style lang="scss">
label {
    @apply text-slate-500;
}

.become-doctor {
    height: 100vh;
    // background: linear-gradient(90deg, #e52e71, #ff8a00);
    .bg-doct {
        @apply bg-white;
        border-top-left-radius: 10% 50%;
        border-bottom-left-radius: 10% 50%;
    }
    .slider-thumb::before {
        position: absolute;
        content: "";
        left: 30%;
        top: 20%;
        width: 450px;
        height: 450px;
        background: #17141d;
        border-radius: 62% 47% 82% 35% / 45% 45% 80% 66%;
        will-change: border-radius, transform, opacity;
        animation: sliderShape 5s linear infinite;
        display: block;
        z-index: -1;
        -webkit-animation: sliderShape 5s linear infinite;
    }

    .p-calendar {
        .p-inputtext {
            background: white !important;
        }
    }
}

@keyframes sliderShape {
    0%,
    100% {
        border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
        transform: translate3d(0, 0, 0) rotateZ(0.01deg);
    }
    34% {
        border-radius: 70% 30% 46% 54% / 30% 29% 71% 70%;
        transform: translate3d(0, 5px, 0) rotateZ(0.01deg);
    }
    50% {
        transform: translate3d(0, 0, 0) rotateZ(0.01deg);
    }
    67% {
        border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%;
        transform: translate3d(0, -3px, 0) rotateZ(0.01deg);
    }
}

.context {
    left: 0;
    position: absolute;
    top: 50vh;
}

.context h1 {
    text-align: center;
    color: #fff;
    font-size: 50px;
}

.area {
    @apply bg-primary;
    background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);
}

.circles {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    overflow: hidden;
}

.circles li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 25s linear infinite;
    bottom: -150px;
}

.circles li:nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}

.circles li:nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5) {
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7) {
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9) {
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10) {
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}

@keyframes animate {
    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100% {
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }
}
</style>
