import axios from "./axios";
import { medicalCenter } from "./endpoint";


export default {

    createMedicalCenter: async (data = {}) => {
        const response = await axios.post(medicalCenter.create(), data);
        return response.data;
    }
}
