<template>
    <!-- component -->
    <div class="bg-primary dark:bg-gray-900">
        <div class="flex justify-center h-screen">
            <div
                class="flex items-center px-6 mx-auto xs:w-full lg:w-1/3 bg-white"
            >
                <div class="flex-1">
                    <div class="flex flex-col justify-center">
                        <div
                            class="mt-3 mb-10 flex justify-start items-center md:hidden lg:hidden xl:hidden"
                        >
                            <a href="/" class="text-5xl font-bold text-white">
                                <img
                                    src="/images/allo_logo.svg"
                                    class="w-32"
                                    alt="AlloDoto Logo"
                                />
                            </a>
                        </div>

                        <h2 class="text-3xl text-slate-500">Connexion</h2>

                        <p class="mt-3 text-secondary dark:text-primary">
                            Entrez vos informations pour vous connecter
                        </p>
                    </div>

                    <div class="mt-8">
                        <form @submit.prevent="save">
                            <div>
                                <label
                                    for="email"
                                    class="block mb-1 text-sm text-gray-600 dark:text-gray-200"
                                    >Email
                                </label>
                                <div class="h-10">
                                    <input
                                        v-model="form.email"
                                        type="email"
                                        name="email"
                                        id="email"
                                        placeholder="example@allodoto.ci"
                                        class="shadow-none rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                    />
                                </div>
                            </div>

                            <div class="mt-6">
                                <div class="flex justify-between mb-1">
                                    <label
                                        for="password"
                                        class="text-sm text-gray-600 dark:text-gray-200"
                                        >Mot de passe</label
                                    >
                                    <a
                                        href="/reset"
                                        class="text-sm text-gray-400 focus:text-blue-500 hover:text-blue-500 hover:underline"
                                        >Mot de passe oublié ?</a
                                    >
                                </div>

                                <div class="h-10">
                                    <input
                                        v-model="form.password"
                                        type="password"
                                        name="password"
                                        id="password"
                                        placeholder="Mot de passe"
                                        class="rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                    />
                                </div>
                            </div>

                            <div class="mt-6">
                                <button
                                    type="submit"
                                    :class="`
                    w-full
                    px-4
                    py-2
                    tracking-wide
                    text-primary pointer-cursor
                    transition-colors
                    duration-200 bg-opacity-25 hover:bg-opacity-50
                    transform rounded-md ${
                        disabled
                            ? 'bg-primary'
                            : 'bg-primary hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50'
                    } `"
                                    :disabled="disabled"
                                >
                                    Se Connecter
                                </button>
                            </div>
                        </form>

                        <p class="mt-6 text-sm text-center text-gray-400">
                            Pas encore de compte ?
                            <a
                                href="/create-my-account"
                                class="text-blue-500 focus:outline-none focus:underline hover:underline"
                                >On y va</a
                            >.
                        </p>
                    </div>
                </div>
            </div>

            <div class="relative w-2/3 xs:hidden bg-bottom bg-contain">
                <div class="mt-10 flex justify-center items-center top">
                    <a href="/" class="text-5xl font-bold text-white">
                        <img
                            src="/images/logo_white.svg"
                            class="w-32"
                            alt="AlloDoto Logo"
                        />
                    </a>
                </div>
                <div
                    class="w-full h-2/3 bg-no-repeat bg-center absolute bottom-0 right-0 left-0 overflow-hidden"
                >
                    <img src="/images/medical_4.svg" alt="" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { computed, getCurrentInstance, ref } from "vue";
export default {
    components: {},
    props: [],
    setup() {
        const form = ref({});
        const isSuccess = ref(true);

        const instance = getCurrentInstance();
        const store = instance.proxy.$store;

        const isSubmitting = ref(false);
        const disabled = computed(
            () =>
                !form.value?.email ||
                !form.value?.password ||
                isSubmitting.value
        );

        const save = () => {
            isSubmitting.value = true;
            store.dispatch("auth/login", {
                login: form.value.email,
                password: form.value.password,
                onSuccess: async (response) => {
                    isSubmitting.value = false;
                    isSuccess.value = true;

                    const user = await store.dispatch("auth/getUser");

                    const roles = (user.roles ?? []).map((x) => x.slug);
                    if (typeof window != "undefined")
                        if (roles.includes("patient")) {
                            window.location.replace("/account");
                        } else window.location.replace("/app");
                },
                onError: () => {
                    isSuccess.value = false;
                    isSubmitting.value = false;
                },
            });
        };

        return { isSuccess, disabled, form, isSubmitting, save };
    },
    methods: {},
};
</script>
