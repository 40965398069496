<template>
    <div class="mt-5 grid grid-cols-3 gap-4">
        <div
            v-for="patient in patients"
            :key="patient.id"
            href="#"
            class="group relative patient-container flex bg-white rounded p-3 cursor-pointer w-full block max-w-sm border border-slate-100 rounded-lg shadow-md"
        >
            <div
                class="actions-block absolute right-0 flex-col items-center justify-center mx-1 hidden group-hover:flex"
            >
                <button
                    class="bg-secondary px-2 py-1 mb-2 rounded text-white"
                    size="sm"
                    @click="gotoDetails(patient.id)"
                >
                    <i class="las la-eye m-0"></i>
                </button>
                <button
                    class="bg-primary px-2 py-1 rounded text-white"
                    size="sm"
                    @click="gotoDetails(patient.id)"
                >
                    <i class="las la-pen m-0"></i>
                </button>
            </div>
            <Avatar size="large" :image="patient.avatar" class="mr-2.5" />
            <div>
                <p class="text-gray-700 dark:text-gray-400 pb-2 font-bold">
                    {{ patient.full_name }}
                </p>
                <span
                    class="text-indigo-800 text-xs font-semibold mr-2 py-0.5 rounded dark:text-indigo-900"
                    >{{ patient.username }}</span
                >
                <!-- <div class="mt-2">
          <span
            v-for="acc in agent.accounts"
            :key="acc.id"
            class="
              bg-blue-100
              text-blue-800 text-xs
              font-semibold
              mr-2
              px-2.5
              py-0.5
              rounded
              dark:bg-blue-200 dark:text-blue-800
            "
            >{{ acc.role.name }}</span
          >
        </div> -->
            </div>
        </div>
        <div
            class="w-full flex bg-white rounded p-3 cursor-pointer w-1/3 block max-w-sm border border-slate-100 rounded-lg shadow-md hover:bg-gray-100"
            @click="
                $router.push({
                    name: 'medical.patientCreate',
                    params: { slug: $route.params.slug },
                })
            "
        >
            <div class="flex justify-center items-center">
                <icon name="plus" size="50"></icon>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { TheCard } from "flowbite-vue";
import Avatar from "primevue/avatar";

export default defineComponent({
    components: { TheCard, Avatar },
    setup(props) {
        const route = useRoute();
        const router = useRouter();
        const store = useStore();
        const patients = ref([]);
        const pagination = ref({
            total: 0,
            lastPage: 1,
            currentPage: 1,
            perpage: 20,
        });
        const gotoDetails = (id) => {
            router.push({ name: "medical.patientDetails", params: { id } });
        };
        const getMedicalCenterPatients = () => {
            store.dispatch("medical/getMedicalCenterPatients", {
                medicalCenterId: route.params.slug,
                onSuccess: (response) => {
                    patients.value = response?.data || [];
                    pagination.value = {
                        total: response.total,
                        currentPage: response.current_page,
                        lastPage: response.last_page,
                    };
                    console.log("data", response);
                },
                onError: (error) => {
                    console.log("error");
                },
            });
        };

        onMounted(() => {
            getMedicalCenterPatients();
        });
        return { patients, gotoDetails };
    },
});
</script>
<style lang="scss">
.patient-container.actions-block {
    display: none;
}
.patient-container:hover .actions-block {
    display: block;
}
</style>
