<template>
  <div class="flex">
    <div class="mr-2 bg-gray" v-if="imageData">
      <img :src="imageData" class="w-full" />
    </div>
    <div>
      <button
        class="
          bg-pending
          w-10
          h-10
          rounded
          flex
          justify-center
          items-center
          text-white
        "
        @click.prevent="openFiler"
      >
        <i class="la la-upload text-xl"></i>
      </button>
      <input
        ref="file"
        type="file"
        name="ambulance-image"
        id="ambulance-image"
        class="invisible"
        accept="image/*"
        @change="fileLoaded"
      />
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      placeholder: { type: String, default: null },
      url: {
        type: String,
        required: false,
        default: null,
      },
    },
    emits: ["fileChanged"],
    data() {
      return {
        imageData: this.url,
      };
    },
    methods: {
      openFiler() {
        this.$refs.file.click();
      },
      fileLoaded(event) {
        const file = event.target.files[0];
        this.$emit("fileChanged", file);
        console.log("message emit from child component");
        var reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          this.imageData = e.target.result;
        };
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(file);
      },
    },
  };
</script>
