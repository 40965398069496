import getters from './getters'
import actions from './actions'
import mutations from './mutations'
import { storageKeys } from '@/services/endpoint'


const token = typeof window !== 'undefined' ? localStorage.getItem(storageKeys.authToken) : null;
const state = {
    user: null,
    currentPortfolio: null,
    portfolios: [],
    token
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
