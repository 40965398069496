import service from '@/services/setting';
import {
    SET_ALL_CITIES,
    SET_ALL_PORTFOLIOS,
    SET_ALL_RECORDS,
    SET_ALL_RESOURCES,
    SET_CARS,
    SET_CITIES,
    SET_COUNTRIES,
    SET_PORTFOLIO,
    SET_RECORD_SECTIONS,
    SET_RECORD_TYPES,
    SET_RESOURCES,
    SET_RESOURCE_TYPES,
    SET_ROLES,
    SET_SPECIALTIES,
    SET_STAFF
} from './mutations';

export const LOGIN = 'LOGIN';
import Storage, { storageKeys } from "../../services/Storage";


export default {
    async getCities({ commit }) {
        try {
            const { cities } = await service.getCities();
            commit({ type: SET_CITIES, cities });
        } catch (error) {
            console.log(error)
        }
    },

    async getCountries({ commit, }) {
        try {
            const { countries } = await service.getCountries();
            commit({ type: SET_COUNTRIES, countries });
        } catch (error) {
            console.log(error)
        }
    },

    async createCountry({ dispatch }, { data, onSuccess, onError }) {
        try {
            await service.saveCountry(data);
            dispatch({ type: 'getCountries' });
            onSuccess();
        } catch (error) {
            onError();
            console.log(error)
        }
    },

    async editCountry({ dispatch }, { id, data, onSuccess, onError }) {
        try {
            await service.putCountry(id, data);
            dispatch({ type: 'getCountries' });
            onSuccess();
        } catch (error) {
            onError();
            console.log(error)
        }
    },

    async createCity({ dispatch }, { data, onSuccess, onError }) {
        try {
            await service.saveCity(data);
            dispatch({ type: 'getCountries' });
            onSuccess();
        } catch (error) {
            onError();
            console.log(error)
        }
    },

    async editCity({ dispatch }, { id, data, onSuccess, onError }) {
        try {
            await service.putCity(id, data);
            dispatch({ type: 'getCountries' });
            onSuccess();
        } catch (error) {
            onError();
            console.log(error)
        }
    },

    async removeCity({ dispatch }, { id, onSuccess, onError }) {
        try {
            await service.removeCity(id);
            dispatch({ type: 'getCountries' });
            onSuccess();
        } catch (error) {
            onError();
            console.log(error)
        }
    },

    async removeCountry({ dispatch }, { id, onSuccess, onError }) {
        try {
            await service.removeCountry(id);
            dispatch({ type: 'getCountries' });
            onSuccess();
        } catch (error) {
            onError();
            console.log(error)
        }
    },

    async getRoles({ commit }) {
        try {
            const { roles } = await service.getRoles();
            commit({ type: SET_ROLES, roles });

        } catch (error) {
            console.log(error);
        }
    },

    async getAllCities({ commit }, { code }) {
        try {
            if (!code) code = '';
            const { cities } = await service.getAllCities({ code });
            commit({ type: SET_ALL_CITIES, cities });

        } catch (error) {
            console.log(error);
        }
    },

    async getStaff({ commit }, { page, portfolioId }) {
        try {
            const { users } = await service.getStaff({ page, portfolioId });
            commit({ type: SET_STAFF, users });

        } catch (error) {
            console.log(error);
        }
    },

    async getWriters({ commit }, { onSuccess, onError }) {
        try {
            const { users } = await service.getWriters({});
            onSuccess(users)
        } catch (error) {
            console.log(error);
        }
    },


    async getPortfolios({ commit }, { }) {
        try {
            const { portfolios } = await service.getPortfolios({});
            commit({ type: SET_ALL_PORTFOLIOS, portfolios });

        } catch (error) {
            console.log(error);
        }
    },

    async getPortfolio({ commit }, { id, onSuccess }) {
        try {
            const { portfolio } = await service.getPortfolio(id);
            commit({ type: SET_PORTFOLIO, portfolio });
            onSuccess()
        } catch (error) {
            console.log(error);
        }
    },

    async editPortfolio({ commit }, { id, data, onSuccess }) {
        try {
            await service.editPortfolio(id, data);
            onSuccess()
        } catch (error) {
            console.log(error);
        }
    },

    async clearPortfolioPhone({ commit }, { id, data, onSuccess }) {
        try {
            await service.clearPhone(id, data);
            onSuccess()
        } catch (error) {
            console.log(error);
        }
    },

    async setPortfolioPhone({ commit }, { id, data, onSuccess, onError }) {
        try {
            await service.setPortfolioPhone(id, data);
            onSuccess()
        } catch (error) {
            console.log(error);
            onError()
        }
    },

    async validatePortfolioPhone({ commit }, { id, data, onSuccess, onError }) {
        try {
            await service.validatePortfolioPhone(id, data);
            onSuccess()
        } catch (error) {
            console.log(error);
            onError()
        }
    },

    async deleteStaff({ dispatch }, { id, onSuccess, onError }) {
        try {
            await service.removeStaff(id);
            dispatch('getStaff')
            onSuccess()
        } catch (error) {
            console.log(error);
            onError(error.data)
        }
    },
    async addStaffMember({ }, { data, onSuccess, onError }) {
        try {
            await service.addStaff(data);
            onSuccess();
        } catch (error) {
            console.log(error);
            onError();
        }
    },

    async addAmbulance({ state, dispatch, }, { data, onSuccess, onError }) {
        try {
            const params = {}
            await service.addAmbulance(data, params);
            dispatch({ type: 'getAmbulances' });
            onSuccess();
        } catch (error) {
            console.log(error);
            onError();
        }
    },

    async updateAmbulance({ dispatch }, { data, id, onSuccess, onError }) {
        try {
            await service.editAmbulance(data, id);
            dispatch({ type: 'getAmbulances' });
            dispatch({ type: 'getResources' });
            onSuccess();
        } catch (error) {
            console.log(error);
            onError();
        }
    },

    async updateAmbulanceResources({ dispatch }, { data, id, onSuccess, onError }) {
        try {
            await service.editAmbulanceResources(data, id);
            dispatch({ type: 'getAmbulances' });
            dispatch({ type: 'getResources' });
            onSuccess();
        } catch (error) {
            console.log(error);
            onError();
        }
    },
    async getAmbulances({ commit, rootState }, { onSuccess, onError }) {
        try {
            const params = { portfolioId: rootState.auth?.currentPortfolio?.id }
            const { cars } = await service.getAmbulances(params);
            commit({ type: SET_CARS, cars });

            if (onSuccess) onSuccess()
        } catch (error) {
            console.log(error);
            if (onError) onError()
        }
    },

    async attachCarImage({ dispatch }, { id, data, onSuccess = () => { }, onError = () => { } }) {
        try {
            await service.attachCarFile(id, data);
            dispatch({ type: 'getAmbulances' });
            onSuccess()
        } catch (error) {
            console.log(error);
            onError()
        }
    },

    async updateAmbulancePosition({ dispatch }, { id, data, onSuccess = () => { }, onError = () => { } }) {
        try {
            await service.updateAmbulancePosition(id, data);
            dispatch({ type: 'getAmbulances' });
            onSuccess();
        } catch (error) {
            console.log(error);
            onError();
        }
    },

    async removeAmbulances({ dispatch }, { id, onSuccess, onError }) {
        try {
            await service.deleteAmbulance(id);
            dispatch({ type: 'getAmbulances' });
            onSuccess();
        } catch (error) {
            console.log(error);
            onError();
        }
    },

    async getResourceTypes({ commit }, { page }) {
        try {
            const { types } = await service.getResourceTypes(page);
            const { data, total, last_page, per_page, current_page } = types;
            commit({ type: SET_RESOURCE_TYPES, types: data, total, last_page, per_page, current_page });
        } catch (error) {
            console.log(error);
        }
    },

    async getAllResourceTypes({ commit }, { onSuccess }) {
        try {
            const { types } = await service.getAllResourceTypes();
            onSuccess(types)
        } catch (error) {
            console.log(error);
        }
    },
    async getResources({ commit, state, }, { portfolioId, page }) {
        try {
            const { resources } = await service.getResources({ portfolioId, page });
            commit({ type: SET_RESOURCES, resources });
        } catch (error) {
            console.log(error);
        }
    },

    async getAllResources({ commit, state, }, { portfolioId, page }) {
        try {
            const { resources } = await service.getAllResources({ portfolioId });
            commit({ type: SET_ALL_RESOURCES, resources });
        } catch (error) {
            console.log(error);
        }
    },

    async addResourceTypes({ dispatch }, { types, onSuccess, onError }) {
        try {
            await service.addResourceTypes({ types });
            dispatch({ type: 'getResourceTypes' });
            onSuccess();
        } catch (error) {
            console.log(error);
            onError();
        }
    },

    async editResourceTypes({ dispatch }, { typeId, typeData, onSuccess, onError }) {
        try {
            await service.editResourceTypes(typeId, typeData);
            dispatch({ type: 'getResourceTypes' });
            onSuccess();
        } catch (error) {
            console.log(error);
            onError();
        }
    },
    async addResources({ dispatch }, { resources, onSuccess, onError }) {
        try {
            await service.addResources({ resources });
            dispatch('getResources', { page: 1, portfolioId: resources[0]?.portfolio_id })
            onSuccess();
        } catch (error) {
            console.log(error);
            onError();
        }
    },

    async removeResourceType({ dispatch }, { id, onSuccess, onError }) {
        try {
            await service.removeResourceType(id);
            dispatch({ type: 'getResourceTypes' });
            onSuccess();
        } catch (error) {
            console.log(error);
            onError();
        }
    },

    async removeResource({ dispatch }, { id, portfolioId, onSuccess, onError }) {
        try {
            await service.removeResource(id, { portfolioId });
            dispatch('getResources', { page: 1, portfolioId })
            onSuccess();
        } catch (error) {
            console.log(error);
            onError();
        }
    },

    async editResource({ dispatch }, { portfolioId, id, data, onSuccess, onError }) {
        try {
            await service.editResources(id, { ...data }, { portfolioId });
            dispatch('getResources', { page: 1, portfolioId })
            onSuccess();
        } catch (error) {
            console.log(error);
            onError();
        }
    },

    async getRecordTypes({ commit }, { page }) {
        try {
            const { types, sections } = await service.getRecordTypes(page);
            const { data, current_page, last_page, per_page, from, to, total } = types;
            commit({ type: SET_RECORD_TYPES, types: data, pagination: { current_page, last_page, per_page, from, to, total } });
            commit({ type: SET_RECORD_SECTIONS, sections });
        } catch (error) {
            console.log(error);
        }
    },

    async getSpecialties({ commit }, { }) {
        try {

            const { specialties } = await service.getSpecialties();
            commit({ type: SET_SPECIALTIES, specialties });
        } catch (error) {
            console.log(error);
        }
    },

    async getAllRecordTypes({ commit }) {
        try {

            const { types, sections } = await service.getAllRecordTypes();
            commit({ type: SET_ALL_RECORDS, types, sections });
        } catch (error) {
            console.log(error);
        }
    },

    async addRecordTypes({ dispatch }, { types, onSuccess, onError }) {
        try {
            await service.addRecordTypes({ types });
            dispatch({ type: 'getResourceTypes' });
            onSuccess();
        } catch (error) {
            console.log(error);
            onError();
        }
    },

    async editRecordType({ dispatch }, { data, id, onSuccess, onError }) {
        try {
            await service.editRecordType(id, data);
            dispatch({ type: 'getResourceTypes' });
            onSuccess();
        } catch (error) {
            console.log(error);
            onError();
        }
    },

    async removeRecordType({ dispatch }, { id, onSuccess, onError }) {
        try {
            await service.removeRecordType(id);
            dispatch({ type: 'getRecordTypes' });
            onSuccess();
        } catch (error) {
            console.log(error);
            onError();
        }
    }

}
