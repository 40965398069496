import User from '../../Model/User'
import Message from '../../Model/Message'

export const Users = [

]

export const MessagesUser1 = [

]

export const MessagesUser2 = [

]

export const MessagesUser3 = [

]
export const MessagesUser4 = [

]
