<template>
    <div class="flex">
        <div class="flex flex-col left-box overflow-visible">
            <div class="mb-10 mt-10">
                <div class="flex items-end" style="white-space: nowrap">
                    <h1
                        class="text-5xl font-medium font-ibm text-primary leading-snug"
                    >
                        Vous êtes
                    </h1>

                    <div
                        class="border-primary1-800 px-2 rounded mx-2 font-medium"
                    >
                        <h3
                            v-for="(item, i) in list"
                            :key="i"
                            :class="`${
                                i == index ? 'block' : 'hidden'
                            } inline-block list-enter-active italic text-5xl font-medium px-2 font-ibm text-slate-800 leading-snug transition transition-all`"
                        >
                            {{ item }}
                        </h3>
                    </div>
                </div>
                <h3
                    class="whitespace-nowrap text-4xl font-qs font-medium text-primary py-3"
                >
                    Envie de gagner du temps ?
                </h3>
                <h2 class="text-4xl font-qs text-primary font-medium py-3">
                    Être plus proche et à l'écoute de vos patients ?
                </h2>
            </div>

            <div class="w-full xs:flex-col flex items-center">
                <a href="/i-am-practitioner">
                    <button
                        class="py-3 px-5 flex items-center max-w-fit w-auto font-medium border bg-primary text-primary bg-opacity-25 hover:bg-opacity-50 font-ibm rounded-sm"
                    >
                        <span class="mr-2 text-primary"
                            >Je m'inscris maintenant</span
                        >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            fill="#206ba4"
                            stroke="currentColor"
                            class="w-5 h-5"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M8.25 4.5l7.5 7.5-7.5 7.5"
                            />
                        </svg>
                    </button>
                </a>
                <span class="px-5 xs:py-3 text-md">ou</span>

                <a href="/contact">
                    <button
                        class="py-3 px-5 flex items-center max-w-fit w-auto font-medium bg-opacity-25 hover:bg-opacity-50 bg-slate-500 text-slate-500 font-ibm rounded"
                    >
                        <span class="mr-2">Je demande une démo</span>
                    </button>
                </a>
            </div>

            <div class="mt-10">
                <h6 class="text-xs">Disponible</h6>

                <div class="flex">
                    <country-flag country="bj" rounded shadow class="mr-1" />
                    <country-flag country="ci" rounded shadow class="mr-1" />
                </div>
            </div>
            <div class="mt-5">
                <h6 class="text-xs">Bientôt</h6>

                <div class="flex">
                    <country-flag country="tg" rounded shadow class="mr-1" />
                    <country-flag country="sn" rounded shadow class="mr-1" />
                </div>
            </div>
        </div>
        <div
            class="bg-right right-box top-0 bg-no-repeat h-full bg-contain absolute right-0"
            :style="`background-image: url(/images/bg-general-4.svg)`"
        ></div>
    </div>
</template>
<script>
import {
    defineComponent,
    computed,
    ref,
    onMounted,
    onBeforeUnmount,
} from "vue";

export default defineComponent({
    components: {},
    setup() {
        const interval = ref(null);
        const index = ref(0);
        const list = computed(() => [
            "médécin généraliste",
            "pédiatre",
            "psychiatre",
            "dermatologue",
            "gynécologue",
        ]);

        const images = computed(() => [
            "/images/bg-general-4.svg",
            "/images/bg-5.svg",
            "/images/bg-6.svg",
            "/images/bg-7.svg",
            "/images/bg-8.svg",
        ]);

        const currentElm = computed(() => list.value[index.value]);

        onMounted(() => {
            interval.value = setInterval(() => {
                if (index.value === list.value.length - 1) index.value = 0;
                else index.value += 1;
            }, 5000);
        });

        onBeforeUnmount(() => {
            clearInterval(interval.value);
        });
        return { list, currentElm, images, index };
    },
});
</script>
<style lang="scss">
.search-box {
    width: 100%;
}
input[type="text"],
textarea {
    @apply bg-slate-100;
}
.list-enter-active {
    animation: fade-in 0.5s ease-in-out;
}

.list-leave-active {
    animation: fade-in 0.5s ease-in-out reverse;
}

@keyframes fade-in {
    0% {
        opacity: 0;
        transform: translateX(30px);
    }
    100% {
        transform: translateX(0px);
    }
}

@keyframes fade-out {
    0% {
        transform: translateX(0px);
    }
    100% {
        opacity: 0;
        transform: translateX(30px);
    }
}
.left-box {
    width: 55%;
}
.right-box {
    width: 45%;
    &::before {
        content: ""; /* empty but necessary */
        position: absolute;
    }
}
@media (max-width: 500px) {
    .left-box {
        width: 100% !important;
        .text-4xl {
            font-size: 20px !important;
        }
        .text-6xl {
            font-size: 40px !important;
        }
    }
    .right-box {
        width: 0 !important;
    }
    .search-box {
        flex-direction: column;

        .name-box,
        .place-box {
            width: 90% !important;
            height: 50px;
            border: none;
            @apply py-2;
        }
        button {
            width: 99%;
            border-radius: 8px !important;
            @apply ml-1;
        }
    }
}
</style>
