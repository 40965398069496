export default {
    get(key) {

        if (typeof window !== 'undefined') return localStorage.getItem(key)
    },
    set(key, value) {
        if (typeof window !== 'undefined') return localStorage.setItem(key, value);
    },
    remove(key) {
        if (typeof window !== 'undefined') localStorage.removeItem(key)
    },
    clear() {
        if (typeof window !== 'undefined') localStorage.clear()
    }
}


export const storageKeys = {
    user_lat: '@userLat',
    user_lng: '@userLng',
    country_name: '@countryName',
    country_code: '@countryCode',
    country_emoji: "@countryEmoji",
    currency_code: "@currencyCode",
    currency_symbol: "@currencySymbol",
    position_time: "@positionTime",
    authToken: '@authToken',

}
