<template>
    <div>
        <div class="bg-[#F8F9FC] px-28 main-search pb-10">
            <div class="flex">
                <div class="flex items-center mt-10 mb-5">
                    <h3 class="font-bold font-qs">Des praticiens à</h3>
                    <h3
                        class="font-bold font-qs text-primary ml-2 cursor-pointer"
                    >
                        {{ city?.name ?? country?.capital }}
                        <span>{{ emoji }}</span>
                    </h3>
                </div>
            </div>
            <div class="w-4/5">
                <p class="text-slate-600">
                    Pour effectuer une recherche, saisissez une spécialité ou un
                    nom dans le champ de recherche, ou utilisez les différents
                    critères proposés. Vous pouvez également consulter la liste
                    de nos praticiens immédiatement disponibles ci-dessous.
                </p>
            </div>

            <div class="mt-4 lg:w-2/3 md:w-full">
                <form id="welcome-form" action="/search-praticians">
                    <div class="">
                        <div
                            class="flex search-box bg-slate-100 border border-slate-300 justify-between rounded-lg"
                        >
                            <div class="flex justify-start w-11/12">
                                <div
                                    class="name-box w-2/3 flex ml-2 justify-start items-center border-r border-[#e3e6e7]"
                                >
                                    <div
                                        class="text-xs mx-1 text-[#a3abb0] border-right"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="currentColor"
                                            class="w-5 h-5"
                                        >
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                                            />
                                        </svg>
                                    </div>

                                    <input
                                        class="w-full text-slate-600 font-qs text-slate-600 peer w-full h-10"
                                        type="text"
                                        style="border: none !important"
                                        placeholder="Nom, établissement, spécialités"
                                    />
                                </div>
                                <div
                                    class="place-box w-1/3 flex items-center relative"
                                >
                                    <div
                                        class="ml-1 mr-1 text-xs text-[#a3abb0]"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="currentColor"
                                            class="w-5 h-5"
                                        >
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                                            />
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                                            />
                                        </svg>
                                    </div>

                                    <SearchInput
                                        class="relative"
                                        placeholder="Où ?"
                                        :options="cities"
                                        @update="
                                            ($event) => handleCityChange($event)
                                        "
                                    />
                                </div>
                            </div>
                            <div class="py-1 mr-1">
                                <button
                                    type="submit"
                                    style="border-radius: 0 8px 8px 0"
                                    class="flex py-4 px-2 text-slate-800 bg-opacity-25 hover:bg-opacity-50 justify-center items-center h-full bg-slate-800"
                                    @click.prevent="search"
                                >
                                    <span class="mx-1">Rechercher</span>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        class="w-6 h-6"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M8.25 4.5l7.5 7.5-7.5 7.5"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div class="mt-5 mb-5 h-auto">
                <h2
                    class="text-slate-400 text-lg"
                    v-if="cities.length && !noData"
                >
                    Praticiens disponibles sur rendez-vous
                </h2>

                <div
                    v-if="isSearching"
                    class="flex justify-center items-center w-full my-4"
                >
                    <NewLoader />
                </div>

                <div
                    v-else-if="noData"
                    class="flex flex-col justify-center items-center py-8"
                >
                    <div
                        class="w-[100px] h-[100px] rounded-full border-2 border-slate-700 flex flex-col justify-center items-center"
                    >
                        <i
                            class="las la-calendar-times text-5xl text-slate-700 py-5"
                        ></i>
                    </div>
                    <p class="py-2 text-md text-slate-700 font-medium">
                        Aucun résultat pour votre recherche.
                    </p>
                </div>

                <div class="" style="" v-if="cities.length && !noData">
                    <div
                        class="mt-4 pb-5"
                        v-for="(data, index) in list"
                        :key="index"
                    >
                        <pratician :planning="data" />
                    </div>
                </div>

                <div v-if="cities.length == 0 && noData" class="py-5">
                    <p class="py-2 text-md text-slate-700 font-semibold">
                        Notre service n'est pas disponible dans votre pays
                        (<strong>{{ countryName }} {{ emoji }}</strong
                        >) actuellement.
                    </p>
                    <a href="/contact">
                        <button
                            v-if="!isSearching"
                            class="px-2 py-2 my-5 hover:border-primary hover:text-primary border border-slate-500 rounded-lg text-slate-500 outline-none grow-0 flex-none"
                        >
                            Nous aider au lancement (<strong>{{
                                countryName
                            }}</strong
                            >)
                        </button>
                    </a>
                </div>

                <div
                    class="my-5 flex justify-center"
                    v-if="cities.length && !noData"
                >
                    <button
                        v-if="
                            !isSearching &&
                            pagination?.last_page != pagination?.current_page
                        "
                        class="px-2 py-2 hover:border-primary hover:text-primary border border-slate-500 rounded-lg text-slate-500 outline-none"
                    >
                        Afficher plus de résultats
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { computed, onMounted, watch, ref } from "vue";
import SearchInput from "./input.vue";
import Storage, { storageKeys } from "../../services/Storage";
import { useStore } from "vuex";
import moment from "moment";
import Pratician from "./pratician.vue";
import NewLoader from "@/components/Loader/index-2.vue";
import { useWindowSize } from "@vueuse/core";
export default {
    components: { SearchInput, Pratician, NewLoader },
    setup() {
        const store = useStore();
        const list = ref([]);
        const pagination = ref(null);
        const city = ref(null);
        const noData = ref(false);
        const country = ref(null);
        const userLatitude = ref(Storage.get(storageKeys.user_lat));
        const userLongitude = ref(Storage.get(storageKeys.user_lng));
        const countryName = computed(() =>
            Storage.get(storageKeys.country_name)
        );
        const emoji = ref(null);
        const cities = computed(() => store.getters["config/allCities"]);
        const isSearching = ref(false);
        const { width, height } = useWindowSize();
        const handleCityChange = (params) => {
            city.value = params;

            search();
        };

        const search = () => {
            const country = Storage.get(storageKeys.country_code);
            isSearching.value = true;
            const data = {
                city: city.value?.slug ?? "",
                country,
                longitude: userLongitude.value,
                latitude: userLatitude.value,
            };

            console.log(data, "dedededede");

            store.dispatch("medical/performSearch", {
                data,
                onSuccess: (res) => {
                    list.value = [...(res?.data ?? [])];
                    pagination.value = { ...res };

                    delete pagination.value?.data;
                    noData.value = !res?.data?.length;
                    isSearching.value = false;
                },
                onError: (res) => {
                    isSearching.value = false;
                    noData.value = false;
                },
            });
        };
        const getCities = (code) => {
            store.dispatch("config/getAllCities", { code });
        };
        const intPosition = () => {
            const unix_time = Storage.get(storageKeys.position_time);
            const now_unix = moment().unix();
            if (unix_time && now_unix - unix_time < 3600 * 10) {
                return;
            }
            fetch("https://api.ipregistry.co/?key=c33s648p0gjrije7")
                .catch(function (err) {
                    console.log("Error", err);
                })
                .then(function (response) {
                    return response.json();
                })
                .then(function (payload) {
                    // console.log("loccc", payload);
                    if (payload?.location) {
                        userLatitude.value = payload.location?.latitude;
                        userLongitude.value = payload.location?.longitude;
                        Storage.set(storageKeys.user_lat, userLatitude.value);
                        Storage.set(storageKeys.user_lng, userLongitude.value);

                        Storage.set(
                            storageKeys.country_code,
                            payload.location?.country?.code.toLowerCase() ?? ""
                        );

                        Storage.set(
                            storageKeys.country_emoji,
                            payload.location?.country?.flag.emoji
                        );

                        Storage.set(
                            storageKeys.country_name,
                            payload.location?.country?.name
                        );

                        emoji.value = payload.location?.country?.flag.emoji;
                        country.value = {
                            name: payload.location?.country?.name,
                            capital: payload.location?.country.capital,
                        };
                    }

                    getCities(
                        payload.location?.country?.code.toLowerCase() ??
                            Storage.get(storageKeys.country_code)
                    );

                    if (payload.currency) {
                        Storage.set(
                            storageKeys.currency_code,
                            payload.currency?.code?.toLowerCase() ?? ""
                        );
                        Storage.set(
                            storageKeys.currency_symbol,
                            payload.currency?.symbol?.toLowerCase() ?? ""
                        );
                    }

                    Storage.set(storageKeys.position_time, moment().unix());
                });
        };
        watch(
            () => width.value,
            (val) => {
                // console.log("width", val);
            }
        );
        onMounted(() => {
            intPosition();
            getCities(Storage.get(storageKeys.country_code));
            search();
        });
        return {
            cities,
            emoji,
            noData,
            isSearching,
            list,
            pagination,
            city,
            countryName,
            country,
            search,
            handleCityChange,
        };
    },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 767px) {
    .main-search {
        padding: 0 20px !important;
    }
}
</style>
