import { routes } from './routes'


// import routes from './routes'
import { createWebHistory, createRouter, createMemoryHistory } from 'vue-router'
import storage from "../services/Storage";
import { storageKeys } from "../services/endpoint";
// import store from '@/store'

const router = createRouter({
    history: typeof window !== 'undefined' ? createWebHistory() : createMemoryHistory(),
    routes
})




router.beforeEach((to, from, next) => {

    if (window.location.href.includes('app') || window.location.href.includes('/account'))
        window.document.title = `${to?.meta?.title ?? ''}  ${to?.meta?.title ? '|' : ""} Allô Doto`;
    const token = storage.get(storageKeys.authToken);

    // if (to.matched.some(record => record.meta.requiresAuth)) {
    //     if (!token) {
    //         window.location.replace('/login')
    //     } else next()
    // } else
    next();
})

export default router
