<template>
    <div class="p-2 mt-4">
        <div class="bg-white rounded">
            <h4 class="font-bold">Tests Reports</h4>
            <div class="flex mt-3 justify-between py-3">
                <div
                    class="flex"
                    v-for="examen in lastExamens"
                    :key="examen.id"
                >
                    <div class="p-1 bg-gray-100">
                        <img
                            src="/images/prescription.png"
                            class="h-14"
                            alt=""
                        />
                    </div>
                    <div class="flex flex-col ml-2">
                        <h4 class="text-black text-md text-blue-700">
                            {{ examen.name }}
                        </h4>
                        <h5 class="text-gray-400 text-xs">
                            {{ examen.created_at }}
                        </h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    computed: {
        ...mapGetters({
            patient: "medical/patient",
            doctors: "medical/doctors",
            prescriptions: "medical/prescriptions",
        }),
        lastExamens() {
            return (this.prescriptions || [])
                .filter((x) => x.type === "examen")
                .sort((a, b) =>
                    moment.utc(b.createdAt).diff(moment.utc(a.createdAt))
                )
                .slice(0, 3);
        },
    },
};
</script>
