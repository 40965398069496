<template>
    <div
        class="bg-white rounded-xl flex justify-between p-5 box-planning transition transition-all"
        style="border: 1px solid rgb(235, 235, 237)"
    >
        <div class="flex w-3/5 justify-between pratician-info">
            <div class="w-1/2 pratician-data">
                <div class="flex items-center">
                    <div
                        v-if="
                            !planning?.pratician?.avatar.includes('-default.')
                        "
                        class="w-14 h-14 rounded-full mb-3 bg-no-repeat bg-slate-400"
                        :style="`
                    background-size: cover;
                    background-position: center;
                    background-image: url(${planning.pratician?.avatar});
                `"
                    ></div>

                    <div
                        v-else
                        class="w-14 h-14 text-xl font-bold font-ibm rounded-full mb-3 bg-no-repeat bg-slate-100 border-2 border-slate-300 text-slate-600 flex justify-center items-center"
                    >
                        {{ initials }}
                    </div>
                    <h3 class="font-bold font-qs text-xl px-2 text-slate-600">
                        Dr. {{ planning.pratician?.full_name }}
                    </h3>
                </div>

                <p class="text-slate-500 font-qs font-bold">
                    {{ specialties }}
                    <span v-if="otherSpecialties.length"> et </span>

                    <el-tooltip
                        v-if="otherSpecialties.length"
                        :content="otherSpecialties.toString()"
                        placement="right"
                    >
                        <a class="underline text-[#2b4660]" href="#"
                            >{{ otherSpecialties.length }} autres spécialités</a
                        >
                    </el-tooltip>
                </p>
                <h5 class="font-bold font-qs text-lg text-slate-600 py-3">
                    {{ planning?.medical_center?.name ?? "-" }}
                </h5>

                <div class="mt-3 action">
                    <a
                        :href="`/praticians/${planning?._id}`"
                        class="w-full mt-2 rounded-md bg-primary py-2 text-center px-3 text-white px-2"
                    >
                        Prendre rendez-vous</a
                    >
                </div>
            </div>
            <div
                class="pricing border border-slate-300 rounded-xl px-8 py-5 w-1/2 prices flex flex-col justify-center"
            >
                <div class="mb-6">
                    <p class="text-slate-400 font-qs text-md">Tarif</p>
                    <h3 class="text-sm font-medium" v-if="planning.price">
                        {{ priceMin }} à {{ priceMax }}
                        <i
                            v-tooltip.bottom="
                                'Ce prix représente une foruchette mais peut differer en fonction du moment de la consultation'
                            "
                            class="la la-info-circle text-md text-[#574f7d]"
                        ></i>
                    </h3>
                    <h3 class="text-sm font-medium" v-else>N/A</h3>
                </div>
                <div>
                    <p class="text-slate-400 font-qs text-md">Adresse</p>
                    <h3 class="text-sm font-medium">
                        {{ planning?.address?.address ?? "-" }}
                    </h3>
                </div>
            </div>
        </div>
        <div
            v-if="displayedSlots?.length > 0"
            class="pratician-slots w-2/5 py-2"
        >
            <div
                class="flex mb-2.5 w-full justify-between"
                v-for="(item, index) in displayedSlots"
                :key="index"
            >
                <div class="mx-2">
                    <h3
                        class="text-sm capitalize text-[#574f7d] font-bold font-qs"
                    >
                        {{ moment(item.date).format("ddd") }}
                    </h3>
                    <h4 class="text-xs text-slate-500 font-ibm capitalize">
                        {{ moment(item.date).format("DD/MM") }}
                    </h4>
                </div>
                <div class="grid grid-rows-7 grid-flow-col gap-4">
                    <button
                        v-for="idx in grid"
                        :key="idx"
                        class="rounded bg-[#B5B9C9] text-[#2b4660] px-2 opacity-75 slot"
                    >
                        {{ moment(item.slots[idx - 1]?.start).format("HH:mm") }}
                    </button>
                </div>
            </div>
            <div class="flex w-full">
                <div class="mx-2 w-1/12"></div>
                <div class="flex justify-center w-11/12">
                    <a
                        :href="`/praticians/${planning?._id}`"
                        class="w-full mt-2 rounded-lg py-1 text-center px-3 border border-[#2b4660] text-[#2b4660] px-2 mx-1 opacity-75"
                    >
                        Voir l'agenda complet
                    </a>
                </div>
            </div>
        </div>
        <div
            v-if="displayedSlots?.length == 0"
            class="w-2/5 bg-slate-100 flex justify-center items-center ml-3 rounded p-3 no-planning"
        >
            <div class="flex box">
                <i class="la la-calendar-times text-sm mx-2 text-slate-800"></i>
                <p
                    class="text-sm text-slate-800"
                    v-if="planning?.medical_center?.profile?.phone_1"
                >
                    Pas de rendez-vous réservable en ligne pour ce
                    praticien.Appelez le
                    <strong>
                        {{
                            formatPhone(
                                planning?.medical_center?.profile?.phone_1
                            )
                        }}</strong
                    >
                    pour prendre un rendez-vous.
                </p>
                <p class="text-sm text-slate-800" v-else>
                    Pas de rendez-vous réservable en ligne pour ce praticien.
                </p>
            </div>
        </div>
    </div>
</template>
<script>
import moment from "moment";
import { computed, defineComponent, onMounted, watch, ref } from "vue";
import { ElPopover, ElTooltip } from "element-plus";
import { useWindowSize } from "@vueuse/core";
import { AsYouType } from "libphonenumber-js";

export default defineComponent({
    components: { ElPopover, ElTooltip },
    props: { planning: { tyep: Object } },
    setup(props) {
        const specialties = computed(() =>
            props.planning.specialties
                .filter((s, i) => i < 3)
                .reduce(
                    (acc, x, i) => (acc += `${i > 0 ? "," : ""} ${x.name}`),
                    ""
                )
                .toString()
        );
        const grid = ref(7);
        const { width, height } = useWindowSize();

        const initials = computed(() =>
            props.planning.pratician.full_name
                .match(/(^\S\S?|\s\S)?/g)
                .map((v) => v.trim())
                .join("")
                .match(/(^\S|\S$)?/g)
                .join("")
                .toLocaleUpperCase()
        );
        const formatPhone = (phone) => {
            return new AsYouType().input(`+${phone}`);
        };
        const otherSpecialties = computed(() =>
            props.planning.specialties
                .filter((s, i) => i >= 3)
                .map((x) => x.name)
        );

        const xOf = new Intl.NumberFormat("fr-FR", {
            style: "currency",
            currency: "XOF",
        });
        const slotsData = ref(null);
        const displayedSlots = ref(null);
        const priceMin = computed(() =>
            xOf.format(props.planning?.price?.min ?? 0)
        );
        const priceMax = computed(() =>
            xOf.format(props.planning?.price?.max ?? 0)
        );
        const buildSlots = () => {
            const groups = props.planning.slots.reduce((groups, game) => {
                const date = game.start.split("T")[0];
                if (!groups[date]) {
                    groups[date] = [];
                }
                groups[date].push(game);
                return groups;
            }, {});

            const groupArrays = Object.keys(groups).map((date) => {
                return {
                    date,
                    slots: groups[date],
                };
            });
            slotsData.value = groupArrays;
            displayedSlots.value = [...groupArrays]
                .sort((a, b) => moment(a.start).unix() - moment(b.start).unix())
                .filter((x, i) => i <= 2)
                .map((s) => ({
                    date: s.date,
                    slots: s.slots
                        .sort(
                            (a, b) =>
                                moment(a.start).unix() - moment(b.start).unix()
                        )
                        .filter((y, index) => index <= 6),
                }));
        };
        const updateDimen = (w) => {
            let r = w;
            let g = 0;
            if (w < 1000) {
                g = (r * 9) / 1000;
            } else {
                r = w / 3;
                g = (r * 6) / 500;
            }

            grid.value = Math.round(g - 1);
        };
        watch(
            () => width.value,
            (val) => {
                updateDimen(val);
            }
        );
        onMounted(() => {
            buildSlots();
            updateDimen(width.value);
        });
        return {
            specialties,
            otherSpecialties,
            priceMax,
            grid,
            formatPhone,
            initials,
            priceMin,
            displayedSlots,
            moment,
        };
    },
});
</script>
<style lang="scss">
.box-planning {
    user-select: none;
    transition: border 0.4s ease-out, box-shadow 0.4s ease-out;
    &:hover {
        box-shadow: 0px 10px 42px 0px rgba(0, 0, 0, 0.1);
    }

    .slot {
        transition: background-color 0.2s, color 0.2s;
    }
}
@media screen and (min-width: 1024px) {
    .action {
        display: none;
    }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
    .box-planning {
        flex-direction: column;
    }
    .pratician-info {
        width: 100% !important;
        justify-content: space-between;

        .action {
            display: none;
        }
    }
    .pratician-slots,
    .no-planning {
        width: 100% !important;
        margin-top: 3em !important;
        margin-left: 0 !important;
        .box {
            width: 100% !important;
        }
    }
}

@media screen and (max-width: 767px) {
    .pratician-info {
        width: 100% !important;
        .pratician-data {
            width: 100% !important;
        }
    }
    .pratician-slots,
    .no-planning {
        display: none !important;
    }
    .prices {
        display: none;
    }
}

@media screen and (max-width: 612px) {
    .pratician-info {
        display: flex;
        flex-direction: column;
        width: 100% !important;
        .pratician-data {
            width: 100% !important;
        }

        .pricing {
            width: 100% !important;
            margin-top: 2em;
        }
    }
    .pratician-slots,
    .no-planning {
        display: none !important;
    }
    .prices {
        display: none;
    }
}
</style>
