<template>
    <Head>
        <meta name="robots" content="index,follow" />
        <title>
            Allô Doto - Créer votre compte et gérer vos rendez-vous avec votre
            médécin
        </title>
        <meta
            head-key="description"
            name="description"
            :content="`Rejoignez Allô Doto au ${citiesText}, pour vos rendez-vous et prises en charge ambulatoires`"
        />
    </Head>
    <div>
        <register-form
            :all-cities="allCities"
            :citiesText="citiesText"
        ></register-form>
    </div>
</template>
<script setup>
import { Head } from "@inertiajs/vue3";
import { onMounted } from "vue";
const props = defineProps({ allCities: Array, citiesText: String });

onMounted(() => {});
</script>
