import axios from "./axios";
import sourceAxios from "axios";

import { doctor, patient, users, intervention, alert, hospital, prescriptions, car, specialty, requirements, medicalCenter, portfolio, staff, appointment, auth } from "./endpoint";
let getAlertToken = null;

let listAppointmentsCancelToken = null
let searchCancelToken = null

export default {

    async getDoctors(data = {}) {
        const response = await axios.get(doctor.get(), data);
        return response.data;
    },
    async getAllDoctors(data = {}) {
        const response = await axios.get(doctor.getAll(), data);
        return response.data;
    },

    async getAlerts(data = {}) {
        const response = await axios.get(alert.get(), data);
        return response.data;
    },
    async countAlerts(data = {}) {
        const response = await axios.get(alert.count(), data);
        return response.data;
    },
    async postAlert(data = {}) {
        const response = await axios.post(alert.create(), data);
        return response.data;
    },

    async getAlert(id, data) {
        if (typeof getAlertToken != undefined) {
            getAlertToken?.cancel(
                'Operation (getAlert) canceled due to new request.'
            )
        }
        getAlertToken = sourceAxios.CancelToken.source()
        const response = await axios.get(alert.one(id), data, getAlertToken.token);
        return response.data;
    },

    async removeAlert(id, data) {
        const response = await axios.delete(alert.one(id), data);
        return response.data;
    },

    async getAlertInterventions(id, data) {
        const response = await axios.get(alert.interventions(id), data);
        return response.data;
    },

    async getInterventions(data = {}) {
        const response = await axios.get(intervention.get(), data);
        return response.data;
    },

    async searchAppointments(data = {}) {
        if (typeof searchCancelToken != undefined) {
            searchCancelToken?.cancel(
                'Operation (PERFORM_SEARCH) canceled due to new request.'
            )
        }
        searchCancelToken = axios.CancelToken.source()
        const response = await axios.get(appointment.search(), data, searchCancelToken.token);
        return response.data;
    },

    async checkAppoitmentPrice() {
        const response = await axios.get(appointment.checkPrice());
        return response.data;
    },

    async rateAppointment(id, data) {
        const response = await axios.post(appointment.rate(id), data);
        return response.data;
    },


    async getPlannings(data = {}) {
        const response = await axios.get(appointment.plannings(), data);
        return response.data;
    },

    async getPlanning(id) {
        const response = await axios.get(appointment.planning(id));
        return response.data;
    },

    async deletePlanning(id) {
        const response = await axios.delete(appointment.planning(id));
        return response.data;
    },

    async savePlanning(data = {}) {
        const response = await axios.post(appointment.createPlanning(), data);
        return response.data;
    },

    async saveAppointment(data = {}) {
        const response = await axios.post(appointment.create(), data);
        return response.data;
    },

    async updateAppointmentTime(id, data = {}) {
        const response = await axios.put(appointment.updateTime(id), data);
        return response.data;
    },

    async cancelAppointmentTime(id, data = {}) {
        const response = await axios.put(appointment.cancel(id), data);
        return response.data;
    },

    async getAppointment(id) {
        const response = await axios.get(appointment.get(id));
        return response.data;
    },

    async buildShare(id) {
        const response = await axios.put(appointment.makeShare(id));
        return response.data;
    },

    async addFileToAppointment(id, data) {
        const response = await axios.file(appointment.files(id), data);
        return response.data;
    },

    async addMedicalCenterDocuments(id, data) {
        const response = await axios.file(medicalCenter.documents(id), data);
        return response.data;
    },

    async sendDocumentsMail(id,) {
        const response = await axios.put(medicalCenter.documentsMail(id));
        return response.data;
    },

    async getAllDocuments(filter) {
        const response = await axios.get(auth.documents(), { ...filter });
        return response.data;
    },

    async getDocuments(id) {
        const response = await axios.get(appointment.documents(id),);
        return response.data;
    },

    async getAppointmentByHash(id) {
        const response = await axios.get(appointment.get(id));
        return response.data;
    },

    async getAppointments(data = {}) {
        const response = await axios.get(appointment.index(), data);
        return response.data;
    },

    async listAppointments(data = {}) {
        if (typeof listAppointmentsCancelToken != undefined) {
            listAppointmentsCancelToken?.cancel(
                'Operation (GET_DELEGATIONS) canceled due to new request.'
            )
        }
        listAppointmentsCancelToken = axios.CancelToken.source()
        const response = await axios.get(appointment.list(), data, listAppointmentsCancelToken.token);
        return response.data;
    },

    async startAppointment(id, data = {}) {
        const response = await axios.put(appointment.start(id), data);
        return response.data;
    },

    async listTimedAppointments(data = {}) {
        const response = await axios.get(appointment.timedList(), data);
        return response.data;
    },

    async getSlots(id) {
        const response = await axios.get(appointment.planningSlots(id));
        return response.data;
    },

    async editPlanning(id, data = {}) {
        const response = await axios.put(appointment.editPlanning(id), data);
        return response.data;
    },

    async getPendingInterventions(data = {}) {
        const response = await axios.get(intervention.pendings(), data);
        return response.data;
    },

    async getInterventionsData(data = {}) {
        const response = await axios.get(intervention.data(), data);
        return response.data;
    },

    async getInterventionsAnalytics(data = {}) {
        const response = await axios.get(intervention.analytics(), data);
        return response.data;
    },


    async postIntervention(data = {}) {
        const response = await axios.post(intervention.create(), data);
        return response.data;
    },

    async putIntervention(id, data = {}) {
        const response = await axios.put(intervention.one(id), data);
        return response.data;
    },
    async deleteIntervention(id) {
        const response = await axios.delete(intervention.one(id));
        return response.data;
    },

    async getPatients(data = {}) {
        const response = await axios.get(patient.get(), data);
        return response.data;
    },

    async getStaffAnalytics(data = {}) {
        const response = await axios.get(staff.analytics(), data);
        return response.data;
    },

    async countPatients(data = {}) {
        const response = await axios.get(patient.count(), data);
        return response.data;
    },

    async countCars(data = {}) {
        const response = await axios.get(car.count(), data);
        return response.data;
    },

    async getPatient(identifier) {
        const response = await axios.get(patient.one(identifier));
        return response.data;
    },

    async carMoved(alertId, interventionId, carId, data) {
        const response = await axios.post(alert.carMovmentRegister(alertId, interventionId, carId), data);
        return response.data;
    },

    async getAgents(medicalCenterId) {
        const response = await axios.get(medicalCenter.agents(medicalCenterId));
        return response.data;
    },

    async getPatientsByMedicalCenter(medicalCenterId) {
        const response = await axios.get(medicalCenter.patients(medicalCenterId));
        return response.data;
    },

    async getDocumentsByMedicalCenter(medicalCenterId) {
        const response = await axios.get(medicalCenter.documents(medicalCenterId));
        return response.data;
    },

    async updateDocumentStatus(medicalCenterId, documentId, data) {
        const response = await axios.put(medicalCenter.documentStatus(medicalCenterId, documentId), data);
        return response.data;
    },

    async getHospitalReferrers(data) {
        const response = await axios.get(users.byRole('manager'), { ...data });
        return response.data;
    },
    async postPatient(data) {
        const response = await axios.post(patient.create(), data);
        return response.data;
    },

    async putPatient(id, data) {
        const response = await axios.put(patient.single(id), data);
        return response.data;
    },

    async getUser(id) {
        const response = await axios.get(users.get(id));
        return response.data;
    },
    async getStaff(id) {
        const response = await axios.get(staff.one(id));
        return response.data;
    },

    async editStaff(id, data) {
        const response = await axios.put(staff.one(id), data);
        return response.data;
    },
    async getHospitals(data = {}) {
        const response = await axios.get(hospital.get(), data);
        return response.data;
    },

    async countHospitals(data = {}) {
        const response = await axios.get(medicalCenter.count(), data);
        return response.data;
    },

    async getHospitalById(id) {
        const response = await axios.get(hospital.one(id));
        return response.data;
    },
    async deleteHospital(id) {
        const response = await axios.delete(hospital.one(id));
        return response.data;
    },

    async postHospital(data) {
        const response = await axios.post(hospital.create(), data);
        return response.data;
    },

    async postHospitalImage(id, data) {
        const response = await axios.post(hospital.image(id), data);
        return response.data;
    },

    async editHospital(id, data) {
        const response = await axios.put(hospital.one(id), data);
        return response.data;
    },

    async postHospitalPresentation(id, data) {
        const response = await axios.put(medicalCenter.presentation(id), data);
        return response.data;
    },

    async postPrescriptions(patientId, data) {
        const response = await axios.post(prescriptions.single(patientId), data)
        return response.data
    },
    async getPatientPrescriptions(patientId) {
        const response = await axios.get(prescriptions.single(patientId))
        return response.data
    },
    async getPrescriptions() {
        const response = await axios.get(prescriptions.new())
        return response.data
    },

    ///
    async getRequirements() {
        const response = await axios.get(requirements.get())
        return response.data
    },
    async getHospitalRequirements() {
        const response = await axios.get(requirements.get())
        return response.data
    },
    async validateHospitalRequirements(id, data) {
        const response = await axios.put(medicalCenter.validate(id,), data)
        return response.data
    }
}
