<template>
    <Head>
        <meta name="robots" content="noindex,nofollow" />
        <title>Allô Doto - Completer votre dossier de validation</title>
        <meta
            head-key="description"
            name="description"
            content="Allô Doto - Devenez praticien,Completer votre dossier de validation"
        />
    </Head>
    <div class="w-full flex flex-col bg-white h-screen">
        <doctor-complete :medical-center="medicalCenter" :all-cities="cities">
        </doctor-complete>
    </div>
</template>

<script setup>
import { onMounted } from "vue";
const props = defineProps({ medicalCenter: Object, cities: Array });

onMounted(() => {});
</script>
