<template>
    <header
        class="relative bg-white dark:bg-gray-800 sticky top-0 bg-white z-50"
    >
        <nav :x-data="{ isOpen: isOpen }" class="">
            <div class="px-4 lg:px-20 py-4 mx-auto">
                <div class="lg:flex lg:items-center lg:justify-between">
                    <div class="flex items-center justify-between">
                        <div class="text-xl font-semibold text-gray-700">
                            <a
                                class="text-2xl font-medium text-sky-500 transition-colors flex items-center duration-300 transform dark:text-sky-400 hover:text-sky-400 dark:hover:text-sky-300"
                                href="/"
                            >
                                <img
                                    src="/images/allo_logo.svg"
                                    alt=""
                                    srcset=""
                                    class="h-6"
                                />
                            </a>
                        </div>

                        <!-- Mobile menu button -->
                        <div class="flex lg:hidden">
                            <button
                                x-cloak
                                @click="isOpen = !isOpen"
                                type="button"
                                class="text-gray-500 dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400 focus:outline-none focus:text-gray-600 dark:focus:text-gray-400"
                                aria-label="toggle menu"
                            >
                                <svg
                                    x-show="!isOpen"
                                    xmlns="http://www.w3.org/2000/svg"
                                    :class="`w-6 h-6 ${isOpen ? 'hidden' : ''}`"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    stroke-width="2"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M4 8h16M4 16h16"
                                    />
                                </svg>

                                <svg
                                    x-show="isOpen"
                                    xmlns="http://www.w3.org/2000/svg"
                                    :class="`w-6 h-6 ${isOpen ? '' : 'hidden'}`"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    stroke-width="2"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>

                    <!-- Mobile Menu open: "block", Menu closed: "hidden" -->
                    <div v-if="isLoading"></div>
                    <div
                        v-else
                        x-cloak
                        :class="[
                            isOpen
                                ? 'translate-x-0 opacity-100 '
                                : 'opacity-0 -translate-x-full',
                        ]"
                        class="absolute inset-x-0 z-20 w-full px-6 py-4 transition-all duration-300 ease-in-out bg-white dark:bg-gray-800 lg:mt-0 lg:p-0 lg:top-0 lg:relative lg:bg-transparent lg:w-auto lg:opacity-100 lg:translate-x-0 lg:flex lg:items-center"
                    >
                        <div
                            class="flex flex-col -mx-6 lg:flex-row lg:items-center"
                        >
                            <a
                                v-if="!profile"
                                href="/login"
                                class="text-primary border border-primary outline-none dark:text-primary hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-md px-4 lg:px-5 py-2 lg:py-2.5 mr-2 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"
                            >
                                Connexion</a
                            >
                            <button
                                v-else-if="profile"
                                type="button"
                                class="flex mr-3 text-sm bg-white rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                                id="user-menu-button"
                                aria-expanded="false"
                                data-dropdown-toggle="user-dropdown"
                                data-dropdown-placement="bottom"
                                @click="toggle"
                            >
                                <span class="sr-only">Open user menu</span>

                                <Avatar
                                    v-if="profile?.avatar"
                                    :image="profile?.avatar"
                                    class="bg-white border-2 shadow-none border-2 border-primary3"
                                    shape="circle"
                                />

                                <Avatar
                                    v-else
                                    class="bg-white border-2 border-white shadow-none border-2 border-primary3"
                                    shape="circle"
                                    :label="profile?.first_name?.charAt(0)"
                                />
                            </button>
                            <!-- Dropdown menu -->
                            <div
                                class="z-50 hidden my-4 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600"
                                id="user-dropdown"
                            >
                                <Menu
                                    ref="menu"
                                    id="overlay_menu"
                                    :model="items"
                                    :popup="true"
                                >
                                    <template #start>
                                        <Button
                                            class="w-full shadow-none rounded-none p-link flex items-center p-2 pl-3 text-color hover:surface-200 border-b border-slate-300"
                                        >
                                            <div
                                                class="flex flex-col justify-center"
                                            >
                                                <span
                                                    class="font-medium font-ibm"
                                                    >{{
                                                        profile?.full_name
                                                    }}</span
                                                >
                                                <span
                                                    class="text-xs font-qs text-left"
                                                    >{{
                                                        profile?.username
                                                    }}</span
                                                >
                                            </div>
                                        </Button>
                                    </template>
                                    <template #item="{ item, label, props }">
                                        <a
                                            class="flex"
                                            v-bind="props.action"
                                            :href="item.url"
                                        >
                                            <span v-bind="props.icon" />
                                            <span v-bind="props.label">{{
                                                label
                                            }}</span>
                                            <Badge
                                                v-if="item.badge"
                                                class="ml-auto"
                                                :value="item.badge"
                                            />
                                        </a>
                                    </template>
                                    <template #end>
                                        <button
                                            class="w-full p-link flex items-center p-2 pl-4 text-color rounded-none hover:surface-200 border-t border-slate-300"
                                            @click="logout"
                                        >
                                            <i
                                                class="la la-sign-out text-danger"
                                            />
                                            <span class="ml-2 text-danger"
                                                >Déconnexion</span
                                            >
                                        </button>
                                    </template>
                                </Menu>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </header>
</template>

<script>
import { ref, onMounted, computed, watch } from "vue";
import { useStore } from "vuex";
import Avatar from "primevue/avatar";
import Menu from "primevue/menu";
import { useRoute } from "vue-router";
import Badge from "primevue/badge";
export default {
    inject: ["page"],
    components: { Avatar, Menu, Badge },
    setup(props) {
        const store = useStore();
        const route = useRoute();
        const profile = ref(null);
        const isLoading = ref(true);
        const isOpen = ref(false);

        const domain = computed(
            () =>
                `https://${
                    typeof window != "undefined"
                        ? /(?<=\.).+/.exec(location.hostname)[0]
                        : "allodoto.com"
                }`
        );

        const menu = ref();

        const items = computed(() => [
            {
                label: "Mon espace",
                icon: "la la-tachometer-alt",
                url: `${domain.value}/account`,
            },
            {
                label: "Profile",
                icon: "la la-user ",
                url: `${domain.value}/account/profile`,
            },
        ]);

        const toggle = (event) => {
            menu.value.toggle(event);
        };

        onMounted(async () => {
            isLoading.value = true;
            store.dispatch("auth/getSilentlyUser", {
                onSuccess: ({ user, action }) => {
                    profile.value = user;

                    console.log("dedededed ===> ", profile.value, user);
                    setTimeout(() => {
                        isLoading.value = false;
                    }, 1000);
                },
                onError: () => {
                    isLoading.value = false;
                },
            });
        });

        return { isOpen, isLoading, profile, items, menu, toggle };
    },
};
</script>
