<template>
    <div
        v-if="isLoading"
        class="h-full flex justify-center items-center text-primary2"
    >
        <Loader class="w-20" />
    </div>
    <div class="flex" v-else>
        <div class="bg-white h-full w-1/4 lg:w-2/ rounded">
            <div class="flex flex-col px-3 mt-2">
                <div class="p-1 bg-white rounded" v-if="hospital?.profile?.img">
                    <img
                        :src="hospital?.profile?.img"
                        alt=""
                        srcset=""
                        class="rounded bg-cover w-20 border border-white object-cover"
                    />
                </div>
                <div class="px-2">
                    <strong
                        ><h2 class="text-md font-qs">
                            {{ hospital?.name }}
                        </h2></strong
                    >
                    <div class="flex justify-start items-center font-ibm py-3">
                        <i
                            class="las la-hospital-alt text-slate-400 text-sm mr-2"
                        ></i>
                        <p class="text-slate-400 text-sm">
                            {{ types[hospital?.type] }}
                        </p>
                    </div>
                    <div class="">
                        <h3
                            :class="`py-2 font-ibm font-medium text-${
                                isAuthNumberValidated ? 'success' : 'danger'
                            } text-sm`"
                        >
                            Aut.: {{ hospital?.authorization_number }}
                            <span
                                :class="`w-1 h-1 p-1 text-xs rounded-full text-sm bg-${
                                    isAuthNumberValidated ? 'success' : 'danger'
                                }`"
                            >
                                <i
                                    :class="`stroke-2 text-xs font-bold las la-${
                                        isAuthNumberValidated
                                            ? 'check'
                                            : 'times'
                                    } text-${
                                        isAuthNumberValidated
                                            ? 'white'
                                            : 'white'
                                    }`"
                                ></i>
                            </span>
                        </h3>
                    </div>
                </div>
            </div>

            <div class="mt-3 px-3 font-ibm">
                <h3 class="text-sm">
                    <i class="las la-stethoscope text-sm text-slate-500"></i>
                    Spécialités
                </h3>
                <div class="font-ibm">
                    <span
                        v-for="sp in hospital?.specialties"
                        :key="sp.id"
                        class="p-1 rounded bg-info text-white m-1 text-sm inline-block"
                        >{{ sp?.name }}</span
                    >

                    <p v-if="!hospital?.specialties.length">-</p>
                </div>
            </div>

            <div class="mt-3 px-3">
                <h3 class="text-sm font-ibm">
                    <i class="las la-map-marker text-sm text-slate-500"></i
                    >Carte et informations d'accès
                </h3>
                <div class="font-ibm">
                    <h4 class="py-2 text-sm text-slate-600">
                        {{ currentAddress?.address }}
                    </h4>

                    <div
                        v-if="currentAddress?.latitude"
                        class="h-48 mt-2 rounded"
                    >
                        <gmap
                            class="h-full"
                            :center="{
                                lat: currentAddress?.latitude,
                                lng: currentAddress?.longitude,
                            }"
                            :zoom="12"
                        />
                    </div>
                </div>
            </div>

            <div class="mt-3 font-ibm px-3 border-t border-slate-300 flex">
                <div class="flex items-center py-2">
                    <i class="las la-user-tie text-lg mr-1"></i>
                    <h3 class="text-sm">
                        {{ genders[hospital?.creator?.gender] }}
                        {{ hospital?.creator?.full_name }}
                        {{
                            hospital?.creator?.username
                                ? `/ ${hospital?.creator?.username}`
                                : ""
                        }}
                    </h3>
                </div>
            </div>

            <div class="px-3 border-t border-slate-300 flex">
                <div class="flex items-center py-2">
                    <i class="las la-at text-sm mr-1"></i>
                    <h3 class="font-ibm">{{ hospital?.profile?.email }}</h3>
                </div>
            </div>

            <div class="px-3 border-t border-slate-300 flex">
                <div class="flex items-center py-3 font-ibm">
                    <span
                        :class="`text-sm text-${
                            statuseClasses[hospital?.status]
                        } font-ibm rounded `"
                    >
                        {{ statuses[hospital?.status] }}
                    </span>
                </div>
            </div>
        </div>
        <div class="w-3/4">
            <div class="pl-5 w-full my-3">
                <div class="flex justify-between">
                    <div class="flex justify-start items-center">
                        <h3 class="text-sm font-ibm">
                            Identifiant:
                            <span
                                class="rounded text-secondary text-sm p-1 font-ibm font-bold"
                                >{{ hospital?.identifier }}</span
                            >
                        </h3>
                    </div>

                    <div>
                        <MenuDropdown
                            :actions="actions"
                            @on-click="redirectTo"
                            callerClass="text-black"
                        >
                            <div
                                class="inline rounded bg-slate-200 text-sm p-2 text-slate-600 text-white"
                            >
                                Action
                                <i class="la la-gear text-sm"></i>
                            </div>
                        </MenuDropdown>
                    </div>
                </div>
            </div>
            <div class="pl-5 w-full">
                <tabs :options="tabs" selected="doctors">
                    <template
                        v-for="opt in tabs"
                        :key="opt.key"
                        #[opt.key]="{}"
                    >
                        <component :is="opt.component"></component>
                    </template>
                </tabs>
            </div>
        </div>

        <SampleModal
            size="w-2/5"
            title="Validation Hôpital"
            :isVisible="canValidate"
            @submit="saveRequirements"
            @cancel="canValidate = false"
            @close="canValidate = false"
        >
            <div>
                <div
                    class="flex items-center my-2 cursor-pointer"
                    v-for="item in validationOptions"
                    :key="item.slug"
                    @click.prevent="handleSelectionOption(item.id)"
                >
                    <div
                        :class="`w-5 h-5 cursor-pointer ${
                            selectedValidationOptions.includes(item.id)
                                ? 'bg-success'
                                : 'bg-slate-300'
                        }  rounded  flex items-center justify-center`"
                    >
                        <icon
                            v-if="selectedValidationOptions.includes(item.id)"
                            name="check"
                            class="text-white p-1"
                        ></icon>
                    </div>
                    <p class="px-2">{{ item.text }}</p>
                </div>
            </div>
        </SampleModal>
    </div>
    <ConfirmDialog></ConfirmDialog>
</template>
<script>
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import gmap from "@/components/maps/gmap";
import TeamTab from "./teamsTab.vue";
import PatientsTab from "./patientTab.vue";
import PresentationTab from "./presentationTab.vue";
import DocumentsTab from "./documentTab.vue";
import MenuDropdown from "@/components/MenuDropdown";
import Loader from "@/components/Loader/index.vue";
import { useAcl } from "vue-simple-acl";
import { useConfirm } from "primevue/useconfirm";
import ConfirmDialog from "primevue/confirmdialog";
import SampleModal from "@/components/Modal/SampleModal.vue";

export default {
    components: { gmap, MenuDropdown, Loader, ConfirmDialog, SampleModal },
    setup(props) {
        const confirm = useConfirm();
        const acl = useAcl();
        const route = useRoute();
        const router = useRouter();
        const store = useStore();
        const canDelete = ref(false);
        const canValidate = ref(false);
        const isLoading = ref(false);
        const hospital = ref(null);
        const currentAddress = computed(() =>
            (hospital.value?.addresses || []).find((x) => x.isMain)
        );
        const activeTab = ref("first");
        const types = ref({
            public_hospital: "Hôpital publique",
            health_center: "Centre médical",
            clinical: "Clinique (Centre de santé privé)",
        });

        const isAuthNumberValidated = computed(() =>
            (hospital.value?.satisfied_requirements || [])
                .map((x) => x.slug)
                .includes("authorization-number-validation")
        );

        const actions = computed(() => {
            const arr = [
                {
                    name: "Editer",
                    icon: "pen",
                    type: "list",
                    section: 1,
                    key: "edit",
                },

                {
                    name: "Ajouter un patient",
                    icon: "user-md",
                    type: "list",
                    class: "",
                    section: 2,
                    key: "add_new_patient",
                },
                {
                    name: "Supprimer",
                    icon: "trash",
                    type: "list",
                    class: "text-danger",
                    section: 3,
                    key: "delete",
                },
            ];
            if (acl.can("is-admin")) {
                arr.push({
                    name: "Valider",
                    icon: "certificate",
                    type: "list",
                    section: 1,
                    key: "validate",
                });
            }

            return arr;
        });

        const tabs = ref([
            {
                name: "L'Équipe",
                icon: "",
                key: "doctors",
                component: TeamTab,
            },
            {
                name: "Patients",
                icon: "",
                key: "patients",
                component: PatientsTab,
            },
            {
                name: "Présentation",
                icon: "",
                key: "info",
                component: PresentationTab,
            },
            {
                name: "Documents",
                icon: "",
                key: "files",
                component: DocumentsTab,
            },
        ]);

        const validationOptions = ref([]);

        const getRequirements = () => {
            store.dispatch("medical/getRequirements", {
                onSuccess: (data) => {
                    validationOptions.value = data;
                },
                onError: (err) => {},
            });
        };

        const redirectTo = (action) => {
            if (action == "edit") {
                router.push({
                    name: "config.editMedicalCenter",
                    params: { slug: route.params.slug },
                });
            }

            if (action == "add_new_patient") {
                router.push({
                    name: "medical.patientCreate",
                    params: { slug: route.params.slug },
                });
            }

            if (action == "validate") {
                handleValidation();
            }
            if (action == "delete") {
                openDialog();
            }
        };
        const saveRequirements = () => {
            store.dispatch("medical/validateRequirements", {
                hospitalId: hospital.value.id,
                data: {
                    medical_center_id: hospital.value.id,
                    ids: selectedValidationOptions.value,
                },
                onSuccess: () => {
                    getHospital();
                },
                onError: () => {},
            });
        };

        const selectedValidationOptions = ref([]);
        const genders = ref({ f: "Mme.", m: "Mr." });

        const statuses = ref({
            pending: "En attente de validation",
            active: "Validé - En ligne",
        });
        const statuseClasses = ref({
            pending: "warning",
            active: "success",
            banned: "danger",
        });
        const handleSelectionOption = (id) => {
            if (selectedValidationOptions.value.includes(id))
                selectedValidationOptions.value =
                    selectedValidationOptions.value.filter((x) => x !== id);
            else selectedValidationOptions.value.push(id);

            console.log(selectedValidationOptions.value);
        };
        const handleDeletion = () => {
            canDelete.value = !canDelete.value;
        };

        const openDialog = () => {
            confirm.require({
                message:
                    "Êtes-vous sûr de vouloir supprimer ce center médical ?",
                header: "Suppression",
                icon: "la la-exclamation-circle",
                rejectLabel: "Non",
                acceptLabel: "Oui",
                rejectClass:
                    "bg-danger text-danger py-1 text-sm text-medium px-2 mr-2 bg-opacity-25",
                acceptClass:
                    "bg-primary text-primary py-1 text-sm text-medium px-2  bg-opacity-25",
                accept: () => {
                    removeHospital();
                },
                reject: () => {},
            });
        };

        const handleValidation = () => {
            selectedValidationOptions.value = (
                hospital.value?.satisfied_requirements || []
            ).map((x) => x.id);
            canValidate.value = !canValidate.value;
        };
        const removeHospital = () => {
            store.dispatch("medical/removeHospital", {
                id: hospital.value?.id,
                onSuccess: (data) => {
                    router.push({ name: "config.hospitals" });
                },
                onError: () => {
                    isLoading.value = false;
                },
            });
        };
        const validate = () => {
            console.log("va;idatta rem");
        };
        const getHospital = () => {
            isLoading.value = true;
            store.dispatch("medical/getHospital", {
                slug: route.params.slug,
                onSuccess: (data) => {
                    selectedValidationOptions.value = (
                        data?.satisfied_requirements || []
                    ).map((x) => x.id);
                    hospital.value = { ...data };
                    isLoading.value = false;
                },
                onError: () => {
                    isLoading.value = false;
                },
            });
        };

        onMounted(() => {
            getHospital();
            getRequirements();
        });

        return {
            hospital,
            types,
            isLoading,
            actions,
            currentAddress,
            genders,
            statuses,
            isAuthNumberValidated,
            validationOptions,
            removeHospital,
            handleDeletion,
            handleSelectionOption,
            activeTab,
            canDelete,
            canValidate,
            selectedValidationOptions,
            tabs,
            statuseClasses,
            validate,
            redirectTo,
            handleValidation,
            saveRequirements,
        };
    },
};
</script>
