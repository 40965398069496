<template>
    <Head>
        <meta name="robots" content="index,follow" />
        <title>
            Allô Doto - Vous cherchez un praticien, docteur ou médécin proche de
            chez vous ? Obtenez un rendez-vous rapidement à
            {{ citiesText }}
        </title>
        <meta head-key="description" name="description" :content="text" />
    </Head>
    <app-bar></app-bar>

    <search />

    <Footer></Footer>
</template>
<script setup>
import { computed, onMounted } from "vue";
import Footer from "@/components/footer/index.vue";
import { Head } from "@inertiajs/vue3";
const props = defineProps({ citiesText: String });

const text = computed(
    () =>
        `Obtenez un rendez-vous rapidement avec un professionel de santé à ${props.citiesText}`
);

onMounted(() => {});
</script>
