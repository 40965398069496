<template>
    <div
        v-if="isLoading"
        class="flex justify-center items-center h-full w-full my-4"
    >
        <NewLoader />
    </div>
    <div class="flex justify-center pb-10" v-else-if="planning">
        <div class="w-11/12 mt-4">
            <div
                class="sticky z-50 flex justify-center items-center top-16 bg-white shadow-md w-10 rounded-full"
            >
                <a href="/search-praticians">
                    <i class="la la-arrow-left text-3xl text-primary"></i>
                </a>
            </div>

            <div
                v-if="currentStep == 0"
                class="flex w-full mt-10 justify-between relative"
            >
                <div :class="`w-${isSmall || isMedium ? 'full' : '8/12'}`">
                    <div class="flex justify-between">
                        <div class="flex items-center">
                            <div
                                v-if="
                                    !planning?.pratician?.avatar.includes(
                                        '-default.'
                                    )
                                "
                                class="w-14 h-14 rounded-full bg-no-repeat bg-slate-300"
                                :style="`
                      background-size: cover;
                      background-position: center;
                      background-image: url(${planning?.pratician?.avatar});
                  `"
                            ></div>

                            <div
                                v-else
                                class="w-14 h-14 text-xl font-bold font-ibm rounded-full bg-no-repeat bg-slate-100 border-2 border-slate-300 text-slate-600 flex justify-center items-center"
                            >
                                {{ initials }}
                            </div>
                            <div>
                                <h3
                                    class="font-bold text-slate-800 font-ibm text-2xl px-2"
                                >
                                    Dr. {{ planning?.pratician?.full_name }}
                                </h3>
                                <p class="text-sm px-2 text-slate-400">
                                    {{ planning?.medical_center?.name }}
                                </p>
                            </div>
                        </div>
                        <div
                            v-if="isSmall || isMedium"
                            class="bg-primary3 text-white cursor-pointer rounded-lg font-ibm px-3 py-2 flex justify-center items-center"
                            @click="hideAvailability = !hideAvailability"
                        >
                            <i class="la la-calendar mr-1"></i>
                            Voir les disponibilités
                        </div>
                    </div>

                    <div class="my-4 bg-white rounded">
                        <div class="flex items-start p-4">
                            <i
                                class="las la-microscope mr-1 text-lg text-slate-500"
                            ></i>
                            <div>
                                <h3
                                    class="text-lg text-slate-800 font-ibm font-medium"
                                >
                                    Expertises et actes
                                </h3>
                                <div class="mt-2">
                                    <Chip
                                        class="mt-1.5 mr-2 text-xs"
                                        :label="sp.name"
                                        v-for="sp in planning.specialties"
                                        :key="sp.id"
                                    />
                                </div>
                            </div>
                        </div>

                        <div
                            class="flex items-start mt-5 p-4 border-t-2 border-[#F8F9FC]"
                        >
                            <i
                                class="las la-money-bill-wave mr-1 text-lg text-slate-500"
                            ></i>
                            <div>
                                <h3
                                    class="text-blackp text-lg font-ibm font-medium"
                                >
                                    Tarifs
                                </h3>
                                <div class="mt-2">
                                    <h3
                                        class="text-sm font-medium"
                                        v-if="planning.price"
                                    >
                                        {{ priceMin }} à {{ priceMax }}
                                        <i
                                            v-tooltip.bottom="
                                                'Ce prix représente une foruchette mais peut différer en fonction du moment de la consultation'
                                            "
                                            class="la la-info-circle text-md text-[#574f7d]"
                                        ></i>
                                    </h3>
                                    <h3 class="text-sm font-medium" v-else>
                                        N/A
                                    </h3>

                                    <p
                                        v-if="planning.price"
                                        class="text-xs font-medium text-slate-400 mt-1"
                                    >
                                        Ces honoraires vous sont communiqués à
                                        titre indicatif par le praticien. Ils
                                        peuvent varier selon le type de soins
                                        finalement réalisés en cabinet, le
                                        nombre de consultations et les actes
                                        additionnels nécessaires. En cas de
                                        dépassement des tarifs, le praticien
                                        doit en avertir préalablement le
                                        patient.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="my-4 bg-white rounded w-full">
                        <div class="flex items-start mt-5 w-full">
                            <div class="flex w-full">
                                <div class="w-3/5 pt-4 flex">
                                    <i
                                        class="las pl-4 la-map-marker mr-1 text-lg text-slate-500"
                                    ></i>
                                    <div class="w-full">
                                        <h3
                                            class="text-slate-800 text-lg font-ibm font-medium"
                                        >
                                            Carte et informations d'accès
                                        </h3>

                                        <div>
                                            <p class="py-2">
                                                {{ planning.address.address }}
                                            </p>
                                        </div>

                                        <div class="mt-2">
                                            <h3 class="font-semibold text-sm">
                                                Indications
                                            </h3>
                                            <p class="py-2">
                                                {{
                                                    planning?.address
                                                        ?.address_indications ??
                                                    address?.indications ??
                                                    "-"
                                                }}
                                            </p>
                                        </div>

                                        <div class="mt-2">
                                            <h3 class="font-semibold text-sm">
                                                Informations pratiques
                                            </h3>
                                            <p class="py-2 text-sm">
                                                Etage:
                                                <strong>{{
                                                    planning?.address
                                                        ?.address_floor >= 0
                                                        ? floors.find(
                                                              (x) =>
                                                                  x.id ==
                                                                  planning
                                                                      ?.address
                                                                      ?.address_floor
                                                          )?.name ?? "-"
                                                        : "-"
                                                }}</strong>
                                            </p>
                                            <p class="py-2 text-sm">
                                                Entrée sans code :
                                                <strong>{{
                                                    planning?.address
                                                        .address_accessibility
                                                        ? "Oui"
                                                        : "Non"
                                                }}</strong>
                                            </p>
                                        </div>
                                        <div class="my-2">
                                            <h3 class="font-semibold text-sm">
                                                Téléphone(s)
                                            </h3>
                                            <p
                                                class="text-primary text-sm"
                                                v-if="
                                                    planning?.medical_center
                                                        ?.profile?.phone_1
                                                "
                                            >
                                                <a
                                                    :href="`tel:${planning?.medical_center?.profile?.phone_1}`"
                                                    >{{
                                                        formatPhone(
                                                            planning
                                                                ?.medical_center
                                                                ?.profile
                                                                ?.phone_1
                                                        )
                                                    }}</a
                                                >
                                            </p>
                                            <p class="text-primary" v-else>-</p>
                                            <p
                                                class="text-primary text-sm"
                                                v-if="
                                                    planning?.medical_center
                                                        ?.profile?.phone_2
                                                "
                                            >
                                                <a
                                                    :href="`tel:${planning?.medical_center?.profile?.phone_2}`"
                                                    >{{
                                                        formatPhone(
                                                            planning
                                                                ?.medical_center
                                                                ?.profile
                                                                ?.phone_2
                                                        )
                                                    }}</a
                                                >
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="w-2/5 flex">
                                    <div
                                        class="w-full"
                                        style="min-height: 300px"
                                    >
                                        <gmap
                                            class="h-full"
                                            :center="center"
                                            :zoom="15"
                                            :can-move-marker="false"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="my-4 bg-white rounded">
                        <div class="flex items-start p-4">
                            <i
                                class="las la-align-left mr-1 text-lg text-slate-500"
                            ></i>
                            <div>
                                <h3
                                    class="text-slate-800 text-lg font-ibm font-medium"
                                >
                                    Présentation
                                </h3>
                                <div class="mt-2">
                                    {{
                                        planning.medical_center?.profile
                                            ?.presentation
                                    }}
                                </div>
                            </div>
                        </div>

                        <div
                            class="flex items-start mt-5 p-4 border-t-2 border-[#F8F9FC]"
                        >
                            <i
                                class="las la-graduation-cap mr-1 text-lg text-slate-500"
                            ></i>
                            <div>
                                <h3
                                    class="text-slate-800 text-lg font-ibm font-medium"
                                >
                                    Diplômes nationaux et universitaires
                                </h3>
                                <div class="mt-2">
                                    <p class="text-sm font-medium">N/A</p>
                                </div>
                            </div>
                        </div>
                        <div
                            class="flex items-start mt-5 p-4 border-t-2 border-[#F8F9FC]"
                        >
                            <i
                                class="las la-info-circle mr-1 text-lg text-slate-500"
                            ></i>
                            <div>
                                <h3
                                    class="text-slate-800 text-lg font-ibm font-medium"
                                >
                                    Informations légales
                                </h3>
                                <div class="mt-2">
                                    <h3 class="text-md text-slate-500 font-ibm">
                                        Numéro RPPS
                                    </h3>
                                    <p class="text-sm font-normal">
                                        {{
                                            planning?.medical_center
                                                ?.authorization_number
                                        }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-if="!hideAvailability || isLarge"
                    :class="`transition transition-all w-${
                        isSmall || isMedium ? '7/12' : '3/12'
                    } ${isSmall || isMedium ? 'absolute right-0 top-14' : ''}`"
                >
                    <div
                        class="w-full rounded bg-white pt-3 pb-5 px-6 b-shadow"
                    >
                        <div
                            v-if="displayedSlots.length > 0"
                            class="mb-4 mt-2 flex justify-between items-center"
                        >
                            <h3 class="text-md font-bold text-slate-700">
                                Sélectionnez un créneau
                            </h3>
                            <div class="flex justify-end">
                                <i
                                    :class="`la la-arrow-left mr-3  ${
                                        currentIndex <= 4
                                            ? 'text-slate-400'
                                            : ' text-[#574f7d] cursor-pointer'
                                    }`"
                                    @click="handlePrev"
                                    style="font-size: 1.5rem"
                                ></i>
                                <i
                                    :class="`la la-arrow-right  ${
                                        currentIndex >= allSlots.length
                                            ? 'text-slate-400'
                                            : ' text-[#574f7d] cursor-pointer'
                                    }`"
                                    style="font-size: 1.5rem"
                                    @click="handleNext"
                                ></i>
                            </div>
                        </div>

                        <div
                            v-else
                            class="mb-4 mt-2 flex justify-between items-center"
                        >
                            <p>Aucun créneau disponible pour le moment</p>
                        </div>

                        <div class="grid grid grid-cols-4 gap-4">
                            <div
                                v-for="(item, index) in displayedSlots"
                                :key="index"
                                :class="`flex flex-col items-center ${
                                    displayedSlots.length < 4 ? 'mr-5' : ''
                                }`"
                            >
                                <div class="mx-2 flex flex-col items-center">
                                    <h3
                                        class="text-sm capitalize text-[#574f7d] font-bold font-ibm"
                                    >
                                        {{ moment(item.date).format("ddd") }}
                                    </h3>
                                    <h4
                                        class="text-xs text-center text-slate-500 font-ibm capitalize"
                                    >
                                        {{ moment(item.date).format("DD MMM") }}
                                    </h4>
                                </div>
                                <div class="flex flex-col items-center">
                                    <button
                                        v-for="(slot, slotIndex) in item.slots"
                                        :key="slotIndex"
                                        :class="`w-14 my-2 rounded bg-[#B5B9C9] border ${
                                            selectedSlot?.start &&
                                            moment(slot?.start).isSame(
                                                moment(selectedSlot.start)
                                            )
                                                ? 'border-slate-500 opacity-[55]'
                                                : 'border-[#B5B9C9]'
                                        } text-[#2b4660] px-1 py-2 mx-1 opacity-75 slot hover:opacity-[95]`"
                                        @click="handleTime(slot)"
                                    >
                                        {{
                                            moment(slot?.start).format("HH:mm")
                                        }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-else-if="currentStep == 1"
                class="w-full flex justify-center"
            >
                <div class="w-5/6 flex">
                    <div class="w-full">
                        <div class="py-3">
                            <h3 class="font-ibm font-semibold">
                                Prenez votre rendez-vous en ligne
                            </h3>
                            <p class="py-3">
                                Renseignez les informations suivantes
                            </p>
                        </div>
                        <div
                            :class="`flex ${
                                isSmall || isMedium ? 'flex-col' : ''
                            }`"
                        >
                            <div
                                :class="`bg-white w-${
                                    isSmall || isMedium ? 'full' : '7/12'
                                } rounded py-10 px-8 mt-2 self-start`"
                            >
                                <div>
                                    <span
                                        class="p-1 rounded hover:bg-slate-300 text-sm cursor-pointer text-slate-700"
                                        @click.prevent="currentStep--"
                                    >
                                        <i class="la la-arrow-left"></i> Étape
                                        précédente</span
                                    >
                                    <h3
                                        class="text-slate-700 font-semibold py-5"
                                    >
                                        Choisissez une spécialité
                                    </h3>

                                    <div
                                        class="overflow-hidden rounded-lg border border-slate-300 mb-4"
                                    >
                                        <div
                                            :class="`py-3 px-2 hover:bg-slate-300 bg-opacity-25 cursor-pointer ${
                                                spec.id == selectedSpecialty?.id
                                                    ? 'bg-slate-300'
                                                    : ''
                                            } ${
                                                spIndex !=
                                                planning.specialties?.length - 1
                                                    ? 'border-b border-slate-300'
                                                    : ''
                                            }`"
                                            v-for="(
                                                spec, spIndex
                                            ) in planning.specialties"
                                            :key="spIndex"
                                            @click.prevent="
                                                selectSpecialty(spec)
                                            "
                                        >
                                            <p
                                                class="text-slate-800 font-light text-sm"
                                            >
                                                {{ spec.name }}
                                            </p>
                                        </div>
                                    </div>

                                    <h3
                                        class="text-slate-700 font-semibold py-5"
                                    >
                                        Choisissez votre motif de consultation
                                    </h3>

                                    <div
                                        class="overflow-hidden rounded-lg border border-slate-300"
                                    >
                                        <div
                                            :class="`py-3 px-2 hover:bg-slate-300 ${
                                                motive.id == selectedMotive?.id
                                                    ? 'bg-slate-300'
                                                    : ''
                                            } bg-opacity-25 cursor-pointer ${
                                                mIndex !== motives.length - 1
                                                    ? 'border-b border-slate-300'
                                                    : ''
                                            }`"
                                            v-for="(motive, mIndex) in motives"
                                            :key="mIndex"
                                            @click="selectMotive(motive)"
                                        >
                                            <h3
                                                class="text-slate-800 font-light text-sm"
                                            >
                                                {{ motive.name }}
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                :class="`w-${
                                    isSmall || isMedium ? 'full mt-5' : '5/12'
                                } flex flex justify-end`"
                            >
                                <div
                                    :class="`w-${
                                        isSmall || isMedium ? 'full' : '5/6'
                                    }`"
                                >
                                    <div class="">
                                        <h3
                                            class="mb-1 text-slate-600"
                                            v-if="user"
                                        >
                                            Patient
                                        </h3>
                                        <div
                                            class="w-full mb-4 bg-white rounded-lg self-start p-4 shadow-none"
                                        >
                                            <div
                                                class="flex flex-col items-start"
                                            >
                                                <div
                                                    class="flex items-center"
                                                    v-if="user"
                                                >
                                                    <div
                                                        v-if="user?.avatar"
                                                        class="w-12 h-12 rounded-full bg-no-repeat bg-slate-200"
                                                        :style="`
                    background-size: cover;
                    background-position: center;
                    background-image: url(${user?.avatar});
                `"
                                                    ></div>
                                                    <div
                                                        v-else
                                                        class="w-14 h-14 text-lg font-bold font-ibm rounded-full bg-no-repeat bg-slate-100 border-2 border-slate-300 text-slate-600 flex justify-center items-center"
                                                    >
                                                        {{ patientInitials }}
                                                    </div>

                                                    <div>
                                                        <h3
                                                            class="font-semibold font-ibm text-lg px-2"
                                                        >
                                                            {{
                                                                user?.full_name
                                                            }}
                                                        </h3>
                                                        <span
                                                            class="text-xs px-2 text-slate-400"
                                                        >
                                                            {{
                                                                user.username ??
                                                                "-"
                                                            }}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div v-else class="w-full">
                                                    <div
                                                        class="border-b border-slate-100 w-full"
                                                    >
                                                        <h3
                                                            class="text-danger text-sm font-ibm text-slate-700"
                                                        >
                                                            Vous êtes nouveau
                                                            ici ?
                                                        </h3>

                                                        <Button
                                                            label="M'inscrire"
                                                            class="bg-primary2 rounded-lg outline-0 px-3 py-2 text-white my-4"
                                                        ></Button>
                                                    </div>
                                                    <div class="mt-3 w-full">
                                                        <h3
                                                            class="text-danger text-sm font-ibm text-slate-700"
                                                        >
                                                            J'ai déjà un compte
                                                            sur Allô Doto ?
                                                        </h3>

                                                        <Button
                                                            label="Se connecter"
                                                            class="bg-warning rounded-lg outline-0 px-3 py-2 text-white my-4"
                                                            @click="
                                                                showLogin = true
                                                            "
                                                        ></Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <h3 class="mb-1 text-slate-600">
                                        Rendez-vous avec:
                                    </h3>
                                    <div
                                        class="shadow-lg w-full bg-white rounded-lg self-start p-4"
                                    >
                                        <div class="flex flex-col items-start">
                                            <div class="flex items-center mb-4">
                                                <div
                                                    v-if="
                                                        !planning?.pratician?.avatar.includes(
                                                            '-default.'
                                                        )
                                                    "
                                                    class="w-12 h-12 rounded-full bg-no-repeat"
                                                    :style="`
                    background-size: cover;
                    background-position: center;
                    background-image: url(${planning?.pratician?.avatar});
                `"
                                                ></div>
                                                <div
                                                    v-else
                                                    class="w-14 h-14 text-lg font-bold font-ibm rounded-full bg-no-repeat bg-slate-100 border-2 border-slate-300 text-slate-600 flex justify-center items-center"
                                                >
                                                    {{ initials }}
                                                </div>

                                                <div>
                                                    <h3
                                                        class="font-bold font-ibm text-lg px-2"
                                                    >
                                                        {{
                                                            planning?.pratician
                                                                ?.full_name
                                                        }}
                                                    </h3>
                                                    <span
                                                        class="text-xs px-2 text-slate-400"
                                                        v-if="
                                                            planning.medical_center
                                                        "
                                                    >
                                                        {{
                                                            planning
                                                                .medical_center
                                                                .name
                                                        }}
                                                    </span>
                                                </div>
                                            </div>

                                            <div
                                                v-if="selectedSpecialty"
                                                class="my-1"
                                            >
                                                <div
                                                    class="flex items-center text-slate-800"
                                                >
                                                    <i
                                                        class="la la-check text-success text-xl mr-2"
                                                    ></i>
                                                    {{ selectedSpecialty.name }}
                                                </div>
                                            </div>
                                            <div
                                                v-if="selectedMotive"
                                                class="my-1"
                                            >
                                                <div
                                                    class="flex items-center text-slate-800"
                                                >
                                                    <i
                                                        class="la la-check text-success text-xl mr-2"
                                                    ></i>
                                                    {{ selectedMotive.name }}
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            v-if="planning?.address"
                                            class="my-1"
                                        >
                                            <div
                                                class="flex items-center text-slate-800"
                                            >
                                                <i
                                                    class="la la-check text-success text-xl mr-2"
                                                ></i>
                                                {{ planning.address.address }}
                                            </div>
                                        </div>
                                        <div
                                            v-if="selectedSlot?.start"
                                            class="mt-1"
                                        >
                                            <div
                                                class="flex items-center text-slate-800"
                                            >
                                                <i
                                                    class="la la-check text-success text-xl mr-2"
                                                ></i>
                                                {{
                                                    moment(
                                                        selectedSlot?.start
                                                    ).format(
                                                        "dddd DD MMMM à HH:mm"
                                                    )
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        v-if="appointmentCoast"
                                        class="w-full flex justify-between items-center my-6"
                                    >
                                        <div
                                            class="my-4 rounded px-3 py-2 border border-info flex justify-between w-9/12"
                                        >
                                            <h3 class="text-info text-sm">
                                                Prix du service :
                                                {{ formattedCost }}
                                            </h3>

                                            <el-tooltip
                                                content="<span>Prix du <strong>service</strong></span><br>Ce prix n'inclus pas le montant de la consultation<br><br>Les frais de la consultation seront <br>payés sur place avec votre praticien"
                                                raw-content
                                            >
                                                <i
                                                    class="la la-info-circle text-info"
                                                ></i>
                                            </el-tooltip>
                                        </div>
                                        <div>
                                            <a
                                                href="/faq"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <i
                                                    class="la la-external-link-alt text-info text-2xl"
                                                ></i>
                                            </a>
                                        </div>
                                    </div>
                                    <div
                                        class="my-5"
                                        v-if="
                                            selectedMotive && selectedSpecialty
                                        "
                                    >
                                        <Button
                                            label="Enregistrer"
                                            severity="primary"
                                            icon="la la-check rotate"
                                            class="bg-primary py-2 mr-2 px-3 text-white font-normal"
                                            :disabled="!user"
                                            @click="handleSave"
                                        />
                                    </div>
                                    <div class="w-full text-xs">
                                        <Message
                                            class="text-xs"
                                            severity="warn"
                                            :closable="false"
                                            ><p class="text-sm">
                                                Une prise de rendez-vous en
                                                ligne est une réservation de
                                                créneau,cette action vous engage
                                                donc et doit être honoré.
                                            </p></Message
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Dialog
        v-model:visible="visible"
        :draggable="false"
        modal
        header="À lire avant votre prise de rendez-vous"
        :style="{ width: isSmall || isMedium ? '80vw' : '50vw' }"
    >
        <h3 class="py-3 font-semibold">3 Consignes</h3>
        <p>
            <strong>1 -</strong> Le créneau vous sera réservé.Veuillez donc à ne
            pas être en retard à votre rendez-vous.
        </p>
        <Divider />
        <p>
            <strong>2 -</strong>
            Il est conseillé de venir au moins <strong>20 minutes</strong> avant
            votre rendez-vous afin de vous assurer de faire les formalités
            d'usage avant le début de la consultation proprement dite.
        </p>
        <Divider />
        <p>
            <strong>3 -</strong>
            Venez au rendez-vous avec votre dossier médical si possible dans le
            cadre d'une consultation de suivi.
        </p>
        <Divider />
        <p>
            Tout <strong>mauvais traitement</strong> (décallage assez long de
            votre créneau, absence du praticien,...) ou geste déplacé du
            praticien envers vous durant la consultation doit être immédiatement
            signalé afin de nous permettre de prendre les mesures nécessaires.
        </p>

        <p class="py-4 text-warning">
            NB: Vous devez vous munir d'un pièce d'identité.
        </p>
        <template #footer>
            <Button
                v-if="!isSubmitingApointment"
                label="J'ai lu et j'accepte les consignes"
                class="bg-primary3 shadow-primary3 py-2 px-3 text-white font-normal"
                @click="saveAppointment"
                autofocus
            />
            <div class="" v-if="isSubmitingApointment">
                <button
                    type="button"
                    class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-success hover:bg-indigo-400 transition ease-in-out duration-150 cursor-not-allowed"
                    disabled=""
                >
                    <svg
                        class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <circle
                            class="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            stroke-width="4"
                        ></circle>
                        <path
                            class="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                    </svg>
                    Sauvegarde en cours...
                </button>
            </div>
        </template>
    </Dialog>
    <LoginModal :show="showLogin" @onLoggedIn="handleUserLoggedIn" />
    <PToast />
</template>
<script>
import { computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import { useStore } from "vuex";
import gmap from "@/components/maps/gmap";
import Message from "primevue/message";
import Chip from "primevue/chip";
import Divider from "primevue/divider";
import Toast from "primevue/toast";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import moment from "moment";
import LoginModal from "@/views/auth/modal.vue";
import { useToast } from "primevue/usetoast";
import NewLoader from "@/components/Loader/index-2.vue";
import { AsYouType } from "libphonenumber-js";
import { useWindowSize } from "@vueuse/core";
import Storage, { storageKeys } from "../../services/Storage";
import { ElTooltip } from "element-plus";

// import {
//     openKkiapayWidget,
//     addKkiapayListener,
//     removeKkiapayListener,
//     addFailedListener,
//     addSuccessListener,
// } from "kkiapay";

export default {
    components: {
        Chip,
        gmap,
        Toast,
        Message,
        Button,
        Dialog,
        ElTooltip,
        Divider,
        LoginModal,
        NewLoader,
    },
    setup() {
        // const {
        //     openKkiapayWidget,
        //     addKkiapayListener,
        //     removeKkiapayListener,
        //     addFailedListener,
        //     addSuccessListener,
        // } = import.meta.glob("kkiapay");
        const toast = useToast();
        const link = computed(() =>
            typeof window != "undefined" ? window.location.href : ""
        );
        const isLoading = ref(true);
        const route = useRoute();
        const router = useRouter();
        const store = useStore();
        const planning = ref(null);
        const visible = ref(false);
        const appointmentCoast = ref(0);

        const formattedCost = computed(() =>
            new Intl.NumberFormat("fr", {
                style: "currency",
                currency: "XOF",
            }).format(appointmentCoast.value)
        );
        const center = ref(null);
        const selectedSlot = ref(null);
        const countSteps = ref(4);
        const showLogin = ref(false);
        const selectedSpecialty = ref();
        const selectedMotive = ref();
        const isSubmitingApointment = ref(false);
        const currentStep = ref(0);
        const hideAvailability = ref(false);
        const { width, height } = useWindowSize();

        const isSmall = computed(() => width.value <= 540);
        const isMedium = computed(
            () => width.value <= 1000 && width.value > 540
        );
        const isLarge = computed(() => width.value > 1000);
        const user = computed(() => store.getters["auth/user"]);
        const floors = computed(() => [
            { id: 0, name: "Rez de chaussez" },
            { id: 1, name: "1er Étage" },
            { id: 2, name: "2ème Étage" },
            ...Array.from(Array(45).keys())
                .filter((x) => x > 2)
                .map((x) => ({ id: x, name: `${x}ème Étage` })),
        ]);
        const motives = computed(() => [
            {
                id: "FIRST_CONSULTATION",
                name: "Première consultation chez ce praticien",
            },
            {
                id: "MEDICAL_MONITORING",
                name: "Consultation de suivi médical (suite à une consultation préalable avec ce praticien)",
            },
            {
                id: "MEDICAL_MONITORING_OTHER",
                name: "Consultation de suivi médical",
            },
            {
                id: "EMERGENCY",
                name: "Urgence",
            },
        ]);
        const initials = computed(() =>
            planning.value?.pratician?.full_name
                .match(/(^\S\S?|\s\S)?/g)
                .map((v) => v.trim())
                .join("")
                .match(/(^\S|\S$)?/g)
                .join("")
                .toLocaleUpperCase()
        );
        const formatPhone = (phone) => {
            return new AsYouType().input(`+${phone}`);
        };
        const patientInitials = computed(() =>
            user.value?.full_name
                .match(/(^\S\S?|\s\S)?/g)
                .map((v) => v.trim())
                .join("")
                .match(/(^\S|\S$)?/g)
                .join("")
                .toLocaleUpperCase()
        );
        const currentTime = ref();
        const currentIndex = ref(4);
        const address = computed(() =>
            (planning.value.medical_center.addresses ?? []).find(
                (x) => x.address == planning.value.address.address
            )
        );
        const specialties = computed(() =>
            (planning.value?.specialties ?? []).map((x) => x.name)
        )?.toString();

        const xOf = new Intl.NumberFormat("fr-FR", {
            style: "currency",
            currency: "XOF",
        });
        const slotsData = ref(null);
        const allSlots = ref([]);
        const displayedSlots = computed(() =>
            allSlots.value.slice(currentIndex.value - 4, currentIndex.value)
        );
        const priceMin = computed(() =>
            xOf.format(planning.value?.price?.min ?? 0)
        );
        const priceMax = computed(() =>
            xOf.format(planning.value?.price?.max ?? 0)
        );

        const selectSpecialty = (data) => {
            selectedSpecialty.value = data;
        };

        const selectMotive = (m) => {
            selectedMotive.value = m;
        };

        const saveAppointment = () => {
            // visible.value = false;
            isSubmitingApointment.value = true;
            const data = {
                patient_id: user.value.id,
                slot_start_unix: currentTime.value,
                specialty: selectedSpecialty.value?.id,
                motive: selectedMotive.value?.id,
                planning_id: planning.value._id,
                slot: selectedSlot.value,
            };
            store.dispatch("medical/createAppointment", {
                data,
                onSuccess: (response) => {
                    isSubmitingApointment.value = false;

                    if (response.invoice?.amount == 0) {
                        window.location.replace(
                            `/appointments/${response.appointment?.id}/success`
                        );
                        return;
                    } else {
                        payNow(response.appointment?.id, response.invoice?.id);
                    }
                    visible.value = false;
                },
                onError: (error) => {
                    isSubmitingApointment.value = false;

                    visible.value = false;
                    const errorMessage =
                        error?.error == "busy_slot"
                            ? "Désolé, ce créneau est déjà reservé."
                            : "Ooops, une erreur est survenue. Veuillez rééssayer plus tard.";

                    toast.add({
                        severity: "error",
                        summary: "Prise de rendez-vous",
                        detail: errorMessage,
                        life: 5000,
                    });

                    console.log(window.location);
                },
            });
        };

        const handleUserLoggedIn = () => {
            store.dispatch("auth/getUser", {});
        };
        const getPlanning = () => {
            const id =
                window.location.pathname.split("/")[
                    window.location.pathname.split("/").length - 1
                ];
            if (!id) return;

            store.dispatch("medical/getPlanning", {
                planningId: id,
                onSuccess: (data) => {
                    planning.value = { ...data };
                    center.value = {
                        lat: data.address.latitude,
                        lng: data.address.longitude,
                    };
                    allSlots.value = [...data.slots];
                    buildSlots(data.slots ?? []);
                    isLoading.value = false;
                },
                onError: () => {
                    isLoading.value = false;
                },
            });
        };
        const buildSlots = (slots) => {
            const groups = slots.reduce((groups, game) => {
                const date = game.start.split("T")[0];
                if (!groups[date]) {
                    groups[date] = [];
                }
                groups[date].push(game);
                return groups;
            }, {});

            const groupArrays = Object.keys(groups).map((date) => {
                return {
                    date,
                    slots: groups[date],
                };
            });
            slotsData.value = groupArrays;
            allSlots.value = [...groupArrays]
                .sort((a, b) => moment(a.start).unix() - moment(b.start).unix())

                .map((s) => ({
                    date: s.date,
                    slots: s.slots.sort(
                        (a, b) =>
                            moment(a.start).unix() - moment(b.start).unix()
                    ),
                }));

            if (currentTime.value) {
                currentStep.value = 1;
                selectedSlot.value = [...allSlots.value]
                    .flatMap((s) => s.slots)
                    .find((x) =>
                        moment.unix(currentTime.value).isSame(moment(x.start))
                    );
            }
        };

        const handleNext = () => {
            let next = 0;
            if (currentIndex.value + 4 > allSlots.value.length)
                next = allSlots.value.length - currentIndex.value;
            if (currentIndex.value > allSlots.value.length) return;

            if (currentIndex.value >= 4) next = 4;
            currentIndex.value += next;
        };

        const handlePrev = () => {
            if (currentIndex.value == 4) return;
            let next = 4;
            if (currentIndex.value > 4) next = 4;
            currentIndex.value -= next;
        };

        const handleTime = (slot) => {
            const unix = moment(slot.start).unix();

            router.push({ path: route.fullPath, query: { time: unix } });
            selectedSlot.value = slot;
            currentStep.value += 1;
        };

        const handleSave = () => {
            if (!user.value) {
                showLogin.value = true;

                return;
            }
            visible.value = !!user.value;
        };

        const payNow = (appointmentId, invoiceId) => {
            const param = { appointmentId, invoiceId };
            let phone = "";
            if (user.value.phone_1) {
                const phoneInternational = new AsYouType().input(
                    `+${user.value.phone_1}`
                );

                console.log(phoneInternational);
                // phone = phoneInternational.formatNational();
            }
            console.log(window.location);
            // return
            if (typeof window !== "undefined") {
                openKkiapayWidget({
                    amount: appointmentCoast.value,
                    api_key: "fc9a2ac07e4111ee86f5f3e8aa6bc781",
                    key: "fc9a2ac07e4111ee86f5f3e8aa6bc781",
                    sandbox: true,
                    theme: "#1ba8dc",
                    data: JSON.stringify(param),
                    callback: `${window.location.origin}/payments-callback/kkiapay`,
                    name: user.value?.full_name,
                    phone: phone,
                });
            }
        };

        const checkAppointmentPrice = () => {
            store.dispatch("medical/checkAppointmentPrice", {
                onSuccess: (data) => {
                    console.log(data, "price");
                    appointmentCoast.value = data?.price ?? 0;
                },
                onError: () => {},
            });
        };

        const updateTransaction = (method, invoiceId, transactionId) => {
            store.dispatch("auth/updateTransaction", {
                method,
                invoiceId,
                transactionId,
                onSuccess: (data) => {
                    console.log(data, "price");
                    appointmentCoast.value = data?.price ?? 0;
                },
                onError: () => {},
            });
        };

        const onKkiapayError = () => {
            if (typeof window == "undefined") return;
            addSuccessListener((response) => {
                console.log(response);
            });

            addFailedListener((error) => {
                const data = JSON.parse(error.data);
                updateTransaction(
                    "kkiapay",
                    data.invoiceId,
                    error?.transactionId
                );
            });
        };

        onMounted(async () => {
            onKkiapayError();
            getPlanning();
            checkAppointmentPrice();
            const token = Storage.get(storageKeys.authToken);
            if (token) handleUserLoggedIn();
            if (typeof window != "undefined") {
                const urlParams = new URLSearchParams(window.location.search);
                let timeParam = urlParams.get("time");

                if (timeParam) {
                    currentTime.value = parseInt(timeParam);
                }
            }
        });

        return {
            initials,
            patientInitials,
            priceMin,
            user,
            payNow,
            link,
            isSubmitingApointment,
            floors,
            priceMax,
            center,
            selectedSpecialty,
            currentStep,
            selectedSlot,
            specialties,
            planning,
            moment,
            appointmentCoast,
            motives,
            formattedCost,
            showLogin,
            currentIndex,
            allSlots,
            visible,
            selectedMotive,
            displayedSlots,
            isLoading,
            address,
            isSmall,
            isMedium,
            isLarge,
            hideAvailability,
            formatPhone,
            saveAppointment,
            handleNext,
            handleSave,
            handlePrev,
            selectMotive,
            selectSpecialty,
            handleTime,
            handleUserLoggedIn,
        };
    },
};
</script>
<style lang="scss" scoped>
.b-shadow {
    box-shadow: 0px 10px 42px 0px rgba(0, 0, 0, 0.1);
}
</style>
