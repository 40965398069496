export default {
  echoPresence({ state }, { userId }) {

    if (!window.Echo) return;

    console.log('broadcasting online Event', userId);
    // window.Echo.
    window.Echo.join(`online`)
      .here((users) => {
        console.log(users);
      })
      .joining((user) => {
        console.log(user.name);
      })
      .leaving((user) => {
        console.log(user.name);
      }).listen('UserOnline', (e) => {
        console.log(e);
      });
  }
}