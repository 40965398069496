import axios from "./axios";
import { blog, editor } from "./endpoint";

export default {

    createCategory: async (data = {}) => {
        const response = await axios.post(editor.categories(), data);
        return response.data;
    },

    getCategories: async (data = {}) => {
        const response = await axios.get(editor.categories(), data);
        return response.data;
    },
    getTags: async (data = {}) => {
        const response = await axios.get(editor.tags(), data);
        return response.data;
    },

    editTag: async (id, data = {}) => {
        const response = await axios.put(editor.tag(id), data);
        return response.data;
    },
    createTag: async (data = {}) => {
        const response = await axios.post(editor.tags(), data);
        return response.data;
    },

    editCategory: async (id, data = {}) => {
        const response = await axios.put(editor.category(id), data);
        return response.data;
    },

    deleteCategory: async (id) => {
        const response = await axios.delete(editor.category(id));
        return response.data;
    },
    deleteTag: async (id) => {
        const response = await axios.delete(editor.tag(id));
        return response.data;
    },

    postWriter: async (data = {}) => {
        const response = await axios.post(blog.postWriter(), data);
        return response.data;
    },
    savePost: async (data = {}) => {
        const response = await axios.post(blog.posts(), data);
        return response.data;
    },

    removePost: async (postId) => {
        const response = await axios.delete(blog.post(postId),);
        return response.data;
    },


    getPosts: async (filter = {}) => {
        const response = await axios.get(blog.posts(), filter);
        return response.data;
    },

    savePostComment: async (id, data = {}) => {
        const response = await axios.post(blog.postComments(id), data);
        return response.data;
    },

    postsSearch: async (filter = {}) => {
        const response = await axios.get(blog.postsSearch(), filter);
        return response.data;
    },

    storeMedia: async (data) => {
        const response = await axios.file(blog.media(), data);
        return response.data;
    }
}
