<template>
    <div class="flex h-full w-full justify-center items-center">
        <div v-if="!notFound" class="w-3/5 flex">
            <div class="w-1/2 flex h-full">
                <div class="py-20">
                    <img
                        src="/images/undraw_feeling_happy_jymo.svg"
                        class=""
                        alt=""
                    />
                </div>
            </div>

            <div class="flex flex-col justify-center items-center w-1/2">
                <h1
                    class="py-5 px-4 text-3xl text-center font-qs text-slate-500 font-semibold"
                >
                    Ravi de vous compter dans la grande famille Allô Doto.
                </h1>
                <h3 class="text-xl text-primary font-bold">
                    Votre compte a bien été activé
                </h3>

                <p class="text-center text-primary">
                    Vous pouvez maintenant prendre rendez-vous avec des
                    praticiens près de chez vous.
                </p>

                <div class="my-10">
                    <a
                        href="/login"
                        class="px-5 py-2 rounded-lg bg-primary2 text-white"
                        >Se connecter</a
                    >
                </div>
            </div>
        </div>
        <div v-else class="w-3/5 flex">
            <div class="w-1/2 flex h-full">
                <div class="py-20">
                    <img
                        src="/images/undraw_cancel_re_pkdm.svg"
                        class=""
                        alt=""
                    />
                </div>
            </div>
            <div class="flex flex-col justify-center items-center w-1/2">
                <h1
                    class="py-5 px-5 text-xl text-center font-qs text-danger font-semibold"
                >
                    Oooops, ce lien n'est plus valide
                </h1>

                <div class="my-10">
                    <a
                        href="/login"
                        class="px-5 py-2 rounded-lg bg-primary2 text-white"
                        >Se connecter</a
                    >
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { onMounted } from "vue";
const props = defineProps({ notFound: Boolean, user: Object });

onMounted(() => {});
</script>
