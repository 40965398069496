<template>
    <div
        class="lg:flex lg:flex-row xl:flex md:flex sm:flex-col xs:flex-col lg:px-24 mb-10"
    >
        <div
            class="lg:w-3/5 sm:mb-10 xs:mb-10 xl:w-3/5 md:w-3/5 sm:w-full xs:w-full pr-5 flex items-center"
        >
            <div>
                <Message severity="success" :closable="false" v-if="isSuccess"
                    >Votre message a bien été reçu. Notre équipe traitera votre
                    demande dans les meilleurs délais et nous vous
                    reviendrons</Message
                >
                <div class="w-full xs:px-5">
                    <div class="w-4/5">
                        <img src="/images/contact-us.jpg" alt="" />
                    </div>
                    <p class="py-10 text-md text-slate-600 md:pr-10">
                        Pour toutes vos demandes (réclamations, aides,...), vous
                        pouvez nous contacter en remplissant ce formulaire de
                        contact ou nous joindre directement
                    </p>

                    <div class="flex">
                        <div
                            class="w-6 h-6 bg-primary3 rounded-full text-white flex justify-center items-center mr-2"
                        >
                            <i class="la la-map-marker"></i>
                        </div>

                        <div>
                            <div class="flex">
                                <address>
                                    Lot 44 Fidjrossè Cotonou, Bénin
                                </address>
                            </div>
                            <address class="mt-2">
                                Rivera Faya laurier 9 - Église saint Paul,
                                Abidjan, Côte d'Ivoire
                            </address>
                        </div>
                    </div>

                    <div class="flex mt-3 items-center">
                        <div
                            class="w-6 h-6 bg-primary2 rounded-full text-white flex justify-center items-center"
                        >
                            <i class="la la-envelope"></i>
                        </div>
                        <div class="ml-2">
                            <p class="py-1">
                                hello@habilis.bj (Habilis Bénin: Société
                                éditrice)
                            </p>
                            <p class="py-1">sos@allodoto.com</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="lg:w-2/5 xl:w-2/5 md:w-2/5 sm:w-full xs:w-full shadow-lg px-8 py-4"
        >
            <h1 class="text-4xl text-slate-600 font-qs font-bold">
                Envoyer une demande
            </h1>

            <div class="mt-10">
                <form @submit.prevent="handleForm">
                    <div class="">
                        <label for="email">Vous êtes : </label>
                        <div class="h-10">
                            <Field v-slot="{}" v-model="form.type" name="type">
                                <Dropdown
                                    v-model="form.type"
                                    :options="types"
                                    optionLabel="name"
                                    placeholder="-"
                                    :inputStyle="{ paddingTop: '8px' }"
                                    class="rounded shadow-none border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                    @update:modelValue="handleType"
                                />
                            </Field>
                        </div>
                    </div>

                    <div class="mt-5">
                        <label for="email">Adresse email</label>
                        <div class="h-10">
                            <Field
                                v-slot="{ field }"
                                name="email"
                                v-model="form.email"
                            >
                                <InputText
                                    type="text"
                                    v-bind="field"
                                    id="email"
                                    name="email"
                                    placeholder="Email *"
                                    class="rounded border bg-white border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                />
                            </Field>
                        </div>
                    </div>

                    <div class="mt-5">
                        <label for="email">Nom & Prénom(s)</label>
                        <div class="h-10">
                            <Field
                                v-slot="{ field }"
                                name="name"
                                v-model="form.name"
                            >
                                <InputText
                                    type="text"
                                    v-bind="field"
                                    id="name"
                                    name="name"
                                    placeholder="Nom et Prénom(s) *"
                                    class="rounded border border-slate-300 bg-white h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                />
                            </Field>
                        </div>
                    </div>

                    <div class="mt-5">
                        <label for="email">Motif</label>
                        <div class="h-10">
                            <Field
                                v-slot="{ field }"
                                name="motive"
                                v-model="form.motive"
                            >
                                <InputText
                                    type="text"
                                    v-bind="field"
                                    id="reason"
                                    placeholder="Motif *"
                                    class="rounded border border-slate-300 bg-white h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                />
                            </Field>
                        </div>
                    </div>

                    <div class="mt-5">
                        <label for="descroiption">Description</label>
                        <div class="">
                            <Field
                                v-slot="{ field }"
                                name="description"
                                v-model="form.description"
                            >
                                <Textarea
                                    v-bind="field"
                                    autoResize
                                    rows="5"
                                    placeholder="Que pouvons nous pour vous ?"
                                    class="rounded border resize-none bg-white border-slate-300 w-full px-2 focus:border-slate-400 transition transition-all shadow-none"
                                />
                            </Field>

                            <span class="text-xs text-slate-200 py-1">
                                Saisissez les détails de votre demande. Un
                                membre de notre équipe d’assistance répondra
                                dans les plus brefs délais.
                            </span>
                        </div>
                    </div>

                    <div class="mt-5">
                        <label for="descroiption"
                            >Pièces jointe (<span class="text-sm"
                                >facultatif</span
                            >)</label
                        >
                        <div
                            class="border-slate-300 border w-full flex items-center rounded px-2 focus:border-slate-400 transition transition-all shadow-none h-10"
                        >
                            <p class="text-sm text-center">
                                Ajouter vos
                                <button
                                    class="mx-1 text-primary3"
                                    @click.prevent="handleFiles"
                                >
                                    fichiers
                                </button>
                                ici
                            </p>
                        </div>

                        <div class="mt-2">
                            <div
                                class="flex justify-between items-center my-2"
                                v-for="(f, index) in loadedFiles"
                                :key="index"
                            >
                                <p class="text-sm">{{ f.name }}</p>

                                <span
                                    class="la la-times cursor-pointer"
                                    @click.prevent="removeFile(index)"
                                ></span>
                            </div>
                        </div>
                    </div>

                    <div class="mt-5 flex">
                        <Field v-slot="{ field }" name="copy" type="checkbox">
                            <Checkbox
                                v-bind="field"
                                v-model="form.copy"
                                inputId="copy"
                                name="copy"
                                inputClass="bg-primary"
                                :binary="true"
                            />
                        </Field>

                        <h2 class="ml-2 text-slate-600">
                            Recevoir une copie du message
                        </h2>
                    </div>

                    <div class="mt-8 mb-10">
                        <Button
                            v-if="isSubmitting"
                            type="button"
                            class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow text-white bg-primary hover:bg-indigo-400 transition ease-in-out duration-150 cursor-not-allowed"
                            disabled=""
                        >
                            <svg
                                class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <circle
                                    class="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    stroke-width="4"
                                ></circle>
                                <path
                                    class="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                            </svg>
                            Enregistrement de votre demande en cours
                        </Button>
                        <button
                            v-else
                            :disabled="!meta?.valid"
                            class="shadow-none outline-none rounded-md bg-primary3 bg-opacity-25 hover:bg-opacity-50 py-2 px-4 w-1/2 text-primary3 font-semibold"
                        >
                            Envoyer
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <input
            type="file"
            ref="file"
            multiple
            class="hidden"
            @change="fileChanged"
        />
        <Toast />
    </div>
</template>

<script>
import { computed, defineComponent, ref } from "vue";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import Toast from "primevue/toast";
import { Field, useForm } from "vee-validate";
import Dropdown from "primevue/dropdown";
import Message from "primevue/message";
import Checkbox from "primevue/checkbox";

import * as yup from "yup";
import { useStore } from "vuex";
import { useToast } from "primevue/usetoast";
export default defineComponent({
    components: {
        InputText,
        Checkbox,
        Toast,
        Textarea,
        Message,
        Field,
        Dropdown,
    },
    setup(props) {
        const toast = useToast();
        const isSuccess = ref(false);
        const file = ref();
        const form = ref({
            type: {},
            copy: false,
            name: "",
            email: "",
            description: "",
            motive: "",
        });
        const store = useStore();
        const loadedFiles = ref([]);
        const isSubmitting = ref(false);
        const types = computed(() => [
            { name: "-", id: null },
            { name: "Un(e) patient(e)", id: "PATIENT" },
            { name: "Un Établissement de soin", id: "MEDICAL_CENTER" },
            { name: "Un laboratoire", id: "LABORATORY" },
            { name: "Une pharmacie", id: "PHARMACY" },
            { name: "Autre (journaliste,...)", id: "OTHER" },
        ]);

        const schema = computed(() =>
            yup.object({
                email: yup
                    .string()
                    .email()
                    .required("Votre adresse email est obligatoire"),
                name: yup
                    .string()
                    .required("Votre nom complet est obligatoire"),
                motive: yup.string().required("Le motif de votre demande"),
                description: yup
                    .string()
                    .required("Décrivez-nous votre demande"),
                type: yup
                    .object({ id: yup.string() })
                    .required("Votre ville est obligatoire"),
            })
        );

        const { meta, setFieldValue, handleSubmit, handleReset } = useForm({
            validationSchema: schema.value,
            initialValues: form.value,
        });

        const handleType = (data) => {
            setFieldValue("type", data);
        };

        const handleFiles = () => {
            file.value?.click();
        };

        const fileChanged = (event) => {
            const { files } = event.target;
            loadedFiles.value.push(...(files ?? []));
        };

        const removeFile = (index) => {
            loadedFiles.value.splice(index, 1);
        };

        const handleForm = handleSubmit((values) => {
            // errorMessage.value = null;
            isSubmitting.value = true;
            const formData = new FormData();
            formData.append("email", values.email);
            formData.append("description", values.description);
            formData.append("name", values.name);
            formData.append("type", values.type.id);
            formData.append("motive", values.motive);

            formData.append("copy", values.copy);

            loadedFiles.value;

            for (let index = 0; index < loadedFiles.value.length; index++) {
                const element = loadedFiles.value[index];
                formData.append("files[]", element);
            }

            store.dispatch("auth/contactUs", {
                data: formData,
                onSuccess: () => {
                    handleReset();
                    isSuccess.value = true;
                    isSubmitting.value = false;
                },
                onError: () => {
                    isSubmitting.value = false;
                    toast.add({
                        severity: "error",
                        summary: "Contact",
                        detail: "Oooops, une erreur est survenue, veuillez recommencer ultérieurement",
                        life: 3000,
                    });
                },
            });
        });

        return {
            types,
            form,
            loadedFiles,
            file,
            meta,
            isSubmitting,
            isSuccess,
            handleForm,
            fileChanged,
            removeFile,
            handleType,
            handleFiles,
        };
    },
});
</script>
