import service from '@/services/center';



export default {
    async create({ commit }, { data, onSuccess, onError }) {
        try {
            await service.createMedicalCenter(data)
            onSuccess()
        } catch (error) {
            console.log(error?.data)
            onError()
        }
    },


}
