<template>
  <div class="w-full flex flex-col px-3">
    <div class="border-b border-slate-200 w-full py-3">
      <h3 class="font-roboto font-bold text-lg">Informations</h3>
    </div>

    <form @submit.prevent="submitForm">
      <div class="py-2">
        <h4 class="py-2">Photo de profile</h4>
        <div
          :style="`background-image: url(${user?.avatar});background-size: contain;background-repeat: no-repeat;background-position-y: center;background-position-x: center;`"
          :class="`relative w-20 h-20 rounded bg-doto-500`"
        >
          <div
            class="absolute w-5 h-5 flex justify-center items-center rounded bg-white bottom-0 right-0"
          >
            <i
              class="la la-pen text-slate-600 cursor-pointer"
              @click.prevent="loadFile"
            ></i>
          </div>
        </div>
      </div>

      <div class="w-full mb-3">
        <div class="flex mt-4 mb-2">
          <div class="w-1/2 mr-1">
            <label
              class="block capitalize tracking-wide text-grey-darker text-xs font-medium mb-1"
              for="grid-last-name"
              >Nom de famille</label
            >
            <div class="h-10">
              <Field
                v-slot="{ field }"
                name="last_name"
                v-model="user.last_name"
              >
                <input
                  v-bind="field"
                  name="last_name"
                  v-model="user.last_name"
                  class="rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                  id="grid-last-name"
                  type="text"
                  placeholder="Doe"
                />
              </Field>
            </div>
          </div>
          <div class="w-1/2 ml-1">
            <label
              class="block capitalize tracking-wide text-grey-darker text-xs font-medium mb-1"
              for="grid-first-name"
              >Prénom(s)</label
            >
            <div class="h-10">
              <Field
                v-slot="{ field }"
                name="first_name"
                v-model="user.first_name"
              >
                <input
                  name="first_name"
                  v-model="user.first_name"
                  v-bind="field"
                  class="rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                  id="grid-first-name"
                  type="text"
                  placeholder="Jane"
                />
              </Field>
            </div>
          </div>
        </div>
        <div class="flex mt-3 mb-2">
          <div class="w-1/2 mr-1">
            <label
              class="block capitalize tracking-wide text-grey-darker text-xs font-medium mb-1"
              for="grid-phone-1"
              >Email</label
            >
            <div class="h-10">
              <Field v-slot="{ field }" name="email" v-model="user.email">
                <input
                  v-bind="field"
                  name="email"
                  v-model="user.email"
                  class="rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                  id="email"
                  disabled
                  type="text"
                  placeholder="test@example.com"
                />
              </Field>
            </div>
          </div>
          <div class="w-1/2 ml-1">
            <label
              class="block capitalize tracking-wide text-grey-darker text-xs font-medium mb-1"
              for="grid-email"
              >Surnom (optionnel)</label
            >
            <div class="h-10">
              <Field v-slot="{ field }" name="nickname" v-model="user.nickname">
                <input
                  v-bind="field"
                  autocomplete="off"
                  name="nickname"
                  v-model="user.nickname"
                  placeholder="Surnom"
                  class="rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                  type="text"
                />
              </Field>
            </div>
          </div>
        </div>

        <div class="flex mt-3 mb-2">
          <div class="w-1/5 mr-1">
            <label
              class="block capitalize tracking-wide text-grey-darker text-xs font-medium mb-1"
              for="grid-state"
              >Ville</label
            >
            <div class="relative h-10">
              <Field v-slot="{ field }" name="city" v-model="user.city">
                <Select
                  v-bind="field"
                  name="city"
                  v-model="user.city"
                  :options="cities"
                  @changed="([city]) => (user.city = city)"
                />
              </Field>
            </div>
          </div>
          <div class="w-1/5 mx-1">
            <label
              class="block capitalize tracking-wide text-grey-darker text-xs font-medium mb-1"
              for="grid-city"
              >Quartier</label
            >
            <div class="h-10">
              <Field
                v-slot="{ field }"
                name="district_name"
                v-model="user.district_name"
              >
                <input
                  v-bind="field"
                  name="district_name"
                  v-model="user.district_name"
                  class="rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                  id="grid-city"
                  type="text"
                  placeholder="Akpakpa"
                />
              </Field>
            </div>
          </div>
          <div class="w-2/5 mx-1">
            <label
              class="block capitalize tracking-wide text-grey-darker text-xs font-medium mb-1"
              for="grid-city"
              >Address</label
            >
            <div class="h-10">
              <Field v-slot="{ field }" name="address" v-model="user.address">
                <input
                  v-bind="field"
                  name="address"
                  v-model="user.address"
                  class="rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                  id="grid-city"
                  type="text"
                  placeholder="12, rue de la liberté"
                />
              </Field>
            </div>
          </div>

          <div class="w-1/5 ml-1">
            <label
              class="block capitalize tracking-wide text-grey-darker text-xs font-medium mb-1"
              for="grid-postal"
              >Code Postal</label
            >
            <div class="h-10">
              <Field
                v-slot="{ field }"
                name="postal_code"
                v-model="user.postal_code"
              >
                <input
                  v-bind="field"
                  name="postal_code"
                  v-model="user.postal_code"
                  class="rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                  id="grid-postal"
                  type="text"
                  placeholder="67000"
                />
              </Field>
            </div>
          </div>
        </div>

        <div class="mt-5 mb-8">
          <Button
            v-if="!isSubmittingProfile"
            size="sm"
            :disabled="!meta.valid || !meta.dirty"
            variant="primary"
            class="border-none rounded bg-primary py-2 px-3 text-white"
            type="submit"
          >
            Enrégistrer
          </Button>
          <Button
            v-else
            type="button"
            class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow text-white bg-primary hover:bg-indigo-400 transition ease-in-out duration-150 cursor-not-allowed"
            disabled=""
          >
            <svg
              class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            Sauvegarde en cours...
          </Button>
        </div>
      </div>
    </form>
    <Toast />
    <input
      class="hidden"
      ref="file"
      type="file"
      name="file"
      id="file"
      accept="image/*"
      @change="handleFile"
    />
  </div>
</template>

<script>
  import { Field, useForm } from "vee-validate";
  import { defineComponent, computed, ref, watch, onMounted } from "vue";
  import { useStore } from "vuex";
  import Select from "../../components/Select/index.vue";
  import * as yup from "yup";
  import Button from "primevue/button";
  import Toast from "primevue/toast";
  import { useToast } from "primevue/usetoast";
  export default defineComponent({
    setup() {
      const store = useStore();
      const toast = useToast();
      const auth = computed(() => store.getters["auth/user"]);
      const cities = computed(() => store.getters["config/cities"]);
      const user = ref({
        ...auth.value,
        district_name: auth.value?.profile?.district_name,
        postal_code: auth.value?.profile?.postal_code,
        address: auth.value?.profile?.address,
      });
      const file = ref();
      const isSubmittingProfile = ref(false);

      const schema = computed(() =>
        yup.object({
          last_name: yup.string().required(""),
          first_name: yup.string().required(""),
          first_name: yup.string().required(""),
          nickname: yup.string().optional().nullable().default(null),
          city: yup
            .object({ id: yup.number() })
            .required("Votre ville est obligatoire"),
          district_name: yup.string().required(),
          address: yup.string().required(),
          postal_code: yup.string().required(),
        })
      );

      const { meta, errors, handleSubmit } = useForm({
        validationSchema: schema.value,
      });
      const loadFile = () => {
        file.value?.click();
      };
      const getCities = () => {
        store.dispatch("config/getCities");
      };

      const handleFile = (event) => {
        const [loaded] = event.target.files;
        const formData = new FormData();
        formData.append("image", loaded);
        store.dispatch("auth/updateAvatar", {
          data: formData,
          onSuccess: () => {
            toast.add({
              severity: "success",
              summary: "Profile",
              detail: "Votre profile a bien été mis à jour",
              life: 3000,
            });
          },
          onError: () => {
            toast.add({
              severity: "error",
              summary: "Profile",
              detail: "Ooops, une erreur est survenue lors de mis à jour",
              life: 5000,
            });
          },
        });
      };

      const submitForm = handleSubmit((values) => {
        isSubmittingProfile.value = true;
        const data = {
          district_name: values.district_name,
          address: values.address,
          marital_status: user.value.marital_status ?? "",
          birth_date: user.value.birth_date ?? "",
          gender: user.value.gender,
          email: user.value.email ?? values.email,
          postal_code: values.postal_code,
          nickname: values.nickname,
          last_name: values.last_name,
          first_name: values.first_name,
          city_id: values.city.id,
        };

        store.dispatch("auth/updateProfile", {
          data,
          onSuccess: () => {
            isSubmittingProfile.value = false;

            toast.add({
              severity: "success",
              summary: "Profile",
              detail: "Votre profile a bien été mis à jour",
              life: 3000,
            });
          },
          onError: () => {
            isSubmittingProfile.value = false;

            toast.add({
              severity: "error",
              summary: "Profile",
              detail: "Ooops, une erreur est survenue lors de mis à jour",
              life: 5000,
            });
          },
        });
      });
      watch(
        () => auth.value,
        (val) => {
          user.value = {
            ...val,
            district_name: val?.profile?.district_name,
            postal_code: val?.profile?.postal_code,
            address: val?.profile?.address,
          };
        }
      );
      onMounted(() => {
        getCities();
      });

      return {
        auth,
        user,
        cities,
        meta,
        errors,
        file,
        isSubmittingProfile,
        submitForm,
        loadFile,
        handleFile,
      };
    },
    components: { Field, Select, Button, Toast },
  });
</script>
