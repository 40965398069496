<template>
    <TransitionRoot
        enter="transition-opacity"
        ease-linear
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity"
        duration-300
        leave-from="opacity-100"
        leave-to="opacity-0"
        appear
        :show="isOpen"
        as="div"
    >
        <Modal
            :size="size"
            v-if="isOpen"
            :id="id"
            class="root"
            ref="root"
            @close="setIsOpen(false)"
        >
            <template #header>
                <div class="flex justify-between w-full">
                    <h3 class="flex items-center text-md font-semibold">
                        {{ title }}
                    </h3>
                </div>
            </template>
            <template #body>
                <div class="max-h-80 overflow-scroll no-scrollbar">
                    <slot name="content"> </slot>
                </div>
            </template>
            <template #footer>
                <div class="flex justify-start">
                    <button
                        @click="setIsOpen(false)"
                        type="button"
                        :class="`${closeClass} focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 mr-2 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600`"
                    >
                        {{ leftText }}
                    </button>
                    <button
                        v-if="!hideValidation"
                        @click="handleSubmit"
                        type="button"
                        class="bg-primary text-primary bg-opacity-25 hover:bg-opacity-50 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                        {{ rightText }}
                    </button>
                </div>
            </template>
        </Modal>
    </TransitionRoot>
</template>

<script>
import { ref, watch, onMounted } from "vue";
import { Modal } from "flowbite-vue";
import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
    DialogDescription,
} from "@headlessui/vue";
import _ from "lodash";
export default {
    components: {
        TransitionRoot,
        Dialog,
        DialogPanel,
        DialogTitle,
        DialogDescription,
        Modal,
    },
    props: {
        isActionDisabled: { type: Boolean, default: false },
        isVisible: { type: Boolean },
        width: { type: String },
        size: { type: String, default: "2xl" },
        title: { type: String },
        hideValidation: { type: Boolean, default: false },
        closeClass: {
            type: String,
            default: "text-gray-500 bg-white hover:bg-gray-100 ",
        },
        rightText: { type: String, default: "Valider" },
        leftText: { type: String, default: "Fermer" },
    },
    emits: ["close", "submit", "cancel"],
    setup(props, { emit }) {
        const root = ref(null);
        const isOpen = ref(props.isVisible);
        const id = _.uniqueId("modal_");
        function handleSubmit() {
            setIsOpen(false);
            emit("submit");
        }
        function setIsOpen(value) {
            isOpen.value = value;

            if (!value) emit("cancel");
        }

        watch(
            () => props.isVisible,
            (val) => {
                isOpen.value = val;
            }
        );

        onMounted(() => {});

        return {
            isOpen,
            id,
            root,
            setIsOpen,
            handleSubmit,
        };
    },
};
</script>
