<template>
    <div class="w-full">
        <form class="w-full">
            <div class="flex w-full">
                <div class="w-2/5 px-1">
                    <label
                        class="block capitalize tracking-wide text-grey-darker text-xs font-semibold mb-1 text-left"
                        for="grid-first-name"
                        >Agents</label
                    >
                    <v-select
                        v-model="item.agents"
                        :options="agents"
                        :multiple="true"
                        :value="item.agents"
                        placeholder="Sélectionnez les agents"
                        @selected="agentsSelected"
                        @removed="agentRemoved"
                    ></v-select>
                </div>

                <div class="w-1/5 px-1">
                    <label
                        class="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-1 text-left"
                        for="grid-last-name"
                        >Conducteur</label
                    >
                    <v-select
                        label="full_name"
                        v-model="item.conductor"
                        :value="item.conductor"
                        :options="conductors"
                        :multiple="false"
                        placeholder="Conducteur"
                        @removed="conductorRemoved"
                        @selected="conductorSelected"
                    ></v-select>
                </div>
                <div class="w-2/5 px-1">
                    <label
                        class="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-1 text-left"
                        for="grid-last-name"
                        >Ressources</label
                    >
                    <v-select
                        placeholder="Sélectionnez les ressources"
                        v-model="item.resources"
                        :value="item.resources"
                        :options="resources"
                        :multiple="true"
                        @selected="resourceSelected"
                        @removed="resourceRemoved"
                    ></v-select>
                </div>
            </div>
            <div class="flex flex-col my-5">
                <div
                    class="w-full mb-2 flex"
                    v-for="(res, index) in item.resources || []"
                    :key="index"
                >
                    <div class="w-4/5 flex flex-col">
                        <label
                            class="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-1 text-left"
                            for="grid-first-name-1"
                            >Ressource</label
                        >
                        <div class="h-10">
                            <input
                                v-model="res.name"
                                disabled
                                class="bg-slate-100 rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                type="text"
                                placeholder="Le nom "
                            />
                        </div>
                    </div>
                    <div class="w-1/5 px-3">
                        <label
                            class="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-1 text-left"
                            for="grid-last-name-1"
                            >Quantité</label
                        >
                        <div class="flex flex-wrap">
                            <div class="flex w-8/12 h-10">
                                <input
                                    v-model="res_quantity[res.id]"
                                    type="number"
                                    :id="`quantity_${res.id}`"
                                    :name="`resources[${res.id}]`"
                                    @input="updateQuantity(index)"
                                    class="bg-white rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    watch: {
        ambulance: {
            handler(amb) {
                if (!amb) this.amb = {};
                else {
                    this.item.resources = [...(amb.resources || [])];
                    [...(amb.resources || [])].forEach((x) => {
                        this.res_quantity[x.id] = x?.pivot.quantity;
                    });

                    this.updateData(amb);
                }
            },
            deep: true,
        },
    },
    props: {
        agents: { type: Array },
        ambulance: { type: Object, default: null },
        conductors: {
            type: Array,
            default: () => [],
        },
        res_types: {
            type: Array,
            default: () => [],
        },
        resources: { type: Array, default: () => [] },
    },
    emits: ["dataUpdated", "cancel"],

    data() {
        return {
            item: {
                res_quantity: {},
                agents: this.agents,
                resources: [],
                conductor: null,
            },
            res_quantity: {},
            value: null,
            options: ["list", "of", "options"],
        };
    },
    methods: {
        limitText(count) {
            return `et ${count} autre(s) ressource(s)`;
        },
        updateData(info) {
            const data = { ...(info || this.item) };
            data.resources = this.res_quantity;

            this.$emit("dataUpdated", data);
        },
        resourceSelected(option, id) {
            const resInCar = this.car.resources.find((x) => x.id == option.id);
            const res = this.item.resources.find((x) => x.id == option.id);

            this.res_quantity[option.id] =
                resInCar?.remaining_quantity || option?.remaining_quantity || 0;
            this.item.res_quantity = this.res_quantity;
            this.updateData();
        },
        agentsSelected(option, id) {
            this.$emit("dataUpdated", this.item);
        },
        conductorSelected(option, id) {
            this.item.conductor = option;

            this.$emit("dataUpdated", this.item);
        },
        conductorRemoved(option, id) {
            this.item.conductor = null;
            this.$emit("dataUpdated", this.item);
        },
        agentRemoved(option, id) {
            this.item.agents = [...this.item.agents].filter((x) => x.id !== id);
            this.updateData(this.item);
        },
        resourceRemoved(option, id) {
            this.item.res_quantity[option.id] = 0;
            delete this.res_quantity[option.id];
            // delete this.item.resources = this.res_quantity

            this.updateData(this.item);
        },
        updateQuantity(index) {
            const resource = this.item.resources[index];
            const quantity =
                parseInt(event.target.value) > resource.remaining_quantity
                    ? resource.remaining_quantity
                    : parseInt(event.target.value);
            this.res_quantity[resource.id] = quantity;
            this.item.resources[index].quantity = quantity;
            this.item.res_quantity = this.res_quantity;
            this.updateData();
        },
    },
    computed: {
        car() {
            return this.ambulance;
        },
        selectedAgents() {
            return this.item.agents.map((x) => x.id);
        },
        selectedConductor() {
            return this.item.conductor ? this.item.conductor.id : null;
        },
    },
    mounted() {
        this.item.agents = [...(this.car?.agents || [])]
            .filter((x) => {
                if (
                    x.roles.length == 1 &&
                    (x.roles || []).map((r) => r.slug).includes("agent")
                ) {
                    return true;
                }
                return false;
            })
            .map((x) => ({
                id: x.id,
                name: x.full_name,
                image: x.avatar,
            }));
        this.item.conductor = {
            image: this.car?.conductor?.avatar,
            id: this.car?.conductor?.id,
            name: this.car?.conductor?.full_name,
        };
        this.resources.map(
            (x) => (this.res_quantity[x?.id] = x?.quantity || 0)
        );
        this.item.resources = [...this.resources];

        //   console.log(this.item);
    },
};
</script>
