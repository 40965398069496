<template>
    <Modal size="lg" v-if="isVisible" @close="isVisible = false">
        <template #body>
            <div class="flex justify-center mb-5">
                <i class="la la-exclamation-circle big-text text-warning"></i>
            </div>
            <p
                class="text-xl text-center leading-relaxed text-black dark:text-gray-400"
            >
                {{ message }}
            </p>
        </template>
        <template #footer>
            <div class="flex justify-end">
                <button
                    @click="closeModal"
                    type="button"
                    :disabled="disabledButtons"
                    class="mr-2 text-danger font-semibold bg-opacity-25 hover:bg-opacity-50 bg-danger hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg text-sm font-medium px-5 py-2.5 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600"
                >
                    Fermer
                </button>
                <button
                    @click="handleSubmit"
                    type="button"
                    :disabled="disabledButtons"
                    class="text-success font-semibold bg-opacity-25 hover:bg-opacity-50 bg-success hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                    Oui
                </button>
            </div>
        </template>
    </Modal>
</template>

<script>
import { watch, ref } from "vue";
import { Modal } from "flowbite-vue";
export default {
    components: {
        Modal,
    },
    props: {
        isVisible: { type: Boolean },
        id: { type: String },
        message: { type: String },
        type: { type: String, default: "warning" },
    },
    emits: ["close", "submit", "cancel"],
    setup(props, { emit }) {
        const modalId = ref(null);

        const disabledButtons = ref(false);

        watch(
            () => props.isVisible,
            (visible) => {
                if (visible) open();
                else closeModal();
            }
        );

        const open = () => {};

        const closeModal = () => {
            // disabledButtons.value = true

            emit("close");
        };

        const handleSubmit = () => {
            // disabledButtons.value = true
            emit("submit");
            closeModal();
        };

        return { modalId, disabledButtons, closeModal, open, handleSubmit };
    },
};
</script>

<style lang="scss">
.big-text {
    font-size: 6em;
}
</style>
