import "./bootstrap";

import { createSSRApp, h } from "vue";
import { createInertiaApp } from "@inertiajs/vue3";

import VuePhoneNumberInput from "@/components/PhoneInput/index.vue";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import "@babel/polyfill";
// import 'flowbite';
import router from "./router";
import store from "./store";
import i18n from "./lang";
import acl from "./acl"; // import the instance of the defined ACL
import "./plugins";
import "aos/dist/aos.css"; // You can also use <link> for styles
import ResourceTypeForm from "@/components/resource-type-form.vue";
import AmbulanceEditForm from "@/components/ambulance-edit.vue";
import FileInput from "@/components/FileInput/index.vue";
import Modal from "@/components/Modal/index.vue";
import Popup from "@/components/Modal/popup.vue";
import Multiselect from "@/components/Multiselect/index.vue";
import Tabs from "@/components/Tab/index.vue";
import "../sass/tailwind.scss";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import Datepicker from "@/components/Datepicker/index.vue";
import GMap from "@/components/maps/gmap.vue";
import DoctorForm from "@/components/doctor-form.vue";
import DoctorComplete from "@/components/doctor/complete.vue";
import RegisterForm from "./views/auth/register-form.vue";
import Login from "@/components/login.vue";
import Reset from "@/components/reset.vue";
import Otp from "@/components/otp.vue";
import NewPassword from "@/components/password.vue";
import AppMenu from "@/components/auth/menu.vue";
import AppSidebar from "@/components/auth/sidebar.vue";
import AppBar from "@/components/auth/normal-bar.vue";
import AppTopbar from "@/components/auth/topbar.vue";
import Dashboard from "./views/index.vue";
import Unauthorized from "./views/auth/403.vue";
import Home from "@/components/home/index.vue";
import Icon from "@/components/Icon/index.vue";
import vSelect from "@/components/Select/index.vue";
import Pagination from "@/components/Pagination/index.vue";
import Toast from "vue-toastification";
import PToast from "primevue/toast";
import Search from "@/views/search/index.vue";
import UserBar from "@/components/auth/userbar.vue";
import PatientApp from "@/views/Account/index.vue";
import Contact from "@/views/Account/contact.vue";
import AppointmentSuccess from "@/views/search/success.vue";
import PraticianDetails from "@/views/search/pratician-details.vue";
import AnonymousAppointment from "@/views/Account/anonymous.vue";
import "vue-toastification/dist/index.css";
import "element-plus/dist/index.css";
import moment from "moment/moment";
import PrimeVue from "primevue/config";
import CountryFlag from "vue-country-flag-next";
import ToastService from "primevue/toastservice";
import ConfirmationService from "primevue/confirmationservice";
import Tooltip from "primevue/tooltip";
import VueSocialSharing from "vue-social-sharing";
import { usePassThrough } from "primevue/passthrough";
import Tailwind from "primevue/passthrough/tailwind";
import "primevue/resources/themes/lara-light-indigo/theme.css";
import { ZiggyVue } from "ziggy";
import { Ziggy } from "./ziggy";

createInertiaApp({
    resolve: (name) => {
        const pages = import.meta.glob("./Pages/**/*.vue", { eager: true });
        return pages[`./Pages/${name}.vue`];
    },
    setup({ el, App, props, plugin, route }) {
        moment.locale("fr");

        const app = createSSRApp({ render: () => h(App, props) });

        // app.config.globalProperties.$route = route;

        app.use(plugin)
            .use(ZiggyVue, Ziggy)
            .use(store)
            .use(router)
            .use(i18n)
            .mixin({ methods: { route } })
            .use(PrimeVue)
            .use(ToastService)
            .use(ConfirmationService)
            .use(acl)
            .use(VueSocialSharing)
            .use(Toast, {
                transition: "Vue-Toastification__fade",
                maxToasts: 20,
                newestOnTop: true,
                hideProgressBar: true,
            })
            .use(VueGoogleMaps, {
                load: {
                    autoBindAllEvents: true,
                    key: "AIzaSyCdWjvZ0QQqaJBp5iUSEyohBcEjXk8LuOg",
                    libraries: "places,drawing", // This is required if you use the Autocomplete plugin
                    v: "3.26",
                },
                autoBindAllEvents: true,
            })
            .directive("tooltip", Tooltip)
            .component("phone-number-input", VuePhoneNumberInput)
            .component("multiselect", Multiselect)
            .component("modal", Modal)
            .component("country-flag", CountryFlag)
            .component("popup", Popup)
            .component("tabs", Tabs)
            .component("date-picker", Datepicker)
            .component("resource-type-form", ResourceTypeForm)
            .component("ambulance-edit-form", AmbulanceEditForm)
            .component("file-input", FileInput)
            .component("v-select", vSelect)
            .component("map-google", GMap)
            .component("doctor-form", DoctorForm)
            .component("doctor-complete", DoctorComplete)
            .component("register-form", RegisterForm)
            .component("login", Login)
            .component("reset", Reset)
            .component("otp", Otp)
            .component("search", Search)
            .component("patient-app", PatientApp)
            .component("PToast", PToast)
            .component("anonymous-appointment", AnonymousAppointment)
            .component("pratician-details", PraticianDetails)
            .component("appointment-success", AppointmentSuccess)
            .component("new-password", NewPassword)
            .component("mobile-menu", AppMenu)
            .component("topbar", AppTopbar)
            .component("sidebar", AppSidebar)
            .component("app-contact", Contact)
            .component("app-bar", AppBar)
            .component("user-bar", UserBar)
            .component("dashboard", Dashboard)
            .component("pagination", Pagination)
            .component("home-page", Home)
            .component("icon", Icon)
            .mount(el);
    },
});
