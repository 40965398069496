<template>
    <div class="bg-white p-3 mb-10">
        <div>
            <div
                v-if="carPositionError"
                class="bg-warning rounded-sm px-3 py-2 text-white mb-3"
            >
                Veuillez entrer la position de l'<router-link
                    class="underline"
                    :to="{ name: 'config.cars' }"
                    >ambulance</router-link
                >
            </div>

            <div
                v-if="hospitalPositionError"
                class="bg-warning rounded-sm px-3 py-2 text-white"
            >
                Veuillez entrer la position de l'<router-link
                    class="underline"
                    :to="{ name: 'config.hospitals' }"
                    >Hôpital</router-link
                >
            </div>
        </div>
        <form>
            <div class="w-full py-3">
                <div class="flex">
                    <div class="mb-2 w-1/2">
                        <label
                            class="block capitalize tracking-wide text-left text-xs mb-1"
                            for="ambulance"
                        >
                            Ambulance
                        </label>
                        <Field
                            v-slot="{ field }"
                            name="ambulance"
                            v-model="form.ambulance"
                        >
                            <Select
                                v-bind="field"
                                v-model="form.ambulance"
                                name="ambulance"
                                id="ambulance"
                                :options="
                                    cars.map((x) => ({
                                        ...x,
                                        name: `${x.brand}/${x.registration}`,
                                    }))
                                "
                            >
                            </Select>
                        </Field>
                    </div>

                    <div class="mb-2 w-1/2 ml-2">
                        <label
                            class="block capitalize tracking-wide text-left text-xs mb-1"
                            for="ambulance"
                        >
                            Hôpital
                        </label>
                        <Field
                            v-slot="{ field }"
                            name="hospital"
                            v-model="form.hospital"
                        >
                            <Select
                                v-bind="field"
                                v-model="form.hospital"
                                placeholder="Hopital"
                                name="hospital"
                                id="hospital"
                                :options="hospitals"
                            >
                            </Select
                        ></Field>
                    </div>
                </div>
                <div>
                    <div class="mb-2 w-100">
                        <label
                            class="block capitalize tracking-wide text-left text-xs mb-1"
                            for="grid-first-name"
                        >
                            Description
                        </label>
                        <Field
                            v-slot="{ field }"
                            name="description"
                            v-model="form.description"
                        >
                            <textarea
                                name="description"
                                v-bind="field"
                                class="w-full bg-white rounded border border-slate-300 h-20 w-full px-2 focus:border-slate-400 transition transition-all resize-none"
                                id="textarea"
                                v-model="form.description"
                                placeholder="Plus d'informations sur l'intervention ?"
                            ></textarea>
                        </Field>
                    </div>

                    <div class="my-3">
                        <div
                            class="flex items-center pretty p-default p-thick p-pulse"
                        >
                            <Field
                                v-slot="{ field }"
                                name="isUnique"
                                type="checkbox"
                                :unchecked-value="false"
                                :value="true"
                                v-model="form.isUnique"
                            >
                                <input
                                    v-bind="field"
                                    name="isUnique"
                                    type="checkbox"
                                    :value="true"
                                />
                            </Field>
                            <div class="state p-warning-o">
                                <label class="ml-2"
                                    >Intervention unique sur cette alerte</label
                                >
                            </div>
                        </div>
                    </div>
                    <div class="flex justify-end">
                        <button
                            size="sm"
                            class="border border-danger text-danger w-1/2 p-2 rounded"
                            @click.prevent="cancel()"
                        >
                            Fermer
                        </button>
                        <button
                            :disabled="isDisabled"
                            v-if="form.hospital?.id && form.ambulance?.id"
                            @click.prevent="
                                handleIntervention(
                                    isEditing
                                        ? 'editIntervention'
                                        : 'createIntervention'
                                )
                            "
                            :class="`rounded bg-${
                                isDisabled ? 'slate-200' : 'primary'
                            } border-0 text-primary w-full p-2 ml-2 bg-opacity-25`"
                        >
                            {{
                                `${
                                    isEditing
                                        ? "Modifier cette intervention"
                                        : "Lancer l'intervention"
                                }`
                            }}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { ref, computed, watch, onMounted } from "vue";
import { Field, useForm } from "vee-validate";
import Select from "../../components/Select/index.vue";
import * as yup from "yup";
import { useStore } from "vuex";
export default {
    components: { Field, Select },
    props: {
        intervention: { type: Object, default: null },
        interventionCreated: { type: Boolean, default: false },
    },
    emits: ["updateParent"],
    setup(props, { emit }) {
        const store = useStore();
        const currentAlert = computed(() => store.getters["medical/alert"]);
        const hospitals = computed(() => store.getters["medical/hospitals"]);
        const cars = computed(() => store.getters["config/cars"]);
        const isEditing = ref(false);

        const carPositionError = ref(false);
        const hospitalPositionError = ref(false);
        const form = ref({
            hospital: null,
            ambulance: null,
            description: null,
            hospital: null,
            isUnique: true,
        });

        const interventionSchema = yup.object({
            id: yup.number().nullable().default(0),
            ambulance: yup
                .object({ id: yup.string() })
                .required("L'ambulance est obligatoire"),
            hospital: yup
                .object({ id: yup.string() })
                .required("L'hôpital est obligatoire"),
            isUnique: yup.boolean().default(true),
        });
        const { values, meta, handleReset, setValues, isSubmitting } = useForm({
            validationSchema: interventionSchema,
        });
        const isDisabled = computed(
            () =>
                carPositionError.value ||
                hospitalPositionError.value ||
                isSubmitting.value
        );
        const cancel = () => {
            handleReset();
            emit("updateParent", "interventions");
        };

        const handleIntervention = (action) => {
            emit("updateParent", action, {
                ...form.value,
                intervention_id: props.intervention?.id,
            });
        };

        const editIntervention = () => {
            emit("updateParent", "editIntervention");
        };

        watch(
            () => values.ambulance,
            (newVal) => {
                carPositionError.value =
                    newVal &&
                    (!newVal?.current_latitude || !newVal?.current_longitude);
                emit("updateParent", "ambulanceChanged", newVal);
            }
        );

        watch(
            () => values.description,
            (newVal) => {
                emit("updateParent", "description", newVal);
            }
        );

        watch(
            () => values.hospital,
            (newVal) => {
                const [addr] = newVal?.addresses ?? [];
                console.log("tresrr", addr, addr && !addr?.address);
                hospitalPositionError.value = (addr && !addr?.address) || !addr;
                emit("updateParent", "hospitalChanged", newVal);
            }
        );

        watch(
            () => values.isUnique,
            (newVal) => {
                emit("updateParent", "isUnique", newVal);
            }
        );

        watch(
            () => props.interventionCreated,
            (val) => {
                isEditing.value = false;
                if (val) handleReset();
            }
        );

        watch(
            () => props.intervention,
            (val) => {
                setValues({
                    id: val?.id,
                    isUnique: true,
                    description: val?.description,
                    ambulance: cars.value.find((x) => x.id === val.car_id),
                    hospital: hospitals.value.find(
                        (x) => x.id === val.hospital_id
                    ),
                });
                isEditing.value = true;
            }
        );

        return {
            form,
            isEditing,
            values,
            meta,
            isDisabled,
            currentAlert,
            hospitals,
            cars,
            hospitalPositionError,
            carPositionError,
            cancel,
            handleIntervention,
            editIntervention,
        };
    },
};
</script>
