<template>
    <div class="relative searchable-list">
        <input
            ref="elm"
            type="text"
            class="font-qs text-slate-600 peer w-full h-10 rounded-sm cursor-pointer outline-none pl-2 pr-7 transition-all duration-300 text-md text-overflow-ellipsis"
            spellcheck="false"
            style="border: none !important"
            :placeholder="placeholder"
            v-model="search"
        />

        <ul
            ref="listEl"
            style="width: 200px !important; border: none !important"
            class="absolute drop-shadow-md bg-slate-300 z-20 border border-slate-300 option-list overflow-y-scroll max-h-64 min-h-[0px] flex flex-col top-12 left-0 max-w-[120%] min-w-[120%] bg-white rounded-sm scale-0 opacity-0 transition-all duration-200 origin-top-left"
        ></ul>

        <!-- <AutoComplete v-model="value" :suggestions="items" @complete="search" /> -->
    </div>
</template>
<script>
import AutoComplete from "primevue/autocomplete";
import { onMounted, ref, watch } from "vue";
export default {
    components: { AutoComplete },
    props: {
        placeholder: {
            type: String,
        },
        options: {
            type: Array,
        },
    },
    emits: ["update"],
    setup(props, { emit }) {
        const domParser = typeof window != "undefined" ? new DOMParser() : null;
        const elm = ref(null);
        const search = ref("");
        const listEl = ref(null);
        const arrow = ref(null);
        const value = ref("");
        const items = ref([]);

        // const search = (event) => {
        //     items.value = [...Array(10).keys()].map(
        //         (item) => event.query + "-" + item
        //     );
        // };
        const dataList = {
            currentValue: null,
            listOpened: false,
            optionTemplate: `
            <li
    			class='data-option select-none break-words inline-block text-sm text-gray-500 bg-gray-100 odd:bg-gray-200 hover:bg-gray-300 hover:text-gray-700 transition-all duration-200 font-bold p-3 cursor-pointer max-w-full '>
    				[[REPLACEMENT]]
            </li>
            `,
            optionElements: [],
            options: [],
            find(str) {
                for (let i = 0; i < dataList.options.length; i++) {
                    const option = dataList.options[i];
                    if (!option.toLowerCase().includes(str.toLowerCase())) {
                        dataList.optionElements[i].classList.remove("block");
                        dataList.optionElements[i].classList.add("hidden");
                    } else {
                        dataList.optionElements[i].classList.remove("hidden");
                        dataList.optionElements[i].classList.add("block");
                    }
                }
            },
            remove(value) {
                const foundIndex = dataList.options.findIndex(
                    (v) => v === value
                );
                if (foundIndex !== -1) {
                    listEl.value.removeChild(
                        dataList.optionElements[foundIndex]
                    );
                    dataList.optionElements.splice(foundIndex, 1);
                    dataList.options.splice(value, 1);
                }
            },
            append(value) {
                if (
                    !value ||
                    typeof value === "object" ||
                    typeof value === "symbol" ||
                    typeof value === "function"
                )
                    return;
                value = value.toString().trim();
                if (value.length === 0) return;
                if (dataList.options.includes(value)) return;

                const html = dataList.optionTemplate.replace(
                    "[[REPLACEMENT]]",
                    value
                );
                const targetEle = domParser
                    ?.parseFromString(html, "text/html")
                    .querySelector("li");
                targetEle.innerHTML = targetEle.innerHTML.trim();
                targetEle.classList.add("text-slate-600");
                targetEle.classList.add("font-normal");
                targetEle.classList.add("hover:bg-slate-100");
                listEl.value.appendChild(targetEle);
                dataList.optionElements.push(targetEle);
                dataList.options.push(value);

                if (!dataList.currentValue) dataList.setValue(value);

                targetEle.onmousedown = (e) => {
                    dataList.optionElements.forEach((el, index) => {
                        if (e.target === el) {
                            dataList.setValue(dataList.options[index]);
                            dataList.hideList();
                            return;
                        }
                    });
                };
            },
            setValue(value) {
                elm.value.value = value;
                dataList.currentValue = value;
                search.value = value;
                console.log("setValue");
            },
            showList() {
                listEl.value.classList.remove("scale-0");
                listEl.value.classList.remove("opacity-0");
                dataList.listOpened = true;

                listEl.value.classList.add("opacity-100");
                listEl.value.classList.add("scale-100");
                // arrow.value.classList.add("rotate-0");
            },
            hideList() {
                dataList.listOpened = false;
                listEl.value.classList.remove("opacity-100");
                listEl.value.classList.remove("scale-100");
                // arrow.value.classList.remove("rotate-0");
                listEl.value.classList.add("scale-0");
                listEl.value.classList.add("opacity-0");
            },
            init() {
                elm.value.oninput = (e) => {
                    dataList.find(e.target.value);
                };
                elm.value.onclick = (el) => {
                    dataList.showList();
                    for (let el of dataList.optionElements) {
                        el.classList.remove("hidden");
                    }
                };
                elm.value.onblur = (e) => {
                    dataList.hideList();
                    dataList.setValue(dataList.currentValue);
                };
            },
        };

        watch(
            () => search.value,
            (val) => {
                const opt = props.options.find((x) => x.name == val);

                emit("update", opt);
            }
        );
        watch(
            () => props.options,
            (val) => {
                const data = [...props.options.map((x) => x.name)];

                data.forEach((v) => dataList.append(v));
            }
        );

        onMounted(() => {
            if (typeof window != "undefined") dataList.init();

            const data = [...props.options.map((x) => x.name)];

            data.forEach((v) => dataList.append(v));
        });

        return {
            listEl,
            elm,
            items,
            arrow,
            value,
            search,
        };
    },
};
</script>
