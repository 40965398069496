<template>
    <div class="flex flex-col mt-2">
        <div class="flex items-center">
            <div
                @click="goBack"
                class="bg-white px-3 py-2 rounded cursor-pointer"
            >
                <i class="la la-arrow-left font-bold"></i>
            </div>

            <p class="px-2 font-semibold">{{ selectedPortolio?.name }}</p>
        </div>
        <div class="mt-4 bg-white px-3 py-2 rounded" v-if="selectedPortolio">
            <div class="flex py-4">
                <div class="w-2/5">
                    <label class="font-semibold" for="name">Nom</label>
                    <Field v-slot="{ field }" name="name" v-model="name">
                        <input
                            type="text"
                            id="name"
                            v-bind="field"
                            placeholder="Nom "
                            class="w-full border rounded-sm focus:outline-none py-2 px-3 text-grey-darker"
                    /></Field>
                </div>
                <div class="flex items-end w-3/5 justify-end">
                    <button
                        class="ml-4 rounded bg-primary text-white px-3 py-2"
                        type="submit"
                        variant="primary"
                        @click="editPortfolioName"
                    >
                        Valider
                    </button>
                </div>
            </div>
        </div>
        <div class="mt-4 bg-white px-3 py-4 rounded" v-if="selectedPortolio">
            <div class="flex flex-col w-full">
                <h3 class="font-bold">
                    Configuration de téléphone
                    <el-tooltip content="" placement="top"
                        ><template #content>
                            Ces numeros sont utilisés pour <br />
                            valider une intervention quand vous <br />
                            n'étes pas en ligne.</template
                        >
                        <i class="la la-info-circle ml-2"></i>
                    </el-tooltip>
                </h3>
                <div class="flex w-1/2 mt-4">
                    <div class="flex flex-col w-4/5">
                        <label class="font-semibold" for="name"
                            >Téléphone 1</label
                        >
                        <div style="height: 38px" class="w-full">
                            <Field
                                v-slot="{ field }"
                                name="phone_1"
                                v-model="phones.phone_1"
                            >
                                <PhoneInput
                                    class="w-full"
                                    v-bind="field"
                                    name="phone_1"
                                    v-model="phones.phone_1"
                                    @phoneChanged="
                                        (number) => (phones.phone_1 = number)
                                    "
                            /></Field>
                        </div>
                    </div>
                    <div
                        v-if="validatingPhoneField === 'phone_1'"
                        class="w-1/5 pl-2"
                    >
                        <label class="font-semibold" for="name">Code</label>
                        <Field
                            v-slot="{ field }"
                            name="code_1"
                            v-model="code_1"
                        >
                            <input
                                type="text"
                                id="code_1"
                                v-bind="field"
                                placeholder="Code "
                                style="height: 38px"
                                class="w-full border rounded-sm focus:outline-none py-2 px-3 text-grey-darker"
                        /></Field>
                    </div>
                    <div class="flex items-end w-3/5 justify-end">
                        <button
                            :class="`rounded bg-${
                                selectedPortolio?.details
                                    ?.phone_1_verified_at &&
                                validatingPhoneField !== 'phone_1'
                                    ? 'success'
                                    : 'warning'
                            } text-white px-3 py-2`"
                            type="submit"
                            variant="primary"
                            @click="handlePhone('phone_1')"
                        >
                            {{
                                selectedPortolio?.details
                                    ?.phone_1_verified_at &&
                                validatingPhoneField !== "phone_1"
                                    ? "Changer"
                                    : "Valider"
                            }}
                        </button>
                        <button
                            class="ml-2 rounded bg-danger text-white px-3 py-2"
                            type="submit"
                            variant="primary"
                            @click="clearPhone('phone_1')"
                        >
                            Effacer
                        </button>
                    </div>
                </div>
                <div class="flex w-1/2 mt-3">
                    <div class="flex flex-col w-4/5">
                        <label class="font-semibold" for="name"
                            >Téléphone 2</label
                        >
                        <div style="height: 38px">
                            <Field
                                v-slot="{ field }"
                                name="phone_2"
                                v-model="phones.phone_2"
                            >
                                <PhoneInput
                                    v-bind="field"
                                    name="phone_2"
                                    v-model="phones.phone_2"
                                    @phoneChanged="
                                        (number) => (phones.phone_2 = number)
                                    "
                            /></Field>
                        </div>
                    </div>
                    <div
                        v-if="validatingPhoneField === 'phone_2'"
                        class="w-1/5 pl-2"
                    >
                        <label class="font-semibold" for="name">Code</label>
                        <Field
                            v-slot="{ field }"
                            name="code_2"
                            v-model="code_2"
                        >
                            <input
                                type="text"
                                id="code_2"
                                v-bind="field"
                                placeholder="Code "
                                style="height: 38px"
                                class="w-full border rounded-sm focus:outline-none py-2 px-3 text-grey-darker"
                        /></Field>
                    </div>
                    <div class="flex items-end w-3/5 justify-end">
                        <button
                            :class="`rounded bg-${
                                selectedPortolio?.details?.phone_2 &&
                                validatingPhoneField !== 'phone_2'
                                    ? 'success'
                                    : 'warning'
                            } text-white px-3 py-2`"
                            type="submit"
                            variant="primary"
                            @click="handlePhone('phone_2')"
                        >
                            {{
                                selectedPortolio?.details
                                    ?.phone_2_verified_at &&
                                validatingPhoneField !== "phone_2"
                                    ? "Changer"
                                    : "Valider"
                            }}
                        </button>

                        <button
                            class="ml-2 rounded bg-danger text-white px-3 py-2"
                            type="submit"
                            variant="primary"
                            @click="clearPhone('phone_2')"
                        >
                            Effacer
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div></div>
    </div>
</template>
<script>
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import PhoneInput from "@/components/PhoneInput";
import { ElTooltip } from "element-plus";
import { Field } from "vee-validate";
export default {
    components: { Field, ElTooltip, PhoneInput },
    setup() {
        const store = useStore();
        const route = useRoute();
        const router = useRouter();
        const currentField = ref(null);
        const validatingPhoneField = ref(null);
        const code_1 = ref(null);
        const code_2 = ref(null);
        const name = ref(null);
        const selectedPortolio = computed(
            () => store.getters["config/portfolio"]
        );
        const phones = ref({});

        const getPortfolio = () => {
            store.dispatch("config/getPortfolio", {
                id: route.params.portfolioId,
                onSuccess: () => {},
            });
        };

        const editPortfolioName = () => {
            store.dispatch("config/editPortfolio", {
                id: route.params.portfolioId,
                data: { name: name.value },
                onSuccess: () => {
                    getPortfolio();
                },
            });
        };

        const clearPhone = (field) => {
            store.dispatch("config/clearPortfolioPhone", {
                id: route.params.portfolioId,
                data: {
                    field,
                    value: phones.value[field]
                        ?.replaceAll(" ", "")
                        ?.replaceAll("+", ""),
                },
                onSuccess: () => {
                    getPortfolio();
                },
            });
        };

        const goBack = () => {
            router.push({ name: "account.portfolio" });
        };

        const handlePhone = (field) => {
            currentField.value = field;

            if (validatingPhoneField.value === field) {
                store.dispatch("config/validatePortfolioPhone", {
                    id: route.params.portfolioId,
                    data: {
                        code: field == "phone_1" ? code_1.value : code_2.value,
                        field,
                        value: phones.value[field]
                            ?.replaceAll(" ", "")
                            ?.replaceAll("+", ""),
                    },
                    onSuccess: () => {
                        getPortfolio();
                        validatingPhoneField.value = null;
                        code_1.value = null;
                        code_2.value = null;
                    },
                    onError: () => {},
                });
                return;
            }

            store.dispatch("config/setPortfolioPhone", {
                id: route.params.portfolioId,
                data: {
                    field,
                    value: phones.value[field]
                        ?.replaceAll(" ", "")
                        ?.replaceAll("+", ""),
                },
                onSuccess: () => {
                    validatingPhoneField.value = field;
                },
                onError: () => {},
            });
        };

        watch(
            () => selectedPortolio.value,
            () => {
                phones.value.phone_1 = selectedPortolio.value?.details?.phone_1
                    ? `+${selectedPortolio.value?.details?.phone_1}`
                    : "";
                phones.value.phone_2 = selectedPortolio.value?.details?.phone_2
                    ? `+${selectedPortolio.value?.details?.phone_2}`
                    : "";

                name.value = selectedPortolio.value?.name;
            }
        );

        onMounted(() => {
            getPortfolio();
        });

        return {
            code_2,
            code_1,
            validatingPhoneField,
            phones,
            name,
            selectedPortolio,
            handlePhone,
            clearPhone,
            goBack,
            editPortfolioName,
        };
    },
};
</script>
