
import { hereMapApiKey } from '@/services/config';
import axios from "./axios";
const hospitals = 'hospital-health-care-facility';

const platform = typeof window !== 'undefined' ? new H.service.Platform({
    'apikey': hereMapApiKey,
    useHTTPS: false,
}) : null;

export const discoverHospitals = async (lat, lng, query) => {
    const url = `https://discover.search.hereapi.com/v1/discover?at=${lat},${lng}&q=${hospitals}&apiKey=${hereMapApiKey}`;
    const response = await axios.get(url);
    return response.data;
}

export const calculateRoute = async (depart, arrival) => {
    // axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    const url = `https://route.ls.hereapi.com/routing/7.2/calculateroute.json?apiKey=${hereMapApiKey}&waypoint0=geo!${depart}&waypoint1=geo!${arrival}&mode=fastest;car;traffic:disabled`;
    const response = await axios.get(url);
    return response.data;
}

export const reverseGeocode = (lat, lng) => {
    // return new Promise()
    const service = platform.getSearchService();
    return service.reverseGeocode({
        at: `${lat},${lng},150`
    });
}
