<template>
    <div class="w-full my-6">
        <div>
            <div md="12">
                <div class="rounded-sm bg-white flex flex-col p-5 mb-5">
                    <div
                        class="flex flex-row justify-between items-center mb-3 w-full"
                    >
                        <div>
                            <h4 class="card-title font-semibold text-slate-800">
                                Les Types de données
                            </h4>
                        </div>
                        <button
                            class="flex justify-center items-center text-sm border-none font-semibold rounded-sm bg-opacity-25 hover:bg-opacity-50 bg-doto-100 text-doto-100 px-2 py-2"
                            @click.prevent="displayModal"
                        >
                            <icon name="plus"></icon>
                            Ajouter un nouveau
                        </button>
                    </div>
                    <div>
                        <div>
                            <div md="12" class="table-responsive">
                                <table
                                    striped
                                    outlined
                                    class="table table-striped"
                                    hover
                                >
                                    <thead>
                                        <tr>
                                            <th
                                                class="whitespace-nowrap font-medium text-sm"
                                            >
                                                Nom
                                            </th>
                                            <th
                                                class="whitespace-nowrap font-medium text-sm"
                                            >
                                                Section
                                            </th>
                                            <th
                                                class="whitespace-nowrap font-medium text-sm"
                                            >
                                                Description
                                            </th>
                                            <th class="whitespace-nowrap"></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr
                                            v-for="rType in recordTypes"
                                            :key="rType.id"
                                        >
                                            <td class="text-sm text-slate-700">
                                                <span
                                                    :class="`${rType.icon_class}`"
                                                ></span>
                                                {{ rType.name }}
                                                <span v-if="rType.data_unity"
                                                    >({{
                                                        rType.data_unity
                                                    }})</span
                                                >
                                            </td>
                                            <td class="text-sm text-slate-700">
                                                {{ rType?.section_type }}
                                            </td>
                                            <td class="text-sm text-slate-700">
                                                {{ rType.description }}
                                            </td>
                                            <td>
                                                <div class="flex justify-end">
                                                    <button
                                                        class="bg-warning mr-1 text-sm px-2 py-1 text-warning rounded bg-opacity-25 hover:bg-opacity-50"
                                                        size="sm"
                                                        @click="
                                                            handleTypeSelection(
                                                                rType
                                                            )
                                                        "
                                                    >
                                                        <i
                                                            class="las la-pen m-0"
                                                        ></i>
                                                    </button>
                                                    <button
                                                        class="px-2 text-sm py-1 bg-danger text-danger rounded bg-opacity-25 hover:bg-opacity-50"
                                                        size="sm"
                                                        @click="
                                                            handleRemoveRecordType(
                                                                rType.id
                                                            )
                                                        "
                                                    >
                                                        <i
                                                            class="la la-trash m-0"
                                                        ></i>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex justify-end py-3 text-sm">
                    <pagination
                        v-model="pagination.current_page"
                        :pagination="recordTypesPagination"
                        @changed="getData"
                    ></pagination>
                </div>
            </div>
        </div>
        <SampleModal
            :is-visible="showNewModal"
            @close="handleNewRType"
            @cancel="handleNewRType"
            @submit="isEditing ? handleEdit() : handleCreate(form)"
            centered
            id="new-car"
            :title="
                isEditing
                    ? 'Mise à jour d\'un type de donnée'
                    : 'Nouveau(x) type(x) de donnée(x)'
            "
        >
            <div class="w-full h-full overflow-y-scroll">
                <form novalidate>
                    <div
                        :class="`my-2  ${
                            isEditing ? '' : 'border border-slate-300 p-3'
                        } rounded relative`"
                        v-for="(item, index) in fields"
                        :key="index"
                    >
                        <div class="absolute top-1 right-0 p-2">
                            <button
                                v-if="!isEditing && index !== 0"
                                class="px-2 py-1 bg-danger rounded text-white"
                                size="sm"
                                @click="remove(index)"
                            >
                                <i class="la la-trash m-0"></i>
                            </button>
                        </div>
                        <div class="flex">
                            <div class="mb-2 w-1/2 mr-2">
                                <label
                                    class="block capitalize tracking-wide text-slate-500 text-xs font-bold mb-1 text-left"
                                    for="grid-state"
                                >
                                    La section d'affichage
                                </label>
                                <Field
                                    :name="`types[${index}].section_type`"
                                    v-slot="{ field }"
                                    v-model="form[index]['section_type']"
                                >
                                    <div class="h-10">
                                        <input
                                            type="text"
                                            v-bind="field"
                                            list="sections"
                                            id="section_type"
                                            :name="`types[${index}].section_type`"
                                            placeholder="Entre ou selectionner une section"
                                            class="bg-white rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                        />
                                        <datalist id="sections">
                                            <option
                                                v-for="m in recordSections"
                                                :key="m.section_type_slug"
                                                :value="m.section_type"
                                            >
                                                {{ m.section_type }}
                                            </option>
                                        </datalist>
                                    </div>
                                </Field>

                                <span class="text-danger text-left pt-1">{{
                                    errors.section_type
                                }}</span>
                            </div>
                            <div class="mb-2 w-1/2">
                                <label
                                    class="block capitalize tracking-wide text-slate-500 text-xs font-bold mb-1 text-left"
                                    for="grid-state"
                                >
                                    Nom de la ressource {{ form.length }}
                                </label>
                                <div class="h-10">
                                    <Field
                                        :name="`types[${index}].name`"
                                        v-slot="{ field }"
                                        v-model="form[index].name"
                                    >
                                        <input
                                            type="text"
                                            id="name"
                                            v-bind="field"
                                            :name="`types[${index}].name`"
                                            placeholder="Entre le nom de la ressource"
                                            class="bg-white rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                        />
                                    </Field>
                                </div>
                                <span class="text-danger text-left pt-1">{{
                                    errors.name
                                }}</span>
                            </div>
                        </div>
                        <div class="flex">
                            <div class="mb-2 w-1/2 mr-2">
                                <label
                                    class="block capitalize tracking-wide text-slate-500 text-xs font-bold mb-1 text-left"
                                    for="grid-state"
                                >
                                    Icône
                                </label>
                                <div class="flex items-center h-10">
                                    <Field
                                        :name="`types[${index}].icon_class`"
                                        v-slot="{ field }"
                                        v-model="form[index].icon_class"
                                    >
                                        <input
                                            placeholder="Icon"
                                            v-bind="field"
                                            v-model="form[index].icon_class"
                                            :name="`types[${index}].icon_class`"
                                            focus
                                            class="bg-white rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                        />
                                    </Field>
                                    <i
                                        v-if="item.icon_class"
                                        :class="`${item.icon_class} mx-1 text-lg`"
                                    ></i>
                                </div>
                                <span class="text-danger text-left pt-1">{{
                                    errors.icon_class
                                }}</span>
                            </div>
                            <div class="flex w-1/2">
                                <div
                                    :class="`mb-2 w-${
                                        item.data_type?.id === 'number'
                                            ? '1/2'
                                            : 'full'
                                    }`"
                                >
                                    <label
                                        class="block tracking-wide text-slate-500 text-xs font-bold mb-1 text-left"
                                        for="grid-state"
                                    >
                                        Le type de donnée
                                    </label>
                                    <div class="h-10">
                                        <Field
                                            :name="`types[${index}].data_type`"
                                            v-slot="{ field }"
                                            v-model="form[index].data_type"
                                        >
                                            <Select
                                                class="w-full"
                                                v-bind="field"
                                                :name="`types[${index}].data_type`"
                                                v-model="form[index].data_type"
                                                :options="dataTypes"
                                                @selected="
                                                    ($event) =>
                                                        (form[index].data_type =
                                                            $event)
                                                "
                                            />
                                        </Field>
                                    </div>
                                    <span class="text-danger text-left pt-1">{{
                                        errors.data_type
                                    }}</span>
                                </div>

                                <div
                                    class="mb-2 w-1/2 pl-2"
                                    v-if="item.data_type?.id === 'number'"
                                >
                                    <label
                                        class="block capitalize tracking-wide text-slate-500 text-xs font-bold mb-1 text-left"
                                        for="data_unity"
                                    >
                                        L'unité
                                    </label>
                                    <div class="h-10">
                                        <Field
                                            :name="`types[${index}].data_unity`"
                                            v-slot="{ field }"
                                            v-model="form[index].data_unity"
                                        >
                                            <input
                                                type="text"
                                                v-bind="field"
                                                id="data_unity"
                                                :name="`types[${index}].data_unity`"
                                                placeholder="Unité"
                                                class="bg-white rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                            />
                                        </Field>
                                    </div>
                                    <span class="text-danger text-left pt-1">{{
                                        errors.data_unity
                                    }}</span>
                                </div>
                            </div>
                        </div>

                        <div class="mb-2">
                            <label
                                class="block capitalize tracking-wide text-slate-500 text-xs font-bold text-left mb-1"
                                for="grid-state"
                            >
                                Description
                            </label>
                            <Field
                                :name="`types[${index}].description`"
                                v-slot="{ field }"
                                v-model="form[index].description"
                            >
                                <textarea
                                    v-bind="field"
                                    :name="`types[${index}].description`"
                                    no-resize
                                    id="exampleFormControlTextarea1"
                                    class="resize-none bg-white rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                    rows="4"
                                ></textarea>
                            </Field>
                        </div>
                    </div>

                    <div class="flex justify-end my-2 py-2">
                        <button
                            v-if="!isEditing"
                            type="button"
                            class="btn btn-outline-secondary text-sm w-20 mr-1 outline-none"
                            @click="addNewElement()"
                        >
                            Ajouter
                        </button>
                    </div>
                </form>
            </div>
        </SampleModal>

        <popup
            message="Êtes-vous sure de vouloir supprimer?"
            :isVisible="canDelete"
            @submit="removeRType"
            @cancel="canDelete = false"
            @close="canDelete = false"
        ></popup>
    </div>
    <ConfirmDialog></ConfirmDialog>
</template>

<script>
import store from "@/store";
import { mapGetters } from "vuex";
import { XIcon, PlusIcon } from "vue-feather-icons";
import { Form, Field, FieldArray, useForm, useFieldArray } from "vee-validate";
import * as yup from "yup";
import { computed, ref } from "@vue/runtime-core";
import Select from "@/components/Select/index.vue";
import SampleModal from "@/components/Modal/SampleModal.vue";
import { useConfirm } from "primevue/useconfirm";
import ConfirmDialog from "primevue/confirmdialog";
import { TYPE } from "vue-toastification";
export default {
    components: {
        XIcon,
        PlusIcon,
        Form,
        Field,
        FieldArray,
        Select,
        SampleModal,
        ConfirmDialog,
    },
    beforeRouteEnter(to, from, next) {
        store.dispatch("config/getAllCities", {});
        store.dispatch("config/getRecordTypes", {}).then((res) => next());
    },
    watch: {},
    data() {
        return {
            defaultValue: {
                name: "",
                icon_class: "las la-briefcase-medical",
                description: "",
                section_type: "",
                data_type: "text",
            },
            initialValues: {
                types: [
                    {
                        name: "",
                        icon_class: "las la-briefcase-medical",
                        description: "",
                        section_type: "",
                        data_type: "text",
                    },
                ],
            },
            types: [
                {
                    name: "",
                    icon_class: "las la-briefcase-medical",
                    description: "",
                    section_type: "",
                    data_type: "text",
                },
            ],
            models: [],
            car: {
                city_id: "",
                type: "a",
            },
        };
    },
    setup(props) {
        const confirm = useConfirm();
        const showNewModal = ref(false);
        const isEditing = ref(false);
        const canDelete = ref(false);
        const currentTypeId = ref();
        const dataTypes = computed(() => [
            { id: "text", name: "Texte" },
            { id: "number", name: "Nombre" },
            { id: "checkbox", name: "Vrai/Faux" },
        ]);
        const form = ref([{}]);
        const pagination = computed(
            () => store.getters["config/recordTypesPagination"]
        );
        const schema = computed(() =>
            yup.object({
                types: yup
                    .array()
                    .of(
                        yup.object({
                            name: yup.string().required(),
                            description: yup.string().required(),
                            section_type: yup.string().required(),
                            data_type: yup.string().required(),
                        })
                    )
                    .min(1),
            })
        );
        const { errors, values, meta } = useForm({
            validationSchema: schema.value,
            initialValues: {
                types: [
                    {
                        name: "",
                        icon_class: "las la-briefcase-medical",
                        description: "",
                        section_type: "",
                        data_type: "text",
                    },
                ],
            },
        });

        const { remove, push, replace, fields } = useFieldArray("types");

        const handleNewRType = () => {
            // addNewElement();

            showNewModal.value = false;
        };
        const handleTypeSelection = (data) => {
            const value = [
                {
                    name: data.name,
                    icon_class: data.icon_class,
                    description: data.description,
                    section_type: data.section_type,
                    data_type: { id: data.data_type },
                },
            ];
            currentTypeId.value = data.id;
            replace(value);
            form.value = value;
            // types.value = value;
            isEditing.value = true;
            showNewModal.value = true;
        };

        const addNewElement = () => {
            const value = {
                name: "",
                icon_class: "las la-briefcase-medical",
                description: "",
                section_type: "",
                data_type: "text",
            };
            console.log("log2");

            form.value.push(value);

            push(value);
        };

        const displayModal = () => {
            const value = [
                {
                    name: "",
                    icon_class: "las la-briefcase-medical",
                    description: "",
                    section_type: "",
                    data_type: "text",
                },
            ];
            form.value = [...value];
            replace(value);
            isEditing.value = false;
            showNewModal.value = true;
        };
        const getData = (page = 1) => {
            store.dispatch("config/getRecordTypes", { page });
        };
        const addNewRecordType = (form) => {
            store.dispatch("config/addRecordTypes", {
                types: form.types,
                onSuccess: () => {
                    getData();
                    handleNewRType();
                },
                onError: () => {},
            });
        };

        const handlePopup = (id) => {
            currentTypeId.value = id;
            canDelete.value = true;
        };

        const handleRemoveRecordType = (id) => {
            currentTypeId.value = id;

            confirm.require({
                message: "Êtes-vous sûr de vouloir supprimer ce type  ?",
                header: "Suppression",
                icon: "la la-exclamation-circle",
                rejectLabel: "Non",
                acceptLabel: "Oui",
                rejectClass:
                    "bg-danger text-danger py-1 text-sm text-medium px-2 mr-2 bg-opacity-25",
                acceptClass:
                    "bg-primary text-primary py-1 text-sm text-medium px-2  bg-opacity-25",
                accept: () => {
                    removeRType();
                },
                reject: () => {},
            });
        };

        const handleCreate = () => {
            const data = form.value.map((x) => ({
                ...x,
                description: x.description || "",
                data_type: x.data_type?.id ? x.data_type?.id : x.data_type,
            }));
            store.dispatch("config/addRecordTypes", {
                types: data,
                onSuccess: () => {
                    store.dispatch("medical/displayToast", {
                        message: "Type modifié avec succès.",
                        context: "Type de donnée",
                        type: "TYPE.SUCCESS",
                    });
                    currentTypeId.value = null;
                    form.value = [];
                    isEditing.value = false;
                    showNewModal.value = false;
                    getData();
                },
                onError: () => {
                    store.dispatch("medical/displayToast", {
                        message: "Oooops, une erreur est survenue.",
                        context: "Type de donnée",
                        type: "TYPE.ERROR",
                    });
                },
            });
        };

        const handleEdit = () => {
            let [type] = form.value;
            type.data_type = type.data_type?.id
                ? type.data_type?.id
                : type.data_type;
            store.dispatch("config/editRecordType", {
                id: currentTypeId.value,
                data: type,
                onSuccess: () => {
                    store.dispatch("medical/displayToast", {
                        message: "Type modifié avec succès.",
                        context: "Type de donnée",
                        type: "TYPE.SUCCESS",
                    });
                    currentTypeId.value = null;
                    form.value = [];
                    isEditing.value = false;
                    showNewModal.value = false;
                    getData();
                },
                onError: () => {
                    store.dispatch("medical/displayToast", {
                        message: "Oooops, une erreur est survenue.",
                        context: "Type de donnée",
                        type: "TYPE.ERROR",
                    });
                },
            });
        };

        const removeRType = () => {
            if (!currentTypeId.value) return;
            store.dispatch("config/removeRecordType", {
                id: currentTypeId.value,
                onSuccess: () => {
                    canDelete.value = false;
                },
                onError: () => {},
            });
        };

        return {
            errors,
            values,
            meta,
            isEditing,
            schema,
            remove,
            handleEdit,
            handleCreate,
            push,
            handleTypeSelection,
            canDelete,
            showNewModal,
            fields,
            pagination,
            form,
            dataTypes,
            removeRType,
            addNewRecordType,
            handlePopup,
            displayModal,
            handleRemoveRecordType,
            handleNewRType,
            addNewElement,
            getData,
        };
    },
    methods: {
        init() {},

        add() {
            for (let index = 0; index < this.types.length; index++) {
                const element = this.types[index];
                if (
                    element.name.length < 10 ||
                    element.icon_class.length < 3 ||
                    element.description.length < 10
                ) {
                    return;
                }
            }
            this.types.push({
                name: "",
                icon_class: "",
                description: "",
                section_type: "",
                data_type: "text",
            });
        },

        rRemoveCar(id) {
            this.$bvModal
                .msgBoxConfirm("Etes-vous sûr de vouloir supprimer ?", {
                    title: "Confirmation",
                    cancelTitle: "Fermer",
                    okTitle: "Supprimer",
                    size: "sm",
                    buttonSize: "sm",
                    okVariant: "success",
                    cancelVariant: "danger",
                    headerClass: "p-2 border-bottom-0",
                    footerClass: "p-2 border-top-0",
                    centered: true,
                })
                .then((value) => {
                    if (value) {
                        this.removeCar(id);
                    }
                })
                .catch((err) => {
                    // An error occurred
                });
        },
    },
    computed: {
        ...mapGetters({
            recordTypes: "config/recordTypes",
            recordTypesPagination: "config/recordTypesPagination",
            recordSections: "config/recordSections",
        }),
    },
    mounted() {},
};
</script>
