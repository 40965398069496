<template>
    <vue-tel-input
        class="bg-slate-100 rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
        :only-countries="['BJ', 'SN', 'CI', 'TG', 'GH', 'FR', 'US', 'CA']"
        :inputOptions="{ showDialCode: true }"
        :value="phoneNumber"
        :defaultCountry="defaultCountry"
        v-model="phoneNumber"
        mode="international"
        :autoFormat="true"
        :name="name"
        :input-options="options"
        :autoDefaultCountry="value?.length < 6"
        @input="phoneUpdated"
    ></vue-tel-input>
</template>

<script>
import { watch, ref } from "vue";
// import { VueTelInput } from "vue3-tel-input";
// import "vue3-tel-input/dist/vue3-tel-input.css";
// import VueTelInput from "vue-tel-input";
// import "vue-tel-input/dist/vue-tel-input.css";

import "vue-tel-input/dist/vue-tel-input.css";
import { VueTelInput } from "vue-tel-input";
export default {
    components: { VueTelInput },
    props: {
        defaultCountry: String,
        name: String,
        placeholder: String,
        readOnly: Boolean,
        value: String,
    },
    setup(props, { emit }) {
        const phoneNumber = ref(props.value || "");

        const options = ref({
            name: props.name || "phoneNumber",
            readonly: props.readOnly || false,
            placeholder: props.placeholder || "Numéro de téléphone",
        });

        const phoneUpdated = (phoneNumber, data) => {
            if (data) {
                emit("phoneChanged", { ...data });
            }
        };

        watch(
            () => props.value,
            (val) => {
                phoneNumber.value = val || "";
            }
        );

        return {
            options,
            phoneNumber,
            phoneUpdated,
        };
    },
};
</script>
<style lang="scss">
.vue-tel-input {
    height: 100% !important;
    @apply border-slate-200;
    // border: 1px solid rgb(226 232 240) !important;
    border-radius: 0.3rem !important;
    -webkit-box-shadow: none;
    &:focus {
        border-color: rgb(241 245 249) !important;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    &:focus-within {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    box-shadow: none;
    .vti__dropdown {
        padding: 0;
    }
    input {
        background: white;
        border: none !important;
        &::-webkit-input-placeholder {
            /* Edge */
            color: rgb(148 163 184) !important;
        }

        &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: rgb(148 163 184) !important;
        }

        &::placeholder {
            color: rgb(148 163 184) !important;
        }
        &:focus {
            border: none !important;
            outline-color: transparent;
            -webkit-box-shadow: none;
            box-shadow: none;
            .vue-tel-input {
                -webkit-box-shadow: none;
                box-shadow: none;
            }
        }
        &:hover {
            outline-color: transparent;
            border: none !important;
            -webkit-box-shadow: none;
            box-shadow: none;
        }
    }
    .open {
        .vti__dropdown-list {
            border: 1px solid rgb(226 232 240) !important;
            border-radius: 0.3rem !important;

            .vti__dropdown-item {
                height: 35px !important;
                line-height: 35px !important;
                display: flex !important;
                align-items: center !important;
            }
        }
    }
    .country-selector {
        height: 100% !important;
        input {
            height: 100% !important;
        }
    }
    .input-tel {
        height: 100% !important;
        width: 100% !important;

        input {
            height: 100% !important;
            border-color: #dee2e6 !important;
            border-radius: 0 3px 3px 0 !important;
            outline: none !important;

            &:focus {
                outline: none !important;
            }
        }
    }

    .select-country-container {
        flex: 0 0 35% !important;
        width: 35% !important;
        min-width: unset !important;

        input {
            border-color: #dee2e6 !important;
            border-radius: 3px 0 0 3px !important;
            outline: none !important;

            &:focus {
                outline: none !important;
            }
        }
    }
}
</style>
