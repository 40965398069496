<template>
    <div
        class="w-full h-full flex flex-col items-center justify-start overflow-scroll"
    >
        <div
            v-if="!isLoading && !isLoadingOld && appointments.length == 0"
            class="flex h-full w-9/12 justify-center items-center"
        >
            <div class="flex flex-col items-center">
                <img class="w-24" src="/images/no-appointment.png" />
                <h3 class="text-lg font-semibold py-3 text-slate-700">
                    Aucun rendez-vous à venir
                </h3>
                <p class="text-sm text-center">
                    Trouvez un praticien et prenez rendez-vous en ligne à tout
                    moment.
                </p>
                <div class="py-5">
                    <a href="/search-praticians">
                        <Button
                            label="Prendre un rendez-vous"
                            class="uppercase text-sm bg-primary3 text-white px-4 py-2"
                            severity="secondary"
                        />
                    </a>
                </div>
            </div>
        </div>
        <div v-else class="w-9/12 mt-10">
            <a href="/search-praticians">
                <Button
                    class="uppercase text-sm bg-primary2 text-white px-4 py-2 shadow-none"
                    severity="secondary"
                >
                    <i class="la la-calendar-plus text-md mr-1" />
                    Nouveau rendez-vous
                </Button>
            </a>
        </div>
        <div class="w-9/12 mt-5" v-if="!isLoading && appointments.length">
            <div class="py-5 sticky top-0">
                <h3 class="font-medium text-xl font-medium text-slate-600">
                    Prochainement...
                </h3>
            </div>
            <div class="flex w-full flex-col">
                <Card
                    v-for="(app, index) in appointments"
                    :key="index"
                    :appointment="app"
                    :isLast="index + 1 === appointments.length"
                />
            </div>
        </div>

        <div class="flex justify-center items-center w-full">
            <div
                class="w-1/3 lg:w-1/5 md:w-1/5"
                v-if="appointments.length == 0"
            >
                <img src="/images/no-calendar.svg" class="" alt="" />
            </div>
        </div>

        <div
            class="flex justify-center text-slate-200 mt-40"
            v-if="isLoadingOld || isLoading"
        >
            <Loader></Loader>
        </div>
        <div v-else-if="oldAppointments.length" class="w-9/12 mt-5">
            <div class="py-5">
                <h3
                    class="sticky bg-[#F8F9FC] top-0 font-medium text-xl font-medium text-slate-600"
                >
                    Mes rendez-vous passés
                </h3>
            </div>
            <div class="flex w-full flex-col mb-10">
                <Card
                    v-for="(app, index) in oldAppointments"
                    :key="index"
                    :appointment="app"
                    :isLast="index + 1 === oldAppointments.length"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from "vue";
import moment from "moment";
import { useStore } from "vuex";
import Loader from "@/components/Loader/index.vue";
import Appointment from "./appointment.vue";
import Button from "primevue/button";
import { useWindowSize } from "@vueuse/core";
import Card from "./listCard.vue";
export default defineComponent({
    components: { Appointment, Loader, Card, Button },
    setup() {
        const { width, height } = useWindowSize();
        const icons = computed(() => ({
            MEDICAL_TEST: "file-medical-alt",
            LABORATORY_RESULT: "prescription",
            RADIOLOGY_REPORT: "biohazard",
        }));
        const typesColors = computed(() => ({
            MEDICAL_TEST: "primary2",
            LABORATORY_RESULT: "primary3",
            RADIOLOGY_REPORT: "warning",
        }));
        const typesTexts = computed(() => ({
            MEDICAL_TEST: "Test Médical",
            LABORATORY_RESULT: "Résultats - Laboratoire",
            RADIOLOGY_REPORT: "Rapport de radiologie",
        }));
        const isSmall = computed(() => width.value <= 540);
        const isMedium = computed(
            () => width.value <= 1000 && width.value > 540
        );
        const isLarge = computed(() => width.value > 1000);
        const store = useStore();
        const isLoading = ref(true);
        const isLoadingOld = ref(true);
        const currentAppointment = ref(null);
        const appointments = ref([]);
        const oldAppointments = ref([]);
        const colors = computed(() => ({
            confirmed: "success",
            cancelled: "danger",
            done: "success",
            pending: "slate-500",
            patient_absent: "warning",
            pratician_absent: "warning",
        }));
        const code_colors = computed(() => ({
            cancelled: "#d34932",
            done: "",
            confirmed: "",
            pending: "#64748B",
            patient_absent: "#f39c12",
            pratician_absent: "#f39c12",
        }));

        const getAppointments = () => {
            const paths = window.location.pathname.split("/");
            const id = parseInt(paths[2]);

            store.dispatch("medical/getAppointments", {
                filter: { time: "next" },
                onSuccess: (response) => {
                    appointments.value = [...response.data];
                    isLoading.value = false;

                    if (response.data?.length) {
                        const [first] = response.data;
                        getAppointment(first.id);
                    }
                },
                onError: (error) => {
                    isLoading.value = false;
                },
            });
        };

        const getOldAppointments = () => {
            isLoadingOld.value = true;
            store.dispatch("medical/getAppointments", {
                filter: { time: "past" },
                onSuccess: (response) => {
                    oldAppointments.value = [...response.data];
                    isLoadingOld.value = false;
                },
                onError: (error) => {
                    isLoadingOld.value = false;
                },
            });
        };

        const getAppointment = (id) => {
            isLoading.value = true;
            store.dispatch("medical/getAppointment", {
                appointmentId: id,
                onSuccess: (response) => {
                    currentAppointment.value = { ...response };
                    isLoading.value = false;
                },
                onError: (error) => {
                    isLoading.value = false;
                },
            });
        };

        const updateAppointment = (id) => {
            getAppointment(id);
        };

        const getInitials = (name) => {
            return name
                .match(/(^\S\S?|\s\S)?/g)
                .map((v) => v.trim())
                .join("")
                .match(/(^\S|\S$)?/g)
                .join("")
                .toLocaleUpperCase();
        };

        onMounted(() => {
            getOldAppointments();
            getAppointments();
        });

        return {
            appointments,
            isLoading,
            colors,
            typesColors,
            isLoadingOld,
            code_colors,
            typesTexts,
            isMedium,
            isSmall,
            icons,
            oldAppointments,
            currentAppointment,
            getInitials,
            getOldAppointments,
            updateAppointment,
            moment,
        };
    },
});
</script>

<style lang="scss" scoped>
.appointment {
    user-select: none;
    transition: border 0.4s ease-out, box-shadow 0.4s ease-out;
    &:hover {
        box-shadow: 0px 10px 42px 0px rgba(0, 0, 0, 0.1);
    }

    .slot {
        transition: background-color 0.2s, color 0.2s;
    }
}

.arrow_box {
    position: relative;
}
.arrow_box:after,
.arrow_box:before {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}
.arrow_box:after {
    border-color: rgba(136, 183, 213, 0);
    //   border-left-color: #88b7d5;
    border-width: 10px;
    margin-top: -10px;
}
.arrow_box:before {
}
</style>
