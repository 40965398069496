<template>
    <div class="w-full my-6">
        <div
            v-if="isLoading"
            class="flex h-full w-full justify-center items-center"
        >
            <Loader class="w-20 h-20 text-slate-400" />
        </div>
        <div v-else>
            <div class="rounded-sm bg-white rounded flex flex-col p-5 mb-5">
                <div class="flex flex-row items-center justify-between mb-3">
                    <div>
                        <h4 class="card-title font-semibold text-slate-800">
                            Les Ambulances
                        </h4>
                    </div>
                    <button
                        v-if="$can('is-admin') || $can('manager')"
                        class="flex font-semibold justify-center text-sm items-center border-none rounded-sm bg-opacity-25 hover:bg-opacity-50 bg-doto-100 text-doto-100 px-2 py-2"
                        @click="selectCar({})"
                    >
                        <icon name="plus" class=""></icon>
                        Ajouter un nouveau
                    </button>
                </div>
                <div v-if="cars.length">
                    <div class="table-responsive">
                        <div class="overflow-x-auto">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th
                                            class="whitespace-nowrap font-medium text-sm"
                                        >
                                            Code
                                        </th>
                                        <th
                                            class="whitespace-nowrap font-medium text-sm"
                                        >
                                            Ville
                                        </th>
                                        <th
                                            class="whitespace-nowrap font-medium text-sm"
                                        >
                                            Type
                                        </th>
                                        <th
                                            class="whitespace-nowrap font-medium text-sm"
                                        >
                                            Registration
                                        </th>
                                        <th
                                            class="whitespace-nowrap font-medium text-sm"
                                        >
                                            Infor.
                                        </th>
                                        <th
                                            class="whitespace-nowrap font-medium text-sm"
                                        >
                                            Adresse
                                        </th>
                                        <th
                                            class="whitespace-nowrap font-medium text-sm"
                                        ></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="car in cars" :key="car.id">
                                        <td class="text-sm text-slate-700">
                                            {{ car.code }}
                                        </td>
                                        <td class="text-sm text-slate-700">
                                            {{ car.city && car.city.name }}
                                        </td>
                                        <td class="text-sm text-slate-700">
                                            {{ car.type }}
                                        </td>
                                        <td class="text-sm text-slate-700">
                                            {{ car.registration }}
                                        </td>
                                        <td class="text-sm text-slate-700">
                                            {{ car.brand }} {{ car.year }}
                                        </td>
                                        <td class="text-sm text-slate-700">
                                            {{ car.address }}
                                        </td>
                                        <td>
                                            <div
                                                v-if="car.status == 'active'"
                                                class="flex justify-end items-end"
                                            >
                                                <button
                                                    class="mx-1 px-1 bg-opacity-25 py-1 rounded-md bg-slate-500 text-sm text-slate-500 hover:bg-gray-300 tracking-wide transition-colors duration-200"
                                                    type="button"
                                                    @click="displayMap(car)"
                                                >
                                                    <i
                                                        class="las la-book-medical m-0 text-md"
                                                    ></i
                                                    >Gérer
                                                </button>
                                                <button
                                                    class="mx-1 px-1 bg-opacity-25 py-1 rounded-md bg-secondary text-secondary hover:bg-gray-300 tracking-wide text-sm transition-colors duration-200"
                                                    variant=" iq-bg-primary mr-1 mb-1"
                                                    size="sm"
                                                    @click="selectCar(car)"
                                                >
                                                    <i
                                                        class="las la-edit m-0 text-md"
                                                    ></i>
                                                    Modifier
                                                </button>
                                                <button
                                                    v-if="
                                                        car.status !==
                                                        'inactive'
                                                    "
                                                    class="mx-1 px-1 py-1 rounded-md bg-warning text-sm bg-opacity-25 text-warning hover:bg-gray-300 tracking-wide transition-colors duration-200"
                                                    variant="iq-bg-primary mr-1 mb-1"
                                                    size="sm"
                                                    @click="
                                                        displayPositionMap(car)
                                                    "
                                                >
                                                    <i
                                                        class="las la-map m-0 text-md"
                                                    ></i>
                                                    Position
                                                </button>
                                                <button
                                                    v-if="
                                                        car.status !==
                                                        'inactive'
                                                    "
                                                    class="mx-1 py-1 px-1 rounded-md text-sm bg-danger bg-opacity-25 text-danger hover:bg-gray-300 tracking-wide transition-colors duration-200"
                                                    variant=" iq-bg-primary mr-1 mb-1"
                                                    size="sm"
                                                    @click.prevent.stop="
                                                        openDialog(car)
                                                    "
                                                >
                                                    <i
                                                        class="la la-trash m-0 text-md"
                                                    ></i>
                                                    Supprimer
                                                </button>
                                            </div>
                                            <div
                                                v-else
                                                class="text-right text-warning text-sm"
                                            >
                                                <p
                                                    class="font-qs font-semibold"
                                                >
                                                    En internvention
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div
                    class="p-10 flex flex-col justify-center items-center"
                    v-else
                >
                    <img class="h-24" src="/images/no-results.png" alt="" />
                    <p class="text-xl text-primary font-primary">
                        Aucune ambulance
                    </p>
                </div>
            </div>
        </div>

        <!-- BEGIN: Slide Over Content -->
        <div
            id="header-footer-slide-over-preview"
            class="modal modal-slide-over"
            data-tw-backdrop="static"
            tabindex="-1"
            aria-hidden="true"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <a data-tw-dismiss="modal" href="javascript:;">
                        <i data-lucide="x" class="w-8 h-8 text-slate-400"></i>
                    </a>
                    <!-- BEGIN: Slide Over Header -->
                    <div class="modal-header">
                        <h2 class="font-medium text-base mr-auto">Détails</h2>

                        <div class="dropdown sm:hidden">
                            <a
                                class="dropdown-toggle w-5 h-5 block"
                                href="javascript:;"
                                aria-expanded="false"
                                data-tw-toggle="dropdown"
                            >
                                <i
                                    data-lucide="more-horizontal"
                                    class="w-5 h-5 text-slate-500"
                                ></i>
                            </a>
                        </div>
                    </div>
                    <!-- END: Slide Over Header -->
                    <!-- BEGIN: Slide Over Body -->
                    <div class="modal-body">
                        <div>
                            <label for="modal-form-1" class="form-label"
                                >Immatriculation</label
                            >
                            <input
                                id="modal-form-1"
                                type="text"
                                class="form-control"
                                placeholder="Nom"
                            />
                        </div>
                        <div class="mt-3">
                            <label for="modal-form-2" class="form-label"
                                >Model de voiture</label
                            >
                            <input
                                name="model"
                                v-model="ambulance.model"
                                id="modal-form-2"
                                type="text"
                                class="form-control"
                                placeholder="Nom"
                            />
                        </div>
                        <div class="mt-3">
                            <label for="modal-form-3" class="form-label"
                                >Subject</label
                            >
                            <input
                                id="modal-form-3"
                                type="text"
                                class="form-control"
                                placeholder="Important Meeting"
                            />
                        </div>
                        <div class="mt-3">
                            <label for="modal-form-4" class="form-label"
                                >Has the Words</label
                            >
                            <input
                                id="modal-form-4"
                                type="text"
                                class="form-control"
                                placeholder="Job, Work, Documentation"
                            />
                        </div>
                        <div class="mt-3">
                            <label for="modal-form-5" class="form-label"
                                >Doesn't Have</label
                            >
                            <input
                                id="modal-form-5"
                                type="text"
                                class="form-control"
                                placeholder="Job, Work, Documentation"
                            />
                        </div>
                        <div class="mt-3">
                            <label for="modal-form-6" class="form-label"
                                >Size</label
                            >
                            <select id="modal-form-6" class="form-select">
                                <option>10</option>
                                <option>25</option>
                                <option>35</option>
                                <option>50</option>
                            </select>
                        </div>
                    </div>
                    <!-- END: Slide Over Body -->
                    <!-- BEGIN: Slide Over Footer -->
                    <div class="modal-footer w-full absolute bottom-0">
                        <button
                            type="button"
                            data-tw-dismiss="modal"
                            class="btn btn-outline-secondary w-20 mr-1"
                        >
                            Cancel
                        </button>
                        <button type="button" class="btn btn-primary w-20">
                            Send
                        </button>
                    </div>
                    <!-- END: Slide Over Footer -->
                </div>
            </div>
        </div>
        <!-- END: Slide Over Content -->

        <SampleModal
            :is-visible="showAmbulanceModal"
            @close="handleAmbulanceModalState"
            @cancel="handleAmbulanceModalState"
            @submit="ambulance.id ? handleTagEdition() : saveAmbulance()"
            width="w-1/3"
            centered
            id="new-car"
            :title="
                ambulance.id
                    ? 'Modifier une ambulance'
                    : 'Ajouter une ambulance'
            "
        >
            <div class="mb-2 w-full">
                <div class="no-scrollbar">
                    <div class="h-5/6 no-scrollbar" style="height: 90%">
                        <Form v-slot="{ errors }" :validation-schema="schema">
                            <div class="text-center">
                                <div class="my-2 w-100">
                                    <div class="mb-2">
                                        <label
                                            class="block capitalize tracking-wide text-grey-darker text-xs font-bold text-left mb-1"
                                        >
                                            Modèle de la voiture
                                        </label>
                                        <div
                                            class="flex flex-col justify-start h-10"
                                        >
                                            <Field
                                                name="model"
                                                rules="required"
                                                v-model="ambulance.model"
                                                v-slot="{ field }"
                                            >
                                                <input
                                                    v-bind="field"
                                                    type="text"
                                                    id="model"
                                                    name="model"
                                                    v-model="ambulance.model"
                                                    list="models"
                                                    placeholder="Entre le nom du modèle"
                                                    class="bg-white h-10 rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                                />
                                                <datalist id="models">
                                                    <option
                                                        v-for="m in models"
                                                        :key="m.id"
                                                        :value="m.Model"
                                                    >
                                                        {{ m.Make }}
                                                    </option>
                                                </datalist>
                                                <span
                                                    class="text-danger text-left pt-1"
                                                    >{{ errors.model }}</span
                                                >
                                            </Field>
                                        </div>
                                    </div>
                                    <div class="d-flex">
                                        <div class="mb-2">
                                            <label
                                                class="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-1 text-left"
                                                for="grid-state"
                                            >
                                                Marque
                                            </label>
                                            <div
                                                class="flex flex-col justify-start h-10"
                                            >
                                                <Field
                                                    name="brand"
                                                    rules="required"
                                                    v-model="ambulance.brand"
                                                    v-slot="{ field }"
                                                >
                                                    <input
                                                        placeholder="Marque"
                                                        name="brand"
                                                        v-bind="field"
                                                        v-model="
                                                            ambulance.brand
                                                        "
                                                        focus
                                                        class="bg-white rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                                    />
                                                    <span
                                                        class="text-danger text-left pt-1"
                                                        >{{
                                                            errors.brand
                                                        }}</span
                                                    >
                                                </Field>
                                            </div>
                                        </div>
                                        <div class="mb-2 w-50">
                                            <label
                                                class="block capitalize tracking-wide text-grey-darker text-xs font-bold text-left mb-1"
                                                for="grid-state"
                                            >
                                                Année de conception
                                            </label>
                                            <div
                                                class="flex flex-col justify-start h-10"
                                            >
                                                <Field
                                                    v-model="ambulance.year"
                                                    name="year"
                                                    rules="required"
                                                    v-slot="{ field }"
                                                >
                                                    <input
                                                        placeholder="Année"
                                                        name="year"
                                                        v-bind="field"
                                                        v-model="ambulance.year"
                                                        focus
                                                        class="bg-white rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                                    />
                                                    <span
                                                        class="text-danger text-left pt-1"
                                                        >{{ errors.year }}</span
                                                    >
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex">
                                        <div class="mb-2 w-50">
                                            <label
                                                class="block capitalize tracking-wide text-grey-darker text-xs font-bold text-left mb-1"
                                                for="grid-state"
                                            >
                                                Immatriculation
                                            </label>
                                            <Field
                                                name="registration"
                                                rules="required"
                                                v-model="ambulance.registration"
                                                v-slot="{ field }"
                                            >
                                                <div
                                                    class="flex flex-col justify-start h-10"
                                                >
                                                    <input
                                                        placeholder="Immatriculation"
                                                        v-model="
                                                            ambulance.registration
                                                        "
                                                        name="registration"
                                                        v-bind="field"
                                                        focus
                                                        class="bg-white rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                                    />
                                                    <span
                                                        class="text-danger text-left pt-1"
                                                        >{{
                                                            errors.registration
                                                        }}</span
                                                    >
                                                </div>
                                            </Field>
                                        </div>
                                        <div class="mb-2 w-50">
                                            <label
                                                class="block capitalize tracking-wide text-grey-darker text-xs font-bold text-left mb-1"
                                                for="grid-state"
                                            >
                                                Couleur
                                            </label>
                                            <Field
                                                name="color"
                                                rules="required"
                                                v-slot="{ field }"
                                                v-model="ambulance.color"
                                            >
                                                <div
                                                    class="flex flex-col justify-start h-10"
                                                >
                                                    <input
                                                        v-bind="field"
                                                        placeholder="Couleur"
                                                        v-model="
                                                            ambulance.color
                                                        "
                                                        type="color"
                                                        name="color"
                                                        style="height: 40px"
                                                        focus
                                                        class="bg-white rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                                    />
                                                    <span
                                                        class="text-danger text-left pt-1"
                                                        >{{
                                                            errors.color
                                                        }}</span
                                                    >
                                                </div>
                                            </Field>
                                        </div>
                                    </div>
                                    <div class="d-flex">
                                        <div class="mb-2">
                                            <label
                                                class="block capitalize tracking-wide text-grey-darker text-xs font-bold text-left mb-1"
                                                for="grid-state"
                                            >
                                                Ville
                                            </label>
                                            <div class="h-10">
                                                <Field
                                                    name="city_id"
                                                    rules="required"
                                                    v-slot="{ field }"
                                                    v-model="ambulance.city"
                                                >
                                                    <div
                                                        class="flex flex-col justify-start"
                                                    >
                                                        <Select
                                                            placeholder="Ville"
                                                            class="w-full"
                                                            v-bind="field"
                                                            name="city_id"
                                                            v-model="
                                                                ambulance.city
                                                            "
                                                            :value="
                                                                ambulance.city
                                                            "
                                                            :options="cities"
                                                            @selected="
                                                                ($event) =>
                                                                    (ambulance.city =
                                                                        $event)
                                                            "
                                                        />

                                                        <span
                                                            class="text-danger text-left pt-1"
                                                            >{{
                                                                errors.city_id
                                                            }}</span
                                                        >
                                                    </div>
                                                </Field>
                                            </div>
                                        </div>
                                        <div class="mb-2">
                                            <label
                                                class="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-1 text-left"
                                                for="grid-first-name"
                                            >
                                                Type d'ambulance
                                            </label>
                                            <div class="h-10">
                                                <Field
                                                    name="type"
                                                    v-slot="{ field }"
                                                    v-model="ambulance.type"
                                                >
                                                    <div
                                                        class="flex flex-col justify-start"
                                                    >
                                                        <Select
                                                            placeholder="Type"
                                                            class="w-full"
                                                            v-bind="field"
                                                            name="type"
                                                            v-model="
                                                                ambulance.type
                                                            "
                                                            :value="
                                                                ambulance.type
                                                            "
                                                            :options="types"
                                                            @selected="
                                                                ($event) =>
                                                                    (ambulance.type =
                                                                        $event)
                                                            "
                                                        />
                                                        <span
                                                            class="text-danger text-left pt-1"
                                                            >{{
                                                                errors.type
                                                            }}</span
                                                        >
                                                    </div>
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <file-input
                                    :url="ambulance.img_url"
                                    @fileChanged="fileChanged"
                                />
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </SampleModal>

        <SampleModal
            :is-visible="showPositionModal"
            @close="handlePositionModalClose"
            @cancel="handlePositionModalClose"
            @submit="updateAmbulancePosition"
            width="w-3/5"
            centered
            id="new-car"
            :title="`Mise à jour de position - Ambulance ${ambulance.code}/${
                ambulance?.city?.name
            }/${ambulance?.type?.toString()?.toUpperCase()}/${
                ambulance.registration
            }`"
        >
            <div class="mb-2">
                <div class="h-64">
                    <div class="h-5/6" style="height: 90%">
                        <gmap
                            class="h-full"
                            :center="center"
                            :locate="center.lat == null"
                            @onMapUpdate="updateMap"
                            :delay="1000"
                        />
                    </div>

                    <div class="my-3">
                        <h3 class="text-sm text-primary">
                            {{ carPosition.address }}
                        </h3>
                    </div>
                </div>
            </div>
        </SampleModal>
        <SampleModal
            :is-visible="showResourceModal"
            @close="handleResourceModalClose"
            @cancel="handleResourceModalClose"
            @submit="updateAmbulanceResources"
            width="w-4/5"
            centered
            id="new-car-resources"
            title="Affectation des ressources"
        >
            <div class="mb-2">
                <ambulance-edit-form
                    :ambulance="ambulance"
                    :resources="resources"
                    :conductors="conductors"
                    :agents="agents"
                    @dataUpdated="dataUpdated"
                >
                </ambulance-edit-form>
            </div>
        </SampleModal>
        <!-- <modal
            id="show-modal"
            title="Affectation des ressources"
            width="w-4/5"
            :isVisible="showResourceModal"
            @close="handleResourceModalClose"
            @submit="updateAmbulanceResources"
            @cancel="handleResourceModalClose"
        >
            <template #content="{}">

            </template>
            <template #footer-action
                ><div class="mr-2 mb-4">
                    <button
                        type="button"
                        class="btn btn-outline-secondary w-20 mr-1"
                        @click.prevent="handleResourceModalClose"
                    >
                        Fermer
                    </button>
                </div>
            </template>
            <template #footer-close
                ><button
                    type="button"
                    class="btn btn-primary w-20 mr-2 mb-4"
                    @click.prevent="updateAmbulanceResources"
                >
                    Enrégistrer
                </button>
            </template>
        </modal> -->

        <popup
            message="Êtes-vous sure de vouloir supprimer ?"
            :isVisible="canDelete"
            @submit="removeCar"
            @cancel="canDelete = false"
            @close="canDelete = false"
        ></popup>
    </div>
    <ConfirmDialog></ConfirmDialog>
</template>

<script>
import store from "@/store";
import { mapGetters } from "vuex";
import HereMap from "@/components/maps/draggablemap";
import gmap from "@/components/maps/gmap.vue";
import { mapCenter, hereMapApiKey } from "@/services/config";
import { reverseGeocode } from "@/services/map";
import { XIcon, PlusIcon } from "vue-feather-icons";
import { Form, Field } from "vee-validate";
import * as yup from "yup";
import Select from "@/components/Select/index.vue";
import { onMounted, watch, computed, ref } from "vue";
import SampleModal from "@/components/Modal/SampleModal.vue";
import { useConfirm } from "primevue/useconfirm";
import ConfirmDialog from "primevue/confirmdialog";
import { TYPE } from "vue-toastification";
import Loader from "@/components/Loader/index.vue";

export default {
    beforeRouteEnter(to, from, next) {
        store.dispatch("config/getAllCities", {});

        next();
    },
    components: {
        HereMap,
        SampleModal,
        Select,
        ConfirmDialog,
        gmap,
        Form,
        Loader,
        PlusIcon,
        XIcon,
        Field,
    },
    watch: {
        "ambulance.model": function (val) {
            const selected = this.models.find((x) => x.Model === val);
            if (selected) {
                this.ambulance.year = selected.Year;
                this.ambulance.brand = selected.Make;
            }
            this.loadCars(val);
        },

        cars: async function (val) {
            await Promise.all(
                val.map(
                    async (c) =>
                        (c.address = await this.buildAddress(
                            c.last_known_position?.latitude ??
                                c.current_latitude,
                            c.last_known_position?.longitude ??
                                c.current_longitude
                        ))
                )
            );
        },
    },

    setup(props) {
        const isLoading = ref(false);
        const selectedCar = ref(null);
        const schema = yup.object({});
        const portfolio = computed(() => store.getters["auth/portfolio"]);
        const confirm = useConfirm();
        const isVisible = ref(false);
        const listenToCars = () => {
            if (typeof window != undefined)
                window.Echo.channel("cars.update").listen(
                    "StatusChanged",
                    (event) => {
                        console.log("StatusChanged", event);

                        store.dispatch("config/getAmbulances", {});
                    }
                );
        };

        const getAmbulances = () => {
            store.dispatch("config/getAmbulances", {
                onSuccess: () => {
                    isLoading.value = false;
                },
                onError: () => {
                    isLoading.value = false;
                },
            });
            store.dispatch("config/getStaff", {
                portfolioId: portfolio.value.id,
                page: 1,
            });
        };

        const removeCar = () => {
            store.dispatch("config/removeAmbulances", {
                id: selectedCar.value.id,
                onSuccess: () => {
                    store.dispatch("medical/displayToast", {
                        message: "Véhicule supprimé avec succès.",
                        context: "Ambulances",
                        type: TYPE.SUCCESS,
                    });

                    getAmbulances();
                },
                onError: () => {
                    store.dispatch("medical/displayToast", {
                        message:
                            "Oooooops, une erreur est survenue lors de la suppression.",
                        context: "Ambulances",
                        type: TYPE.ERROR,
                    });
                },
            });
        };

        const openDialog = (ambulance) => {
            selectedCar.value = ambulance;

            confirm.require({
                message: "Êtes-vous sûr de vouloir supprimer cette ambulance ?",
                header: "Suppression",
                icon: "la la-exclamation-circle",
                rejectLabel: "Non",
                acceptLabel: "Oui",
                rejectClass:
                    "bg-danger text-danger py-1 text-sm text-medium px-2 mr-2 bg-opacity-25",
                acceptClass:
                    "bg-primary text-primary py-1 text-sm text-medium px-2  bg-opacity-25",
                accept: () => {
                    removeCar();
                },
                reject: () => {},
            });
        };

        watch(
            () => portfolio.value,
            (val) => {
                getAmbulances();
            }
        );
        onMounted(() => {
            isLoading.value = true;
            listenToCars();
            getAmbulances();
        });

        return { schema, isLoading, openDialog };
    },
    data() {
        return {
            canDelete: false,
            showResourceModal: false,
            showPositionModal: false,
            showAmbulanceModal: false,
            carResources: {},
            carPosition: {
                address: "",
                latitude: null,
                longitude: null,
            },
            types: [
                { id: "a", name: "A" },
                { id: "b", name: "B" },
                { id: "c", name: "C" },
            ],
            carFile: null,
            apiKey: hereMapApiKey,
            center: mapCenter,
            models: [],
            currentCar: {},
            ambulance: {
                city_id: "",
                type: "a",
            },
        };
    },
    methods: {
        handleAmbulanceModalState(value = false) {
            this.showAmbulanceModal = value;
        },
        handleResourceModalClose() {
            this.showResourceModal = false;
        },
        handlePositionModalClose() {
            this.showPositionModal = false;
        },
        fileChanged(file) {
            console.log(file);
            this.carFile = file;
        },
        updateMap(map, position) {
            this.carPosition = {
                ...this.carPosition,

                latitude: position.lat,
                longitude: position.lng,
            };
            this.getAddress(position.lat, position.lng);
        },
        selectCar(car) {
            this.ambulance = {
                ...car,
                type: (car?.type || "")?.toLowerCase(),
            };

            this.agents = (car.agents || []).map((x) => ({
                id: x.id,
                name: x.full_name,
                image: x.avatar,
            }));

            const [firstConductor] = car.conductors || [];

            this.ambulance.conductor = firstConductor || {};

            this.showAmbulanceModal = true;
        },
        dataUpdated(car) {
            this.currentCar = {
                ...car,
            };

            if (car.last_known_position) {
                this.center = {
                    lng:
                        car.last_known_position.longitude ||
                        car.current_longitude,
                    lat:
                        car.last_known_position.latitude ||
                        car.current_latitude,
                };
            }
        },
        displayMap(car) {
            this.ambulance = {
                ...car,
            };

            this.ambulance.conductor = car?.conductor[0] || {};

            this.currentCar = {
                ...car,
            };
            if (car.last_known_position) {
                this.center = {
                    lng:
                        car.last_known_position.longitude ||
                        car.current_longitude,
                    lat:
                        car.last_known_position.latitude ||
                        car.current_latitude,
                };
            }

            this.showResourceModal = true;
        },

        async displayPositionMap(car) {
            this.ambulance = {
                ...car,
            };

            this.ambulance.conductor = car?.conductor[0] ?? {};

            this.currentCar = {
                ...car,
            };
            this.carPosition.address = "";
            this.center = {
                lng:
                    car.last_known_position?.longitude || car.current_longitude,
                lat: car.last_known_position?.latitude || car.current_latitude,
            };

            this.carPosition.address = await this.buildAddress(
                this.center.lat,
                this.center.lng
            );

            this.showPositionModal = true;
        },
        async loadCars(value) {
            const where = encodeURIComponent(
                JSON.stringify({
                    Model: {
                        $regex: `${value}`,
                    },
                })
            );
            const response = await fetch(
                `https://parseapi.back4app.com/classes/Carmodels_Car_Model_List?limit=10&where=${where}`,
                {
                    headers: {
                        "X-Parse-Application-Id":
                            "AUOooeNvJMMtB2ePMaReDgCfzzLiKr64izVQD3iK", // This is your app's application id
                        "X-Parse-REST-API-Key":
                            "8VAZsmvsaKuRJtQHnX5Ly6Vrd9GZ8KiwyTStyr2U", // This is your app's REST API key
                    },
                }
            );
            const { results } = await response.json();
            this.models = results;
        },
        saveImage(id, file) {
            const form = new FormData();
            form.append("image", file);
            this.$store.dispatch("config/attachCarImage", {
                id: id,
                data: form,
                onSuccess: () => {
                    this.carFile = null;
                },
            });
        },
        updateAmbulance() {
            this.$store.dispatch("config/updateAmbulance", {
                data: { ...this.ambulance, portfolio_id: this.portfolio.id },
                id: this.ambulance.id,
                onSuccess: () => {
                    if (this.carFile)
                        this.saveImage(this.ambulance.id, this.carFile);
                    this.currentCar = {
                        city_id: "",
                        model: "",
                        year: "",
                    };
                    this.handleAmbulanceModalState(false);
                },
                onError: () => {},
            });
        },

        saveAmbulance(values) {
            if (this.ambulance?.id) {
                this.updateAmbulance();
            } else this.addNewcar();
        },

        updateAmbulanceResources() {
            const formData = {};
            formData.res_quantity = this.currentCar?.res_quantity || {};
            formData.agents = Array.from(
                new Set((this.currentCar.agents || []).map((x) => x.id))
            );
            formData.conductor =
                this.currentCar?.conductor?.id ??
                this.currentCar?.conductor[0]?.id;
            delete formData.resources;

            console.log(formData, this.currentCar);
            this.$store.dispatch("config/updateAmbulanceResources", {
                data: formData,
                id: this.ambulance.id,
                onSuccess: () => {
                    this.currentCar = {
                        city_id: "",
                        model: "",
                        year: "",
                    };
                    // this.ambulance=null
                    this.handleResourceModalClose();
                },
                onError: () => {},
            });
        },

        updateAmbulancePosition() {
            this.$store.dispatch("config/updateAmbulancePosition", {
                data: this.carPosition,
                id: this.currentCar.id,
                onSuccess: () => {
                    this.carPosition = {
                        address: "",
                        latitude: null,
                        longitude: null,
                    };
                    this.handlePositionModalClose();
                    this.rebuildAddresses();
                },
                onError: () => {},
            });
        },

        async buildAddress(lat, lng) {
            if (!lat || !lng) return "-";

            const [{ position, address }] = (await reverseGeocode(lat, lng))
                ?.items;
            return address.label;
        },

        getAddress(lat, lng) {
            if (!lat || !lng) return;
            reverseGeocode(lat, lng).then((result) => {
                const { position, address } = result.items[0];
                this.carPosition.address = address.label;
            });
        },
        addNewcar() {
            const formData = this.ambulance;
            formData.city_id = this.ambulance.city.id;
            formData.type = this.ambulance.type.id;
            this.$store.dispatch("config/addAmbulance", {
                data: { ...formData, portfolio_id: this.portfolio.id },
                onSuccess: () => {
                    this.ambulance = {
                        city_id: "",
                        model: "",
                        year: "",
                    };

                    this.handleAmbulanceModalState(false);
                    this.rebuildAddresses();
                },
                onError: () => {},
            });
        },
        rRemoveCar(car) {
            this.currentCar = { ...car };
            this.canDelete = true;
        },

        removeCar() {
            this.$store.dispatch("config/removeAmbulances", {
                id: this.currentCar.id,
                onSuccess: () => {
                    this.canDelete = false;
                },
                onError: () => {
                    this.canDelete = false;
                },
            });
        },

        async rebuildAddresses() {
            await Promise.all(
                this.cars.map(
                    async (c) =>
                        (c.address = await this.buildAddress(
                            c.last_known_position?.latitude ??
                                c.current_latitude,
                            c.last_known_position?.longitude ??
                                c.current_longitude
                        ))
                )
            );
        },
    },
    computed: {
        ...mapGetters({
            cars: "config/cars",
            resources: "config/allResources",
            users: "config/users",
            portfolio: "auth/portfolio",
            cities: "config/allCities",
        }),
        conductors() {
            return this.users
                .filter((u) => u.roles.map((r) => r.slug).includes("conductor"))
                .map((x) => ({ ...x, name: x.full_name }));
        },
        agents() {
            return this.users
                .filter((u) => u.roles.map((r) => r.slug).includes("agent"))
                .map((x) => ({ ...x, name: x.full_name }));
        },
    },
    async mounted() {
        this.loadCars();
        this.rebuildAddresses();
        store.dispatch("config/getAllResources", {
            portfolioId: this.portfolio.id,
        });
    },
};
</script>
