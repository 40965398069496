<template>
    <div
        v-if="isLoading"
        class="h-full flex justify-center items-center text-primary2"
    >
        <Loader class="w-20" />
    </div>
    <div v-else class="w-full h-full my-6">
        <div class="w-full h-full">
            <div class="w-full h-full">
                <div
                    class="rounded-sm bg-white flex flex-col p-5 mb-5 w-full h-full overflow-y-full"
                >
                    <div class="flex justify-between items-center mb-3 w-full">
                        <div>
                            <h4 class="card-title font-semibold text-slate-800">
                                Les hôpitaux
                            </h4>
                        </div>
                        <button
                            v-can.any="['medical', 'admin', 'manager']"
                            @click="
                                $router.push({ name: 'config.hospitalsCreate' })
                            "
                            class="flex justify-center items-center text-sm font-semibold border-none rounded-sm bg-opacity-25 hover:bg-opacity-50 bg-doto-100 text-doto-100 px-2 py-2"
                        >
                            <icon name="plus" class=""></icon>
                            Ajouter un nouveau
                        </button>
                    </div>
                    <div class="w-full h-full">
                        <div v-if="hospitals?.length" class="w-full h-full">
                            <div class="table-responsive">
                                <table
                                    striped
                                    outlined
                                    class="table table-striped"
                                    hover
                                >
                                    <thead>
                                        <tr>
                                            <th
                                                class="whitespace-nowrap font-medium text-sm"
                                            >
                                                Identifiant
                                            </th>
                                            <th
                                                class="whitespace-nowrap font-medium text-sm"
                                            >
                                                Nom
                                            </th>
                                            <th
                                                class="whitespace-nowrap font-medium text-sm"
                                            >
                                                No Authorisation
                                            </th>
                                            <th
                                                class="whitespace-nowrap font-medium text-sm"
                                            >
                                                Ville
                                            </th>
                                            <th
                                                class="whitespace-nowrap font-medium text-sm"
                                            >
                                                Déclarant
                                            </th>
                                            <th
                                                class="whitespace-nowrap font-medium text-sm"
                                            >
                                                Status
                                            </th>
                                            <th
                                                class="whitespace-nowrap font-medium text-sm"
                                            ></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr
                                            v-for="user in hospitals"
                                            :key="user.id"
                                        >
                                            <td class="text-sm text-slate-700">
                                                <span
                                                    class="bg-pink-100 text-pink-800 text-xs font-semibold mr-2 px-2.5 py-1.5 rounded dark:bg-pink-200 dark:text-pink-900"
                                                >
                                                    {{ user.identifier }}
                                                </span>
                                            </td>
                                            <td class="text-sm text-slate-700">
                                                {{ user.name }}
                                            </td>
                                            <td class="text-sm text-slate-700">
                                                {{ user.authorization_number }}
                                            </td>
                                            <td class="text-sm text-slate-700">
                                                {{ user.city?.name }}
                                            </td>
                                            <td class="text-sm text-slate-700">
                                                {{ user.creator?.full_name }}
                                            </td>
                                            <td class="text-sm text-slate-700">
                                                <span
                                                    :class="`
                            text-${status[user.status]}
                           text-xs
                            font-semibold
                            mr-2
                            px-2.5
                            py-1.5
                            rounded
                            dark:bg-pink-200 dark:text-pink-900`"
                                                >
                                                    {{
                                                        statusText[user.status]
                                                    }}
                                                </span>
                                            </td>

                                            <td>
                                                <div class="flex justify-end">
                                                    <button
                                                        class="border border-slate-400 text-sm px-2 py-1 rounded text-slate-400"
                                                        size="sm"
                                                        @click="
                                                            $router.push({
                                                                name: 'config.hospitalDetails',
                                                                params: {
                                                                    slug: user.slug,
                                                                },
                                                            })
                                                        "
                                                    >
                                                        <i
                                                            class="las la-eye m-0"
                                                        ></i>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div v-else class="p-3 h-full">
                            <div
                                class="flex flex-col justify-center items-center h-full"
                            >
                                <img
                                    class="w-1/4"
                                    src="/images/no-result.svg"
                                />
                                <h4
                                    class="text-primary font-medium my-3 text-xl"
                                >
                                    Aucun centre médical (Hôpital) enrégistré
                                    pour le moment
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex justify-end py-3">
                    <pagination
                        :pagination="pagination"
                        @changed="getData"
                    ></pagination>
                </div>
            </div>
        </div>
        <popup
            message="Êtes-vous sûre de vouloir supprimer ?"
            :isVisible="canDelete"
            @submit="removeHospital"
            @cancel="canDelete = false"
            @close="canDelete = false"
        ></popup>
    </div>
</template>

<script>
import store from "@/store";
import { mapGetters, useStore } from "vuex";
import { computed, onMounted, ref, watch } from "vue";
import Loader from "@/components/Loader/index.vue";

export default {
    beforeRouteEnter(to, from, next) {
        store.dispatch("config/getAllCities", {});
        store.dispatch("config/getResources", {}).then((res) => next());
    },
    components: { Loader },
    watch: {},
    data() {
        return {
            map: null,
            status: {
                pending: "warning",
                active: "success",
                banned: "danger",
                closed: "pink-100",
            },
            statusText: {
                pending: "En attente d'autorisation",
                active: "Validé",
                banned: "Bani",
                closed: "Refusé",
            },
        };
    },
    setup(props) {
        const store = useStore();
        const portfolio = computed(() => store.getters["auth/portfolio"]);
        const hospitals = computed(() => store.getters["medical/hospitals"]);
        const pagination = computed(
            () => store.getters["medical/hospitalsPagination"]
        );
        const isLoading = ref(false);
        const getData = (page = 1) => {
            isLoading.value = true;
            store.dispatch("medical/getHospitals", {
                filter: { portfolioId: portfolio.value?.id, page },
                onSuccess: () => {
                    isLoading.value = false;
                },
                onError: () => {
                    isLoading.value = false;
                },
            });
        };

        watch(
            () => portfolio.value,
            (val) => {
                getData();
            }
        );

        onMounted(() => {
            getData();
        });

        return { hospitals, isLoading, pagination };
    },
};
</script>
