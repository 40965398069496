<template>
    <div class="w-full flex justify-center mt-10">
        <div class="w-1/2">
            <div
                class="w-full rounded-lg overflow-hidden"
                :style="`${isCodeModalDisplay ? 'filter: blur(6px)' : ''}`"
            >
                <div :class="`bg-slate-800`">
                    <div
                        :class="`h-10 flex bg-${
                            colors[appointment.status]
                        } items-center p-3 text-sm text-white`"
                    >
                        <span class="capitalize">
                            <i class="la la-calendar mr-1"></i
                            >{{
                                moment(appointment?.start_date).format(
                                    "dddd LL"
                                )
                            }}</span
                        >
                        <span>
                            <i class="la la-clock ml-2 mr-1"></i
                            >{{
                                moment(appointment?.start_date).format("HH:mm")
                            }}</span
                        >
                    </div>
                </div>
                <div>
                    <div class="bg-white p-3">
                        <div class="flex items-center">
                            <div class="flex flex-col px-2">
                                <h3
                                    class="font-bold font-qs text-xl text-slate-600"
                                >
                                    Dr. {{ appointment?.pratician?.full_name }}
                                </h3>
                                <p
                                    v-if="specialties"
                                    class="text-slate-400 text-xs"
                                >
                                    {{ specialties }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="py-5 bg-white border-t-2 border-[#F8F9FC] px-12 text-sm font-semibold"
                >
                    <div
                        class="flex justify-between"
                        v-if="appointment?.status == 'pending'"
                    >
                        <div class="">
                            <p
                                class="text-primary cursor-pointer"
                                @click.prevent="requestUpdate"
                            >
                                <i class="la la-history mr-1"></i> Déplacer le
                                RDV
                            </p>
                        </div>
                        <div>
                            <p
                                class="text-danger cursor-pointer"
                                @click.prevent="requestCancel"
                            >
                                <i class="la la-calendar-times mr-1"></i>
                                Annuler le RDV
                            </p>
                        </div>
                    </div>
                    <div class="" v-else>
                        <p :class="`text-${colors[appointment.status]}`">
                            {{ statuses[appointment.status] }}
                        </p>
                    </div>
                </div>

                <div
                    class="my-4 rounded-lg bg-white overflow-hidden cursor-pointer"
                >
                    <div class="flex justify-between p-4">
                        <div>
                            <h3 class="font-semibold text-md">
                                Préparez la consultation
                            </h3>
                            <p class="font-normal text-sm py-2">
                                Pour gagner du temps et améliorer votre prise en
                                charge.
                            </p>
                        </div>
                        <div>
                            <img
                                src="/images/upload_documents.png"
                                alt=""
                                class="w-12"
                            />
                        </div>
                    </div>
                    <div
                        class="p-4 flex justify-between items-center hover:bg-slate-100"
                    >
                        <h3
                            class="text-primary text-sm cursor-pointer font-semibold"
                        >
                            <i class="la la-paperclip"></i> Charger les
                            documents
                        </h3>
                        <i class="la la-angle-right text-primary"></i>
                    </div>
                </div>

                <div
                    class="my-4 rounded-lg bg-white overflow-hidden cursor-pointer"
                >
                    <div class="flex justify-between p-4">
                        <div>
                            <h3 class="font-semibold text-md">Patient</h3>
                            <div class="flex">
                                <i class="la la-user-circle text-xl mr-1"></i>
                                <div>
                                    <h4
                                        class="font-normal flex items-center text-sm py-1 text-slate-600"
                                    >
                                        {{ appointment?.patient?.full_name }}
                                    </h4>
                                    <p class="text-slate-400 text-xs">
                                        {{ appointment?.patient?.username }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div></div>
                    </div>
                </div>

                <div
                    class="my-4 p-4 rounded-lg bg-white overflow-hidden cursor-pointer"
                >
                    <div class="flex border-b-2 border-[#F8F9FC] py-4">
                        <div class="w-7">
                            <i class="la la-headset text-md text-slate-600"></i>
                        </div>
                        <div>
                            <h3 class="text-md text-slate-600 font-semibold">
                                Téléphone du lieu de consultation
                            </h3>
                            <p
                                class="text-primary"
                                v-if="
                                    appointment?.medical_center?.profile
                                        ?.phone_1
                                "
                            >
                                {{
                                    formatPhone(
                                        appointment?.medical_center?.profile
                                            ?.phone_1
                                    )
                                }}
                            </p>
                            <p class="text-primary" v-else>-</p>
                            <p
                                class="text-primary"
                                v-if="
                                    appointment?.medical_center?.profile
                                        ?.phone_2
                                "
                            >
                                {{
                                    formatPhone(
                                        appointment?.medical_center?.profile
                                            ?.phone_2
                                    )
                                }}
                            </p>
                        </div>
                    </div>
                    <div class="flex border-b-2 border-[#F8F9FC] py-4">
                        <div class="w-7">
                            <i
                                class="la la-map-marker text-md text-slate-600"
                            ></i>
                        </div>
                        <div>
                            <h3 class="text-md text-slate-600 font-semibold">
                                Lieu de la consultation
                            </h3>
                            <p class="py-2 text-primary">
                                {{ appointment?.medical_center?.name }}
                            </p>
                            <p class="text-primary">
                                {{ appointment?.address }}
                            </p>
                        </div>
                    </div>
                    <div class="flex border-b-2 border-[#F8F9FC] py-4">
                        <div class="w-7">
                            <i
                                class="la la-credit-card text-md text-slate-600"
                            ></i>
                        </div>
                        <div>
                            <h3 class="text-md text-slate-600 font-semibold">
                                Moyen de paiement
                            </h3>
                            <p class="py-2 text-primary">
                                {{ payment }}
                            </p>
                        </div>
                    </div>
                    <div class="flex py-4">
                        <div class="w-7">
                            <i
                                class="la la-money-bill text-md text-slate-600"
                            ></i>
                        </div>
                        <div>
                            <h3 class="text-md text-slate-600 font-semibold">
                                Tarif
                            </h3>
                            <p
                                class="py-2 text-blackp"
                                v-if="appointment?.planning?.price"
                            >
                                {{
                                    price.format(
                                        appointment?.planning?.price?.min
                                    )
                                }}
                                <span class="px-2">-</span>
                                {{
                                    price.format(
                                        appointment?.planning?.price?.max
                                    )
                                }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Toast />
            <ConfirmDialog
                :pt="{
                    headerTitle: { class: 'text-danger' },
                    footer: {
                        class: [
                            'shrink-0 ',
                            'border-t-0 bg-white text-gray-700 px-6 pb-6 text-right rounded-b-lg',
                            'dark:bg-gray-900  dark:text-white/80',
                        ],
                    },
                }"
            ></ConfirmDialog>
            <Dialog
                v-model:visible="displayTimes"
                modal
                :header="`Modification du rendez-vous - ${moment(
                    selectedTime
                ).format('LLL')}`"
                :style="{ width: '50vw' }"
            >
                <div v-if="loadingSlots" class="flex justify-center">
                    <Loader class="w-20" />
                </div>
                <div v-else>
                    <Accordion
                        :activeIndex="0"
                        :pt="{
                            headerTitle: 'font-semibold text-sm',
                        }"
                    >
                        <AccordionTab
                            :header="moment(dateData.date).format('LL')"
                            v-for="(dateData, index) in slotsData"
                            :key="index"
                        >
                            <div class="grid grid-cols-6 gap-4 w-full">
                                <button
                                    v-for="idx in dateData.slots.length"
                                    :key="idx"
                                    :class="`rounded   px-2 py-2 opacity-75 slot ${
                                        moment(selectedTime).unix() ==
                                        moment(
                                            dateData.slots[idx - 1]?.start
                                        ).unix()
                                            ? 'bg-slate-700 text-white'
                                            : 'bg-[#B5B9C9] text-[#2b4660]'
                                    }`"
                                    @click.prevent="
                                        selectSlot(dateData.slots[idx - 1])
                                    "
                                >
                                    {{
                                        moment(
                                            dateData.slots[idx - 1]?.start
                                        ).format("HH:mm")
                                    }}
                                </button>
                            </div>
                        </AccordionTab>
                    </Accordion>
                </div>
                <template #footer>
                    <Button
                        label="Fermer"
                        icon="la la-times"
                        class="px-3 py-2 text-sm mr-2"
                        @click="displayTimes = false"
                        text
                    />
                    <Button
                        label="Valider"
                        class="px-3 py-2 text-sm bg-success text-white font-mediun"
                        icon="la la-check"
                        @click="validate"
                        autofocus
                    />
                </template>
            </Dialog>
            <Dialog
                v-model:visible="isCodeModalDisplay"
                modal
                header="Validation"
                :style="{ width: '30vw', background: 'white' }"
            >
                <template #container="">
                    <div class="card px-5">
                        <Message
                            :closable="false"
                            class="bg-[#6fddde] font-ibm"
                            icon="la la-lock text-slate-700"
                            ><p class="text-slate-700 text-sm">
                                Détails du rendez-vous, annulation et
                                déplacement
                            </p></Message
                        >
                    </div>
                    <div class="px-5">
                        <h3
                            class="text-sm text-center text-slate-700 font-medium font-ibm px-5"
                        >
                            Entrez les <strong>3 premières lettres</strong> du
                            nom de famille du patient.
                        </h3>
                    </div>

                    <div class="py-10 flex justify-center items-center">
                        <v-otp-input
                            ref="otpInput"
                            v-model:value="bindModal"
                            input-classes="otp-input"
                            separator=" "
                            :num-inputs="3"
                            :should-auto-focus="true"
                            input-type="letter-numeric"
                            :conditionalClass="[
                                'border border-slate-300 w-10 h-10 rounded-lg px-1 mr-4 text-center text-slate-600 font-semibold',
                                'border border-slate-300 w-10 h-10 rounded-lg px-1 mr-4 text-center text-slate-600 font-semibold',
                                'border border-slate-300 w-10 h-10 rounded-lg px-1 text-center text-slate-600 font-semibold',
                                'four',
                            ]"
                            :placeholder="['*', '*', '*', '*']"
                            @on-complete="handleOnComplete"
                        />
                    </div>

                    <div class="py-5">
                        <div class="flex justify-center items-center">
                            <div class="flex justify-end w-36">
                                <p>Hervé <strong>Ass</strong>ofoun</p>
                                :
                            </div>
                            <h3
                                class="text-sm font-semibold px-2 text-slate-600"
                            >
                                ASS
                            </h3>
                        </div>
                        <div class="flex justify-center items-center">
                            <div class="flex justify-end w-36">
                                <p>Youssef <strong>De S</strong>ouza</p>
                                :
                            </div>
                            <h3
                                class="text-sm font-semibold px-2 text-slate-600"
                            >
                                DES
                            </h3>
                        </div>
                        <div class="flex justify-center items-center">
                            <div class="flex justify-end w-36">
                                <p>Finafa <strong>Lô</strong></p>
                                :
                            </div>
                            <h3
                                class="text-sm font-semibold px-2 text-slate-600"
                            >
                                LO
                            </h3>
                        </div>
                    </div>
                </template>
            </Dialog>
        </div>
    </div>
</template>

<script>
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import moment from "moment";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import ConfirmDialog from "primevue/confirmdialog";
import Toast from "primevue/toast";
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import Loader from "@/components/Loader/index.vue";
import { AsYouType } from "libphonenumber-js";
import VOtpInput from "vue3-otp-input";
import store from "../../store";
import Message from "primevue/message";
import { replaceAccent } from "../../Utils/function";

export default defineComponent({
    components: {
        Toast,
        Message,
        ConfirmDialog,
        Dialog,
        Button,
        Loader,
        AccordionTab,
        Accordion,
        VOtpInput,
    },
    props: {
        data: { type: Object },
    },
    emits: ["updateAppointment"],
    setup(props, { emit }) {
        const isCodeModalDisplay = ref(true);
        const binModal = ref("");
        const appointment = computed(() => props.data);
        const displayTimes = ref(false);
        const selectedTime = ref(props.data?.start_date);

        const code = computed(() =>
            replaceAccent(appointment.value?.patient?.last_name)
                ?.slice(0, 3)
                ?.toUpperCase()
        );
        const slotsData = ref([]);
        const allSlots = ref([]);
        const loadingSlots = ref(false);
        const slots = ref([]);
        const confirm = useConfirm();
        const toast = useToast();
        const selectedSlot = ref(null);
        const statuses = computed(() => ({
            cancelled: "Rendez-vous annulé",
            done: "",
            patient_absent: "Patient non présent au rendez-vous",
            pratician_absent: "Pratician non présent au rendez-vous",
        }));
        const colors = computed(() => ({
            cancelled: "danger",
            done: "",
            pending: "slate-500",
            patient_absent: "warning",
            pratician_absent: "warning",
        }));
        const ways = computed(() => ({
            CARD: "Cartes bancaires",
            CASH: "Èspèces",
            CHECK: "Chèques de banque",
        }));
        const specialties = computed(() =>
            (props.data?.planning?.specialties ?? [])
                .filter((s, i) => i < 3)
                .reduce(
                    (acc, x, i) => (acc += `${i > 0 ? "," : ""} ${x.name}`),
                    ""
                )
                .toString()
        );
        const formatPhone = (phone) => {
            return new AsYouType().input(`+${phone}`);
        };
        const payment = computed(() => {
            let value = "-";
            const len = (props.data?.payments ?? []).length;
            if (len)
                value = (props.data?.payments ?? [])
                    .slice(0, len - 1)
                    .reduce(
                        (acc, x, i) =>
                            (acc +=
                                i > 0
                                    ? `, ${ways.value[x]}`
                                    : `${ways.value[x]}`),
                        ""
                    );
            if (len >= 2 && (props.data.payments ?? [])[len - 1])
                value += ` et ${
                    ways.value[(props.data.payments ?? [])[len - 1]]
                }`;

            return value;
        });
        const initials = computed(() =>
            props.data?.pratician?.full_name
                .match(/(^\S\S?|\s\S)?/g)
                .map((v) => v.trim())
                .join("")
                .match(/(^\S|\S$)?/g)
                .join("")
                .toLocaleUpperCase()
        );

        const price = ref(
            new Intl.NumberFormat("fr-FR", {
                style: "currency",
                currency: "XOF",
            })
        );
        const selectSlot = (data) => {
            selectedSlot.value = { ...data };
            selectedTime.value = data.start;
        };

        const buildShare = () => {
            store.dispatch("medical/buildShare", {
                id: props.data.id,
                onSuccess: (hash) => {
                    window.location.replace(`/anonimous-sessions/${hash}`);
                },
                onError: () => {
                    // loadingSlots.value = false;
                },
            });
        };
        const getSlots = () => {
            loadingSlots.value = true;
            store.dispatch("medical/getSlots", {
                planningId: props.data.planning_id,
                onSuccess: (data) => {
                    slots.value = [...data];
                    buildSlots(data);
                    loadingSlots.value = false;
                },
                onError: () => {
                    loadingSlots.value = false;
                },
            });
        };

        const handleOnComplete = (data) => {
            if (data == code.value) isCodeModalDisplay.value = false;
        };

        const buildSlots = (slots) => {
            const groups = slots.reduce((groups, game) => {
                const date = game.start.split("T")[0];
                if (!groups[date]) {
                    groups[date] = [];
                }
                groups[date].push(game);
                return groups;
            }, {});

            const groupArrays = Object.keys(groups).map((date) => {
                return {
                    date,
                    slots: groups[date],
                };
            });
            slotsData.value = groupArrays;
            allSlots.value = [...groupArrays]
                .sort((a, b) => moment(a.start).unix() - moment(b.start).unix())
                .map((s) => ({
                    date: s.date,
                    slots: s.slots.sort(
                        (a, b) =>
                            moment(a.start).unix() - moment(b.start).unix()
                    ),
                }));
        };
        const validate = () => {
            store.dispatch("medical/updateAppointmentTime", {
                slot: selectedSlot.value,
                appointmentId: props.data.id,
                onSuccess: () => {
                    emit("updateAppointment", props.data.id);
                    displayTimes.value = false;
                    toast.add({
                        severity: "success",
                        summary: "Votre rendez-vous a été mis à jour",
                        detail: "Modification horaire",
                        life: 3000,
                    });
                },
                onError: () => {},
            });
        };

        const cancel = () => {
            store.dispatch("medical/cancelAppointment", {
                appointmentId: props.data.id,
                onSuccess: () => {
                    emit("updateAppointment", props.data.id);
                    displayTimes.value = false;
                    toast.add({
                        severity: "success",
                        summary: "Votre rendez-vous a été bien annulé",
                        detail: "Annulation",
                        life: 3000,
                    });
                },
                onError: () => {},
            });
        };
        const requestUpdate = () => {
            displayTimes.value = true;
            getSlots();
        };

        const requestCancel = () => {
            confirm.require({
                message: "Êtes-vous sûre de vouloir annuler ce rendez-vous ?",
                header: "Annulation",
                draggable: false,
                acceptClass: "",
                rejectClass: "",
                //   icon: "p-confirm-dialog-icon pi pi-exclamation-circle ",
                accept: () => {
                    cancel();
                },
                reject: () => {},
            });
        };

        onMounted(() => {
            console.log("dedededede", props.data);
        });

        return {
            initials,
            payment,
            price,
            isCodeModalDisplay,
            colors,
            statuses,
            selectSlot,
            selectedTime,
            displayTimes,
            specialties,
            ways,
            binModal,
            appointment,
            slotsData,
            loadingSlots,
            moment,
            handleOnComplete,
            buildShare,
            validate,
            formatPhone,
            requestUpdate,
            requestCancel,
        };
    },
});
</script>

<style lang="scss">
.grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
}
.grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
}
</style>
