<template>
    <Head>
        <meta name="robots" content="noindex,nofollow" />

        <title>Allô Doto - Tableau de board</title>
        <meta
            head-key="description"
            name="description"
            content="Allô Doto - Tableau de board"
        />
    </Head>
    <div id="allo-app-dashboard" class="w-full h-screen allo-app-dashboard">
        <div class="px-2">
            <topbar></topbar>
        </div>
        <!-- END: Top Bar -->
        <div class="flex overflow-hidden max-h-screen h-full">
            <!-- BEGIN: Side Menu -->
            <div class="bg-tertiary overflow-scroll">
                <sidebar></sidebar>
            </div>
            <!-- END: Side Menu -->
            <!-- BEGIN: Content -->
            <div class="content min-h-full">
                <dashboard />
            </div>
            <!-- END: Content -->
        </div>
    </div>
</template>

<script>
// import "#/theme/js/app.js";
// import "#/theme/js/ckeditor-classic.js";
// import "#/theme/js/ckeditor-inline.js";
// import "#/theme/js/ckeditor-balloon.js";
// import "#/theme/js/ckeditor-balloon-block.js";
// import "#/theme/js/ckeditor-document.js";
export default {
    setup() {},
};
</script>
<style lang="scss">
@import "#/theme/css/app.scss";
</style>
