
export const SET_DOCTORS = 'SET_DOCTORS';
export const SET_PATIENTS = 'SET_PATIENTS';
export const SET_ALERTS = 'SET_ALERTS';
export const SET_ALERT = 'SET_ALERT';
export const SET_INTERVENTIONS = 'SET_INTERVENTIONS';
export const SET_HOSPITALS = 'SET_HOSPITALS';
export const SET_HOSPITAL = 'SET_HOSPITAL';
export const SET_PATIENT = 'SET_PATIENT'
export const SET_PRESCRIPTIONS = 'SET_PRESCRIPTIONS'
export const SET_PATIENTS_PAGE = 'SET_PATIENTS_PAGE'
export const SET_USER = 'SET_USER'
export const SET_PLANNINGS = 'SET_PLANNINGS'
export const SET_PLANNING_PAGE = 'SET_PLANNING_PAGE'

export default {
    [SET_DOCTORS](state, { doctors }) {
        const { data, last_page, per_page, total, current_page } = doctors;
        state.doctors = data;
        state.doctorPagination.total = total;
        state.doctorPagination.lastPage = last_page;
        state.doctorPagination.perPage = per_page;
        state.doctorPagination.page = current_page;
    },

    [SET_PATIENTS](state, { patients }) {
        const { data, last_page, per_page, total, current_page } = patients;
        state.patients = data;
        state.patientsPagination.total = total;
        state.patientsPagination.lastPage = last_page;
        state.patientsPagination.perPage = per_page;
        state.patientsPagination.page = current_page;
    },

    [SET_PATIENTS_PAGE](state, { page }) {
        state.patientsPagination.page = page;
    },

    [SET_PLANNING_PAGE](state, { page }) {
        state.planningPagination.page = page;
    },

    [SET_USER](state, { user }) {
        state.user = { ...user };
    },

    [SET_ALERTS](state, { alerts }) {
        const { data, last_page, per_page, total, current_page } = alerts;
        state.alerts = data;
        state.alertPagination.total = total;
        state.alertPagination.lastPage = last_page;
        state.alertPagination.perPage = per_page;
        state.alertPagination.page = current_page;
    },

    [SET_PLANNINGS](state, { plannings }) {
        const { data, last_page, per_page, total, current_page } = plannings;
        state.plannings = data;
        state.planningPagination.total = total;
        state.planningPagination.lastPage = last_page;
        state.planningPagination.perPage = per_page;
        state.planningPagination.page = current_page;
    },

    [SET_ALERT](state, { alert }) {
        state.alert = { ...alert };
    },
    [SET_PATIENT](state, { patient }) {
        state.patient = { ...patient };
    },
    [SET_HOSPITAL](state, { hospital }) {
        state.medicalCenter = { ...hospital };
    },

    [SET_HOSPITALS](state, { hospitals }) {
        const { data, current_page, last_page, per_page, from, to, total } = hospitals;
        state.hospitalsPagination = { current_page, last_page, per_page, from, to, total }
        state.hospitals = [...data];
    },
    [SET_PRESCRIPTIONS](state, { prescriptions }) {
        state.prescriptions = [...prescriptions];
        console.log(state.prescriptions);
    },

    [SET_INTERVENTIONS](state, { interventions }) {
        const { data, last_page, per_page, total, current_page } = interventions;
        state.alerts = data;
        state.interventionPagination.total = total;
        state.interventionPagination.lastPage = last_page;
        state.interventionPagination.perPage = per_page;
        state.interventionPagination.page = current_page;
    }
}
