<template>
  <div>
    <form :action="post" method="post">
      <input type="hidden" name="_token" :value="csrf" />
      <div class="-mx-3 md:flex mb-1" id="form_group" v-for="(item, index) in types" :key="index">
        <div class="md:w-1/3 px-3 mb-1 md:mb-0">
          <label
            class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
            for="grid-first-name"
          >Nom</label>
          <input
            v-model="item.name"
            :name="`types[${index}][name]`"
            class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-red rounded py-3 px-4 mb-3"
            id="grid-first-name"
            type="text"
            placeholder="Tablets XSO"
          />
        </div>
        <div class="md:w-1/3 px-3">
          <label
            class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
            for="grid-last-name"
          >Icon</label>
          <input
            v-model="item.icon_class"
            :name="`types[${index}][icon_class]`"
            class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4"
            id="grid-last-name"
            type="text"
            placeholder="las la-bong"
          />
          <p class="text-red text-xs italic mb-6">
            Please fill out this field with icons on this
            <a
              target="_blank"
              href="https://icons8.com/line-awesome"
            >link</a>
          </p>
        </div>

        <div class="md:w-1/3 px-3">
          <label
            class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
            for="grid-password"
          >Description</label>
          <textarea
            v-model="item.description"
            :name="`types[${index}][description]`"
            rows="1"
            class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4 resize-none"
            placeholder="What is this type for ?"
          ></textarea>
          <p class="text-grey-dark text-xs italic">Make it as long and as crazy as youd like</p>
        </div>
      </div>
      <div class="flex justify-end mt-4 form-actions">
        <a
          :href="cancel"
          class="focus:outline-none modal-close bg-gray-400 p-2 m-1 rounded-md text-black hover:bg-gray-300"
        >Cancel</a>
        <button
          @click="add()"
          type="button"
          class="focus:outline-none bg-primary-600 m-1 rounded-md p-2 text-black hover:bg-primary-500"
        >
          Ajouter
          un nouveau
        </button>
        <button
          type="submit"
          class="focus:outline-none bg-teal-500 p-2 m-1 rounded-md text-white hover:bg-teal-400"
        >Confirm</button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "resource-type-form",
  props: ["index", "post", "cancel"],
  data: () => ({
    types: [{ name: "", icon_class: "", description: null }],
    csrf: document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content")
  }),
  methods: {
    add() {
      this.types.push({ name: "", icon_class: "", description: null });
    }
  }
};
</script>
