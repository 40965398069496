<template>
  <div class="w-full h-full flex justify-center overflow-hidden">
    <div class="w-9/12 overflow-y-scroll">
      <div class="py-5">
        <h3
          v-if="documents.length"
          class="font-medium text-xl font-medium text-slate-600"
        >
          Mes documents
        </h3>
      </div>
      <div class="card text-sm h-full">
        <DataTable
          :value="documents"
          tableStyle="min-width: 50rem"
          v-if="documents.length"
        >
          <Column field="document" header="Document" class="text-sm">
            <template #body="slotProps">
              <div class="flex justify-start">
                <div
                  :class="`mr-1 bg-${
                    typesColors[slotProps.data.type]
                  } bg-opacity-25 w-10 h-10 rounded-md flex justify-center items-center`"
                >
                  <i
                    :class="`las la-${icons[slotProps.data.type]} text-${
                      typesColors[slotProps.data.type]
                    } text-lg`"
                  ></i>
                </div>
                <div>
                  <h3 class="text-sm font-ibm font-semibold">
                    {{ typesTexts[slotProps.data?.type] }}
                  </h3>
                  <span class="text-slate-500">{{
                    slotProps.data?.identifier.toUpperCase()
                  }}</span>
                </div>
              </div>
            </template></Column
          >
          <Column field="name" header="Patient" class="text-sm"></Column>
          <Column field="category" header="Créé le" class="text-sm">
            <template #body="slotProps">
              {{ moment(slotProps.data.created_at).format("LL") }}
            </template>
          </Column>
          <Column field="quantity" header="Créé Par" class="text-sm">
            <template #body="slotProps">
              <p class="text-sm font-medium font-qs">
                {{
                  slotProps.data.created_by == user.id
                    ? "Créé par vous"
                    : `Dr ${slotProps.data?.creator?.full_name}`
                }}
              </p>
            </template></Column
          >
          <Column field="actions" header=""></Column>
        </DataTable>
        <div
          v-if="documents.length == 0"
          class="flex h-full w-full justify-center items-center"
        >
          <div class="flex flex-col items-center">
            <img class="w-36" src="/images/no-document.jpg" />
            <h3 class="text-lg font-semibold py-3 text-slate-700">
              Aucun document pour le moment
            </h3>
            <p class="text-sm text-center">
              Trouvez un praticien et prenez rendez-vous en ligne à tout moment.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { computed, defineComponent, onMounted, ref } from "vue";
  import DataTable from "primevue/datatable";
  import Column from "primevue/column";
  import { useStore } from "vuex";
  import moment from "moment";
  export default defineComponent({
    components: { Column, DataTable },
    setup() {
      const user = computed(() => store.getters["auth/user"]);

      const documents = ref([]);
      const pagination = ref();
      const store = useStore();
      const icons = computed(() => ({
        MEDICAL_TEST: "file-medical-alt",
        LABORATORY_RESULT: "prescription",
        RADIOLOGY_REPORT: "biohazard",
      }));

      const typesColors = computed(() => ({
        MEDICAL_TEST: "primary2",
        LABORATORY_RESULT: "primary3",
        RADIOLOGY_REPORT: "warning",
      }));

      const typesTexts = computed(() => ({
        MEDICAL_TEST: "Test Médical",
        LABORATORY_RESULT: "Résultats - Laboratoire",
        RADIOLOGY_REPORT: "Rapport de radiologie",
      }));

      const getDocs = () => {
        store.dispatch("medical/getAllDocuments", {
          filter: {},
          onSuccess: (resp) => {
            console.log("resppp", resp);

            documents.value = [...resp.data];
          },
          onError: () => {},
        });
      };

      onMounted(() => {
        getDocs();
      });

      return {
        user,
        typesTexts,
        typesColors,
        icons,
        documents,
        pagination,
        moment,
      };
    },
  });
</script>