import axios from "./axios";
import sourceAxios from "axios";
import { invoice } from "./endpoint";


let listInvoicesCancelToken = null

export default {

    async getInvoices(data = {}) {
        if (typeof listInvoicesCancelToken != undefined) {
            listInvoicesCancelToken?.cancel(
                'Operation (GET_DELEGATIONS) canceled due to new request.'
            )
        }
        listInvoicesCancelToken = sourceAxios.CancelToken.source()
        const response = await axios.get(invoice.index(), data, listInvoicesCancelToken.token);
        return response.data;
    },

}