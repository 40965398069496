<template>
    <div class="border-b border-slate-100 dark:border-slate-700">
        <ul
            class="flex flex-wrap -mb-px text-sm font-medium text-center"
            id="myTab"
            data-tabs-toggle="#tab-contents"
            role="tablist"
        >
            <li
                v-for="option in options"
                :key="option.key"
                class=""
                role="presentation"
            >
                <button
                    :class="`
            inline-block
            p-2 mr-4
            rounded-t-lg
            hover:text-slate-700
            dark:text-slate-500 dark:hover:text-slate-500
            ${
                option.key === value
                    ? 'border-b-2 border-primary text-primary'
                    : 'text-slate-600'
            }
            dark:border-slate-500`"
                    :id="`${option.key}-tab`"
                    :data-tabs-target="`#${option.key}`"
                    type="button"
                    role="tab"
                    :aria-controls="option.key"
                    :aria-selected="option.key === value"
                    @click.prevent.stop="updateTab(option.key)"
                >
                    <span :class="`la ${option.icon}`"></span> {{ option.name }}
                </button>
            </li>
        </ul>
    </div>
    <div id="tab-contents">
        <div
            v-for="option in options"
            :key="option.key"
            :class="`${option.key === value ? '' : 'hidden'} rounded-lg`"
            :id="`${option.key}`"
            role="tabpanel"
            :aria-labelledby="`${option.key}-tab`"
        >
            <slot v-if="option.key === value" :name="option.key"></slot>
        </div>

        <!-- <div
      v-for="option in options"
      :key="option.key"
      :class="`${option.key === value ? '' : 'hidden'} rounded-lg`"
      :id="`${option.key}`"
      role="tabpanel"
      :aria-labelledby="`${option.key}-tab`"
    >
      <component :is="option.component" v-if="option.key === value"></component>
    </div> -->
    </div>
</template>
<script>
import { onMounted, ref, watch } from "vue";
export default {
    props: {
        options: { type: Array },
        selected: { type: String, default: "" },
    },
    emits: ["tabChanged"],
    setup(props, { emit }) {
        const value = ref(props.selected);

        const updateTab = (tab) => {
            value.value = tab;
            emit("tabChanged", tab);
        };

        watch(
            () => props.selected,
            (val) => {
                updateTab(val);
            }
        );

        onMounted(() => {
            updateTab(props.selected);
        });
        return { value, updateTab };
    },
};
</script>
