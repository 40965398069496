import Lodash from 'lodash'
import Axios from 'axios';
window._ = Lodash

try {
    console.log("Welcome !!!");
} catch (error) {

}
/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = Axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';


window.onscroll
/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */








window.addEventListener("scroll", function () {

    const [header] = document.getElementsByTagName('header')

    const isBlog = typeof window !== 'undefined' && window.location.host.includes('blog.')
    if (header && !isBlog) {
        if (window.pageYOffset > 0) {
            header?.classList.add("sticking");
        } else {
            header?.classList.remove("sticking");
        }
    }

});
