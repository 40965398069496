<template>
    <div class="w-full h-screen flex px-20">
        <div class="w-4/5 flex justify-start items-center">
            <div class="flex flex-col items-start">
                <div class="mb-10">
                    <p class="text-doto-400 font-roboto">
                        {{ user?.username }}
                    </p>
                </div>
                <h1 class="font-roboto text-left big-text text-danger">
                    Erreur 401
                </h1>

                <h3 class="font-qs text-left text-xl py-5 text-danger">
                    Vous n'êtes pas autorisé à accéder à cette page.
                </h3>
                <p
                    v-if="user?.email"
                    class="font-roboto text-left text-xl py-2"
                >
                    Vous êtes :
                    <strong class="text-doto-300 text-sm"
                        >{{ user?.email }} /
                        {{ user?.roles.map((x) => x.name).join(", ") }}</strong
                    >
                </p>

                <span
                    class="py-1 bg-primary px-2 text-white cursor-pointer rounded mt-4"
                    @click="$router.push({ name: 'index' })"
                >
                    <i class="la la-clockwise text-xl"></i>
                    Tableau de bord</span
                >
            </div>
        </div>

        <div
            class="bg-no-repeat min-h-screen w-3/5 bg-center bg-right img-bg"
        ></div>
    </div>
</template>

<script>
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
    setup() {
        const store = useStore();
        const user = computed(() => store.getters["auth/user"]);
        const email = computed(() => user.value?.email);
        return { user };
    },
});
</script>

<style lang="scss">
.big-text {
    font-size: 2rem;
    line-height: 2.5rem;
}
.img-bg {
    // background-image: url("~@/assets/images/illustrations/403.svg");
}
</style>
