<template>
  <div class="mt-5">
    <div
      class="flex justify-between items-center"
      v-if="appointment && !isLoading"
    >
      <div class="flex items-center">
        <router-link :to="{ name: 'app.appointments' }">
          <div
            class="w-14 h-14 rounded-full flex justify-center items-center bg-white mr-3 shadow-lg"
          >
            <i class="la la-arrow-left text-slate-500 text-2xl"></i>
          </div>
        </router-link>
        <h3 class="text-lg font-bold">Détails rendez-vous</h3>
      </div>

      <h5 class="text-slate-500 font-medium">
        Rendez-vous pris le
        <span class="font-semibold">{{
          moment(appointment?.created_at).format("LLLL")
        }}</span>
      </h5>
    </div>
    <div v-if="isLoading" class="flex justify-center text-primary2">
      <Loader class="w-20" />
    </div>
    <div v-else class="bg-white mt-5 p-3 rounded">
      <div class="flex flex-col" v-if="appointment?.cancelled_by">
        <div class="flex items-center">
          <i class="la la-calendar-times text-xl text-danger mr-2"></i>
          <h4>Annulé Par</h4>
        </div>
        <div class="text-danger">
          <h3 class="text-sm">
            <span class="font-semibold">{{
              appointment?.cancellor?.full_name
            }}</span>
            le
            <span class="font-semibold">{{
              moment(appointment?.cancelled_at).format("LLLL")
            }}</span>
          </h3>
        </div>
      </div>

      <div class="mt-3 flex font-semibold items-center">
        <i class="la la-clock text-xl text-slate-500 mr-2"></i>
        <span class="font-semibold text-slate-500 capitalize">{{
          moment(appointment?.start_date).format("LLLL")
        }}</span>
      </div>
      <div class="mt-3 flex font-semibold items-center flex justify-between">
        <div>
          <i class="la la-map-signs text-xl text-slate-500 mr-2"></i>
          <span class="font-semibold text-slate-500 capitalize">{{
            appointment?.address
          }}</span>
        </div>

        <div class="w-1/3 flex">
          <i class="la la-vials text-2xl text-slate-500 mr-2"></i>
          <div>
            <span class="font-semibold text-slate-600 capitalize">{{
              getType(appointment?.type)?.name
            }}</span>
          </div>
        </div>
      </div>

      <div class="mt-10 flex justify-between w-full">
        <div class="">
          <h3 class="text-lg text-slate-500 font-medium">Patient :</h3>

          <div class="mt-2">
            <div class="flex items-center">
              <img
                :src="`${appointment?.patient?.avatar}`"
                class="w-10 h-10 rounded-full overflow-hidden shadow-2 border-round"
              />
              <div class="ml-2">
                <h3>{{ appointment?.patient?.full_name }}</h3>
                <span class="text-slate-500 text-xs">{{
                  appointment?.patient?.username
                }}</span>

                <div class="mt-3">
                  <span class="text-slate-500 text-xs">{{
                    appointment?.patient?.phone_1 ?? "-"
                  }}</span>

                  <span
                    class="text-slate-500 text-xs"
                    v-if="appointment?.patient?.phone_2"
                  >
                    / {{ appointment?.patient?.phone_2 ?? "-" }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="">
          <h3 class="text-lg text-slate-500 font-medium">Centre médical :</h3>

          <div class="mt-2">
            <div class="flex items-center">
              <img
                :src="`${appointment?.medical_center?.profile?.img}`"
                class="w-10 h-10 rounded-full overflow-hidden shadow-2 border-round"
              />
              <div class="ml-2">
                <h3>{{ appointment?.medical_center?.name }}</h3>
                <span class="text-slate-500 text-xs">{{
                  appointment?.medical_center?.identifier
                }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="">
          <h3 class="text-lg text-slate-500 font-medium">Praticien :</h3>

          <div class="mt-2">
            <div class="flex items-center">
              <img
                :src="`${appointment?.pratician?.avatar}`"
                class="w-10 h-10 bg-primary3 rounded-full overflow-hidden shadow-2 border-round"
              />
              <div class="ml-2">
                <h3>{{ appointment?.pratician?.full_name }}</h3>
                <span class="text-slate-500 text-xs">{{
                  appointment?.pratician?.username
                }}</span>
                <h5>{{ appointment?.specialty?.name ?? "-" }}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-end mt-5" v-if="appointment && !isLoading">
      <div>
        <Button
          type="button"
          label="Débuter ce rendez-vous"
          class="bg-primary3 py-2 px-5 rounded text-white font-semibold leading-6 text-sm shadow"
          @click="confirmStart"
          v-if="isConfirmed"
        />

        <Button
          type="button"
          class="bg-slate-600 py-2 px-5 rounded text-white font-semibold leading-6 text-sm shadow ml-2"
          @click="confirmStart"
          v-if="appointment?.started_at"
        >
          <i class="la la-wallet text-xl mr-2"></i> Faire payer
        </Button>

        <Button
          v-if="isConfirmed"
          type="button"
          label="Déplacer ce rendez-vous"
          class="bg-warning py-2 px-5 rounded text-white font-semibold leading-6 text-sm shadow ml-2"
          @click="requestUpdate"
        />

        <Button
          v-if="isConfirmed"
          type="button"
          label="Annuler"
          class="bg-danger py-2 px-5 rounded text-white font-semibold leading-6 text-sm shadow ml-2"
          @click="requestCancel"
        />
      </div>
    </div>
  </div>
  <ConfirmDialog group="positioned"></ConfirmDialog>
  <Toast></Toast>
  <Dialog
    v-model:visible="displayTimes"
    headerClass="text-xs"
    modal
    :header="`Modification du rendez-vous - ${moment(selectedTime).format(
      'LLL'
    )}`"
    :style="{ width: isSmall || isMedium ? '85vw' : '40vw' }"
  >
    <div v-if="loadingSlots" class="flex justify-center text-primary2">
      <Loader class="w-20" />
    </div>
    <div v-else>
      <Accordion
        headerClass="text-sm"
        :activeIndex="0"
        :pt="{
          headerTitle: 'font-semibold text-xs',
        }"
      >
        <AccordionTab
          headerClass="text-sm"
          :header="moment(dateData.date).format('LL')"
          v-for="(dateData, index) in slotsData"
          :key="index"
        >
          <div class="grid grid-cols-6 gap-4 w-full">
            <button
              v-for="idx in dateData.slots.length"
              :key="idx"
              :class="`rounded   px-2 py-2 opacity-75 slot ${
                moment(selectedTime).unix() ==
                moment(dateData.slots[idx - 1]?.start).unix()
                  ? 'bg-slate-700 text-white'
                  : 'bg-[#B5B9C9] text-[#2b4660]'
              }`"
              @click.prevent="selectSlot(dateData.slots[idx - 1])"
            >
              {{ moment(dateData.slots[idx - 1]?.start).format("HH:mm") }}
            </button>
          </div>
        </AccordionTab>
      </Accordion>
    </div>
    <template #footer>
      <Button
        label="Fermer"
        icon="la la-times"
        class="px-3 py-2 text-sm mr-2"
        @click="displayTimes = false"
        text
      />
      <Button
        label="Valider"
        class="px-3 py-2 text-sm bg-success text-white font-mediun shadow-none"
        icon="la la-check"
        @click="validate"
        autofocus
      />
    </template>
  </Dialog>
</template>

<script>
  import { defineComponent, onMounted, ref, computed } from "vue";
  import { useRoute } from "vue-router";
  import { useStore } from "vuex";
  import Button from "primevue/button";
  import Toast from "primevue/toast";
  import moment from "moment";
  import { useConfirm } from "primevue/useconfirm";
  import Accordion from "primevue/accordion";
  import Dialog from "primevue/dialog";
  import AccordionTab from "primevue/accordiontab";
  import ConfirmDialog from "primevue/confirmdialog";
  import { useToast } from "primevue/usetoast";
  import { useWindowSize } from "@vueuse/core";
  import Loader from "@/components/Loader/index.vue";

  export default defineComponent({
    components: {
      Button,
      Dialog,
      Accordion,
      Loader,
      AccordionTab,
      ConfirmDialog,
      Toast,
    },
    setup() {
      const isLoading = ref(true);
      const { width } = useWindowSize();
      const isConfirmed = computed(
        () => !appointment.value || appointment.value?.status == "confirmed"
      );
      const slots = ref([]);
      const isSmall = computed(() => width.value <= 540);
      const isMedium = computed(() => width.value <= 1000 && width.value > 540);
      const isLarge = computed(() => width.value > 1000);
      const toast = useToast();
      const loadingSlots = ref(false);
      const confirm = useConfirm();
      const displayTimes = ref(false);
      const route = useRoute();
      const store = useStore();
      const appointment = ref();
      const selectedTime = ref();
      const slotsData = ref([]);
      const allSlots = ref([]);
      const selectedSlot = ref();
      const getAppointment = () => {
        const appointmentId = route.params.appointmentId;
        store.dispatch("medical/getAppointment", {
          appointmentId: appointmentId,
          onError: () => {
            isLoading.value = false;
          },
          onSuccess: (resp) => {
            selectedTime.value = resp.start_date;
            appointment.value = { ...resp };
            isLoading.value = false;
          },
        });
      };

      const cancel = () => {
        store.dispatch("medical/cancelAppointmentTime", {
          appointmentId: appointment.value.id,
          onSuccess: () => {
            displayTimes.value = false;
            toast.add({
              severity: "success",
              summary: "Le rendez-vous a bien été bien annulé",
              detail: "Annulation",
              life: 3000,
            });
          },
          onError: () => {
            toast.add({
              severity: "error",
              summary: "Annulation rendez-vous",
              detail:
                "Oooops, une erreur est survenue lors de l'annulation. Essayer de joindre le patient par téléphone",
              life: 10000,
            });
          },
        });
      };

      const startAppointment = () => {
        const appointmentId = route.params.appointmentId;
        store.dispatch("medical/startAppointment", {
          appointmentId: appointmentId,
          onError: () => {
            toast.add({
              severity: "error",
              summary: "Début rendez-vous",
              detail: "Oooops, vous ne pouvez pas faire cette action",
              life: 3000,
            });
          },
          onSuccess: (resp) => {
            getAppointment();
            toast.add({
              severity: "info",
              summary: "Début rendez-vous",
              detail: "Bien noté",
              life: 3000,
            });
          },
        });
      };

      const requestCancel = () => {
        confirm.require({
          group: "positioned",
          message: "Êtes-vous sûre de vouloir annuler ce rendez-vous ?",

          icon: "la text-danger la-exclamation-triangle",
          header: "Annulation",
          acceptLabel: "Oui",
          rejectLabel: "Non",
          draggable: false,
          position: "bottom",
          rejectClass: "shadow-none px-3 py-2",
          acceptClass: "bg-success text-white shadow-none px-3 py-2 ml-2",
          acceptLabel: "Oui",
          rejectLabel: "Non",

          accept: () => {
            cancel();
          },
          reject: () => {},
        });
      };
      const selectSlot = (data) => {
        selectedSlot.value = { ...data };
        selectedTime.value = data.start;
      };

      const validate = () => {
        store.dispatch("medical/updateAppointmentTime", {
          slot: selectedSlot.value,
          appointmentId: appointment.value.id,
          onSuccess: () => {
            selectSlot({});
            displayTimes.value = false;
            toast.add({
              severity: "success",
              summary: "Le rendez-vous a bien été mis à jour",
              detail: "Modification horaire",
              life: 3000,
            });
          },
          onError: () => {
            toast.add({
              severity: "error",
              summary: "Oooops, une erreur est survenue lors de la mise à jour",
              detail: "Modification horaire",
              life: 3000,
            });
          },
        });
      };

      const confirmStart = () => {
        confirm.require({
          group: "positioned",
          message: "Vous débutez bien ce rendez-vous à l'instant ?",
          header: "Confirmation",
          icon: "la la-info-circle",
          position: "bottom",
          rejectClass: "shadow-none px-3 py-2",
          acceptClass: "bg-success text-white shadow-none px-3 py-2 ml-2",
          acceptLabel: "Oui",
          rejectLabel: "Non",
          accept: () => {
            startAppointment();
          },
          reject: () => {},
        });
      };

      const getType = (type) => {
        const motives = [
          {
            id: "FIRST_CONSULTATION",
            name: "Première consultation chez ce praticien",
          },
          {
            id: "MEDICAL_MONITORING",
            name: "Consultation de suivi médical (suite à une consultation préalable avec ce praticien)",
          },
          {
            id: "MEDICAL_MONITORING_OTHER",
            name: "Consultation de suivi médical",
          },
          {
            id: "EMERGENCY",
            name: "Urgence",
          },
        ];

        return motives.find((x) => x.id == type);
      };

      const getSlots = () => {
        loadingSlots.value = true;
        store.dispatch("medical/getSlots", {
          planningId: appointment.value?.planning_id,
          onSuccess: (data) => {
            slots.value = [...data];
            buildSlots(data);
            loadingSlots.value = false;
          },
          onError: () => {
            loadingSlots.value = false;
          },
        });
      };

      const requestUpdate = () => {
        displayTimes.value = true;
        getSlots();
      };

      const buildSlots = (slots) => {
        const groups = slots.reduce((groups, game) => {
          const date = game.start.split("T")[0];
          if (!groups[date]) {
            groups[date] = [];
          }
          groups[date].push(game);
          return groups;
        }, {});

        const groupArrays = Object.keys(groups).map((date) => {
          return {
            date,
            slots: groups[date],
          };
        });
        slotsData.value = groupArrays;
        allSlots.value = [...groupArrays]
          .sort((a, b) => moment(a.start).unix() - moment(b.start).unix())
          .map((s) => ({
            date: s.date,
            slots: s.slots.sort(
              (a, b) => moment(a.start).unix() - moment(b.start).unix()
            ),
          }));
      };

      onMounted(() => {
        getAppointment();
      });

      return {
        appointment,
        displayTimes,
        allSlots,
        moment,
        selectSlot,
        slots,
        requestCancel,
        validate,
        isSmall,
        isMedium,
        isLarge,
        slotsData,
        loadingSlots,
        isLoading,
        selectedTime,
        getType,
        isConfirmed,
        requestUpdate,
        confirmStart,
      };
    },
  });
</script>