<template>
  <div class="w-full my-6">
    <div class="w-full flex" v-if="patient">
      <div class="w-1/4">
        <div
          class="w-full bg-white rounded justify-center items-center flex flex-col py-4 px-2"
        >
          <div
            class="border-2 border-slate-300 h-20 w-20 rounded-full overflow-hidden"
          >
            <img :src="patient.avatar" alt="" srcset="" />
          </div>
          <div class="flex flex-col justify-center items-center flex flex-col">
            <h3 class="text-primary-600 text-md font-bold mt-2">
              {{ patient.full_name }}
            </h3>
            <h5 class="text-black mt-3 text-sm">
              <strong>Patient ID:</strong> {{ patient.username }}
            </h5>
            <h4 class="text-gray-700 text-xs">
              Age:
              <span v-if="age"> {{ age }} ans</span>
              <span v-else>-</span>
            </h4>
          </div>
          <div class="mt-4 px-10">
            <button
              class="w-full bg-doto-300 text-white rounded px-3 py-1 hover:bg-primary-700 mb-3"
            >
              View profile
            </button>
            <button
              class="w-full bg-doto-600 text-doto-600 bg-opacity-25 hover:bg-opacity-50 rounded px-3 py-1"
            >
              Edit profile
            </button>
          </div>
        </div>

        <div class="w-full bg-white flex flex-col items-left mt-3 p-3 rounded">
          <h6 class="text-gray-400 text-xs">Status</h6>
          <h3 class="text-blue-700 text-md">-</h3>
        </div>

        <div
          class="w-full bg-white flex flex-col items-start mt-3 rounded py-3"
        >
          <h4 class="text-md font-bold my-4 text-center w-full">
            Informations
          </h4>
          <div class="flex flex w-full py-1">
            <h6 class="text-black text-xs w-1/2 flex justify-end">
              <strong>Status: </strong>
            </h6>
            <h3 class="text-black text-xs ml-2">-</h3>
          </div>

          <div class="flex flex w-full py-1">
            <h6 class="text-black text-xs w-1/2 flex justify-end">
              <strong>Groupe Sanguin: </strong>
            </h6>
            <h3 class="text-black text-xs ml-2 uppercase">
              {{ patient.profile?.blood_group }}
            </h3>
          </div>

          <div
            class="flex flex w-full py-1"
            v-for="record in patient.records"
            :key="record?.type?.id"
          >
            <h6 class="text-black text-xs w-1/2 flex justify-end text-right">
              <strong>{{ record?.type.name }}: </strong>
            </h6>
            <h3
              v-if="record.type.data_type == 'checkbox'"
              class="text-black text-xs ml-2"
            >
              {{ record.value == 1 ? "Oui" : "Non" }}
            </h3>
            <h3 v-else class="text-black text-xs ml-2">
              {{ record.value }}
            </h3>
          </div>

          <div class="flex flex w-full py-1">
            <h6 class="text-black text-xs w-1/2 flex justify-end">
              <strong>Taille: </strong>
            </h6>
            <h3 class="text-black text-xs ml-2">
              {{ patient.profile?.size }}
            </h3>
          </div>

          <div class="flex flex w-full py-1">
            <h6 class="text-black text-xs w-1/2 flex justify-end">
              <strong>Poids: </strong>
            </h6>
            <h3 class="text-black text-xs ml-2">
              {{ patient.profile?.weight }} kg
            </h3>
          </div>

          <div class="flex flex w-full py-1">
            <h6 class="text-black text-xs w-1/2 flex justify-end">
              <strong>Dernière connexion: </strong>
            </h6>
            <h3 class="text-black text-xs ml-2">
              {{ patient.last_login ?? "-" }}
            </h3>
          </div>
        </div>
      </div>
      <div class="w-3/4 pl-3 mb-5">
        <div class="flex my-4 justify-start">
          <button
            class="bg-slate-500 text-white rounded px-3 py-1 hover:bg-blue-700 mr-2"
          >
            Print patient prescription & reports
            <i class="fa fa-printer text-white mx-2"></i>
          </button>

          <button
            class="bg-slate-500 text-white rounded px-3 py-1 hover:bg-blue-700 mr-2"
          >
            Email patient prescription & reports
            <i class="fa fa-envelope text-white mx-2"></i>
          </button>
          <button
            class="bg-slate-500 text-white rounded px-3 py-1 hover:bg-blue-700"
          >
            Post-Op Notes
          </button>
        </div>
        <div
          class="rounded-t-md bg-white py-2 flex justify-center items-center mb-1"
        >
          <h5 class="text-primary-500">Signaux vitaux</h5>
        </div>
        <div class="w-full flex justify-between">
          <div class="w-1/4 flex flex-col items-start pr-2">
            <div class="bg-white rounded w-full p-3">
              <img src="/images/heart.png" class="h-8 w-8" alt="" />
              <h4 class="text-black text-sm my-2 text-gray-400 font-light">
                Heart Rate
              </h4>
              <h4 class="text-sm">
                <strong class="text-primary-600 text-lg"
                  >{{ heartRate ? heartRate.value : "-" }}
                </strong>
                bmp
              </h4>
            </div>
          </div>

          <div class="w-1/4 flex flex-col items-center pr-2">
            <div class="bg-white rounded w-full p-3">
              <img src="/images/lungs.png" class="h-8 w-8" alt="" />
              <h4 class="text-black text-sm my-2 text-gray-400 font-light">
                Oxygen Saturation
              </h4>
              <h4 class="text-sm">
                <strong class="text-primary-600 text-lg"
                  >{{ oxygenSaturation ? oxygenSaturation.value : "-" }}
                </strong>
                %
              </h4>
            </div>
          </div>

          <div class="w-1/4 flex flex-col items-center pr-2">
            <div class="bg-white rounded w-full p-3">
              <img src="/images/temperatures.png" class="h-8 w-8" alt="" />
              <h4 class="text-black text-sm my-2 text-gray-400 font-light">
                Body Temperature
              </h4>
              <h4 class="text-sm">
                <strong class="text-primary-600 text-lg"
                  >{{ bodyTemperature ? bodyTemperature.value : "-" }}
                </strong>
                &#176;C
              </h4>
            </div>
          </div>

          <div class="w-1/4 flex flex-col items-end">
            <div class="bg-white rounded w-full p-3">
              <img src="/images/swab.png" class="h-8 w-8" alt="" />
              <h4 class="text-black text-sm my-2 text-gray-400 font-light">
                Glucose Level
              </h4>
              <h4 class="text-sm">
                <strong class="text-primary-600 text-lg"
                  >{{ glucoseLevel ? glucoseLevel.value : "-" }}
                </strong>
                mg/dl
              </h4>
            </div>
          </div>
        </div>

        <div class="bg-white p-3 mt-4 rounded">
          <tabs :options="tabs" selected="prescriptions">
            <template v-for="opt in tabs" :key="opt.key" #[opt.key]="{}">
              <component :is="opt.component"></component>
            </template>
          </tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import store from "@/store";
  import { mapGetters } from "vuex";
  import moment from "moment";
  import { XIcon, PlusIcon } from "vue-feather-icons";
  import Prescription from "./prescription.vue";
  import Reports from "./reports.vue";
  import { onMounted, ref, computed, watch } from "vue";
  import { useRouter } from "vue-router";
  export default {
    components: { XIcon, PlusIcon, Prescription },
    async beforeRouteEnter(to, from, next) {
      store.dispatch("medical/getHospitals", {});

      await store.dispatch("medical/loadPatient", {
        identifier: to.params.id,
      });
      next();
    },
    setup() {
      const portfolio = computed(() => store.getters["auth/portfolio"]);
      const router = useRouter();
      const patient = computed(() => store.getters["medical/patient"]);
      const tabs = ref([
        {
          name: "Prescriptions",
          icon: "",
          key: "prescriptions",
          component: Prescription,
        },
        {
          name: "Résultats",
          icon: "",
          key: "results",
          component: Reports,
        },
        {
          name: "Upload",
          icon: "fa-upload",
          key: "upload",
          component: Prescription,
        },
        {
          name: "Consultations",
          icon: "fa-upload",
          key: "consultations",
          component: Prescription,
        },
      ]);
      const age = computed(() =>
        Math.round(moment().diff(patient.value.birth_date, "years", true))
      );

      watch(
        () => portfolio.value,
        (val) => {
          router.push({ name: "app.patients" });
        }
      );
      onMounted(() => {
        store.dispatch("medical/getPatientPrescriptions", {
          patientId: patient.value.id,
        });
      });
      return { tabs, patient, age };
    },

    computed: {
      ...mapGetters({
        // patient: "medical/patient",
        doctors: "medical/doctors",
        prescriptions: "medical/prescriptions",
      }),
      records() {
        return this.patient.records || [];
      },
      lastPrescriptions() {
        return (this.prescriptions || [])
          .filter((x) => x.type === "pharmaceuticals")
          .sort((a, b) => moment.utc(b.created_at).diff(moment.utc(a.created_at)))
          .slice(0, 3);
      },
      lastExamens() {
        return (this.prescriptions || [])
          .filter((x) => x.type === "examen")
          .sort((a, b) => moment.utc(b.createdAt).diff(moment.utc(a.createdAt)))
          .slice(0, 3);
      },
      glucoseLevel() {
        return this.records.find(
          (x) => x.type && x.type.slug === "glucose-level"
        );
      },
      bodyTemperature() {
        return this.records.find(
          (x) => x.type && x.type.slug === "body-temperature"
        );
      },
      heartRate() {
        return this.records.find((x) => x.type && x.type.slug === "heart-rate");
      },
      oxygenSaturation() {
        return this.records.find(
          (x) => x.type && x.type.slug === "oxygen-saturation"
        );
      },
    },
  };
</script>
<style lang="scss">
.app-tabs {
  .border {
    border-width: 0 !important;
    padding: 0 !important;
  }
}
</style>
