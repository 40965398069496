<template>
    <div class="mt-5 mb-10">
        <div v-if="interventions.length" class="table-responsive">
            <table striped outlined class="table table-striped" hover>
                <thead>
                    <tr>
                        <th
                            class="whitespace-nowrap font-ibm font-medium text-sm"
                        >
                            Identifiant
                        </th>

                        <th
                            class="whitespace-nowrap font-ibm font-medium text-sm"
                        >
                            Adresse
                        </th>
                        <th
                            class="whitespace-nowrap font-ibm font-medium text-sm"
                        >
                            Hopital
                        </th>
                        <th
                            class="whitespace-nowrap font-ibm font-medium text-sm"
                        >
                            Ambulance
                        </th>
                        <th
                            class="whitespace-nowrap font-ibm font-medium text-sm"
                        >
                            Date de création
                        </th>
                        <th
                            class="whitespace-nowrap font-ibm font-medium text-sm"
                        >
                            Status
                        </th>
                        <th class="whitespace-nowrap"></th>
                    </tr>
                </thead>

                <tbody>
                    <tr
                        v-for="intervention in interventions"
                        :key="intervention.id"
                    >
                        <td class="font-ibm text-sm text-slate-700">
                            <span
                                class="bg-pink-100 text-pink-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-pink-200 dark:text-pink-900"
                                >{{ intervention.code }}</span
                            >
                        </td>

                        <td class="font-ibm text-sm text-slate-700">
                            {{ intervention.address }}
                        </td>
                        <td class="font-ibm text-sm text-slate-700">
                            {{ intervention.hospital?.name }}
                        </td>
                        <td class="font-ibm text-sm text-slate-700">
                            {{ intervention.ambulance?.code }} /
                            {{ intervention.ambulance?.registration }}
                        </td>

                        <td class="font-ibm text-sm text-slate-700">
                            {{
                                intervention.created_at
                                    ? moment(intervention.created_at).format(
                                          "l hh:mm:ss"
                                      )
                                    : "-"
                            }}
                        </td>
                        <td class="font-ibm text-sm text-slate-700">
                            {{ intervention.status }}
                        </td>

                        <td class="font-ibm text-sm text-slate-700">
                            <div class="flex justify-end">
                                <button
                                    class="bg-warning mr-1 px-2 py-1 rounded text-warning bg-opacity-25"
                                    size="sm"
                                    @click="
                                        $router.push({
                                            name: 'app.alerts_get',
                                            params: { id: intervention.id },
                                        })
                                    "
                                >
                                    <i class="las la-eye m-0"></i>
                                </button>
                                <button
                                    v-if="intervention.status == 'pending'"
                                    class="px-2 py-1 bg-danger rounded text-danger bg-opacity-25"
                                    size="sm"
                                    @click.prevent="handlePopup(intervention)"
                                >
                                    <i class="la la-trash m-0"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-else class="p-3">
            <div class="flex flex-col justify-center items-center">
                <img class="w-1/5" src="/images/no-result.svg" />
                <h4 class="text-primary font-bold my-3 text-md">
                    Aucune intervention
                </h4>
            </div>
        </div>
    </div>
    <Modal
        title="Suppression"
        width="w-2/5"
        :isVisible="canDelete"
        @cancel="cancelDeletion"
        @submit="submitDeletion"
    >
        <div>
            <p class="font-qs text-md">Cette action est définitive.</p>
            <h3 class="text-danger font-ibm text-lg font-bold py-2">
                Êtes-vous sûre de vouloir continuer ?
            </h3>
        </div>
    </Modal>
</template>

<script>
import { onMounted, ref, watch, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import moment from "moment";
import Modal from "@/components/Modal/SampleModal.vue";

export default {
    name: "Interventions",
    components: { Modal },
    props: { selected: { type: Boolean, default: true } },
    setup(props) {
        const interventions = ref([]);
        const store = useStore();
        const route = useRoute();
        const currentIntervention = ref(null);
        const portfolio = computed(() => store.getters["auth/portfolio"]);
        const canDelete = ref(false);
        const getInterventions = () => {
            store.dispatch("medical/getAlertInterventions", {
                portfolioId: portfolio.value?.id,
                id: route.params.id,
                onSuccess: (data) => {
                    interventions.value = [...data];
                },
                onError: (err) => {},
            });
        };

        const handlePopup = (interv) => {
            currentIntervention.value = { ...interv };
            canDelete.value = true;
        };

        const cancelDeletion = () => {
            canDelete.value = false;
            currentIntervention.value = null;
        };

        const submitDeletion = () => {
            store.dispatch("medical/removeIntervention", {
                interventionId: currentIntervention.value.id,
                onSuccess: () => {
                    cancelDeletion();
                    getInterventions();
                },
                onError: () => {},
            });
        };
        onMounted(() => {
            getInterventions();
        });

        watch(
            () => props.selected,
            (val) => {
                if (val) getInterventions;
            }
        );

        return {
            cancelDeletion,
            interventions,
            canDelete,
            currentIntervention,
            moment,
            handlePopup,
            submitDeletion,
        };
    },
};
</script>
