<template>
    <Head>
        <meta name="robots" content="noindex,nofollow" />

        <title>Allô Doto - Rendez-vous confirmé</title>
        <meta
            head-key="description"
            name="description"
            content="Allô Doto - Rendez-vous confirmé"
        />
    </Head>
    <div id="app" class="bg-[#F8F9FC] min-h-screen">
        <app-bar></app-bar>

        <appointment-success :data="appointment"></appointment-success>
    </div>

    <Footer></Footer>
</template>

<script setup>
import Footer from "@/components/footer/index.vue";
import { Head } from "@inertiajs/vue3";

import { onMounted } from "vue";
const props = defineProps({ appointment: Object });

onMounted(() => {});
</script>
