<template>
    <VueMultiselect
        class="w-full form-control"
        v-model="modelValue"
        :placeholder="placeholder"
        label="name"
        track-by="id"
        :options="options"
        :multiple="multiple"
        :taggable="taggable"
        :utc="false"
        @input="$emit('input', $event)"
        @select="handleChange"
        @remove="handleRemove"
    ></VueMultiselect>
</template>

<script>
import { ref, watch } from "vue";

import VueMultiselect from "vue-multiselect";
export default {
    components: { VueMultiselect },
    props: {
        taggable: { type: Boolean, default: false },
        multiple: { type: Boolean, default: false },
        options: { type: Array, default: () => [] },
        placeholder: { type: String, default: null },
        value: { type: [String, Array], default: null },
    },
    emits: ["select", "remove"],
    setup(props, { emit }) {
        const modelValue = ref(props.value);

        const handleChange = (option, id) => {
            emit("change", option, id);
            emit("select", option, id);
        };
        const handleRemove = (elm, id) => {
            emit("remove", elm, id);
        };

        watch(props, () => {
            modelValue.value = props.value;
        });
        return { modelValue, handleChange, handleRemove };
    },
};
</script>
<style lang="scss">
@import "vue-multiselect/dist/vue-multiselect.min.css";

.multiselect__input {
    border: none;
    border: none !important;
    outline: none !important;
    border-color: inherit !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    &:hover {
        border: none !important;
        outline: none !important;
        border-color: inherit;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
}
</style>
