import { storageKeys } from "../../services/endpoint";
import storage from "@/services/Storage";

export const SET_TOKEN = 'SET_TOKEN';
export const SET_USER = 'SET_USER';
export const SET_PORTFOLIOS = 'SET_PORTFOLIOS'
export const SET_PORTFOLIO = 'SET_PORTFOLIO'

export default {
    [SET_TOKEN](state, { token }) {
        state.token = token;
        storage.set(storageKeys.authToken, token);
    },
    [SET_PORTFOLIOS](state, { portfolios }) {
        if (portfolios.length == 1) state.currentPortfolio = portfolios[0]
        state.portfolios = [...portfolios];
    },
    [SET_PORTFOLIO](state, { portfolio }) {
        state.currentPortfolio = { ...portfolio };
    },
    [SET_USER](state, { user }) {
        state.user = user;
        // if (user.api_token) state.commit({ type: SET_TOKEN, token: user.api_token });
    }
}
