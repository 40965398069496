<template>
    <div
        class="top-bar-boxed h-[70px] md:h-[65px] z-[10] border-b border-white/[0.08] md:mt-0 -mx-3 sm:-mx-8 md:-mx-0 px-3 md:border-b-0 relative md:fixed md:inset-x-0 md:top-0 sm:px-8 md:px-10 md:pt-10 md:bg-gradient-to-b md:from-slate-100 md:to-transparent dark:md:from-darkmode-700"
    >
        <div class="h-full flex px-6 items-center">
            <a
                href="/"
                class="logo flex items-center -intro-x md:flex xl:w-[180px] block"
            >
                <img
                    alt="Allô Doto Image"
                    class="logo__image w-20"
                    src="/images/logo_white.svg"
                />
            </a>

            <nav aria-label="breadcrumb" class="-intro-x h-[45px] mr-auto">
                <ol class="breadcrumb breadcrumb-light">
                    <li class="breadcrumb-item"><a href="#">Application</a></li>
                    <li class="breadcrumb-item active" aria-current="page">
                        {{ meta?.name ?? "" }}
                    </li>
                </ol>
            </nav>

            <div class="intro-x relative mr-3 sm:mr-6 w-1/5">
                <div
                    class="search hidden sm:block"
                    v-if="portfolios.length > 0"
                >
                    <Select
                        class="w-full"
                        name="city"
                        v-model="selectedPortfolio"
                        :value="selectedPortfolio"
                        :options="portfolios"
                        @selected="handlePortfolioChange"
                    />
                </div>
                <a class="notification notification--light sm:hidden" href="">
                    <i
                        data-lucide="search"
                        class="notification__icon dark:text-slate-500"
                    ></i>
                </a>
                <div class="search-result">
                    <div class="search-result__content">
                        <div class="search-result__content__title">Pages</div>
                        <div class="mb-5">
                            <a href="" class="flex items-center">
                                <div
                                    class="w-8 h-8 bg-success/20 dark:bg-success/10 text-success flex items-center justify-center rounded-full"
                                >
                                    <i class="w-4 h-4" data-lucide="inbox"></i>
                                </div>
                                <div class="ml-3">Mail Settings</div>
                            </a>
                            <a href="" class="flex items-center mt-2">
                                <div
                                    class="w-8 h-8 bg-pending/10 text-pending flex items-center justify-center rounded-full"
                                >
                                    <i class="w-4 h-4" data-lucide="users"></i>
                                </div>
                                <div class="ml-3">Users & Permissions</div>
                            </a>
                            <a href="" class="flex items-center mt-2">
                                <div
                                    class="w-8 h-8 bg-primary/10 dark:bg-primary/20 text-primary/80 flex items-center justify-center rounded-full"
                                >
                                    <i
                                        class="w-4 h-4"
                                        data-lucide="credit-card"
                                    ></i>
                                </div>
                                <div class="ml-3">Transactions Report</div>
                            </a>
                        </div>
                        <div class="search-result__content__title">Users</div>
                        <div class="mb-5">
                            <a href="" class="flex items-center mt-2">
                                <div class="w-8 h-8 image-fit">
                                    <img
                                        alt="Midone - HTML Admin Template"
                                        class="rounded-full"
                                        src="/dist/images/profile-15.jpg"
                                    />
                                </div>
                                <div class="ml-3">{{ user?.full_name }}</div>
                                <div
                                    class="ml-auto w-48 truncate text-slate-500 text-xs text-right"
                                >
                                    {{ user?.email }}
                                </div>
                            </a>
                            <a href="" class="flex items-center mt-2">
                                <div class="w-8 h-8 image-fit">
                                    <img
                                        alt="Midone - HTML Admin Template"
                                        class="rounded-full"
                                        src="/dist/images/profile-7.jpg"
                                    />
                                </div>
                                <div class="ml-3">Johnny Depp</div>
                                <div
                                    class="ml-auto w-48 truncate text-slate-500 text-xs text-right"
                                >
                                    johnnydepp@left4code.com
                                </div>
                            </a>
                            <a href="" class="flex items-center mt-2">
                                <div class="w-8 h-8 image-fit">
                                    <img
                                        alt="Midone - HTML Admin Template"
                                        class="rounded-full"
                                        src="/dist/images/profile-12.jpg"
                                    />
                                </div>
                                <div class="ml-3">Robert De Niro</div>
                                <div
                                    class="ml-auto w-48 truncate text-slate-500 text-xs text-right"
                                >
                                    robertdeniro@left4code.com
                                </div>
                            </a>
                            <a href="" class="flex items-center mt-2">
                                <div class="w-8 h-8 image-fit">
                                    <img
                                        alt="Midone - HTML Admin Template"
                                        class="rounded-full"
                                        src="/dist/images/profile-1.jpg"
                                    />
                                </div>
                                <div class="ml-3">Morgan Freeman</div>
                                <div
                                    class="ml-auto w-48 truncate text-slate-500 text-xs text-right"
                                >
                                    morganfreeman@left4code.com
                                </div>
                            </a>
                        </div>
                        <div class="search-result__content__title">
                            Products
                        </div>
                        <a href="" class="flex items-center mt-2">
                            <div class="w-8 h-8 image-fit">
                                <img
                                    alt="Midone - HTML Admin Template"
                                    class="rounded-full"
                                    src="/dist/images/preview-1.jpg"
                                />
                            </div>
                            <div class="ml-3">Samsung Q90 QLED TV</div>
                            <div
                                class="ml-auto w-48 truncate text-slate-500 text-xs text-right"
                            >
                                Electronic
                            </div>
                        </a>
                        <a href="" class="flex items-center mt-2">
                            <div class="w-8 h-8 image-fit">
                                <img
                                    alt="Midone - HTML Admin Template"
                                    class="rounded-full"
                                    src="/dist/images/preview-14.jpg"
                                />
                            </div>
                            <div class="ml-3">Sony A7 III</div>
                            <div
                                class="ml-auto w-48 truncate text-slate-500 text-xs text-right"
                            >
                                Photography
                            </div>
                        </a>
                        <a href="" class="flex items-center mt-2">
                            <div class="w-8 h-8 image-fit">
                                <img
                                    alt="Midone - HTML Admin Template"
                                    class="rounded-full"
                                    src="/dist/images/preview-3.jpg"
                                />
                            </div>
                            <div class="ml-3">Dell XPS 13</div>
                            <div
                                class="ml-auto w-48 truncate text-slate-500 text-xs text-right"
                            >
                                PC &amp; Laptop
                            </div>
                        </a>
                        <a href="" class="flex items-center mt-2">
                            <div class="w-8 h-8 image-fit">
                                <img
                                    alt="Midone - HTML Admin Template"
                                    class="rounded-full"
                                    src="/dist/images/preview-11.jpg"
                                />
                            </div>
                            <div class="ml-3">Nike Tanjun</div>
                            <div
                                class="ml-auto w-48 truncate text-slate-500 text-xs text-right"
                            >
                                Sport &amp; Outdoor
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            <div class="intro-x dropdown mr-4 sm:mr-6">
                <div
                    class="dropdown-toggle notification notification--bullet cursor-pointer"
                    role="button"
                    aria-expanded="false"
                    data-tw-toggle="dropdown"
                >
                    <i
                        data-lucide="bell"
                        class="notification__icon dark:text-slate-500"
                    ></i>
                </div>
                <div class="notification-content pt-2 dropdown-menu">
                    <div class="notification-content__box dropdown-content">
                        <div class="notification-content__title">
                            Notifications
                        </div>
                        <div class="cursor-pointer relative flex items-center">
                            <div class="w-12 h-12 flex-none image-fit mr-1">
                                <img
                                    alt="Midone - HTML Admin Template"
                                    class="rounded-full"
                                    src="/dist/images/profile-15.jpg"
                                />
                                <div
                                    class="w-3 h-3 bg-success absolute right-0 bottom-0 rounded-full border-2 border-white"
                                ></div>
                            </div>
                            <div class="ml-2 overflow-hidden">
                                <div class="flex items-center">
                                    <a
                                        href="javascript:;"
                                        class="font-medium truncate mr-5"
                                        >Christian Bale</a
                                    >
                                    <div
                                        class="text-xs text-slate-400 ml-auto whitespace-nowrap"
                                    >
                                        06:05 AM
                                    </div>
                                </div>
                                <div
                                    class="w-full truncate text-slate-500 mt-0.5"
                                >
                                    It is a long established fact that a reader
                                    will be distracted by the readable content
                                    of a page when looking at its layout. The
                                    point of using Lorem
                                </div>
                            </div>
                        </div>
                        <div
                            class="cursor-pointer relative flex items-center mt-5"
                        >
                            <div class="w-12 h-12 flex-none image-fit mr-1">
                                <img
                                    alt="Midone - HTML Admin Template"
                                    class="rounded-full"
                                    src="/dist/images/profile-7.jpg"
                                />
                                <div
                                    class="w-3 h-3 bg-success absolute right-0 bottom-0 rounded-full border-2 border-white"
                                ></div>
                            </div>
                            <div class="ml-2 overflow-hidden">
                                <div class="flex items-center">
                                    <a
                                        href="javascript:;"
                                        class="font-medium truncate mr-5"
                                        >Johnny Depp</a
                                    >
                                    <div
                                        class="text-xs text-slate-400 ml-auto whitespace-nowrap"
                                    >
                                        06:05 AM
                                    </div>
                                </div>
                                <div
                                    class="w-full truncate text-slate-500 mt-0.5"
                                >
                                    Lorem Ipsum is simply dummy text of the
                                    printing and typesetting industry. Lorem
                                    Ipsum has been the industry&#039;s standard
                                    dummy text ever since the 1500
                                </div>
                            </div>
                        </div>
                        <div
                            class="cursor-pointer relative flex items-center mt-5"
                        >
                            <div class="w-12 h-12 flex-none image-fit mr-1">
                                <img
                                    alt="Midone - HTML Admin Template"
                                    class="rounded-full"
                                    src="/dist/images/profile-12.jpg"
                                />
                                <div
                                    class="w-3 h-3 bg-success absolute right-0 bottom-0 rounded-full border-2 border-white"
                                ></div>
                            </div>
                            <div class="ml-2 overflow-hidden">
                                <div class="flex items-center">
                                    <a
                                        href="javascript:;"
                                        class="font-medium truncate mr-5"
                                        >Robert De Niro</a
                                    >
                                    <div
                                        class="text-xs text-slate-400 ml-auto whitespace-nowrap"
                                    >
                                        05:09 AM
                                    </div>
                                </div>
                                <div
                                    class="w-full truncate text-slate-500 mt-0.5"
                                >
                                    Contrary to popular belief, Lorem Ipsum is
                                    not simply random text. It has roots in a
                                    piece of classical Latin literature from 45
                                    BC, making it over 20
                                </div>
                            </div>
                        </div>
                        <div
                            class="cursor-pointer relative flex items-center mt-5"
                        >
                            <div class="w-12 h-12 flex-none image-fit mr-1">
                                <img
                                    alt="Midone - HTML Admin Template"
                                    class="rounded-full"
                                    src="/dist/images/profile-1.jpg"
                                />
                                <div
                                    class="w-3 h-3 bg-success absolute right-0 bottom-0 rounded-full border-2 border-white"
                                ></div>
                            </div>
                            <div class="ml-2 overflow-hidden">
                                <div class="flex items-center">
                                    <a
                                        href="javascript:;"
                                        class="font-medium truncate mr-5"
                                        >Morgan Freeman</a
                                    >
                                    <div
                                        class="text-xs text-slate-400 ml-auto whitespace-nowrap"
                                    >
                                        01:10 PM
                                    </div>
                                </div>
                                <div
                                    class="w-full truncate text-slate-500 mt-0.5"
                                >
                                    Contrary to popular belief, Lorem Ipsum is
                                    not simply random text. It has roots in a
                                    piece of classical Latin literature from 45
                                    BC, making it over 20
                                </div>
                            </div>
                        </div>
                        <div
                            class="cursor-pointer relative flex items-center mt-5"
                        >
                            <div class="w-12 h-12 flex-none image-fit mr-1">
                                <img
                                    alt="Midone - HTML Admin Template"
                                    class="rounded-full"
                                    src="/dist/images/profile-11.jpg"
                                />
                                <div
                                    class="w-3 h-3 bg-success absolute right-0 bottom-0 rounded-full border-2 border-white"
                                ></div>
                            </div>
                            <div class="ml-2 overflow-hidden">
                                <div class="flex items-center">
                                    <a
                                        href="javascript:;"
                                        class="font-medium truncate mr-5"
                                        >Russell Crowe</a
                                    >
                                    <div
                                        class="text-xs text-slate-400 ml-auto whitespace-nowrap"
                                    >
                                        06:05 AM
                                    </div>
                                </div>
                                <div
                                    class="w-full truncate text-slate-500 mt-0.5"
                                >
                                    Contrary to popular belief, Lorem Ipsum is
                                    not simply random text. It has roots in a
                                    piece of classical Latin literature from 45
                                    BC, making it over 20
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- END: Notifications -->
            <!-- BEGIN: Account Menu -->
            <div class="intro-x dropdown w-8 h-8">
                <div
                    class="dropdown-toggle bg-white w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in scale-110"
                    role="button"
                    aria-expanded="false"
                    data-tw-toggle="dropdown"
                >
                    <img
                        alt="Midone - HTML Admin Template"
                        :src="user?.avatar"
                    />
                </div>
                <div class="dropdown-menu w-56">
                    <ul
                        class="dropdown-content bg-primary/80 before:block before:absolute before:bg-black before:inset-0 before:rounded-md before:z-[-1] text-white"
                    >
                        <li class="p-2">
                            <div class="font-medium">{{ user?.full_name }}</div>
                            <div
                                class="text-xs text-white/60 mt-0.5 dark:text-slate-500"
                            >
                                {{ user?.roles.map((x) => x.name).join(", ") }}
                            </div>
                        </li>
                        <li>
                            <hr class="dropdown-divider border-white/[0.08]" />
                        </li>
                        <li>
                            <router-link
                                :to="{ name: 'account.profile' }"
                                class="dropdown-item hover:bg-white/5"
                            >
                                <i data-lucide="user" class="w-4 h-4 mr-2"></i>
                                Mon Profil
                            </router-link>
                        </li>
                        <li>
                            <router-link
                                :to="{ name: 'account.portfolio' }"
                                class="dropdown-item hover:bg-white/5"
                            >
                                <i data-lucide="edit" class="w-4 h-4 mr-2"></i>
                                Portfolio
                            </router-link>
                        </li>

                        <li>
                            <a href="" class="dropdown-item hover:bg-white/5">
                                <i
                                    data-lucide="help-circle"
                                    class="w-4 h-4 mr-2"
                                ></i>
                                Help
                            </a>
                        </li>
                        <li>
                            <hr class="dropdown-divider border-white/[0.08]" />
                        </li>
                        <li>
                            <a
                                @click="logoutUser()"
                                href="#"
                                class="dropdown-item hover:bg-white/5"
                            >
                                <i
                                    data-lucide="toggle-right"
                                    class="w-4 h-4 mr-2"
                                ></i>
                                Logout
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Select from "@/components/Select/index.vue";
import { useStore } from "vuex";
import { computed, onMounted, ref, watch } from "vue";
import storage from "../../services/Storage";
import { useRoute } from "vue-router";

export default {
    components: { Select },
    setup(props) {
        const store = useStore();
        const route = useRoute();
        const portfolios = computed(() => store.getters["auth/portfolios"]);
        const user = computed(() => store.getters["auth/user"]);
        const selectedPortfolio = ref();
        const meta = computed(() => route?.meta);
        const handlePortfolioChange = (data) => {
            store.commit("auth/SET_PORTFOLIO", { portfolio: data });
        };

        const logoutUser = () => {
            store.dispatch("auth/logout", {
                onSuccess: () => {
                    storage.clear();
                    window.location.replace("/login");
                },
                onError: () => {},
            });
        };

        watch(
            () => portfolios.value,
            (val) => {
                if (!selectedPortfolio.value) {
                    handlePortfolioChange(val[0]);
                    selectedPortfolio.value = val[0];
                }
            }
        );

        onMounted(() => {});
        return {
            selectedPortfolio,
            portfolios,
            user,
            meta,
            logoutUser,
            handlePortfolioChange,
        };
    },
};
</script>
