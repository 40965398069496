<template>
    <GMapMap
        class="h-full"
        ref="map"
        :center="mapCenter"
        :zoom="zoom"
        :options="options"
        map-type-id="terrain"
        style="width: 100%; height: 100%"
    >
        <GMapMarker
            v-if="mapCenter"
            :position="mapCenter"
            :clickable="true"
            :draggable="true"
            @dragend="onDrag"
        />
    </GMapMap>
</template>
<script>
import { gmapStyle } from "@/Utils/map.js";
import { watch, onMounted, ref } from "vue";

export default {
    props: {
        center: Object,
        onMapUpdate: Function,
        delay: {
            type: Number,
            default: 0,
        },
        zoom: { type: Number, default: 12 },
        locate: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const mapCenter = ref(
            props.center.lat ? props.center : { lat: 45.508, lng: -73.587 }
        );
        const options = ref({ styles: gmapStyle, disableDefaultUI: true });
        const map = ref(null);

        const onDrag = (evt) => {
            emit("onMapUpdate", map.value, {
                lat: evt.latLng.lat(),
                lng: evt.latLng.lng(),
            });
            const cameraOptions = {
                tilt: 0,
                heading: 0,
                zoom: props.zoom,
                center: { lat: evt.latLng.lat(), lng: evt.latLng.lng() },
            };
            map.value?.panTo(cameraOptions.center);
        };

        const geolocateUser = () => {
            navigator.geolocation.getCurrentPosition((position) => {
                mapCenter.value = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };
                emit("onMapUpdate", map.value, {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                });
                map.value?.panTo(mapCenter.value);
            });
        };

        watch(
            () => props.center,
            (center) => {
                if (center)
                    mapCenter.value = { lat: center.lat, lng: center.lng };
            }
        );

        watch(
            () => props.locate,
            (center) => {
                if (center) geolocateUser();
            }
        );

        onMounted(() => {
            if (props.center.lat) mapCenter.value = props.center;
            if (props.locate) geolocateUser();
        });
        return { options, map, mapCenter, onDrag };
    },
};
</script>
<style lang="scss">
.vue-map-container {
    height: 100% !important;
}
</style>
