<template>
    <div class="flex mt-6 mb-20 w-full new-post">
        <div class="w-9/12">
            <form
                novalidate
                autocomplete="false"
                @submit.prevent="savePost"
                class=""
                style="width: 97%"
            >
                <div class="w-full bg-white rounded px-6 py-4">
                    <div class="mb-5">
                        <h1 class="text-primary font-bold text-lg">
                            Nouveau post
                        </h1>
                    </div>

                    <div class="mb-3">
                        <label
                            class="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-1"
                            for="grid-zip"
                        >
                            Titre
                        </label>
                        <div class="h-10">
                            <Field
                                name="title"
                                v-slot="{ field }"
                                v-model="form.title"
                            >
                                <input
                                    v-bind="field"
                                    name="title"
                                    v-model="form.title"
                                    class="bg-white h-10 rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                    type="text"
                                    placeholder="Titre de votre article"
                            /></Field>
                        </div>
                    </div>

                    <div class="w-full flex">
                        <div class="w-1/3">
                            <label
                                class="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-1"
                                for="category"
                            >
                                Catégorie
                            </label>
                            <div class="flex mb-2">
                                <Field
                                    name="category"
                                    v-slot="{ field }"
                                    v-model="form.category"
                                >
                                    <Select
                                        v-bind="field"
                                        name="category"
                                        v-model="form.category"
                                        :options="categories"
                                        @changed="
                                            (value) => (form.category = value)
                                        "
                                    />
                                </Field>
                            </div>
                        </div>

                        <div class="w-1/3 pl-2 pr-2">
                            <label
                                class="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-1"
                                for="grid-state"
                            >
                                Date de publication
                            </label>
                            <div style="height: 38px">
                                <Field
                                    v-slot="{ field }"
                                    name="published_at"
                                    v-model="form.published_at"
                                >
                                    <Datepicker
                                        class="h-full"
                                        v-bind="field"
                                        v-model="form.published_at"
                                        id="excludes"
                                        :min-date="form.published_at"
                                        placeholder="Date"
                                    />
                                </Field>
                            </div>
                        </div>
                    </div>

                    <div class="mb-3 h-30">
                        <label
                            class="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-1"
                            for="grid-zip"
                        >
                            Résumé de votre article
                        </label>
                        <div class="w-full">
                            <Field
                                v-slot="{ field }"
                                name="summary"
                                v-model="form.summary"
                            >
                                <textarea
                                    v-bind="field"
                                    v-model="form.summary"
                                    rows="5"
                                    class="resize-none w-full bg-white rounded border border-slate-300 w-full px-2 focus:border-slate-400 focus:ring-transparent focus:outline-offset-0 focus:shadow-none transition transition-all"
                                />
                            </Field>
                        </div>
                    </div>

                    <div class="">
                        <label
                            class="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-1"
                            for="grid-zip"
                        >
                            Contenu de votre article
                        </label>
                        <div class="w-full">
                            <MdEditor
                                v-model="form.content"
                                language="en-US"
                                :htmlPreview="false"
                                previewTheme="cyanosis"
                                :toolbarsExclude="[
                                    'github',
                                    'catalog',
                                    'htmlPreview',
                                ]"
                                @onUploadImg="handleUpload"
                                @onChange="handleContent"
                            />
                        </div>
                    </div>
                </div>

                <div class="mt-5 flex justify-end">
                    <button
                        type="button"
                        @click="
                            $router.push({
                                name: 'blog.posts',
                            })
                        "
                        class="border-none rounded bg-danger bg-opacity-25 hover:bg-opacity-50 mx-3 py-2 px-3 font-semibold text-danger"
                    >
                        Annuler
                    </button>
                    <button
                        :disabled="!meta.valid"
                        type="submit"
                        class="border-none rounded hover:bg-opacity-50 bg-opacity-25 bg-success text-success font-semibold px-3 py-2"
                    >
                        Enrégistrer
                    </button>
                </div>
            </form>
        </div>
        <div class="w-3/12">
            <div class="w-full p-4 bg-white rounded px-6 py-4 mt-5">
                <div class="mb-4">
                    <h1 class="text-md font-semibold text-slate-400">
                        Informations complémentaires
                    </h1>
                </div>

                <div class="w-full">
                    <label
                        class="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-1"
                        for="category"
                    >
                        Tags
                    </label>
                    <div class="flex mb-2 w-full">
                        <Field
                            name="tags"
                            v-slot="{ field }"
                            v-model="form.tags"
                        >
                            <Select
                                class="w-full"
                                v-bind="field"
                                name="tags"
                                v-model="form.tags"
                                :multiple="true"
                                :options="tags"
                                @changed="(value) => (form.tags = value)"
                            />
                        </Field>
                    </div>
                </div>

                <div class="mt-5 mb-3">
                    <span
                        v-for="(t, i) in form.tags"
                        :key="i"
                        :style="`background: ${t.color};color-te:  ${t.color}`"
                        :class="`bg-opacity-25 px-3 py-2 font-medium rounded-full mr-2`"
                        >{{ t.name }}</span
                    >
                </div>

                <div class="flex flex-col">
                    <div class="mb-3 w-full">
                        <label
                            class="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-1"
                            for="signature"
                        >
                            Signature
                        </label>
                        <Field
                            name="signature"
                            v-slot="{ field }"
                            v-model="form.signature"
                        >
                            <input
                                id="signature"
                                v-bind="field"
                                name="signature"
                                v-model="form.signature"
                                class="bg-white h-10 rounded border border-slate-300 w-full px-2 focus:border-slate-400 transition transition-all"
                                type="text"
                                placeholder="Votre signature du post"
                        /></Field>
                    </div>

                    <div class="mb-3 w-full">
                        <label
                            class="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-1"
                            for="signature_line_2"
                        >
                            Poste (2ème ligne)
                        </label>
                        <Field
                            name="signature_line_2"
                            v-slot="{ field }"
                            v-model="form.signature_line_2"
                        >
                            <input
                                id="signature_line_2"
                                v-bind="field"
                                name="signature_line_2"
                                v-model="form.signature_line_2"
                                class="bg-white h-10 rounded border border-slate-300 w-full px-2 focus:border-slate-400 transition transition-all"
                                type="text"
                                placeholder="Ex: Journaliste à la Gazette Info Abidjan"
                        /></Field>
                    </div>
                </div>
            </div>

            <div class="w-full p-4 bg-white rounded px-6 py-4 mt-5">
                <div class="">
                    <h1 class="text-md font-semibold text-slate-500">
                        Image de couverture
                    </h1>

                    <div class="relative mt-3 h-20">
                        <div
                            class="flex justify-center text-slate-200"
                            v-if="isSavingImage"
                        >
                            <Loader class="h-10 w-10"></Loader>
                        </div>
                    </div>
                </div>

                <div class="mb-5 mt-2">
                    <div v-bind="getRootProps()">
                        <input v-bind="getInputProps()" />

                        <div v-if="savedMedia" class="w-full">
                            <img
                                :src="`/cdn/${savedMedia?.path}`"
                                alt=""
                                class="h-50"
                                srcset=""
                            />
                        </div>
                        <p v-if="isDragActive" class="text-sm">
                            Déposer votre image de couverture ici...
                        </p>
                        <p v-else class="text-warning text-sm">
                            Déposer votre image de couverture ici, ou cliquer
                            pour sélectionner
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Select from "@/components/Select/index.vue";
import { useDropzone } from "vue3-dropzone";
import { MdEditor } from "md-editor-v3";
import { Field, useForm } from "vee-validate";
import "md-editor-v3/lib/style.css";
import { ref, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { computed } from "vue";
import Loader from "@/components/Loader/index.vue";
import Datepicker from "@/components/Datepicker/index.vue";
import * as yup from "yup";
import { useRouter } from "vue-router";
import { TYPE } from "vue-toastification";

export default {
    components: {
        MdEditor,
        Field,
        Select,
        Datepicker,
        Loader,
    },
    setup() {
        const store = useStore();
        const router = useRouter();
        const user = computed(() => store.getters["auth/user"]);
        const isSavingImage = ref(false);
        const form = ref({
            content: "",
            title: "",
            category: null,
            tags: [],
            published_at: new Date(),
            signature_line_2: "",
            signature: `${user.value?.full_name ?? ""}`,
        });

        const schema = computed(() =>
            yup.object({
                title: yup.string().required(),
                category: yup.object({ id: yup.string() }).required(),
                published_at: yup.string().required(),
                summary: yup.string().required("summaryRequired"),
                content: yup.string().required("bodyRequired"),
                signature: yup.string().required("signatureRequired"),
            })
        );

        const { errors, values, meta, setFieldValue, resetForm } = useForm({
            validationSchema: schema.value,
            validateOnMount: true,
        });

        const categories = ref([]);
        const tags = ref([]);
        const imageUrl = ref("");
        const savedMedia = ref();

        const onDrop = (acceptFiles, rejectReasons) => {
            if (acceptFiles.length == 0) return;

            isSavingImage.value = true;

            storeImage(acceptFiles[0], (media) => {
                if (media) {
                    savedMedia.value = media;
                    form.value.thumbnail_id = media.id;
                }

                isSavingImage.value = false;
            });
        };

        const storeImage = (file, callback) => {
            const formData = new FormData();
            formData.append("media", file);
            store.dispatch("blog/storeMedia", {
                data: formData,
                onSuccess: ({ media }) => {
                    callback(media);
                },
                onError: () => {},
            });
        };

        const { getRootProps, getInputProps, ...rest } = useDropzone({
            maxFiles: 1,
            multiple: false,
            accept: "image/*",
            onDrop,
        });

        const handleContent = (data) => {
            setFieldValue("content", data);

            console.log(values);
        };

        const handleUpload = async (files, callback) => {
            const res = await Promise.all(
                files.map(async (x) =>
                    storeImage(x, (media) => {
                        callback([`${media.url}`]);
                    })
                )
            );
        };

        const getCategories = () => {
            store.dispatch("blog/getCategories", {
                onSuccess: (response) => {
                    console.log(response);
                    categories.value = [...response];
                },
                onError: () => {},
            });
        };

        const getTags = () => {
            store.dispatch("blog/getTags", {
                onSuccess: (response) => {
                    tags.value = [...response];
                },
                onError: () => {},
            });
        };

        const savePost = () => {
            const data = {
                category_id: values.category.id,
                title: values.title,
                thumbnail_id: form.value.thumbnail_id,
                published_at: values.published_at,
                content: values.content,
                summary: values.summary,
                signature: values.signature,
                signature_job_title: values.signature_line_2 ?? "",
                tags_id: form.value.tags.map((x) => x.id),
            };

            store.dispatch("blog/savePost", {
                data,
                onSuccess: () => {
                    store.dispatch("medical/displayToast", {
                        message: "Votre article a été crée avec succès.",
                        context: "Blog",
                        type: TYPE.SUCCESS,
                    });

                    resetForm();

                    form.value = {
                        content: "",
                        title: "",
                        category: null,
                        tags: [],
                        published_at: new Date(),
                        signature_line_2: "",
                        signature: `${user.value?.full_name ?? ""}`,
                    };

                    router.push({ name: "blog.posts" });
                },
                onError: () => {
                    store.dispatch("medical/displayToast", {
                        message: "Oooops, une erreur est survenue.",
                        context: "Blog",
                        type: TYPE.ERROR,
                    });
                },
            });
        };

        watch(
            () => user.value,
            () => {
                form.value.signature = user.value?.full_name ?? "";
            }
        );

        onMounted(() => {
            getCategories();
            getTags();
        });

        return {
            form,
            errors,
            values,
            meta,
            tags,
            imageUrl,
            categories,
            isSavingImage,
            savedMedia,
            handleContent,
            savePost,
            setFieldValue,
            getRootProps,
            getInputProps,
            ...rest,
            handleUpload,
        };
    },
};
</script>

<style lang="scss" scoped>
.new-post {
    textarea {
        &:focus {
            border: none !important;
            border-width: 1px;
        }
    }
}
</style>
