<template>
    <div class="w-full bg-white my-6">
        <div class="w-full flex justify-start mb-3">
            <h2 class="text text-black font-semibold text-sm p-3">
                Renseignez les informations du centre de santé
            </h2>
        </div>
        <div class="flex pb-5">
            <div class="w-2/5">
                <div class=""></div>

                <form>
                    <div class="w-full px-3">
                        <label
                            class="block capitalize tracking-wide text-left text-xs mb-1"
                            for="grid-state"
                        >
                            Type d'alert (Problème)
                        </label>
                        <div class="relative h-10">
                            <Field
                                v-slot="{ field }"
                                v-model="newAlert.type"
                                name="type"
                            >
                                <Select
                                    v-bind="field"
                                    name="type"
                                    v-model="newAlert.type"
                                    :options="types"
                                >
                                </Select>
                            </Field>
                        </div>
                    </div>

                    <div class="w-full px-3 mt-2">
                        <label
                            class="block capitalize tracking-wide text-left text-xs mb-1"
                            for="grid-state"
                        >
                            Patient
                        </label>
                        <div class="relative">
                            <Field
                                v-slot="{ field }"
                                v-model="newAlert.patient"
                                name="patient"
                            >
                                <Select
                                    v-bind="field"
                                    name="patient"
                                    v-model="newAlert.patient"
                                    :options="
                                        patients.map((x) => ({
                                            ...x,
                                            name: x.full_name,
                                        }))
                                    "
                                >
                                </Select>
                            </Field>
                        </div>
                    </div>

                    <div class="w-full md:mb-0 px-3 w-full mt-2">
                        <label
                            class="block capitalize tracking-wide text-left text-xs mb-1"
                            for="grid-first-name-1"
                            >Adresse</label
                        >
                        <div class="h-10">
                            <input
                                v-model="newAlert.address"
                                name="address"
                                disabled
                                class="bg-slate-100 rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                type="text"
                                placeholder="L'adresse"
                            />
                        </div>
                        <!-- <div class="text-danger" v-if="errors.has('name')">
              <span>{{ $t("validator.address.required") }}</span>
            </div> -->
                    </div>

                    <div class="mb-2 w-full px-3 mt-2">
                        <label
                            class="block capitalize tracking-wide text-left text-xs mb-1"
                            for="grid-state"
                        >
                            Description
                        </label>
                        <div class="h-20">
                            <textarea
                                v-model="newAlert.description"
                                name="description"
                                no-resize
                                id="exampleFormControlTextarea1"
                                class="resize-none bg-white rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                rows="5"
                                cols="5"
                            ></textarea>
                        </div>
                    </div>

                    <div class="flex justify-end mt-2 mb-1 pt-2 pb-1 px-3">
                        <button
                            @click.prevent="
                                $router.push({ name: 'app.alerts' })
                            "
                            class="border rounded border-danger text-danger mr-2 p-2"
                            variant="danger"
                        >
                            Quitter
                        </button>
                        <button
                            @click.prevent="createAlert()"
                            type="submit"
                            :disabled="
                                newAlert.type == '' ||
                                newAlert.patientId == '' ||
                                newAlert.latitude == 0 ||
                                newAlert.longitude == 0
                            "
                            class="border-none rounded hover:bg-opacity-50 bg-primary text-primary bg-opacity-25 px-3 py-1"
                        >
                            Enrégistrer
                        </button>
                    </div>
                </form>
            </div>
            <div class="w-3/5 pr-3 pb-3">
                <gmap
                    class="w-full h-full"
                    :center="center"
                    @onMapUpdate="updateMap"
                />
            </div>
        </div>
    </div>
</template>
<script>
import HereMap from "@/components/maps/draggablemap";
import gmap from "@/components/maps/gmap";
import store from "@/store";
import { mapCenter, hereMapApiKey } from "@/services/config";
import { mapGetters, mapActions, useStore } from "vuex";
import moment from "moment";
import Select from "../../components/Select/index.vue";
import { ref, computed } from "vue";
import { Field } from "vee-validate";
import { useRouter } from "vue-router";

export default {
    components: { HereMap, gmap, Select, Field },
    beforeRouteEnter(to, from, next) {
        store.dispatch("medical/getPatients", {}).then((res) => next());
    },
    setup() {
        const store = useStore();
        const router = useRouter();
        const portfolio = computed(() => store.getters["auth/portfolio"]);

        const types = ref([
            {
                id: "heart-attack",
                name: "Attaque cardiaque",
            },
            {
                id: "accident",
                name: "Accident",
            },
            { id: "hypertension", name: "Hypertension" },
            { id: "pregnant", name: "Grossesse" },
        ]);
        const newAlert = ref({
            description: "",
            patient: null,
            latitude: 0,
            longitude: 0,
            address: "",
            type: null,
        });

        const createAlert = () => {
            const formData = {
                type: newAlert.value.type?.id,
                patientId: newAlert.value.patient?.id,
                longitude: newAlert.value.longitude,
                latitude: newAlert.value.latitude,
                address: newAlert.value.address,
                description: newAlert.value.description,
                requested_at: new Date(),
                protfolio_id: portfolio.value?.id,
            };
            store.dispatch("medical/postAlert", {
                form: formData,
                onSuccess: () => {
                    store.dispatch("medical/displayToast", {
                        message: "Une alerte a bien été créée.",
                        context: "",
                        type: "TYPE.SUCCESS",
                    });

                    router.push({ name: "app.alerts" });
                },
                onError: () => {},
            });
        };
        return { types, newAlert, createAlert };
    },
    data() {
        return {
            platform: null,
            apiKey: hereMapApiKey,
            center: mapCenter,
            descriptions: {
                accident:
                    "Je viens d'avoir un accident.J'attend votre intervention.\n",
                "heart-attack": "J'ai une crise cardique. \n",
                hypertension: "J'ai un crise de tension. \n",
                pregnant:
                    "Je suis enceinte et j'ai besoin d'une intervention urgente.\n",
            },
        };
    },
    watch: {
        "newAlert.type": function (val) {
            if (val) this.newAlert.description = this.descriptions[val.id];
        },
    },
    methods: {
        ...mapActions({
            postAlert: "medical/postAlert",
        }),

        reverseGeocodePosition(lat, lng) {
            var service = this.platform.getSearchService();
            let self = this;
            service.reverseGeocode(
                {
                    at: `${lat},${lng},150`,
                },
                (result) => {
                    const { position, address } = result.items[0];
                    this.newAlert.address = address.label;
                },
                (err) => {
                    console.error("Error: [reverseGeocodePosition] => ", err);
                }
            );
        },
        updateMap(data, position) {
            console.log(position);
            this.newAlert.longitude = position.lng;
            this.newAlert.latitude = position.lat;
            if (position && this.platform) {
                this.reverseGeocodePosition(position.lat, position.lng);
            }
        },
    },
    computed: {
        ...mapGetters({
            patients: "medical/patients",
        }),
    },
    mounted() {
        this.platform = new H.service.Platform({
            apikey: this.apiKey,
            useHTTPS: false,
        });
    },
};
</script>
