import getters from './gettter'
import actions from './actions'
import mutations from './mutations'
const state = {
    medicalCenters: [],
    medicalCenter: null
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
