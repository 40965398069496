export default {
    patients: state => state.patients,
    doctors: state => state.doctors,
    alerts: state => state.alerts,
    alert: state => state.alert,
    hospitals: state => state.hospitals,
    medicalCenter: state => state.medicalCenter,
    hospitalsPagination: state => state.hospitalsPagination,
    interventions: state => state.interventions,
    patient: state => state.patient,
    patientsPagination: state => state.patientsPagination,
    prescriptions: state => state.prescriptions
}
